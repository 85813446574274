import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getLocations, getOrganizationLevel } from "../../../redux/userActions";

export default function AssetLocationForm({ to, setTo, t }) {
  // Data Fetch
  const [levelsList, setLevelsList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const resultLevel = await getOrganizationLevel();
      setLevelsList(resultLevel?.data);
    }
    fetchData();
  }, []);

  // Local States
  const [orgLevel, setOrgLevel] = useState();
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    async function fetchData(level) {
      if (level) {
        const res = await getLocations(level);
        setLocationList(res?.data);
      }
    }
    fetchData(orgLevel);
  }, [orgLevel]);

  return (
    <section className="AddShipment__form">
      <div className="ModalPopup__form_container">
        <div className="ModalPopup__form_section">
          <div className="Input__row two_column">
            <div className="Input__column">
              <p className="Input__label_fs">{t("org_level")}</p>
              <FormControl fullWidth>
                <Select
                  size="small"
                  className="mui_custom_input_field"
                  onChange={(e) => {
                    setOrgLevel(e.target.value);
                    setTo();
                  }}
                >
                  {levelsList?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>
                      {item?.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="Input__column">
              <p className="Input__label_fs">{t("to") + " " + t("location")}</p>
              <FormControl fullWidth>
                <Select
                  size="small"
                  className="mui_custom_input_field"
                  value={to}
                  onChange={(e) => {
                    setTo(e.target.value);
                  }}
                >
                  {locationList?.map((item, index) => (
                    <MenuItem key={index} value={item?._id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
