import { useEffect, useState } from "react";
import MainPAR from "./mainPAR/MainPAR";

import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import { getProducts, getCountries } from "../../../redux/userActions";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import toast from "react-hot-toast";
import { getInventories } from "../../../redux/inventoryActions";
import PARform1 from "./formPAR/PARform1";
import PARform2 from "./formPAR/PARform2";
import PARform3 from "./formPAR/PARform3";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductReport from "/icons/all/Product Report.svg";

export default function CreatePAR() {
  const { t } = useTranslation();
  const params = useLocation();
  const navigate = useNavigate();
  const EditPARData = params?.state?.value;

  const reportNum =
    "VAR-BANA-" +
    new Date().getFullYear() +
    (new Date().getMonth() + 1) +
    (new Date().getDate() + 1) +
    "-" +
    new Date().getHours() +
    new Date().getMinutes() +
    "-" +
    new Date().getSeconds();

  let [searchParams, setSearchParams] = useSearchParams();

  const formTab = searchParams.get("form");

  const [formComplete, setFormComplete] = useState(1);
  const [products, setProducts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [mainForm, setMainForm] = useState(false);

  const [error, setError] = useState(
    "Please fill the Product Info's at the top",
  );

  const [finalData, setFinalData] = useState(EditPARData || {});

  useEffect(() => {
    async function fetchData() {
      const res = await getProducts("NOT_VACCINE");
      setProducts(res.data);
      const re = await getCountries();
      setCountries(re.data);
    }
    fetchData();
  }, []);

  let CurrentCountry;

  if (finalData?.country) {
    CurrentCountry = countries?.filter(
      (country) => country?.name === finalData?.country,
    );
  }

  const [storageInfo, setStorageInfo] = useState();

  // Getting Storage Info
  useEffect(() => {
    const fetchData = async () => {
      const data = await getInventories();
      setStorageInfo(data?.data);
    };
    fetchData();
  }, []);

  const setData = (form, data) => {
    const saveData = { ...finalData };

    if (form == 0) {
      saveData.inventoryId = storageInfo?.[0]._id;
      saveData.product = data.product;
      saveData.country = data.country;
      saveData.reportNo = reportNum;
      saveData.reportDate = data.reportDate;
      saveData.date_of_invoice = data.date_of_invoice;
      saveData.date_of_shipment = data.date_of_shipment;
      saveData.time_of_shipment = data.time_of_shipment;
      saveData.placeOfInspection = data.place_of_inspection;
      setError("");
    } else if (form == 1) {
      const prodsInv = [];

      for (let i = 0; i < data?.vaccineDetails?.length; i++) {
        prodsInv.push({
          productId: saveData?.product,
          manufacturerId: data?.manufacture, // manufacturerId
          quantityPerUnit: data?.quantityPerUnit,
          mfgDate: data?.vaccineDetails[i].mfgDate,
          expDate: data?.vaccineDetails[i].expDate,
          batchNo: data?.vaccineDetails[i].batchNo,
          noOfUnits: parseInt(data?.vaccineDetails[i].boxes),
        });
      }

      saveData.shipmentDetails = {
        poNo: data.po,
        consignee: data.consignee,
        originCountry: data?.Country,
        vaccineType: data.description,
        shortShipmentNotification: data.Question1,
        shortShipment: data.Question2,
        comments: data.comment,
        products: prodsInv,
      };

      saveData.shippingIndicators = {
        invoiceAmount: data.invoice_amount,
      };
      saveData.productInfo = data.productInfo;
    } else if (form == 2) {
      saveData.documents = data;
    } else if (form == 3) {
      saveData.signatures = data;
    } else if (form == 4) {
      if (
        finalData?.signatures?.inspectionSupervisor?.date &&
        finalData?.signatures?.receivedDate &&
        finalData?.signatures?.contact &&
        finalData?.signatures?.epiManager?.date
      ) {
        navigate("/user/arrival-report/product/review", {
          state: { value: saveData },
        });
        setMainForm(false);
        toast.success(t("form_completed_msg"));
      } else {
        toast.error(t("save_form_msg"));
      }
    }
    setFinalData(saveData);
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img src={ProductReport} className='Sidebar__icon' />
          <h1 className='Page__headline_title_fs'>
            {t("product_arrival_report")}
          </h1>
        </div>
        <BreadCrumb
          url1='/user/arrival-report/product'
          Link1={t("product_arrival_report")}
          Link2={t("create_PAR")}
        />
      </div>
      <div className='Page__report_wrapper'>
        <MainPAR
          products={products}
          countries={countries}
          setMainFormData={setData}
          storedData={finalData}
          reportNum={reportNum}
          setMainForm={setMainForm}
        />
      </div>
      {formTab && (
        <div className='VaccineArrivalReport__timeline_wrapper'>
          <div className='ProductArrivalReport__timeline'>
            <div
              className={`TimelineCard ${formComplete > 1 && "done"}`}
              onClick={() => {
                navigate("?form=part1");
              }}
            >
              <TimeDot
                tooltip={t("PAR_part1_title")}
                status={
                  formTab === "part1"
                    ? "progress"
                    : formComplete > 1
                    ? "done"
                    : ""
                }
              />
            </div>
            <div
              className={`TimelineCard ${formComplete > 2 && "done"}`}
              onClick={() => {
                if (formComplete > 1) {
                  navigate("?form=part2");
                }
              }}
            >
              <TimeDot
                tooltip={t("PAR_part2_title")}
                status={
                  formTab === "part2"
                    ? "progress"
                    : formComplete > 2
                    ? "done"
                    : ""
                }
              />
            </div>

            <div
              className={`TimelineCard VAR_Complete ${
                formComplete > 3 && "done"
              }`}
              onClick={() => {
                if (formComplete > 3) {
                  navigate("?form=part3");
                }
              }}
            >
              <TimeDot
                tooltip={t("PAR_part3_title")}
                status={
                  formTab === "part3"
                    ? "progress"
                    : formComplete > 3
                    ? "done"
                    : ""
                }
              />
              <div className='VAR_Complete__icon'>
                <Tooltip title={t("review_form")} arrow>
                  <span>
                    <i className='fa-solid fa-clipboard-check mi_white'></i>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='VaccineArrivalReport__forms'>
        {formTab === "part1" && (
          <PARform1
            setFormComplete={setFormComplete}
            setData={setData}
            countries={countries}
            storedData={finalData}
            products={products}
            mainForm={mainForm}
          />
        )}
        {formTab === "part2" && (
          <PARform2
            storedData={finalData}
            setData={setData}
            setFormComplete={setFormComplete}
            products={products}
            reportNo={finalData.reportNo}
          />
        )}
        {formTab === "part3" && (
          <PARform3
            storedData={finalData}
            setData={setData}
            setFormComplete={setFormComplete}
            products={products}
          />
        )}
      </div>
    </section>
  );
}

function TimeDot({ status, tooltip }) {
  return (
    <Tooltip title={tooltip} arrow>
      <div className={`TimeDot ${status && status}`}></div>
    </Tooltip>
  );
}
