import React, { useState, useEffect } from "react";
import { Select, FormControl, MenuItem } from "@mui/material";
import FetchBatchLastMile from "./fetchBatch/FetchBatchLastMile";
import { currentInventoryStock } from "../../../redux/inventoryActions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function VaccineDetails({ formik, setVacList, reOpened }) {
  const { t } = useTranslation();
  const [vaccineList, setVaccineList] = React.useState();
  const [openBatchPopup, setOpenBatchPopup] = useState(false);

  const location = useLocation()

  useEffect(() => {
    async function fetchData() {
      const res = await currentInventoryStock("VACCINE", 0, 0);
      const vaxins = res?.data?.data;
      const invStock = vaxins.map((prod) => prod.product);
      const vaccines = invStock.filter((vac) => vac.type === "VACCINE");
      setVaccineList(vaccines);
      setVacList(vaccines);
    }
    fetchData();
  }, [setVacList]);

  const fetchBatch = () => {
    setOpenBatchPopup(true);
  };

  const handleCloseBatchPopup = () => {
    setOpenBatchPopup(false);
  };

  return (
    <React.Fragment>
      <div className="Benef_container">
        <div className="beneficiary__section_title">
          {location.pathname.includes("beneficiary") && 
          <p className="Benef_title">{t("vaccine_details")}</p>}

          {location.pathname.includes("beneficiary") &&  formik?.values?.batchNo?.count && (
            <p className="Benef_note_title">
              {formik?.values?.batchNo?.count} {t("vaccine_details_msg")}
            </p>
          )}
        </div>
        <div className="ModalPopup__form_section">
          <div className="Input__table">
            <div className="Input__row two_column Benef_table">
              <div className="Input__column">
                <p className="Form__heading_fs">
                  {t("vaccine_name")} <b>*</b>
                </p>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    id="vaccine"
                    name="vaccine"
                    className="mui_custom_input_field"
                    onChange={formik.handleChange}
                    value={formik.values.vaccine}
                    disabled={
                      formik?.values?.beneficiaryDetails?.length > 0 || reOpened
                    }
                  >
                    {vaccineList?.map((vaccine, index) => (
                      <MenuItem key={index} value={vaccine._id}>
                        {vaccine.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.errors.vaccine && formik.touched.vaccine && (
                  <span className="Input__error_fs">
                    {formik.errors.vaccine}
                  </span>
                )}
              </div>
              <div className="Input__row two_column">
                <div className="Input__column">
                  <p className="Form__heading_fs">
                    {t("batch_number")} <b>*</b>
                  </p>
                  <input
                    type="text"
                    className="mi_custom_textfield date_field"
                    id="batchNo"
                    name="batchNo"
                    onChange={formik.handleChange}
                    value={formik.values.batchNo.name}
                    disabled
                    style={{ opacity: "0.5" }}
                  />
                  {formik.errors.batchNo && formik.touched.batchNo && (
                    <span className="Input__error_fs">
                      {formik.errors.batchNo}
                    </span>
                  )}
                </div>
                {!reOpened &&
                  formik?.values?.beneficiaryDetails?.length <= 0 && (
                    <div
                      className="Input__row "
                      style={{ marginTop: "1.4rem" }}
                    >
                      <button
                        onClick={fetchBatch}
                        type="button"
                        className="mi_btn mi_btn_medium mi_btn_secondary"
                        style={{ height: "2.5rem" }}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          {t("fetch_batch")}
                        </span>
                      </button>
                    </div>
                  )}

                  {location.pathname.includes("recall-inventory") && (
                    <div
                     className="Input__row "
                     style={{ marginTop: "1.4rem" }}
                    >
                    <button
                      onClick={fetchBatch}
                      type="button"
                      className="mi_btn mi_btn_medium mi_btn_secondary"
                      style={{ height: "2.5rem" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("fetch_batch")}
                      </span>
                    </button>
                   </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FetchBatchLastMile
        keepMounted
        formik={formik}
        open={openBatchPopup}
        onClose={handleCloseBatchPopup}
        t={t}
      />
    </React.Fragment>
  );
}
