import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';
import { capitalToNormalCase } from '../../../../shared/utils/utils';
import { ageCalculate } from '../../../../shared/common/commonFunctions';
import { timeAgo } from '../../../../shared/utils/utils';

export default function InvExpired({
  inventory,
  filteredColumns,
  totalRecords,
  page,
  setPage,
  limit,
  setLimit,
  tableRef,
  typePage,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }} ref={tableRef}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {filteredColumns.map((column) => (
              <TableCell key={column.key}>
                <FieldHead
                  title={t(column.title)}
                  align={column.key === 'action' ? 'center' : 'start'}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body'>
          {inventory && inventory.length > 0 ? (
            inventory.map((row) => (
              <InvSummaryRow
                key={row._id}
                row={row}
                filteredColumns={filteredColumns}
                t={t}
                typePage={typePage}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: '2rem' }} colSpan={7}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function InvSummaryRow({ row, filteredColumns, t, typePage }) {
  const navigate = useNavigate();

  const handleReOrder = () => {
    navigate('/user/orders/review-orders', {
      state: {
        products: [
          {
            productId: row?.productId?._id,
            name: row?.productId?.name,
            units: row?.productId?.units,
            type: row?.productId?.type,
            quantity: row?.quantity,
            mfgDate: row?.productId?.createdAt, // Replace with the actual manufacturing date if available
            expDate: '', // Replace with the actual expiration date if available
            fromPage: 'ReOrder',
          },
        ],
      },
    });
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {filteredColumns.map((column) => (
        <TableCell key={column.key}>
          {column.key === 'productCategory' && (
            <FieldBody text={capitalToNormalCase(row?.productId?.type)} />
          )}
          {column.key === 'productName' && (
            <>
              <ProductIcon
                type={row?.productId?.type}
                name={row?.productId?.name}
                size='tiny'
                fallback={
                  <FieldBody text={row?.productId?.name} color='bold' />
                }
              />
            </>
          )}
          {column.key === 'productId' && (
            <FieldBody text={row?.productId?.id} color='bold' />
          )}
          {column.key === 'manufacturer' && (
            <FieldBody text={row?.manufacturer?.name} />
          )}
          {column.key === 'quantity' && (
            <FieldBody text={`${row?.quantity} ${row?.productId?.units}`} />
          )}
          {column.key === 'batchNo' && <FieldBody text={row?.batchNo} />}
          {column.key === 'expired' && (
            <FieldBody text={timeAgo(row?.expDate)} />
          )}
          {column.key === 'action' && (
            <>
              {typePage !== 'dataVisualization' ? (
                <TableCell>
                  <div className='Table__actions'>
                    <button
                      onClick={handleReOrder}
                      className='mi_btn mi_btn_medium mi_btn_variant_green'
                    >
                      <span>{t('reorder')}</span>
                    </button>
                  </div>
                </TableCell>
              ) : (
                <TableCell align='center'>---</TableCell>
              )}
            </>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}
