import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { Checkbox } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";
import toast from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function BatchesTable({
  batchList,
  batch,
  page,
  limit,
  handleChangePage,
  handleChangeRowsPerPage,
  productData,
  setProductData,
  vvmUpdateData,
  setVvmUpdateData,
  category,
  t,
}) {
  const sorted = batch?.sort(
    (a, b) => new Date(a.expDate) - new Date(b.expDate)
  );
  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className="mi_table__head">
            <TableRow>
              <TableCell>
                <FieldHead title={t("batch_no")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("mfg_date")} />
              </TableCell>

              <TableCell>
                <FieldHead title={t("exp_date")} />
              </TableCell>

              <TableCell align="center">
                <FieldHead title={t("vvm_status")} align={"center"} />
              </TableCell>

              <TableCell align="center">
                <FieldHead title={t("updated_vvm_status")} align={"center"} />
              </TableCell>

              <TableCell align="center">
                <FieldHead title={t("temperature")} align={"center"} />
              </TableCell>

              <TableCell align="center">
                <FieldHead title={t("serial_no")} align={"center"} />
              </TableCell>

              <TableCell align="center">
                <FieldHead title={t("inspected_date_time")} align={"center"} />
              </TableCell>

              <TableCell align="center">
                <FieldHead title={t("action")} align={"center"} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mi_table__body">
            {sorted?.map((row, index) => (
              <SelectBatchRow
                key={row._id}
                row={row}
                t={t}
                productData={productData}
                setProductData={setProductData}
                vvmUpdateData={vvmUpdateData}
                setVvmUpdateData={setVvmUpdateData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={batchList?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}

function SelectBatchRow({
  row,
  t,
  setProductData,
  productData,
  vvmUpdateData,
  setVvmUpdateData,
}) {
  const [vvmStatus, setVvmStatus] = React.useState("");
  const [temperature, setTemperature] = React.useState();
  const [serialNo, setSerialNo] = React.useState();
  const [time, setTime] = React.useState(dayjs());

  const [isAdded, setIsAdded] = React.useState(false);

  const [data, setData] = React.useState();
  const [filteredOptions, setFilteredOptions] = React.useState();

  const vvmOptions = [
    { id: 1, name: "1 - Usable", value: 1 },
    { id: 2, name: "2 - Usable", value: 2 },
    { id: 3, name: "3 - Non Usable", value: 3 },
    { id: 4, name: "4 - Non Usable", value: 4 },
  ];

  React.useEffect(() => {
    const filt = vvmOptions?.filter((opt) => opt.id != row?.vvmStatus);
    setFilteredOptions(filt);
  }, [row?.vvmStatus]);

  useEffect(() => {
    if (vvmUpdateData?.length) {
      const vvmData = vvmUpdateData?.find((item) => item?.atomId === row?._id);
      if (vvmData) {
        setTemperature(vvmData?.temperature);
        setVvmStatus(vvmData?.vvmStatus);
        setSerialNo(vvmData?.serialNo);
        setTime(vvmData?.timestamp);
        setData(vvmData);
      }
    }
  }, [vvmUpdateData]);

  const handleUpdate = () => {
    let dat = [];
    const data = {
      atomId: row?._id,
      temperature: temperature,
      vvmStatus: vvmStatus,
      serialNo: serialNo,
      timestamp: time,
      productId: row?.productId,
      currentVvmStatus: row?.vvmStatus,
      batchNo: row?.batchNo,
    };
    if (vvmStatus && temperature && time) {
      dat.push(data);
      if (productData?.length > 0) {
        setProductData((prevState) => {
          const exists = prevState.some(
            (status) => status.atomId === data.atomId
          );
          if (!exists) {
            return [...prevState, data];
          }
          return prevState;
        });
      } else {
        setProductData(dat);
      }
      setIsAdded(true);
    } else {
      toast.error(t("please_fill_all_row_values"));
    }
  };

  const handleUpdateRow = () => {
    const data = {
      atomId: row?._id,
      temperature: temperature,
      vvmStatus: vvmStatus,
      serialNo: serialNo,
      timestamp: time,
      productId: row?.productId,
      currentVvmStatus: row?.vvmStatus,
      batchNo: row?.batchNo,
    };
    const updateData = vvmUpdateData?.map((batch) =>
      batch?.atomId === row?._id ? { ...data } : batch
    );
    setVvmUpdateData(updateData);
    setIsAdded(true);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <FieldBody
            text={row?.batchNo}
            subtext={
              row?.currentCampaignDetails?.id
                ? row?.currentCampaignDetails?.id
                : null
            }
            color="bold"
          />
        </TableCell>

        <TableCell>
          <FieldBody text={new Date(row?.mfgDate).toLocaleDateString()} />
        </TableCell>

        <TableCell>
          {row?.expDate ? (
            <FieldBody
              text={new Date(row?.expDate).toLocaleDateString()}
              color="red"
            />
          ) : (
            <FieldBody subtext={"- - -"} />
          )}
        </TableCell>

        <TableCell align="center">
          <FieldBody
            text={
              row?.vvmStatus == 1 || row?.vvmStatus == 2
                ? row?.vvmStatus + "-Usable"
                : row?.vvmStatus + "-Not Usable"
            }
          />
        </TableCell>

        <TableCell>
          <FormControl sx={{ m: 1, minWidth: 165 }}>
            <Select
              size="small"
              className="mui_custom_input_field extra_width"
              id="vvmStatus"
              name="vvmStatus"
              onChange={(event) => {
                setVvmStatus(event.target.value);
              }}
              value={vvmStatus}
              disabled={isAdded}
            >
              {filteredOptions?.map((opt) => (
                <MenuItem value={opt?.value}>{opt?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>

        <TableCell align="center">
          <input
            type="number"
            className="mi_custom_textfield"
            name="temperature"
            onChange={(event) => {
              setTemperature(event.target.value);
            }}
            value={temperature}
            disabled={isAdded}
          />
        </TableCell>

        <TableCell align="center">
          <input
            type="number"
            className="mi_custom_textfield"
            name="serialNo"
            onChange={(event) => {
              setSerialNo(event.target.value);
            }}
            value={serialNo}
            disabled={isAdded}
          />
        </TableCell>

        <TableCell align="center">
          {/* <input
            type="date"
            className="mi_custom_textfield"
            name="time"
            onChange={(event) => {
              setTime(event.target.value);
            }}
            value={time}
            disabled={isAdded}
          /> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                className="mui_custom_datetimepicker"
                value={time}
                onChange={(newValue) => {
                  setTime(newValue);
                }}
                disabled={isAdded}
              />
            </DemoContainer>
          </LocalizationProvider>
        </TableCell>

        <TableCell>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            {data?.atomId ? (
              <button
                type="button"
                className={`mi_btn mi_btn_medium  ${
                  isAdded ? "mi_btn_disabled" : "mi_btn_primary"
                }`}
                onClick={handleUpdateRow}
                disabled={isAdded}
              >
                <span>{t("update")}</span>
              </button>
            ) : (
              <button
                type="button"
                className={`mi_btn mi_btn_medium  ${
                  isAdded ? "mi_btn_disabled" : "mi_btn_primary"
                }`}
                onClick={handleUpdate}
                disabled={isAdded}
              >
                <span>{t("update")}</span>
              </button>
            )}
            <button
              type="button"
              className={`mi_btn mi_btn_medium mi_btn_outline`}
              onClick={() => setIsAdded(false)}
            >
              <span>{t("edit")}</span>
            </button>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
