import React from 'react';
import { data } from '../data';
import {
  BarChart,
  CartesianGrid,
  YAxis,
  XAxis,
  LineChart,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
} from 'recharts';

export default function DataGraph() {
  return (
    <div>
      <ResponsiveContainer width='100%' height={250}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type='monotone' dataKey='orders' stroke='#437ee3' />
          <Line type='monotone' dataKey='inventory' stroke='#68d94b' />
          <Line type='monotone' dataKey='shipments' stroke='#a678df' />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

{
  /* <div>
         <ResponsiveContainer width="100%" height={250}>
          <BarChart data={data}>
           <CartesianGrid strokeDasharray="3 3" />
           <XAxis dataKey="name" />
           <YAxis />
           <Tooltip />
           <Legend />
           <Bar dataKey="orders" fill="#437ee3" />
           <Bar dataKey="inventory" fill="#68d94b" />
           <Bar dataKey="shipments" fill="#a678df" />
          </BarChart>
         </ResponsiveContainer>
        </div> */
}
