import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../../shared/utils/tableField/FieldBody';
import StatusLabel from '../../../../../shared/utils/statusLabel/StatusLabel';
import { Collapse, TablePagination } from '@mui/material';
import { Link } from 'react-router-dom';
import EmptyTable from '../../../../../shared/utils/emptyTable/EmptyTable';
import { cancelOrder } from '../../../../../redux/orderActions';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import IconTransfer from '/icons/analytics/IconTransfer.svg';
import IconCampaign from '/icons/analytics/campaign.png';
import { orderOutStatus } from '../../../../../shared/common/commonFunctions';
import PermissionRoute from '../../../../../web/routes/routeLayers/PermissionRoute';
import { getUserLocationInfo } from '../../../../../redux/orderActions';
import { useTranslation } from 'react-i18next';
import ProductIcon from '../../../../../shared/utils/productIcon/ProductIcon';
import OrderProductList from '../../../../components/orderProductList/OrderProductList';

export default function OrderOutTable({
  orders,
  setCurrentTab,
  filteredColumns,
  setAnalyticUpdated,
  pagination,
  page,
  setPage,
  limit,
  setLimit,
}) {
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [location, setLocation] = useState();

  const OrderData = orders;

  const fetchData = async () => {
    const result = await getUserLocationInfo();
    setLocation(result.data);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {filteredColumns.map((column) => (
              <TableCell key={column.key}>
                <FieldHead
                  title={t(column.title)}
                  align={column.key === 'lastUpdateBy' ? 'end' : 'start'}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body mi_table_collapse_body'>
          {OrderData && OrderData.length > 0 ? (
            OrderData.map((row, i) => (
              <InvSummaryRow
                key={i}
                row={row}
                filteredColumns={filteredColumns}
                setAnalyticUpdated={setAnalyticUpdated}
                setCurrentTab={setCurrentTab}
                location={location}
                t={t}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: '2rem' }} colSpan={6} align='center'>
                {OrderData ? (
                  <EmptyTable />
                ) : (
                  <div className='Loading_message'>
                    <p className='page__note_fs'>Loading...</p>
                  </div>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={pagination?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function InvSummaryRow({
  row,
  filteredColumns,
  setAnalyticUpdated,
  setCurrentTab,
  location,
  t,
}) {
  const navigate = useNavigate();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [statusLabel, statusColor] = orderOutStatus(row.status);

  const newArray = row?.products?.map((item) => ({
    productId: item?.productId,
    name: item?.product?.name,
    units: item?.product?.units,
    type: item?.product?.type,
    quantity: item?.quantity,
    mfgDate: item?.product?.createdAt, // Replace with the actual manufacturing date if available
    expDate: '', // Replace with the actual expiration date if available
    fromPage: 'ReOrder',
  }));

  const handleCancelOrder = async (id) => {
    const res = await cancelOrder({ orderId: id, status: 'CANCELLED' });

    if (res.success) {
      setAnalyticUpdated((prev) => !prev);
      setCurrentTab('summary');
      toast.success(t('order_cancelled'));
    }
  };

  const locations = {
    supplier: row?.destination,
    delivery: row?.source,
  };

  const handleReOrder = () => {
    navigate('/user/orders/review-orders', {
      state: { products: newArray, locations: locations },
    });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        className='mi_collapse_visible_row mi_table__row_clickable'
        onClick={() => setCollapseOpen(!collapseOpen)}
      >
        {filteredColumns?.map((column) => (
          <React.Fragment key={column?.key}>
            {column.key === 'supplier' && (
              <TableCell>
                <FieldBody
                  text={row.destination.name}
                  subtext={row.destination.postalAddress + ', '}
                  subtext2={row.destination.division}
                  color='bold'
                />
              </TableCell>
            )}
            {column.key === 'product_quantity' && (
              <TableCell>
                <div className='table__field_two_data'>
                  <FieldBody
                    text={row.products.length + ' ' + 'Products'}
                    color='bold'
                  />
                  {row?.isTransfer && (
                    <img className='Transfer__icon' src={IconTransfer} />
                  )}

                  {row?.campaignId && (
                    <img className='Campaign__icon' src={IconCampaign} />
                  )}
                </div>
              </TableCell>
            )}
            {column.key === 'date_OrderId' && (
              <TableCell>
                <FieldBody
                  text={new Date(row.createdAt).toLocaleDateString()}
                  subtext={t('order_id') + ': ' + row.id}
                  color='bold'
                />
              </TableCell>
            )}

            {column.key === 'delivery' && (
              <TableCell>
                <FieldBody
                  text={row.source.name}
                  subtext={row.source.postalAddress + ', '}
                  subtext2={row.source.division}
                  color='bold'
                />
              </TableCell>
            )}
            {column.key === 'status' && (
              <TableCell>
                <StatusLabel status={statusLabel} tone={statusColor} />
              </TableCell>
            )}
            {column.key === 'lastUpdateBy' && (
              <TableCell align='center'>
                <div className='table__field_two_data end'>
                  <FieldBody
                    text={new Date(row.updatedAt).toLocaleDateString()}
                  />
                  {collapseOpen ? (
                    <i className='fa-solid fa-chevron-up table_collapse_icon'></i>
                  ) : (
                    <i className='fa-solid fa-chevron-down table_collapse_icon'></i>
                  )}
                </div>
              </TableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
      <TableRow className='mi_table__body_No_padding'>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <div className='Collapse__table'>
              <div className='Collapse__row single_column'>
                <article className='Collapse__column_list'>
                  <OrderProductList products={row?.products} />
                </article>

                <article className='Collapse__actions'>
                  <div className='table__field_two_data'>
                    {row.status === 'CREATED' && (
                      <PermissionRoute allowed={'CANCEL_ORDER'} type='actions'>
                        <button
                          className='mi_btn mi_btn_medium mi_btn_secondary'
                          onClick={() => handleCancelOrder(row?._id)}
                        >
                          <span>{t('cancel_order')}</span>
                        </button>
                      </PermissionRoute>
                    )}
                    {row.status === 'in transit' && (
                      <button className='mi_btn mi_btn_medium mi_btn_secondary'>
                        <span>{t('inbound_shipments')} </span>
                      </button>
                    )}
                    {row.status === 'partially fulfilled' && (
                      <PermissionRoute allowed={'CANCEL_ORDER'} type='actions'>
                        {' '}
                        <button
                          className='mi_btn mi_btn_medium mi_btn_secondary'
                          onClick={handleCancelOrder()}
                        >
                          <span>{t('cancel_order')}</span>
                        </button>
                      </PermissionRoute>
                    )}
                    {row.status === 'CANCELLED' && (
                      <PermissionRoute allowed={'RE_ORDER'} type='actions'>
                        {' '}
                        <button
                          onClick={handleReOrder}
                          className='mi_btn mi_btn_medium mi_btn_secondary'
                        >
                          <span>{t('reorder')}</span>
                        </button>
                      </PermissionRoute>
                    )}
                    {row.status === 'REJECTED' && (
                      <PermissionRoute allowed={'RE_ORDER'} type='actions'>
                        <button
                          onClick={handleReOrder}
                          className='mi_btn mi_btn_medium mi_btn_secondary'
                        >
                          <span>{t('reorder')}</span>
                        </button>
                      </PermissionRoute>
                    )}
                    {row.status === 'FULFILLED' && (
                      <PermissionRoute allowed={'RE_ORDER'} type='actions'>
                        <button
                          onClick={handleReOrder}
                          className='mi_btn mi_btn_medium mi_btn_secondary'
                        >
                          <span>{t('reorder')}</span>
                        </button>
                      </PermissionRoute>
                    )}
                    {row.status === 'ACCEPTED' && null}

                    <PermissionRoute allowed={'VIEW_ORDER'} type='actions'>
                      <Link
                        to={'/user/orders/view-order/' + row._id}
                        className='mi_btn mi_btn_medium mi_btn_outline'
                      >
                        <span>{t('view_details')}</span>
                      </Link>
                    </PermissionRoute>
                  </div>
                </article>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
