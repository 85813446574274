import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import {
  active_list,
  expired_list,
  expired_in_transit_list,
  open_list,
  damaged_list,
  lost_list,
  cold_chain_failure_list,
  recall_list,
  quarantine_list,
} from './data';
import { capitalToNormalCase } from '../../../../shared/utils/utils';

export default function InventoryForm({
  from,
  setFrom,
  to,
  setTo,
  t,
  selectedProducts,
}) {
  // Options for with & without order ID
  const statusOptions = [
    { name: 'ACTIVE', key: 'ACTIVE' },
    { name: 'EXPIRED', key: 'EXPIRED' },
    { name: 'EXPIRED_IN_TRANSIT', key: 'EXPIRED_IN_TRANSIT' },
    { name: 'OPEN_VIAL', key: 'OPEN' },
    { name: 'DAMAGED', key: 'DAMAGED' },
    { name: 'LOST', key: 'LOST' },
    { name: 'COLD_CHAIN_FAILURE', key: 'COLD_CHAIN_FAILURE' },
    { name: 'RECALL', key: 'RECALL' },
    { name: 'QUARANTINE', key: 'QUARANTINE' },
  ];

  const [toStatus, setToStatus] = useState([]);

  useEffect(() => {
    if (from) {
      if (from === 'ACTIVE') {
        setToStatus(active_list);
      } else if (from === 'EXPIRED') {
        setToStatus(expired_list);
      } else if (from === 'EXPIRED_IN_TRANSIT') {
        setToStatus(expired_in_transit_list);
      } else if (from === 'OPEN') {
        setToStatus(open_list);
      } else if (from === 'DAMAGED') {
        setToStatus(damaged_list);
      } else if (from === 'LOST') {
        setToStatus(lost_list);
      } else if (from === 'COLD_CHAIN_FAILURE') {
        setToStatus(cold_chain_failure_list);
      } else if (from === 'RECALL') {
        setToStatus(recall_list);
      } else if (from === 'QUARANTINE') {
        setToStatus(quarantine_list);
      } else {
        setToStatus([]);
      }
    }
  }, [from]);

  // useEffect(() => {
  //   if (from) {
  //     const data = statusOptions?.filter((item) => item.name !== from);
  //     setToStatusOption(data);
  //   } else {
  //     setToStatusOption(statusOptions);
  //   }
  // }, [statusOptions, from]);

  // useEffect(() => {
  //   if (to) {
  //     const data = statusOptions?.filter((item) => item.name !== to);

  //     setFromStatusOption(data);
  //   } else {
  //     setFromStatusOption(statusOptions);
  //   }
  // }, [statusOptions, to]);

  return (
    <section className='AddShipment__form'>
      <div className='ModalPopup__form_container'>
        <div className='ModalPopup__form_section'>
          <div className='Input__row two_column'>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t('from_status')}</p>
              <FormControl fullWidth>
                <Select
                  className='mui_custom_input_field'
                  size='small'
                  name='from'
                  value={from || ''}
                  onChange={(event) => {
                    setFrom(event.target.value);
                  }}
                  disabled={selectedProducts?.length > 0}
                >
                  {statusOptions?.length ? (
                    statusOptions?.map((loc, index) => (
                      <MenuItem key={index} value={loc.key}>
                        {capitalToNormalCase(loc.name)}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Option</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t('to_status')}</p>
              <FormControl fullWidth>
                <Select
                  className='mui_custom_input_field'
                  size='small'
                  name='to'
                  value={to || ''}
                  onChange={(event) => {
                    setTo(event.target.value);
                  }}
                  disabled={from ? false : true}
                >
                  {toStatus?.length ? (
                    toStatus?.map((loc, index) => (
                      <MenuItem key={index} value={loc.key}>
                        {capitalToNormalCase(loc.name)}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Option</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
