import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Printer from "../../../../shared/printer/Printer";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ExportQuarantine(props) {
  const {
    onClose,
    open,
    inventoryPrint,
    tableRef,
    currentTab,
    xport,
    ...other
  } = props;

  const { t } = useTranslation();

  const InvCurrentColumns = [
    { key: "productCategory", title: "product_category" },
    { key: "productName", title: "product_name" },
    { key: "batchNo", title: "batch_no" },
    { key: "manufacturer", title: "manufacturer" },
    { key: "quantity", title: "quantity" },
    { key: "status", title: "status" },
  ];

  const handleExport = () => {
    xport();
    onClose();
  };

  const pdfPrintRef = useRef();
  const handlePrint = useReactToPrint({ contentRef: pdfPrintRef });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className='ModalPopup__dialog'
      maxWidth={"xl"}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>
            <div className='modal__heading_group'>
              <div className='modal__heading'>
                <h1 className='page__body_subheading_fs'>Preview Table Data</h1>
              </div>

              <div className='modal__quanity'> </div>
            </div>
          </div>
          <div
            className='ModalPopup__close_icon'
            onClick={() => {
              onClose();
            }}
          >
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>

      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className='ModalPopup__body '>
          <section className='Page__main_wrapper' ref={pdfPrintRef}>
            <Printer title='Inventory Quarantine'>
              <div className='printing_body'>
                <table style={tableStyle} ref={tableRef}>
                  <thead>
                    <tr>
                      {InvCurrentColumns?.map((column) => (
                        <th style={thStyle}>{t(column?.title)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {inventoryPrint?.map((row) => (
                      <tr>
                        <td style={thTdStyle}>{row?.productId?.type}</td>
                        <td style={thTdStyle}>{row?.productId?.name}</td>
                        <td style={thTdStyle}>{row?.batchNo}</td>
                        <td style={thTdStyle}>{row?.manufacturer?.name}</td>
                        <td style={thTdStyle}>
                          {row.quantity + " " + row?.productId?.units}
                        </td>
                        <td style={thTdStyle}>{row?.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Printer>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className='InventoryOrders__actions_Popup'>
          <div>
            Total inventory {currentTab} products : {inventoryPrint?.length}
          </div>
          <div className='Page__left_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handlePrint}
            >
              <i className='fa-solid fa-download'></i>
              <span>{t("generate_PDF")}</span>
            </button>
            <button
              onClick={handleExport}
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              Download
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}

const tableStyle = {
  borderCollapse: "collapse",
  width: "100%",
};

const thTdStyle = {
  border: "1px solid black",
  padding: "8px",
  textAlign: "left",
  fontSize: "10px",
};

const thStyle = {
  ...thTdStyle,
  backgroundColor: "#f2f2f2",
};
