import React from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import VaccineReport from '/icons/all/Vaccine Report.svg';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
} from '@mui/material';
import FieldBody from '../../../shared/utils/tableField/FieldBody';
import FieldHead from '../../../shared/utils/tableField/FieldHead';
import EmptyTable from '../../../shared/utils/emptyTable/EmptyTable';
import { assetIcon } from '../../../shared/utils/utils';
import StatusLabel from '../../../shared/utils/statusLabel/StatusLabel';
import { useGetAssetTransfer } from '../../../services/coldchain-services/queries';
import {
  useAcceptAssetTransfer,
  useCancelAssetTransfer,
} from '../../../services/coldchain-services/mutations';
import { useSelector } from 'react-redux';

export default function AssetTransfer() {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const data = [{ id: 1 }, { id: 2 }];

  const { data: transferData } = useGetAssetTransfer(0, 10);

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <div
            className='back_Link'
            onClick={() => navigate('/user/cold-chain')}
          >
            <i className='bx bx-arrow-back icon_blue'></i>
          </div>

          <h1 className='Page__headline_title_fs'>{t('asset_transfer')}</h1>
        </div>
        <div className='Page__right_actions'>
          <Link
            to='/user/assets-transfer/new'
            className='mi_btn mi_btn_medium mi_btn_primary'
          >
            <i className='fa-solid fa-plus'></i>
            <span>{t('create') + ' ' + t('asset_transfer')}</span>
          </Link>
        </div>
      </div>

      <div className='Page__table_wrapper'>
        <TableContainer>
          <Table sx={{ minWidth: 485 }} aria-label='simple table'>
            <TableHead className='mi_table__head'>
              <TableRow>
                <TableCell>
                  <FieldHead title={t('asset')} />
                </TableCell>
                <TableCell>
                  <FieldHead title={t('model')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('from')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('to')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('created_at')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('status')} />
                </TableCell>
                <TableCell align='center'>
                  <FieldHead title={t('action')} align='center' />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className='mi_table__body'>
              {transferData?.data?.length > 0 ? (
                transferData?.data?.map((row) => (
                  <AssetTransferHistory row={row} t={t} userInfo={userInfo} />
                ))
              ) : (
                <TableRow className='mi_table__body_No_padding'>
                  <TableCell style={{ padding: '2rem' }} colSpan={5}>
                    <EmptyTable />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
}

function AssetTransferHistory({ row, t, userInfo }) {
  const accept = useAcceptAssetTransfer();
  const cancel = useCancelAssetTransfer();
  const acceptReject = async (status, id) => {
    if (status === 'ACCEPTED') {
      await accept.mutateAsync(
        {
          transferId: id,
        },
        {
          onSuccess: () => {
            toast.success(t('transfer_accepted'));
          },
        }
      );
    } else if (status === 'CANCELLED') {
      await cancel.mutateAsync(
        {
          transferId: id,
        },
        {
          onSuccess: () => {
            toast.success(t('transfer_canceled'));
          },
        }
      );
    }
  };

  console.log(row);

  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <div className='asset_banner_small'>
            <img src={assetIcon('TCW 3000 AC')} alt='asset_icon' />
          </div>
        </TableCell>
        <TableCell>
          <FieldBody text={'TCW 3000 AC +3'} />
        </TableCell>

        <TableCell>
          <FieldBody text={row?.sourceDetails?.name} />
        </TableCell>
        <TableCell>
          <FieldBody text={row?.destinationDetails?.name} />
        </TableCell>
        <TableCell>
          <FieldBody text={new Date(row?.createdAt).toLocaleDateString()} />
        </TableCell>

        <TableCell>
          <StatusLabel
            status={row?.status}
            tone={
              row?.status === 'ACCEPTED'
                ? 'green'
                : row?.status === 'CANCELLED'
                ? 'red'
                : 'blue'
            }
          />
        </TableCell>

        <TableCell>
          {userInfo?.locationId === row?.sourceDetails?._id && (
            <>
              {row?.status === 'CREATED' && (
                <div className='Table__actions'>
                  <button
                    className='mi_btn mi_btn_medium mi_btn_outline'
                    onClick={() => acceptReject('CANCELLED', row?._id)}
                  >
                    <span>{t('Cancel')}</span>
                  </button>{' '}
                </div>
              )}
            </>
          )}
          {userInfo?.locationId === row?.destinationDetails?._id && (
            <>
              {row.status === 'CREATED' && (
                <div className='Table__actions'>
                  <button
                    className='mi_btn mi_btn_medium mi_btn_outline'
                    onClick={() => acceptReject('CANCELLED', row?._id)}
                  >
                    <span>{t('reject_button')}</span>
                  </button>
                  <button
                    className='mi_btn mi_btn_medium mi_btn_secondary'
                    onClick={() => acceptReject('ACCEPTED', row?._id)}
                  >
                    <span>{t('accept')}</span>
                  </button>
                </div>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
