import { useMemo } from 'react';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import LocationMarker from '/icons/location.svg';

// Define marker icons outside of the component to avoid re-creation
const markerIcon = new L.Icon({
  iconUrl: LocationMarker,
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

export default function EmptyMap({ activeSource, handleShipInfoChange }) {
  const position = useMemo(() => [23.8387, 90.24064], []);



  return (
    <div className='geo__map_view'>
      <div className='ShipmentMap__container'>
        <MapContainer
          center={position}
          zoom={7}
          scrollWheelZoom={true}
          style={{ zIndex: 1, position: 'relative' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
          />
          {activeSource &&
            activeSource?.map((location) => (
              <Marker
                key={location._id}
                icon={markerIcon}
                position={location?.coordinates}
                eventHandlers={{
                  click: () => {
                    handleShipInfoChange(location?._id);
                  },
                }}
              ></Marker>
            ))}
        </MapContainer>
      </div>
    </div>
  );
}
