import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from 'react-i18next';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';
import { capitalToNormalCase } from '../../../../shared/utils/utils';

export default function InvNearExpiry({
  inventory,
  filteredColumns,
  totalRecords,
  page,
  setPage,
  limit,
  setLimit,
  tableRef,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 486 }} ref={tableRef}>
          <TableHead className='mi_table__head'>
            <TableRow>
              {/* <TableCell>
                <FieldHead title="Product Category" />
              </TableCell>
              <TableCell>
                <FieldHead title="Product Name" />
              </TableCell>
              <TableCell>
                <FieldHead title="Product ID" />
              </TableCell>
              <TableCell>
                <FieldHead title="Manufacturer" />
              </TableCell>
              <TableCell>
                <FieldHead title="Quantity" />
              </TableCell>
              <TableCell>
                <FieldHead title="Batch Number" />
              </TableCell>
              <TableCell>
                <FieldHead title="Expiry Date" />
              </TableCell> */}
              {filteredColumns.map((column) => (
                <TableCell key={column.key}>
                  <FieldHead title={t(column.title)} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body'>
            {inventory ? (
              <>
                {inventory?.length > 0 ? (
                  <>
                    {inventory?.map((row, index) => (
                      <InvSummaryRow
                        row={row}
                        key={index}
                        filteredColumns={filteredColumns}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: '2rem' }} colSpan={7}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell
                  style={{ padding: '2rem' }}
                  colSpan={7}
                  align='center'
                >
                  <div className='Loading_message'>
                    <EmptyTable />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}

function InvSummaryRow({ row, filteredColumns }) {
  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {filteredColumns.map((column) => (
          <React.Fragment key={column?.key}>
            {column.key === 'productCategory' && (
              <TableCell>
                <FieldBody text={capitalToNormalCase(row?.productId?.type)} />
              </TableCell>
            )}
            {column.key === 'productName' && (
              <TableCell>
                <ProductIcon
                  type={row?.productId?.type}
                  name={row?.productId?.name}
                  size='tiny'
                  fallback={
                    <FieldBody text={row?.productId?.name} color='bold' />
                  }
                />
              </TableCell>
            )}
            {column.key === 'productId' && (
              <TableCell>
                <FieldBody text={row?.productId?.id} color='bold' />
              </TableCell>
            )}
            {column.key === 'manufacturer' && (
              <TableCell>
                <FieldBody text={row?.manufacturer?.name} />
              </TableCell>
            )}
            {column.key === 'quantity' && (
              <TableCell>
                <FieldBody text={row.quantity + ' ' + row?.productId?.units} />
              </TableCell>
            )}
            {column.key === 'batchNo' && (
              <TableCell>
                <FieldBody text={row?.batchNo} />
              </TableCell>
            )}
            {column.key === 'expiryDate' && (
              <TableCell>
                {row?.expDate ? (
                  <FieldBody
                    subtext={new Date(row?.expDate).toLocaleDateString()}
                  />
                ) : (
                  <FieldBody subtext={'- - -'} />
                )}
              </TableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </React.Fragment>
  );
}
