import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { Checkbox } from "@mui/material";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";

export default function ProductOrdTable({
  products,
  removeProductOrders,
  handleOpenEditPopup,
  setEditedProduct,
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="ProductTable__wrapper">
        <div className="Page__select_tab_wrapper">
          <div className="Page__select_tab_header">
            <div className="Select__tab_headline row">
              <h1 className="page__body_heading_fs">
                {t("selected_product(s)")}:
              </h1>

              <div className="tab_analytics count">
                <span>{products?.length ? products?.length : 0}</span>
              </div>
            </div>
          </div>
          <div className="Page__product_table">
            <TableContainer>
              <Table sx={{ minWidth: 485 }}>
                <TableHead className="mi_table__head br-top">
                  <TableRow>
                    <TableCell>
                      <FieldHead title={t("product_name")} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t("quantity")} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t("product_category")} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="mi_table__body">
                  {products?.map((row, i) => (
                    <TableRowCard
                      row={row}
                      key={i}
                      i={i}
                      removeProductOrders={removeProductOrders}
                      handleOpenEditPopup={handleOpenEditPopup}
                      setEditedProduct={setEditedProduct}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function TableRowCard({
  row,
  removeProductOrders,
  handleOpenEditPopup,
  setEditedProduct,
}) {
  return (
    <React.Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <ProductIcon
            type={row?.type}
            name={row?.name}
            size="tiny"
            fallback={<FieldBody subtext={row.name} />}
          />
        </TableCell>
        <TableCell>
          <div className="edit_input_wrap">
            <FieldBody subtext={row.quantity + " " + row?.units} />
          </div>
        </TableCell>
        <TableCell>
          <FieldBody subtext={row.type} />
        </TableCell>

        <TableCell>
          <div className="ReviewProducts__actions">
            <div
              className="Review__action_ico edit_ico"
              onClick={() => {
                setEditedProduct(row);
                handleOpenEditPopup();
              }}
            >
              {" "}
              <i className="fa-solid fa-pencil icon_blue"></i>
            </div>

            <div
              className="Review__action_ico delete_ico"
              onClick={() => {
                removeProductOrders(row?.productId);
                toast.success("Product removed from the list");
              }}
            >
              <i className="fa-solid fa-trash-can icon_blue"></i>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
