import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import InfoIcon from '/icons/table/info.svg';
import { useNavigate } from 'react-router-dom';
import { getParList } from '../../../../redux/inventoryActions';
import { useEffect, useState } from 'react';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';

export default function PARtable() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [varData, setVarData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getParList(page, limit);
      setVarData(res.data);
    };
    fetchData();
  }, [page, limit]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label='simple table'>
        <TableHead className='mi_table__head'>
          <TableRow>
            <TableCell>
              <FieldHead title={t('parid_no')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('date_of_report')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('product')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('from')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('to')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('created_at')} />
            </TableCell>
            <TableCell align='center'>
              <FieldHead title={t('status')} align='center' />
            </TableCell>
            <TableCell align='center'>
              <FieldHead title={t('view')} align='center' />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {varData?.data.length > 0 ? (
            varData?.data?.map((row, index) => (
              <TableBodyRow
                row={row}
                key={row?._id}
                index={index}
                length={varData?.data?.length}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: '2rem' }} colSpan={6}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={varData?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function TableBodyRow({ row, index, length }) {
  const navigate = useNavigate();

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        <FieldBody text={`PAR${length - index}`} color='bold' />
      </TableCell>
      <TableCell>
        <FieldBody text={new Date(row?.reportDate).toLocaleDateString()} />
      </TableCell>
      <TableCell>
        <ProductIcon
          type={row?.shipmentDetails?.products[0]?.productDetails?.type}
          name={row?.shipmentDetails?.products[0]?.productDetails?.name}
          size='tiny'
          fallback={
            <FieldBody
              text={row?.shipmentDetails?.products[0]?.productDetails?.name}
              color='bold'
            />
          }
        />
      </TableCell>
      <TableCell>
        <FieldBody
          text={row?.shipmentDetails?.products[0]?.manufacturer?.name}
        />
      </TableCell>
      <TableCell>
        <FieldBody text='CMSD' />
      </TableCell>
      <TableCell>
        <FieldBody text={new Date(row?.createdAt).toLocaleDateString()} />
      </TableCell>
      <TableCell align='center'>
        {row?.status ? (
          <StatusLabel
            status={row?.status}
            tone={
              row?.status === 'ACCEPTED'
                ? 'green'
                : row?.status === 'REJECTED'
                ? 'red'
                : 'blue'
            }
          />
        ) : (
          '---'
        )}
      </TableCell>
      <TableCell>
        <div
          onClick={() =>
            navigate(`/user/arrival-report/product/view/${row?._id}`)
          }
          className='Table__Icon mi_link'
        >
          <img src={InfoIcon} alt='Icon' />
        </div>
      </TableCell>
    </TableRow>
  );
}
