import axios from 'axios';
import { API_URL } from '../../config';

export const getUserInfo = async () => {
  return (await axios.get(`${API_URL.userInfoUrl}`)).data.data;
};

export const getUserLocationInfo = async () => {
  return (await axios.get(`${API_URL.userLocationInfoUrl}`)).data.data;
};

export const updateUserInfo = async (data) => {
  return (await axios.patch(`${API_URL.userInfoUrl}`, data)).data.data;
};
