import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormControl, MenuItem, Select } from "@mui/material";
import "./VARform.css";
import toast from "react-hot-toast";
import { getAirportInfo } from "../../../../redux/inventoryActions";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const VARform2 = ({ setFormComplete, setData, storedData, currentCountry }) => {
  const [airports, setAirports] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData(id) {
      const air = await getAirportInfo(id, true);
      setAirports(air.data);
    }
    fetchData(currentCountry?.[0]?._id);
  }, [currentCountry]);

  const validationSchema = Yup.object().shape({
    awb: Yup.string().required(t("awb_number_error")),
    flight: Yup.string().required(t("flight_number_error")),
    etaDate: Yup.date().required(t("eta_date_error")),
    etaTime: Yup.string().required(t("eta_time_error")),
    arrivalDate: Yup.date().required(t("arrival_date_error")),
    arrivalTime: Yup.string().required(t("arrival_time_error")),
    destination: Yup.string().required(t("country_error")),
    clearingAgent: Yup.string().required(t("clearing_agent_error")),
    behalfOf: Yup.string().required(t("on_behalf_of_error")),
  });

  const [scheduledDate, scheduledTimeWithZone] = storedData?.flightArrivalReport
    ?.scheduledDateTime
    ? storedData?.flightArrivalReport?.scheduledDateTime.split("T")
    : "";

  const [actualDate, actualTimeWithZone] = storedData?.flightArrivalReport
    ?.actualDateTime
    ? storedData?.flightArrivalReport?.actualDateTime.split("T")
    : "";

  const formik = useFormik({
    initialValues: {
      awb: storedData?.flightArrivalReport?.awb || "",
      flight: storedData?.flightArrivalReport?.flightNo || "",
      etaDate: scheduledDate || dayjs().format("YYYY-MM-DD"),
      etaTime:
        scheduledTimeWithZone?.substring(0, 5) || dayjs().format("HH:mm"),
      arrivalDate: actualDate || dayjs().format("YYYY-MM-DD"),
      arrivalTime:
        actualTimeWithZone?.substring(0, 5) || dayjs().format("HH:mm"),
      destination: storedData?.flightArrivalReport?.destination || "",
      clearingAgent: storedData?.flightArrivalReport?.clearingAgent?.name || "",
      behalfOf:
        storedData?.flightArrivalReport?.clearingAgent?.behalf || "CMSD",
    },
    validationSchema,
    onSubmit: (values) => {
      setData(2, values);
      navigate("?form=part3");
      setFormComplete(3);

      toast.success(t("VAR_part2_title") + " " + t("saved"));
    },
  });

  const handlePrev = () => {
    navigate("?form=part1");
    setFormComplete(1);
  };

  return (
    <form onSubmit={formik.handleSubmit} className='VARform'>
      <div className='VARform__header'>
        <h1 className='page__body_bigheading_fs'>{t("VAR_part2_title")}</h1>
      </div>
      <div className='VARform__body'>
        <div className='VARform__table'>
          <article className='VARform__row three_column'>
            <div className='VARform__column'>
              <p className='page__tiny_fs mi_sky'>{t("flight_info")}</p>
              <div className='Input__row two_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("awb_number")} <b>*</b>
                  </p>
                  <input
                    {...formik.getFieldProps("awb")}
                    type='number'
                    className='mi_custom_textfield'
                  />
                  {formik.touched.awb && formik.errors.awb && (
                    <span className='Input__error_fs'>{formik.errors.awb}</span>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("flight_number")} <b>*</b>
                  </p>
                  <input
                    {...formik.getFieldProps("flight")}
                    type='text'
                    className='mi_custom_textfield'
                  />

                  {formik.touched.flight && formik.errors.flight && (
                    <span className='Input__error_fs'>
                      {formik.errors.flight}
                    </span>
                  )}
                </div>
              </div>
              <div className='Input__column' style={{ marginTop: "0.5rem" }}>
                <p className='Input__label_fs'>
                  {t("airport_destination")} <b>*</b>
                </p>
                <FormControl fullWidth>
                  <Select
                    {...formik.getFieldProps("destination")}
                    value={formik.values.destination}
                    size='small'
                    className='mui_custom_input_field'
                  >
                    {airports?.map((country, index) => (
                      <MenuItem key={index} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {formik.touched.destination && formik.errors.destination && (
                  <span className='Input__error_fs'>
                    {formik.errors.destination}
                  </span>
                )}
              </div>
            </div>

            <div className='VARform__column'>
              <p className='page__tiny_fs mi_sky'>
                {t("eta_as_per_notification")}
              </p>
              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("eta_date")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("etaDate")}
                  type='date'
                  className='mi_custom_textfield date_field'
                />

                {formik.touched.etaDate && formik.errors.etaDate && (
                  <span className='Input__error_fs'>
                    {formik.errors.etaDate}
                  </span>
                )}
              </div>
              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("eta_time")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("etaTime")}
                  type='time'
                  className='mi_custom_textfield date_field'
                />

                {formik.touched.etaTime && formik.errors.etaTime && (
                  <span className='Input__error_fs'>
                    {formik.errors.etaTime}
                  </span>
                )}
              </div>
            </div>

            <div className='VARform__column'>
              <p className='page__tiny_fs mi_sky'>
                {t("actual_time_of_arrival")}
              </p>
              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("actual_date")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("arrivalDate")}
                  type='date'
                  className='mi_custom_textfield date_field'
                />

                {formik.touched.arrivalDate && formik.errors.arrivalDate && (
                  <span className='Input__error_fs'>
                    {formik.errors.arrivalDate}
                  </span>
                )}
              </div>
              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t("actual_time")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("arrivalTime")}
                  type='time'
                  className='mi_custom_textfield date_field'
                />

                {formik.touched.arrivalTime && formik.errors.arrivalTime && (
                  <span className='Input__error_fs'>
                    {formik.errors.arrivalTime}
                  </span>
                )}
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className='VARform__footer'>
        <article className='VARform__row three_column'>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t("name_of_clearing_agent")} <b>*</b>
            </p>
            <input
              {...formik.getFieldProps("clearingAgent")}
              type='text'
              className='mi_custom_textfield'
            />
            <span className='Input__error_fs'>
              {formik.touched.clearingAgent && formik.errors.clearingAgent}
            </span>
          </div>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t("on_behalf_of")} <b>*</b>
            </p>
            <input
              {...formik.getFieldProps("behalfOf")}
              disabled
              type='text'
              className='mi_custom_textfield'
              style={{ opacity: "0.5" }}
            />
            <span className='Input__error_fs'>
              {formik.touched.behalfOf && formik.errors.behalfOf}
            </span>
          </div>
          <div className='Input__column_action'>
            <button
              type='button'
              className='mi_btn mi_btn_medium mi_btn_secondary'
              onClick={handlePrev}
            >
              <span>{t("prev")}</span>
            </button>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              type='submit'
            >
              <span>{t("save_continue")}</span>
            </button>
          </div>
        </article>
      </div>
    </form>
  );
};

export default VARform2;
