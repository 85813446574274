import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import OutboundOrders from './outboundOrders/OutboundOrders';
import InboundOrders from './inboundOrders/InboundOrders';
import ImportPopup from '../../../shared/importPopup/ImportPopup';
import ConfigureTable from '../../../shared/configureTable/ConfigureTable';
import PermissionRoute from '../../../web/routes/routeLayers/PermissionRoute';
import Export from '/icons/all/Export.svg';
import Filter from '/icons/all/Filter.svg';
import Search from '/icons/all/Search.svg';
import OrderIcon from '/icons/all/orders/Orders.svg';

import ExportTable from '../../components/shadowExport/ExportTable';
import {
  getInBoundOrders,
  getOutboundOrders,
} from '../../../redux/orderActions';

export default function Orders() {
  const { t } = useTranslation();
  const [currentOrder, setCurrentOrder] = useState(
    localStorage.getItem('OrdersTab') || 'outbound'
  );
  const [importOpenPopup, setImportOpenPopup] = useState(false);
  const [searchCapture, setSearchCapture] = useState();
  const [openPrint, setOpenPrint] = useState(false);
  const [ordersPrint, setOrdersPrint] = useState();
  const [tab, setTab] = useState();
  const [transfer, setTransfer] = useState();

  const { orgLevel } = useSelector((state) => state.auth);

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  const handleOpenImportPopup = () => {
    setImportOpenPopup(true);
  };

  const handleCloseImportPopup = () => {
    setImportOpenPopup(false);
  };

  const handleTabChange = (tab) => {
    setCurrentOrder(tab);
    setSearchCapture();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnData, setColumnData] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let initialColumns = {};
  let columns = [];

  const OrderOutboundColumns = [
    { key: 'supplier', title: 'supplier' },
    { key: 'product_quantity', title: 'product_quantity' },
    { key: 'date_OrderId', title: 'date_order_id' },
    { key: 'delivery', title: 'delivery' },
    { key: 'status', title: 'status' },
    { key: 'lastUpdateBy', title: 'last_updated_by' },
  ];

  const OrderInboundColumns = [
    { key: 'product_quantity', title: 'product_quantity' },
    { key: 'order_sent_by', title: 'order_sent_by' },
    { key: 'orderId', title: 'order_id' },
    { key: 'order_placed_on', title: 'order_placed_on' },
    { key: 'delivery_location', title: 'delivery_location' },
    { key: 'status', title: 'status' },
    { key: 'action', title: 'action' },
  ];

  const OrderOutboundInitialColumns = {
    supplier: true,
    product_quantity: true,
    date_OrderId: true,
    delivery: true,
    status: true,
    lastUpdateBy: true,
  };

  const OrderInboundInitialColumns = {
    product_quantity: true,
    order_sent_by: true,
    orderId: true,
    order_placed_on: true,
    delivery_location: true,
    status: true,
    action: true,
  };

  if (currentOrder === 'outbound') {
    initialColumns = OrderOutboundInitialColumns;
    columns = OrderOutboundColumns;
  } else if (currentOrder === 'inbound') {
    initialColumns = OrderInboundInitialColumns;
    columns = OrderInboundColumns;
  }

  useEffect(() => {
    setSelectedColumns(initialColumns);
  }, [currentOrder]);

  const [selectedColumns, setSelectedColumns] = useState(initialColumns);
  const disableFilter =
    selectedColumns.status &&
    selectedColumns.orderId &&
    selectedColumns.action &&
    selectedColumns.delivery_location &&
    selectedColumns.order_placed_on &&
    selectedColumns.order_sent_by &&
    selectedColumns.product_quantity;

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns?.filter(
    (column) => selectedColumns[column.key]
  );

  const openPrintPopup = async () => {
    if (currentOrder === 'outbound') {
      const res = await getOutboundOrders(tab, transfer, 0, 0);
      if (res?.success === true) {
        setOrdersPrint(res?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentOrder === 'inbound') {
      const result = await getInBoundOrders(tab, 0, 0);
      if (result?.success === true) {
        setOrdersPrint(result?.data?.data);
        setOpenPrint(true);
      }
    }
  };

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(
        wb,
        `${currentOrder}Orders_${dayjs().format('DD-MM-YYYY')}.xlsx`
      );
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, [currentOrder]);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        {/* <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <img src={OrderIcon} style={{ height: '22px' }} />
            <h1 className='Page__headline_title_fs'>{t('orders')}</h1>
          </div>
        </div> */}
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <PermissionRoute allowed={'CREATE_ORDER'} type='actions'>
              <Link
                to='/user/orders/add-orders'
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <i className='fa-solid fa-plus'></i>
                <span>{t('add_orders')}</span>
              </Link>
            </PermissionRoute>
            <PermissionRoute allowed={'CREATE_ORDER'} type='actions'>
              <Link
                to='/user/orders/add-orders'
                state={{ transfer: true }}
                className='mi_btn mi_btn_medium mi_btn_secondary bg_green'
              >
                <i className='fa-solid fa-right-left'></i>
                <span>{t('transfer')}</span>
              </Link>
            </PermissionRoute>
          </div>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              <img src={Search} className='Search__icon' />
              <input
                type='text'
                placeholder={t('search')}
                onInput={(e) => {
                  setSearchCapture(e.target.value);
                }}
              />
            </article>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              <img src={Filter} style={{ height: '18px' }} />
              <span>{t('filter')}</span>
            </button>

            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
              filters={disableFilter}
            />

            <PermissionRoute allowed={'EXPORT_ORDER'} type='actions'>
              <button
                onClick={openPrintPopup}
                className='mi_btn mi_btn_medium mi_btn_outline'
              >
                {/* <i className="fa-solid fa-file-export"></i> */}
                <img src={Export} style={{ height: '18px' }} />
                <span>{t('export')}</span>
              </button>
            </PermissionRoute>
          </div>
        </div>
        <div className='Page__tab_select_space'>
          <div className='tab__btn_group'>
            <div
              className={`tab__button ${
                currentOrder === 'outbound' && 'active'
              }`}
              onClick={() => handleTabChange('outbound')}
            >
              <h1 className='tab__btn_text_fs'>{t('outbound_orders')}</h1>
            </div>
            <div
              className={`tab__button ${
                currentOrder === 'inbound' && 'active'
              }`}
              onClick={() => handleTabChange('inbound')}
            >
              <h1 className='tab__btn_text_fs'>{t('inbound_orders')}</h1>
            </div>
          </div>
        </div>
        {currentOrder === 'outbound' ? (
          <OutboundOrders
            orgLevel={orgLevel}
            searchCapture={searchCapture}
            filteredColumns={filteredColumns}
            setTab={setTab}
            setTransfer={setTransfer}
          />
        ) : (
          currentOrder === 'inbound' && (
            <InboundOrders
              searchCapture={searchCapture}
              filteredColumns={filteredColumns}
              setTab={setTab}
              setTransfer={setTransfer}
            />
          )
        )}
      </section>

      <ImportPopup open={importOpenPopup} onClose={handleCloseImportPopup} />

      <ExportTable
        open={openPrint}
        onClose={handleClosePrintPopup}
        ordersPrint={ordersPrint}
        tableRef={tableRef}
        xport={xport}
        tab={tab}
        currentOrder={currentOrder}
      />
    </React.Fragment>
  );
}
