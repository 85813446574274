import { useEffect, useState } from "react";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import "./AddBeneficiary.css";
import SelectBeneficiary from "./SelectBeneficiary";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addBeneficiaries } from "../../../redux/inventoryActions";
import { callPopup } from "../../../store/slices/popupSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import AlertPopup from "../../components/alertPopup/AlertPopup";
import { useTranslation } from "react-i18next";

import LastMile from "/icons/all/Last Mile.svg";

export default function AddBeneficiary() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useLocation();
  const [error1, setError1] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [vacList, setVacList] = useState();
  const [reOpened, setReOpened] = useState();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const validate = (values) => {
    let errors = {};

    if (values.beneficiaryDetails.length < 1) {
      if (!values.vaccine) errors.vaccine = t("vaccine_name_error");
      if (!values.batchNo) errors.batchNo = t("batch_number_error");
      if (!values.dose) errors.dose = t("dose_number_error");
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      vaccine: "",
      batchNo: "",
      dose: "",
      beneficiaryId: "",
      beneficiaryDetails: [],
    },
    validate,
    onSubmit: async (values, { resetForm }) => {
      const beneficiaryData = {
        atomId: values.batchNo.id,
        beneficiaries: values.beneficiaryDetails,
      };
      setOpen(false);
      const res = await addBeneficiaries(beneficiaryData);
      if (res.success) {
        if (params?.state?.beneficiaries) {
          dispatch(
            callPopup({
              open: true,
              type: "success",
              page: "add-beneficiary",
              message: t("benefs_updated_successfully"),
            }),
          );
        } else {
          dispatch(
            callPopup({
              open: true,
              type: "success",
              page: "add-beneficiary",
              message: t("benefs_added_successfully"),
            }),
          );
        }

        resetForm();
        Navigate("/user/last-mile");
      } else {
        dispatch(
          callPopup({
            open: true,
            type: "error",
            page: "add-beneficiary",
            title: res.data.message,
            message: res.data.data,
          }),
        );
      }
    },
  });

  useEffect(() => {
    if (params?.state?.beneficiaries) {
      setReOpened(params?.state?.beneficiaries);
      formik.setFieldValue(
        "vaccine",
        params?.state?.beneficiaries?.productDetails?._id,
      );
      formik.setFieldValue("batchNo", {
        name: params?.state?.beneficiaries?.batchNo,
        id: params?.state?.beneficiaries?._id,
        count: params?.state?.beneficiaries?.quantityPerUnit,
      });
    }
  }, [params]);

  const addProduct = () => {
    const selectedVac = vacList.find(
      (vac) => formik.values.vaccine === vac._id,
    );
    if (
      formik.values.vaccine !== "" &&
      formik.values.batchNo !== "" &&
      formik.values.dose !== "" &&
      formik.values.beneficiaryId !== ""
    ) {
      setError1(false);
      setEditItem(false);
      formik.setValues({
        ...formik.values,
        beneficiaryDetails: [
          ...formik.values.beneficiaryDetails,
          {
            id: Math.floor(Math.random() * 1000),
            doseNo: formik.values.dose,
            beneficiaryId: formik.values.beneficiaryId,
            name: formik.values.patientName,
            dob: formik.values.age,
            parentName: formik.values.parentName,
            phoneNumber: formik.values.parentNumber,
            gender: formik.values.gender,
            batchNo: formik.values.batchNo.name,
            vaccine: selectedVac?.name,
          },
        ],
      });
      formik.setFieldValue("id", "");
      formik.setFieldValue("dose", "");
      formik.setFieldValue("beneficiaryId", "");
      formik.setFieldValue("parentName", "");
      formik.setFieldValue("patientName", "");
      formik.setFieldValue("parentNumber", "");
      formik.setFieldValue("age", "");
      formik.setFieldValue("gender", "");
    } else {
      toast.error(t("please_fill_all_the_details"));
    }
  };

  const updatePatient = (userId) => {
    if (
      formik.values.dose !== "" && 
      formik.values.beneficiaryId !== ""
    ) {
    formik.setFieldValue(
      "beneficiaryDetails",
      formik.values.beneficiaryDetails.map((user) =>
        user.id === formik.values.id
          ? {
              ...user,
              doseNo: formik.values.dose,
              beneficiaryId: formik.values.beneficiaryId,
              name: formik.values.patientName,
              dob: formik.values.age,
              parentName: formik.values.parentName,
              phoneNumber: formik.values.parentNumber,
              gender: formik.values.gender,
            }
          : user,
      ),
    );
    formik.setFieldValue("id", "");
    formik.setFieldValue("dose", "");
    formik.setFieldValue("beneficiaryId", "");
    formik.setFieldValue("parentName", "");
    formik.setFieldValue("patientName", "");
    formik.setFieldValue("parentNumber", "");
    formik.setFieldValue("age", "");
    formik.setFieldValue("gender", "");
    setEditItem(false);
   }else {
    toast.error(t("please_fill_all_the_details"));    
   }
  };

  const beneficiaryField =
    formik.values.batchNo.count - formik?.values?.beneficiaryDetails?.length <=
    0;

  return (
    <section className='Page__main_wrapper'>
      <form onSubmit={formik.handleSubmit}>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <img src={LastMile} className="Lastmile__icon"/>
            <h1 className='Page__headline_title_fs'>
              {reOpened ? t("update") : t("register_beneficiary")}
            </h1>
          </div>
          <BreadCrumb
            url1='/user/last-mile'
            Link1={t("last_mile")}
            Link2={t("add_beneficiary")}
          />
        </div>
        <AlertPopup
          open={open}
          setOpen={setOpen}
          handleClickOpen={handleClickOpen}
          formik={formik}
          reOpened={reOpened}
          t={t}
        />
        <SelectBeneficiary
          type='beneficiary'
          formik={formik}
          addProduct={addProduct}
          error1={error1}
          editItem={editItem}
          setEditItem={setEditItem}
          updatePatient={updatePatient}
          setVacList={setVacList}
          reOpened={reOpened}
        />
        <section className='InventoryOrders__actions'>
          <div className='Page__left_actions'>
            <Link to={"/user/last-mile"}>
              <button className='mi_btn mi_btn_medium mi_btn_outline'>
                <span>{t("back")}</span>
              </button>
            </Link>
            {beneficiaryField ? (
              <button
                type='submit'
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <span>{t("complete")}</span>
              </button>
            ) : (
              <button
                type='button'
                className='mi_btn mi_btn_medium mi_btn_primary'
                onClick={() => {
                  if (formik.values.beneficiaryDetails.length > 0) {
                    setOpen(true);
                  } else {
                    toast.error(t("please_add_beneficiaries"));
                  }
                }}
              >
                <span>{t("confirm")}</span>
              </button>
            )}
          </div>
        </section>
      </form>
    </section>
  );
}
