import React from 'react';
import './NewShipment.css';
import BreadCrumb from '../../../shared/utils/breadCrumb/BreadCrumb';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import ShipmentForm from './shipmentForm/ShipmentForm';
import { getInBoundOrders } from '../../../redux/orderActions';
import ShipmentTable from './shipmentTable/ShipmentTable';
import ProductTable from './shipmentTable/ProductTable';
import FetchProduct from './fetchProduct/FetchProduct';
import {
  Alert,
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { getCampaigns, getTransitNo } from '../../../redux/shipmentActions';
import { useDispatch } from 'react-redux';
import { callPopup } from '../../../store/slices/popupSlice';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';

export default function NewShipment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaignIdLocation = useLocation();
  const campaignIdData = campaignIdLocation?.state?.campaignId;
  const { id: orderIdParams } = useParams();
  const [shipmentCompleted, setShipmentCompleted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [orders, setOrders] = React.useState([]);
  const [campaigns, setCampaigns] = React.useState([]);

  const [tagShipment, setTagShipment] = React.useState(false);
  const [externalTracking, setExternalTracking] = React.useState(false);
  const [transitNo, setTransitNo] = React.useState(null);

  const navigate = useNavigate();
  const params = useLocation();

  // Fetching API for Orders
  const fetchData = async () => {
    try {
      const res = await getInBoundOrders('UNFULFILLED');
      if (res?.success) {
        setOrders(res.data.data);
      }
      const result = await getCampaigns(true, 0, 0);
      if (res?.success) {
        setCampaigns(result?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error); // Log error for debugging
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const { data: tripList } = useQuery({
    queryKey: ['transitNo'],
    queryFn: () => getTransitNo(),
  });

  // Getting ORDER ID, FROM, TO, SHIP INFO
  const [campaignID, setCampaignID] = React.useState(null);
  const [orderID, setOrderID] = React.useState(null);
  const [from, setFrom] = React.useState();
  const [fromDefault, setFromDefault] = React.useState();
  const [to, setTo] = React.useState();
  const [toDefault, setToDefault] = React.useState();
  const [shipmentDate, setShipmentDate] = React.useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [deliveryDate, setDeliveryDate] = React.useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [selectedOrder, setSelectedOrder] = React.useState('');
  const [productList, setProductList] = React.useState([]);

  const [fromLocation, setFromLocation] = React.useState();
  const [toLocation, setToLocation] = React.useState();

  React.useEffect(() => {
    if (orderIdParams) {
      setOrderID(orderIdParams);
    }
  }, [orderIdParams]);

  React.useEffect(() => {
    if (campaignIdData) {
      setCampaignID(campaignIdData);
    }
  }, [campaignIdData]);

  React.useEffect(() => {
    // Filtering ORDER Based on Order ID ( After Selected )
    const newSelectedOrder = orders?.filter((order) => order?._id === orderID);
    // Log selected order for debugging
    setSelectedOrder(newSelectedOrder);
  }, [orderID, orderIdParams, orders]);

  React.useEffect(() => {
    if (selectedOrder.length > 0) {
      setFrom(selectedOrder[0]?.destination?._id);
      setFromDefault(selectedOrder[0]?.destination);

      setTo(selectedOrder[0]?.source?._id);
      setToDefault(selectedOrder[0]?.source);

      setProductList(selectedOrder?.[0]?.products);
    }
  }, [selectedOrder]);

  // Product List Popup
  const [openProductPopup, setOpenProductPopup] = React.useState(false);
  const handleOpenProductPopup = () => {
    if (from) {
      setOpenProductPopup(true);
    } else {
      setErrorMessage(t('from_location_error'));
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }
  };

  const handleCloseProductPopup = () => {
    setOpenProductPopup(false);
  };

  //  Product List without ORDER ID Flow
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [productsListWOID, setProductsListWOID] = React.useState(null);

  //  Delete Rows
  const handleRemoveProducts = (id) => {
    const newProductsListWOID = productsListWOID?.filter(
      (prod) => prod?._id !== id
    );

    const newSelectedProducts = selectedProducts?.filter(
      (prod) => prod?._id !== id
    );

    const updatedSelectedProductList = selectedBatchList
      ?.flat()
      ?.filter((product) => product?.productId !== id);

    setProductsListWOID(newProductsListWOID);
    setSelectedProducts(newSelectedProducts);
    setSelectedBatchList(updatedSelectedProductList);
  };

  const fetchProductDetails = (id) => {
    if (id !== undefined) {
      const campaignRes = campaigns?.find((campaign) => campaign?._id === id);
      if (campaignRes) {
        setProductsListWOID([campaignRes?.productDetails]);
      }
    }
  };
  React.useEffect(() => {
    fetchProductDetails(campaignID);
  }, [campaignID, campaigns]);

  //  Product Batch List Selection
  const [selectedBatchList, setSelectedBatchList] = React.useState([]);

  React.useEffect(() => {
    if (params?.state?.shipmentData) {
      if (params?.state?.shipmentData?.orderId) {
        setSelectedBatchList(params?.state?.shipmentData?.products);
        setOrderID(params?.state?.shipmentData?.orderId);
        setDeliveryDate(params?.state?.shipmentData?.deliveryDate);
        setShipmentDate(params?.state?.shipmentData?.shippingDate);
        setFrom(params?.state?.shipmentData?.source);
        setTo(params?.state?.shipmentData?.destination);
        setFromLocation(params?.state?.fromLocations);
        setToLocation(params?.state?.toLocations);
      } else if (params?.state?.shipmentData?.campaignId) {
        setSelectedBatchList(params?.state?.shipmentData?.products);
        setCampaignID(params?.state?.shipmentData?.campaignId);
        setProductsListWOID(params?.state?.shipmentData?.productsListWOID);
        setDeliveryDate(params?.state?.shipmentData?.deliveryDate);
        setShipmentDate(params?.state?.shipmentData?.shippingDate);
        setFrom(params?.state?.shipmentData?.source);
        setTo(params?.state?.shipmentData?.destination);
        setFromLocation(params?.state?.fromLocations);
        setToLocation(params?.state?.toLocations);
      } else {
        setSelectedBatchList(params?.state?.shipmentData?.products);
        setProductsListWOID(params?.state?.shipmentData?.productsListWOID);
        setDeliveryDate(params?.state?.shipmentData?.deliveryDate);
        setShipmentDate(params?.state?.shipmentData?.shippingDate);
        setFrom(params?.state?.shipmentData?.source);
        setTo(params?.state?.shipmentData?.destination);
        setFromLocation(params?.state?.fromLocations);
        setToLocation(params?.state?.toLocations);
      }
    }
    if (params?.state?.selectedProducts) {
      setSelectedProducts(params?.state?.selectedProducts);
    }
  }, [params]);

  //  Final Data
  const createShipmentData = orderID
    ? {
        orderId: orderID,
        source: from,
        destination: to,
        products: selectedBatchList.flat(),
        shippingDate: shipmentDate,
        deliveryDate: deliveryDate,
        productList: productList,
        selectedOrder: selectedOrder,
        transitNo: transitNo,
        externalTracking: externalTracking,
      }
    : campaignID
    ? {
        campaignId: campaignID,
        source: from,
        destination: to,
        products: selectedBatchList.flat(),
        shippingDate: shipmentDate,
        deliveryDate: deliveryDate,
        productList: productList,
        selectedOrder: selectedOrder,
        productsListWOID: productsListWOID,
        transitNo: transitNo,
        externalTracking: externalTracking,
      }
    : {
        source: from,
        destination: to,
        products: selectedBatchList.flat(),
        shippingDate: shipmentDate,
        deliveryDate: deliveryDate,
        productsListWOID: productsListWOID,
        selectedOrder: selectedOrder,
        transitNo: transitNo,
        externalTracking: externalTracking,
      };

  //  Create shipment API Call
  const handleCreateShipment = async (e) => {
    e.preventDefault();

    if (!createShipmentData?.source) {
      setErrorMessage('From Address is Required');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }
    if (!createShipmentData?.destination) {
      setErrorMessage('To Address is Required');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }
    if (!createShipmentData?.shippingDate) {
      setErrorMessage('Shipping Date is Required');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }

    if (
      createShipmentData?.source &&
      createShipmentData?.destination &&
      createShipmentData?.shippingDate
    ) {
      navigate('/user/shipments/review-shipments', {
        state: {
          shipmentData: createShipmentData,
          selectedProducts: selectedProducts,
          fromLocations: fromLocation,
          toLocations: toLocation,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <div className='back_Link' onClick={() => navigate(-1)}>
              <i className='bx bx-arrow-back icon_blue'></i>
            </div>

            <h1 className='Page__headline_title_fs'>
              {t('add_shipment_title')}
            </h1>
          </div>
          <BreadCrumb
            url1='/user/shipments'
            Link1={t('shipment')}
            Link2={t('create_shipment')}
          />
        </div>

        <form className='ModalPopup__form_container'>
          <div className='CreateShipment__grid_layout'>
            <div className='CreateShipment__space'>
              <ShipmentForm
                ordersData={orders}
                orderID={orderID}
                setOrderID={setOrderID}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                shipmentDate={shipmentDate}
                setShipmentDate={setShipmentDate}
                deliveryDate={deliveryDate}
                setDeliveryDate={setDeliveryDate}
                fromDefault={fromDefault}
                toDefault={toDefault}
                campaigns={campaigns}
                campaignID={campaignID}
                setCampaignID={setCampaignID}
                orderIdParams={orderIdParams}
                selectedOrder={selectedOrder}
                setFromLocations={setFromLocation}
                setToLocations={setToLocation}
                t={t}
              />
              {/* {!tagShipment && ( */}
              <div className='Input__row two_column column_center'>
                <div className='Authform__terms_button'>
                  <Checkbox
                    // disabled={tagShipment ? true : false}
                    name='terms'
                    sx={{ padding: 0 }}
                    checked={externalTracking != '' ? true : false}
                    onChange={(e) => setExternalTracking(!externalTracking)}
                    value={externalTracking}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <p className='page__note_fs mi_font_bold'>
                    {t('nextleaf_msg')}
                  </p>
                </div>
              </div>
              {/* )} */}

              {/* {!externalTracking && ( */}
              <div className='Input__row two_column column_center'>
                <div className='Authform__terms_button'>
                  <Checkbox
                    disabled={tripList?.length > 0 ? false : true}
                    name='terms'
                    sx={{ padding: 0 }}
                    checked={tagShipment != '' ? true : false}
                    onChange={(e) => setTagShipment(!tagShipment)}
                    value={tagShipment}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <p className='page__note_fs mi_font_bold'>
                  {t('tag_existing_shipments')} 
                  </p>
                </div>
                {tagShipment && (
                  <div className='Input__column'>
                    {/* <p className='Input__label_fs'></p> */}
                    <FormControl fullWidth>
                      <Autocomplete
                        size='small'
                        className='mui_custom_autocomplete'
                        value={transitNo || ''}
                        onChange={(event, newValue) => setTransitNo(newValue)}
                        options={tripList?.map((item) => item) || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className='mui_custom_autocomplete'
                            size='small'
                            placeholder={t('transit_number')}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}
              </div>
              {/* )} */}

              <div className='CreateShipment__table'>
                <div className='Select__tab_headline'>
                  <h1 className='page__body_heading_fs'>
                    {t('product_list')} :
                  </h1>
                </div>
                {orderID ? (
                  <ShipmentTable
                    selectedOrder={selectedOrder}
                    selectedBatchList={selectedBatchList}
                    setSelectedBatchList={setSelectedBatchList}
                    productList={productList}
                    setProductList={setProductList}
                    setSelectedOrder={setSelectedOrder}
                    t={t}
                  />
                ) : (
                  <>
                    <ProductTable
                      products={productsListWOID}
                      handleRemoveProducts={handleRemoveProducts}
                      selectedBatchList={selectedBatchList}
                      setSelectedBatchList={setSelectedBatchList}
                      campaignID={campaignID}
                      t={t}
                    />
                    {campaignID === null ? (
                      <div className='ShipProdTable__addmore'>
                        <div
                          className='ShipProdTable__addmore_btn'
                          onClick={handleOpenProductPopup}
                        >
                          <i className='fa-solid fa-plus'></i>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>

              <section className='Create__shipment_action Create__shipment_action_space'>
                <div>
                  {errorMessage && (
                    <Alert severity='error'>{errorMessage}</Alert>
                  )}
                </div>

                <div className='Page__left_actions'>
                  {shipmentCompleted ? (
                    <Link
                      to='/user/shipments'
                      type='submit'
                      className='mi_btn mi_btn_medium mi_btn_primary'
                    >
                      <span>{t('go_to_shipments')}</span>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to='/user/shipments'
                        type='button'
                        className='mi_btn mi_btn_medium mi_btn_outline'
                      >
                        <span>{t('back')}</span>
                      </Link>
                      {productList?.length > 0 ? (
                        <button
                          type='submit'
                          onClick={(e) => handleCreateShipment(e)}
                          className={`mi_btn mi_btn_medium ${
                            productList?.length > 0
                              ? productList?.length >=
                                selectedBatchList?.flat()?.length
                                ? 'mi_btn_primary'
                                : 'mi_btn_disabled'
                              : 'mi_btn_disabled'
                          }`}
                          disabled={
                            productList?.length > 0
                              ? productList?.length ===
                                selectedBatchList?.flat()?.length
                                ? false
                                : true
                              : true
                          }
                        >
                          <span>{t('confirm')}</span>
                        </button>
                      ) : (
                        <button
                          type='submit'
                          onClick={(e) => handleCreateShipment(e)}
                          className={`mi_btn mi_btn_medium ${
                            productsListWOID?.length > 0
                              ? productsListWOID?.length ===
                                selectedBatchList?.flat()?.length
                                ? 'mi_btn_primary'
                                : 'mi_btn_disabled'
                              : 'mi_btn_disabled'
                          }`}
                          disabled={
                            productsListWOID?.length > 0
                              ? productsListWOID?.length ===
                                selectedBatchList?.flat()?.length
                                ? false
                                : true
                              : true
                          }
                        >
                          <span>{t('confirm')}</span>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
        </form>
      </section>
      <FetchProduct
        keepMounted
        from={from}
        open={openProductPopup}
        onClose={handleCloseProductPopup}
        productsListWOID={productsListWOID}
        setProductsListWOID={setProductsListWOID}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        t={t}
      />
    </React.Fragment>
  );
}
