import React from "react";
import "./NotificationCard.css";
import { Tooltip } from "@mui/material";
import { useUpdateNotificationMsg } from "../../../services/other-services/mutations";

export default function NotificationCard({
  data,
  type,
  eventType,
  title,
  body,
  isCritical,
  isRead,
  date,
}) {
  const { mutate } = useUpdateNotificationMsg();

  const handleReadStatus = (id) => {
    mutate(id);
  };
  return (
    <React.Fragment>
      <div
        className={`NotificationFieldBody__row ${isRead && "Readed"}`}
        onClick={() => handleReadStatus(data?._id)}
      >
        <div className="NotificationFieldBody__body">
          <div className="NotificationFieldBody__header">
            <h1 className="NotificationFieldBody__heading_fs">
              {eventType}: {title}
            </h1>
            <div className="NotificationFieldBody__marks">
              {type === "ALERT" && (
                <Tooltip title="Alerts" placement="top-end" arrow>
                  <div>
                    <i className="fa-solid fa-circle-info alerts_icon"></i>
                  </div>
                </Tooltip>
              )}

              {isCritical && (
                <Tooltip title="Critical" placement="top-end" arrow>
                  <div>
                    <i className="fa-solid fa-triangle-exclamation critical_icon"></i>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <p className="NotificationFieldBody__body_fs">{body}</p>
        </div>
        <div className="Notification__timestamp">
          <p className="Notification__timestamp_fs">
            {new Date(date).toLocaleDateString()}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
