import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./VARform.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VARform6 = ({ setFormTab, setFormComplete, setData, storedData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  const formik = useFormik({
    initialValues: {
      options: storedData?.conditions?.type || "good",
      question1: storedData?.conditions?.labelsAttached || true,
      comment: storedData?.conditions?.comment || "",
    },
    validationSchema: Yup.object({
      options: Yup.string().required(t("required_error")),
      question1: Yup.boolean().required(t("required_error")),
      comment: Yup.string(),
    }),
    onSubmit: (values) => {
      setData(6, {
        labelsAttached: values.question1,
        type: values.options,
        comment: values.comment,
      });
      navigate("?form=part7");
      setFormComplete(7);

      toast.success(t("VAR_part6_saved"));
    },
  });

  const handlePrev = () => {
    navigate("?form=part5");
    setFormComplete(5);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="VARform">
      <div className="VARform__header">
        <h1 className="page__body_bigheading_fs">{t("VAR_part6_title")}</h1>
      </div>
      <div className="VARform__body">
        <div className="VARradio__questions two_column">
          <p className="page__body_fs mi_black">{t("VAR_part6_options")}</p>

          <div className="VARradio__questions two_column">
            <FormControl fullWidth>
              <Select
                {...formik.getFieldProps("options")}
                size="small"
                className="mui_custom_input_field"
              >
                <MenuItem value={"good"}>Good</MenuItem>
                <MenuItem value={"bad"}>Bad</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="VARradio__questions two_column extra_y_space">
          <p className="page__body_fs mi_black">{t("VAR_part6_question1")}</p>
          <FormControl>
            <RadioGroup row {...formik.getFieldProps("question1")}>
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="VARradio__questions two_column">
          <div className="Input__column">
            <p className="Input__label_fs">{t("VAR_part6_comment")}</p>
            <textarea
              {...formik.getFieldProps("comment")}
              className="mi_custom_textarea"
            />
            {formik.errors.comment && formik.touched.comment && (
              <span className="Input__error_fs">{formik.errors.comment}</span>
            )}
          </div>
          <div></div>
        </div>
      </div>
      <div className="VARform__footer">
        <div className="Input__column_action">
          <button
            type="button"
            className="mi_btn mi_btn_medium mi_btn_secondary"
            onClick={handlePrev}
          >
            <span>{t("prev")}</span>
          </button>
          <button className="mi_btn mi_btn_medium mi_btn_primary" type="submit">
            <span>{t("save_continue")}</span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default VARform6;
