import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';
import AnalyticCard from '../../../../shared/utils/analyticCard/AnalyticCard';
import OrderInTable from './orderTables/OrderInTable';
import Shipped from '/icons/all/orders/Shipped.svg';
import OrdersAccepted from '/icons/all/orders/Orders Accepted.svg';
import OrdersRejected from '/icons/all/orders/Orders Rejected.svg';
import OrdersFulfilled from '/icons/all/orders/Order fullfilled.svg';
import PartiallyFulfilled from '/icons/all/orders/Partially fullfilled order.svg';
import OrdersCanceled from '/icons/all/orders/Cancelled order.svg';
import PartiallyShipped from '/icons/all/orders/Partially shipped order.svg';
import OrderReceived from '/icons/all/orders/Order received.svg';
import Summary from '/icons/all/orders/summary.svg';
import {
  getInBoundOrders,
  getInboundOrdersAnalytics,
} from '../../../../redux/orderActions';
import { formatNumber } from '../../../../shared/common/commonFunctions';

const InboundOrders = ({ searchCapture, filteredColumns, setTab }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('summary');
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [pagination, setPagination] = useState({
    recordsPerPage: 0,
    totalRecords: 0,
    currentPage: 0,
  });
  const [analyticUpdated, setAnalyticUpdated] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const statusMap = {
    summary: null,
    received: 'CREATED',
    accepted: 'ACCEPTED',
    fulfilled: 'FULFILLED',
    partial_fulfilled: 'PARTIALLY_FULFILLED',
    shipped: 'SHIPPED',
    partially_shipped: 'PARTIALLY_SHIPPED',
    rejected: 'REJECTED',
    cancelled: 'CANCELLED',
    transfer: 'CREATED',
  };

  const status = statusMap[currentTab];

  useEffect(() => {
    const fetchData = async () => {
      const [inboundAnalytics, inbound] = await Promise.all([
        getInboundOrdersAnalytics(),
        getInBoundOrders(status, page, limit),
      ]);
      setAnalytics(inboundAnalytics?.data);
      setOrders(inbound?.data?.data);
      setAllOrders(inbound?.data?.data);
      setPagination({
        recordsPerPage: inbound?.data?.limit,
        totalRecords: inbound?.data?.totalRecords,
        currentPage: inbound?.data?.page,
      });
    };
    setTab(status);
    fetchData();
  }, [currentTab, page, limit, status, analyticUpdated]);

  useEffect(() => {
    setPage(0);
  }, [currentTab]);

  useEffect(() => {
    if (searchCapture) {
      const fuse = new Fuse(allOrders, { keys: ['id'] });
      const result = fuse.search(searchCapture).map(({ item }) => item);
      setOrders(result.length ? result : []);
    } else {
      setOrders(allOrders);
    }
  }, [searchCapture, allOrders]);

  const myref = useRef();
  const [scrollClick, setScrollClicked] = useState('left-end');

  const scrollRight = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
    setScrollClicked(true);
  };

  const scrollLeft = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
  };

  return (
    <Fragment>
      <div className={`Analytic__wrapper`}>
        <div className='Scroll__Analytic_list' ref={myref}>
          <AnalyticCard
            state='summary'
            icon={Summary}
            title={t('summary')}
            number='-'
            tab={currentTab}
            setTab={setCurrentTab}
            labelType='pink_label'
          />
          <AnalyticCard
            state='received'
            icon={OrderReceived}
            title={t('received')}
            number={formatNumber(analytics?.receivedOrders)}
            tab={currentTab}
            setTab={setCurrentTab}
            labelType='green_label'
          />
          <AnalyticCard
            state='accepted'
            icon={OrdersAccepted}
            title={t('accepted')}
            number={formatNumber(analytics?.acceptedOrders)}
            tab={currentTab}
            setTab={setCurrentTab}
            labelType='blue_label'
          />
          <AnalyticCard
            state='fulfilled'
            icon={OrdersFulfilled}
            title={t('fulfilled')}
            number={formatNumber(analytics?.fullFiledOrders)}
            tab={currentTab}
            setTab={setCurrentTab}
            labelType='green_label'
          />
          <AnalyticCard
            state='partial_fulfilled'
            icon={PartiallyFulfilled}
            title={t('partially_fulfilled')}
            number={formatNumber(analytics?.partialFullFiledOrders)}
            tab={currentTab}
            setTab={setCurrentTab}
            labelType='green_label'
          />
          <AnalyticCard
            state='shipped'
            icon={Shipped}
            title={t('shipped')}
            number={formatNumber(analytics?.shippedOrders)}
            tab={currentTab}
            setTab={setCurrentTab}
            labelType='green_label'
          />
          <AnalyticCard
            state='partially_shipped'
            icon={PartiallyShipped}
            title={t('partially_shipped')}
            number={formatNumber(analytics?.partiallyShippedOrders)}
            tab={currentTab}
            setTab={setCurrentTab}
            labelType='green_label'
          />
          <AnalyticCard
            state='cancelled'
            icon={OrdersCanceled}
            title={t('cancelled')}
            number={formatNumber(analytics?.canceledOrders)}
            tab={currentTab}
            setTab={setCurrentTab}
          />
          <AnalyticCard
            state='rejected'
            icon={OrdersRejected}
            title={t('rejected')}
            number={formatNumber(analytics?.rejectedOrders)}
            tab={currentTab}
            setTab={setCurrentTab}
          />
        </div>
        {scrollClick === 'right-end' && (
          <div
            className='scroll__analytic_btn left_btn'
            onClick={() => {
              scrollLeft(-1024);
              setScrollClicked('left-end');
            }}
          >
            <i className='fa-solid fa-chevron-left'></i>
          </div>
        )}

        {scrollClick === 'left-end' && (
          <div
            className='scroll__analytic_btn right_btn'
            onClick={() => {
              scrollRight(1024);
              setScrollClicked('right-end');
            }}
          >
            <i className='fa-solid fa-chevron-right'></i>
          </div>
        )}
      </div>

      <div className='Page__table_space' style={{ position: 'relative' }}>
        <OrderInTable
          orders={orders}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setAnalyticUpdated={setAnalyticUpdated}
          filteredColumns={filteredColumns}
          pagination={pagination}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      </div>
    </Fragment>
  );
};

export default InboundOrders;
