import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AnalyticTile from '../../../../shared/utils/analyticTile/AnalyticTile';
import ShipmentsOutTable from '../shipments/ShipmentsOutTable';
import ShipmentsInTable from '../shipments/ShipmentsInTable';

import OutboundShipment from '/icons/dashboard/outboundShip.svg';
import InboundShipment from '/icons/dashboard/inboundShip.svg';
import {
  useGetInboundSupplyData,
  useGetOutboundSupplyData,
} from '../../../../services/shipment-services/queries';
import { useSearchParams } from 'react-router-dom';

export default function ShipmentTable() {
  const { t } = useTranslation();
  const [currentOrder, setCurrentOrder] = useState('outbound');

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get('locations').split(','),
    fromDate: searchParams.get('fromDate') || null,
    toDate: searchParams.get('toDate') || null,
  };

  let data;
  switch (currentOrder) {
    case 'outbound':
      ({ data } = useGetOutboundSupplyData(apiBody, page, limit));
      break;
    case 'inbound':
      ({ data } = useGetInboundSupplyData(apiBody, false, page, limit));
      break;

    default:
      data = null;
  }

  const ShipmentOutboundColumns = [
    { key: 'from', title: 'from' },
    { key: 'shipment_date', title: 'shipment_date' },
    { key: 'shipment_id', title: 'shipment_id' },
    { key: 'transit_no', title: 'transit_no' },
    { key: 'order_id', title: 'order_id' },
    { key: 'to', title: 'to' },
    { key: 'status', title: 'status' },
  ];

  const ShipmentInboundColumns = [
    { key: 'from', title: 'from' },
    { key: 'shipment_date', title: 'shipment_date' },
    { key: 'shipment_id', title: 'shipment_id' },
    { key: 'transit_no', title: 'transit_no' },
    { key: 'order_id', title: 'order_id' },
    { key: 'to', title: 'to' },
    { key: 'status', title: 'status' },
  ];

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__tab_select_space'>
          <div className='tab__btn_group'>
            <AnalyticTile
              state='outbound'
              icon={OutboundShipment}
              title={t('outbound') + ' ' + t('shipments')}
              tab={currentOrder}
              setTab={setCurrentOrder}
            />

            <AnalyticTile
              state='inbound'
              icon={InboundShipment}
              title={t('inbound') + ' ' + t('shipments')}
              tab={currentOrder}
              setTab={setCurrentOrder}
            />
          </div>
        </div>
        {currentOrder === 'outbound' ? (
          <ShipmentsOutTable
            data={data}
            headers={ShipmentOutboundColumns}
            tab={currentOrder}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        ) : (
          currentOrder === 'inbound' && (
            <ShipmentsInTable
              data={data}
              headers={ShipmentInboundColumns}
              tab={currentOrder}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )
        )}
      </section>
    </React.Fragment>
  );
}
