import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  getInboundIndentData,
  getInboundSupplyData,
  getOutboundIndentData,
  getOutboundSupplyData,
} from './api';

export const useGetInboundSupplyData = (data, page, limit) => {
  return useQuery({
    queryKey: ['inboundSupplyDataViz', { data, page }],
    queryFn: () => getInboundSupplyData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetOutboundSupplyData = (data, page, limit) => {
  return useQuery({
    queryKey: ['outboundSupplyDataViz', { data, page }],
    queryFn: () => getOutboundSupplyData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetInboundIndentData = (data, page, limit) => {
  return useQuery({
    queryKey: ['inboundIndentDataViz', { data, page }],
    queryFn: () => getInboundIndentData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetOutboundIndentData = (data, page, limit) => {
  return useQuery({
    queryKey: ['outboundIndentDataViz', { data, page }],
    queryFn: () => getOutboundIndentData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};
