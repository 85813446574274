import React from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { DialogActions, DialogTitle } from "@mui/material";
import BatchTable from "./batchTable/BatchTable";
import { getBatchByProduct } from "../../../redux/inventoryActions";
import ProductIcon from "../../../shared/utils/productIcon/ProductIcon";
import { capitalToNormalCase } from "../../../shared/utils/utils";

// Transition for Popup
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewBatch(props) {
  const { onClose, open, id, ...other } = props;
  const [batches, setBatches] = React.useState();
  const [pagination, setPagination] = React.useState({
    recordsPerPage: 0,
    totalRecords: 0,
    currentPage: 0,
  });
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(7);

  React.useEffect(() => {
    async function fetchData() {
      if (id) {
        const res = await getBatchByProduct(
          id,
          ["ACTIVE", "OPEN"],
          page,
          limit
        );
        setBatches(res?.data);
        setPagination({
          recordsPerPage: res?.data?.limit,
          totalRecords: res?.data?.totalRecords,
          currentPage: res?.data?.page,
        });
      }
    }
    fetchData();
  }, [id, page, limit]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className="ModalPopup__dialog"
      maxWidth={batches?.product?.type === "VACCINE" ? "lg" : "md"}
      open={open}
      TransitionComponent={Transition}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className="ModalPopup__header">
          <div className="ModalPopup__header__title_fs">
            Batch Details ::{" "}
            {batches?.product?.type &&
              capitalToNormalCase(batches?.product?.type)}
            :{" "}
            <ProductIcon
              type={batches?.product?.type}
              name={batches?.product?.name}
              size="tiny"
              fallback={<span>{batches?.product?.name}</span>}
            />
            {/* - {batches?.product?.id}{" "} */}
          </div>
          <div className="ModalPopup__close_icon" onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="ModalPopup__body">
          <section className="Page__main_wrapper">
            <div className="Page__table_space">
              <BatchTable
                batches={batches}
                pagination={pagination}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                category={batches?.product?.type}
              />
            </div>
          </section>
        </div>
      </DialogContent>
    </Dialog>
  );
}
