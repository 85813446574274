import React, {
  useEffect,
  useState,
  Suspense,
  useMemo,
  useCallback,
} from 'react';
import './GeoTracking.css';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { FormControl, MenuItem, Select } from '@mui/material';
import {
  getChildrenLocations,
  getChildrenOrgLevels,
} from '../../../redux/userActions';
import L from 'leaflet';
import toast from 'react-hot-toast';
import LocationMarker from '/icons/location.svg';
import OrderIcon from '/icons/all/orders/Orders.svg';
import MarkerPopup from './MakerPopup';

// Define marker icons outside of the component to avoid re-creation
const markerIcon = new L.Icon({
  iconUrl: LocationMarker,
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

export default function GeoTracking() {
  const { t } = useTranslation();
  const [orgLevel, setOrgLevel] = useState();
  const [locations, setLocations] = useState([]);
  const [levels, setLevels] = useState([]);



  useEffect(() => {
    const fetchOrgLevels = async () => {
      try {
        const resultLevel = await getChildrenOrgLevels();
        setLevels(resultLevel?.data || []);
      } catch (error) {
        toast.error('Failed to fetch organization levels');
      }
    };
    fetchOrgLevels();
  }, []);

  useEffect(() => {
    const fetchLocations = async (level) => {
      if (level) {
        try {
          const res = await getChildrenLocations(level);
          setLocations(res?.data || []);
        } catch (error) {
          toast.error('Failed to fetch locations');
        }
      }
    };
    fetchLocations(orgLevel);
  }, [orgLevel]);

  const position = useMemo(() => [23.8387, 90.24064], []);

  const handleOrgLevelChange = useCallback((e) => {
    setOrgLevel(e.target.value);
  }, []);

  return (
    <section className='Page__main_wrapper'>
      {/* <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img src={OrderIcon} style={{ height: "22px" }} />
          <h1 className='Page__headline_title_fs'>
            {" "}
            {t("data_visualization")}{" "}
          </h1>
        </div>
      </div> */}
      <div className='AddCampaign__container'>
        <div className='Input__table'>
          <div className='Input__row two_column'>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t('org_level')}</p>
              <FormControl fullWidth>
                <Select
                  size='small'
                  className='mui_custom_input_field'
                  value={orgLevel || ''}
                  onChange={handleOrgLevelChange}
                >
                  {levels?.map((level, index) => (
                    <MenuItem key={index} value={level?.value}>
                      <div className='all_uppers'> {level?.name}</div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className='geo__map_view'>
        <div className='ShipmentMap__container'>
          <MapContainer
            center={position}
            zoom={7}
            scrollWheelZoom={true}
            style={{ zIndex: 1, position: 'relative' }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
            />
            {orgLevel &&
              locations?.map((location) => (
                <Marker
                  key={location._id}
                  icon={markerIcon}
                  position={location?.location?.coordinates}
                >
                  <Popup>
                    <Suspense fallback={<div>Loading...</div>}>
                      <MarkerPopup location={location} />
                    </Suspense>
                  </Popup>
                </Marker>
              ))}
          </MapContainer>
        </div>
      </div>
    </section>
  );
}
