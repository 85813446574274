import { useEffect, useRef, useMemo } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import { API_URL } from '../../../config';
import sourceMarker from '/icons/home.svg';
import LocationMarker from '/icons/location.svg';

class CustomOSRMv1 extends L.Routing.OSRMv1 {
  buildRouteUrl(waypoints, options) {
    const coordinates = waypoints
      .map((wp) => wp.latLng.lng + ',' + wp.latLng.lat)
      .join(';');
    const params = {
      alternatives: options.alternatives,
      steps: options.steps,
    };
    const queryString = L.Util.getParamString(params);
    return API_URL.routingUrl + '/' + coordinates + queryString;
  }
}

export default function TripSummaryMap({ tripInfo, locationTrace }) {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const routingControl = useRef(null);

  const markerSourceIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: sourceMarker,
        iconSize: [40, 40],
        iconAnchor: [17, 46],
        popupAnchor: [0, -46],
      }),
    []
  );

  const markerDeliveredIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: LocationMarker,
        iconSize: [40, 40],
        iconAnchor: [17, 46],
        popupAnchor: [0, -46],
      }),
    []
  );

  useEffect(() => {
    const sourceLocation = tripInfo?.sourceLocation?.coordinates || [];

    const destinationLocations =
      tripInfo?.shipments?.map((shipment) => shipment?.location?.coordinates) ||
      [];

    const mapElement = mapRef.current;

    if (mapElement) {
      if (mapInstance.current) {
        mapInstance.current.remove();
        routingControl.current = null;
      }

      // const newMap = L.map(mapElement);
      const newMap = L.map(mapElement).setView([23.8387, 90.24064], 7); // Default Bangladesh View

      mapInstance.current = newMap;

      L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
        { attribution: '&copy; OpenStreetMap' }
      ).addTo(newMap);

      // Add markers
      if (sourceLocation.length) {
        new L.marker(sourceLocation, {
          icon: markerSourceIcon,
        }).addTo(newMap);
      }

      if (destinationLocations.length) {
        destinationLocations.forEach((loc) => {
          new L.marker(loc, {
            icon: markerDeliveredIcon,
          }).addTo(newMap);
        });
      }

      //   const list = [
      //     [22.852245, 88.851099],
      //     [22.852245, 90.851099],
      //   ];

      //   if (sourceLocation.length && destinationLocations.length) {
      //     routingControl.current = L.Routing.control({
      //       router: new CustomOSRMv1(),
      //       waypoints: [sourceLocation, ...destinationLocations],
      //       lineOptions: {
      //         styles: [{ color: '#0078ff', weight: 4 }],
      //       },
      //       addWaypoints: false,
      //       routeWhileDragging: true,
      //       createMarker: (i, waypoint) => {
      //         return L.marker(waypoint.latLng, {
      //           icon: i === 0 ? markerSourceIcon : markerDeliveredIcon,
      //           draggable: false,
      //         });
      //       },
      //     }).addTo(newMap);
      //   }

      if (locationTrace?.length > 0) {
        routingControl.current = L.Routing.control({
          router: new CustomOSRMv1(),
          waypoints: locationTrace,
          lineOptions: {
            styles: [{ color: '#99CF63', weight: 2 }],
          },
          addWaypoints: false,
          routeWhileDragging: true,
          createMarker: () => {
            return null;
          },
        }).addTo(newMap);
      }
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
        mapInstance.current = null;
        routingControl.current = null;
      }
    };
  }, [tripInfo, markerSourceIcon, markerDeliveredIcon]);

  return (
    <div className='ShipmentMap__container'>
      <div
        ref={mapRef}
        style={{ zIndex: 1, height: '60vh', position: 'relative' }}
      />
    </div>
  );
}
