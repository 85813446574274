import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { useTranslation } from 'react-i18next';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import { assetIcon } from '../../../../shared/utils/utils';
import ColdChainTableCard from './ColdChainTableCard';
import { API_URL } from '../../../../config';

export default function EquipmentTable({
  equipmentData,
  page,
  handleChangePage,
  limit,
  handleChangeRowsPerPage,
}) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label='simple table'>
        <TableHead className='mi_table__head'>
          <TableRow>
            <TableCell>
              <FieldHead title={t('Asset')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('Model')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('serial_no')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('equipment_type')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('PQS Status')} />
            </TableCell>

            <TableCell>
              <FieldHead title={t('Status')} />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className='mi_table__body'>
          {equipmentData?.data?.length > 0 ? (
            equipmentData?.data?.map((item) => (
              <RecallAdjustBodyRow item={item} />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: 0 }} colSpan={5}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={equipmentData?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function RecallAdjustBodyRow({ item }) {
  const { t } = useTranslation();
  const [collapseOpen, setCollapseOpen] = useState(false);

  const imageUrl = `${API_URL.imagesUrl}/${item?.imageId}`;
  return (
    <>
      <TableRow
        key={item?.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        onClick={() => setCollapseOpen(!collapseOpen)}
      >
        <TableCell>
          <div className='asset_banner'>
            <img
              src={item?.imageId ? imageUrl : assetIcon(item?.model)}
              alt=''
            />
          </div>
        </TableCell>
        <TableCell>
          <FieldBody text={item?.model} />
        </TableCell>

        <TableCell>
          <FieldBody text={item?.serialNumber} />
        </TableCell>
        <TableCell>
          <FieldBody text={item?.type} />
        </TableCell>
        <TableCell>
          <FieldBody text={item?.pqsStatus} />
        </TableCell>

        <TableCell>
          <StatusLabel
            status={item?.status}
            tone={item?.status === 'WORKING' ? 'green' : 'grey'}
          />
          <FieldBody />
        </TableCell>
      </TableRow>
      <TableRow className='mi_table__body_No_padding'>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <ColdChainTableCard key={item?._id} item={item} t={t} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
