import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../shared/utils/tableField/FieldBody";
import EmptyTable from "../../../shared/utils/emptyTable/EmptyTable";
import { assetIcon } from "../../../shared/utils/utils";
import { useTranslation } from "react-i18next";

export default function AssetSendingTable({ assets, handleRemoveProducts }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 485 }} aria-label="simple table">
          <TableHead className="mi_table__head">
            <TableRow>
              <TableCell>
                <FieldHead title={t("asset")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("model")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("id")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("serial_no")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("type")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("pqr_status")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("status")} />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mi_table__body">
            {assets?.length > 0 ? (
              <>
                {assets?.map((row) => {
                  return (
                    <SelectBatchRow
                      key={row.id}
                      row={row}
                      handleRemoveProducts={handleRemoveProducts}
                      assets={assets}
                    />
                  );
                })}
              </>
            ) : (
              <TableRow className="mi_table__body_No_padding">
                <TableCell style={{ padding: "2rem" }} colSpan={6}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function SelectBatchRow({ row, handleRemoveProducts, assets }) {
  return (
    <>
      <React.Fragment>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell>
            <div className="asset_banner_small">
              <img src={assetIcon(row?.model)} alt="asset" />
            </div>
          </TableCell>
          <TableCell>
            <FieldBody text={row?.model} color="bold" />
          </TableCell>

          <TableCell>
            <FieldBody text={row?.id} color="bold" />
          </TableCell>
          <TableCell>
            <FieldBody text={row?.serialNumber} color="bold" />
          </TableCell>
          <TableCell>
            <FieldBody text={row?.type} />
          </TableCell>
          <TableCell>
            <FieldBody text={row?.pqsStatus} />
          </TableCell>
          <TableCell>
            <FieldBody text={row?.status} />
          </TableCell>
          <TableCell>
            {assets?.length > 1 && (
              <div
                className="Review__action_ico delete_ico"
                onClick={() => handleRemoveProducts(row?._id)}
              >
                <i className="fa-solid fa-trash-can icon_blue"></i>
              </div>
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>
  );
}
