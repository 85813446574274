import React from 'react';
import './AnalyticTile.css';

export default function AnalyticTile({
  icon,
  title,
  state,
  tab,
  setTab,
  nonClickable,
  size,
}) {
  return (
    <React.Fragment>
      <div
        className={`AnalyticTile__card ${state && tab === state && 'active'} ${
          nonClickable ? 'nonClickable' : 'Clickable'
        }`}
        onClick={() => {
          if (state && setTab) {
            setTab(state);
          }
        }}
      >
        <div className={`AnalyticCard__header ${size}`}>
          {icon && <img src={icon} alt='icon' className='AnalyticTile__icon' />}

          <h1 className='AnalyticTile__title_fs'>{title}</h1>
        </div>
      </div>
    </React.Fragment>
  );
}
