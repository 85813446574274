import './ServicePage.css';
import AlertPage from './AlertPage/AlertPage';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserProfile from './ProfilePage/UserProfile';

export default function ServicePage() {
  const { t } = useTranslation();
  const { url: page } = useParams();
  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <i className='bx bx-cog'></i>
          <h1 className='Page__headline_title_fs'>{t('services')}</h1>
        </div>
      </div>
      <div className='Services__tab_bar'>
        <Link className={`Services__tab_button`} to='/user/services/profile'>
          <h2
            className={`Services__tab_link_fs ${
              page === 'profile' && 'active'
            }`}
          >
            {t('profile')}
          </h2>
        </Link>
        <Link to='/user/services/settings' className={`Services__tab_button `}>
          <h2
            className={`Services__tab_link_fs ${
              page === 'settings' && 'active'
            }`}
          >
            {t('manage_alerts')}
          </h2>
        </Link>
        {/* <div className='Services__tab_button'>
          <h2 className='Services__tab_link_fs'>My Orders</h2>
        </div>
        <div className='Services__tab_button'>
          <h2 className='Services__tab_link_fs'>Help & Support</h2>
        </div>
        <div className='Services__tab_button'>
          <h2 className='Services__tab_link_fs'>Others</h2>
        </div> */}
      </div>

      {page === 'settings' ? (
        <AlertPage t={t} />
      ) : (
        <UserProfile t={t} page={page} />
      )}
    </section>
  );
}
