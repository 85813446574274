import React, { useEffect, useRef, useState } from "react";

import IconSummary from "/icons/analytics/IconSummary.svg";
import BeneficiarySummary from "../../beneficiary/beneficiaryTables/BeneficiarySummary";
import TotalBeneficiaries from "../../beneficiary/beneficiaryTables/TotalBeneficiaries";
import { useTranslation } from "react-i18next";

import AnalyticTile from "../../../../shared/utils/analyticTile/AnalyticTile";
import {
  useGetBeneficiariesData,
  useGetUnitsUtilizedData,
} from "../../../../services/lastmile-services/queries";
import { useSearchParams } from "react-router-dom";

export default function LastMileTable() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState("summary");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  const apiBody = {
    locations: searchParams.get("locations").split(","),
    fromDate: searchParams.get("fromDate") || null,
    toDate: searchParams.get("toDate") || null,
  };

  let data;
  switch (currentTab) {
    case "summary":
      ({ data } = useGetUnitsUtilizedData(apiBody, page, limit));
      break;
    case "vaccinatedSoFar":
      ({ data } = useGetBeneficiariesData(apiBody, false, page, limit));
      break;
    case "vaccinatedToday":
      ({ data } = useGetBeneficiariesData(apiBody, true, page, limit));
      break;
    default:
      data = null;
  }

  let initialColumns = {};
  let columns = [];

  const summaryColumns = [
    { key: "vaccine", title: "vaccine" },
    { key: "batch_no", title: "batch_no" },
    { key: "dose_utilized", title: "dose_utilized" },
    { key: "status", title: "status" },
    { key: "last_updated", title: "last_updated" },
    { key: "action", title: "action" },
  ];

  const summaryInitialColumns = {
    vaccine: true,
    batch_no: true,
    dose_utilized: true,
    status: true,
    last_updated: true,
    action: true,
  };

  const totalBeneficiaryColumns = [
    { key: "vaccine", title: "vaccine" },
    { key: "batch_no", title: "batch_no" },
    { key: "dose_number", title: "dose_number" },
    { key: "beneficiary_id", title: "beneficiary_id" },
    { key: "vaccinated_date", title: "vaccinated_date" },
  ];

  const totalBeneficiaryInitialColumns = {
    vaccine: true,
    batch_no: true,
    dose_number: true,
    beneficiary_id: true,
    vaccinated_date: true,
  };

  if (currentTab === "summary") {
    initialColumns = summaryInitialColumns;
    columns = summaryColumns;
  } else if (currentTab === "vaccinatedSoFar") {
    initialColumns = totalBeneficiaryInitialColumns;
    columns = totalBeneficiaryColumns;
  } else if (currentTab === "vaccinatedToday") {
    initialColumns = totalBeneficiaryInitialColumns;
    columns = totalBeneficiaryColumns;
  }

  const [selectedColumns, setSelectedColumns] = useState(initialColumns);

  useEffect(() => {
    setSelectedColumns(initialColumns);
  }, [currentTab]);

  const filteredColumns = columns?.filter(
    (column) => selectedColumns[column.key],
  );

  const tableRef = useRef();

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__Analytic_list'>
        <AnalyticTile
          state='summary'
          icon={IconSummary}
          title={t("total_no_of_units_utilized")}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='vaccinatedSoFar'
          icon={IconSummary}
          title={t("no_of_benefs_vaccinated_so_far")}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='vaccinatedToday'
          icon={IconSummary}
          title={t("no_of_benefs_vaccinated_today")}
          tab={currentTab}
          setTab={setCurrentTab}
        />
      </div>

      <div className='Page__table_space'>
        {currentTab === "summary" && (
          <BeneficiarySummary
            t={t}
            tableRef={tableRef}
            filteredColumns={filteredColumns}
            beneficiariesList={data}
          />
        )}

        {currentTab === "vaccinatedSoFar" && (
          <TotalBeneficiaries
            beneficiariesList={data}
            filteredColumns={filteredColumns}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            t={t}
            tableRef={tableRef}
          />
        )}
        {currentTab === "vaccinatedToday" && (
          <TotalBeneficiaries
            beneficiariesList={data}
            filteredColumns={filteredColumns}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            currentTab={currentTab}
            t={t}
            tableRef={tableRef}
          />
        )}
      </div>
    </section>
  );
}
