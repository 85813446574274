import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";

export default function BatchLastMileTable({ formik, onClose, batchList, t }) {
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 485 }} aria-label="simple table">
          <TableHead className="mi_table__head">
            <TableRow>
              <TableCell>
                <FieldHead title={t("batch_no")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("mfg_date")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("exp_date")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("manufacturer")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("VVM_status")} />
              </TableCell>

              <TableCell>
                <FieldHead title={t("quantity")} />
              </TableCell>
              <TableCell align="center">
                <FieldHead title={t("no_of_vials")} align={"center"} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("dose_per_vial")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("action")} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mi_table__body">
            {batchList?.data?.length > 0 ? (
              batchList?.data?.map((row, i) => (
                <SelectBatchRow
                  key={i}
                  row={row}
                  formik={formik}
                  onClose={onClose}
                  t={t}
                />
              ))
            ) : (
              <TableRow
                className="mi_table__body_No_padding"
                sx={{ height: "300px" }}
              >
                <TableCell style={{ padding: "2rem" }} colSpan={6}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function SelectBatchRow({ row, i, formik, onClose, t }) {
  const addBatch = () => {
    formik.setFieldValue("batchNo", {
      name: row?.batchNo,
      id: row?._id,
      count: row?.quantityPerUnit,
    });
    onClose();
  };
  return (
    <React.Fragment>
      <TableRow
        key={i}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>
          <FieldBody
            text={row?.batchNo}
            subtext={
              row?.currentCampaignDetails?.id
                ? row?.currentCampaignDetails?.id
                : null
            }
            color="bold"
          />
        </TableCell>

        <TableCell>
          <FieldBody
            text={new Date(row?.mfgDate).toLocaleDateString()}
            color="bold"
          />
        </TableCell>
        <TableCell>
          <FieldBody
            text={new Date(row?.expDate).toLocaleDateString()}
            color="bold"
          />
        </TableCell>

        <TableCell>
          <FieldBody
            text={row?.manufacturer?.name.substring(0, 25) + "... "}
            color="bold"
          />
        </TableCell>

        <TableCell>
          {row?.vvmStatus ? (
            <FieldBody text={row?.vvmStatus} />
          ) : (
            <FieldBody subtext={"- - -"} />
          )}
        </TableCell>

        <TableCell>
          <FieldBody text={row?.quantity} color="bold" />
        </TableCell>
        <TableCell>
          <FieldBody text={row?.noOfUnits} />
        </TableCell>
        <TableCell align="center">
          <FieldBody text={row?.quantityPerUnit} color="bold" />
        </TableCell>
        <TableCell>
          <div className="Input__row ">
            <button
              type="button"
              className="mi_btn mi_btn_medium mi_btn_secondary"
              onClick={addBatch}
            >
              <span style={{ whiteSpace: "nowrap" }}>{t("add")}</span>
            </button>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
