import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import AnalyticCard from "../../../../shared/utils/analyticCard/AnalyticCard";
import ShipOutDamagedTable from "./ShipOutDamagedTable";
import ShipmentOutboundTable from "./ShipmentOutboundTable";
import Shipped from "/icons/all/shipments/Shipped.svg";
import Delivered from "/icons/all/shipments/delivered.svg";
import Summary from "/icons/all/shipments/summary.svg";
import PartiallyDelivered from "/icons/all/shipments/Shipment partially delivered.svg";
import {
  getOutboundShipments,
  getOutboundShipmentsAnalytics,
} from "../../../../redux/shipmentActions";
import { formatNumber } from "../../../../shared/common/commonFunctions";

const OutboundShip = ({
  searchCapture,
  filteredColumns,
  currentShipment,
  setTab,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState("summary");
  const [shipments, setShipments] = useState([]);
  const [allShipments, setAllShipments] = useState([]);
  const [shipmentAnalytics, setShipmentAnalytics] = useState({});
  const [pagination, setPagination] = useState({
    recordsPerPage: 0,
    totalRecords: 0,
    currentPage: 0,
  });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const statusMap = {
    summary: null,
    delivered: "RECEIVED",
    shipped: "CREATED",
    partially_delivered: "PARTIALLY_RECEIVED",
  };
  const status = statusMap[currentTab];

  useEffect(() => {
    const fetchData = async () => {
      const res = await getOutboundShipments(status, page, limit);
      setShipments(res.data.data);
      setAllShipments(res.data.data);
      setPagination({
        recordsPerPage: res.data.limit,
        totalRecords: res.data.totalRecords,
        currentPage: res.data.page,
      });
      const result = await getOutboundShipmentsAnalytics();
      setShipmentAnalytics(result.data);
    };
    setTab(status)
    fetchData();
  }, [currentTab, page, limit, status]);

  useEffect(() => {
    setPage(0);
  }, [currentTab]);

  useEffect(() => {
    if (searchCapture) {
      const fuse = new Fuse(allShipments, {
        keys: ["source.name", "destination.name", "id", "createdAt"],
      });
      const result = fuse.search(searchCapture).map(({ item }) => item);
      setShipments(result.length ? result : []);
    } else {
      setShipments(allShipments);
    }
  }, [searchCapture, allShipments]);

  return (
    <>
      <div className='Page__Analytic_list'>
        <AnalyticCard
          state='summary'
          icon={Summary}
          title={t("summary")}
          number='-'
          tab={currentTab}
          setTab={setCurrentTab}
          labelType='pink_label'
        />
        <AnalyticCard
          state='shipped'
          icon={Shipped}
          title={t("shipped")}
          number={formatNumber(shipmentAnalytics?.shippedOutboundShipments)}
          tab={currentTab}
          setTab={setCurrentTab}
          labelType='blue_label'
        />
        <AnalyticCard
          state='delivered'
          icon={Delivered}
          title={t("delivered")}
          number={formatNumber(shipmentAnalytics?.deliveredOutboundShipments)}
          tab={currentTab}
          setTab={setCurrentTab}
          labelType='green_label'
        />
        <AnalyticCard
          state='partially_delivered'
          icon={PartiallyDelivered}
          title={t("partially_delivered")}
          number={formatNumber(
            shipmentAnalytics?.partiallyDeliveredOutboundShipments,
          )}
          tab={currentTab}
          setTab={setCurrentTab}
        />
      </div>

      <div className='Page__table_space'>
        {currentTab === "damaged" ? (
          <ShipOutDamagedTable />
        ) : (
          <ShipmentOutboundTable
            shipments={shipments}
            filteredColumns={filteredColumns}
            pagination={pagination}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            currentShipment={currentShipment}
          />
        )}
      </div>
    </>
  );
};

export default OutboundShip;
