import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Collapse, TablePagination } from '@mui/material';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { useTranslation } from 'react-i18next';
import {
  orderInStatus,
  orderOutStatus,
} from '../../../../shared/common/commonFunctions';
import OrderProductList from '../../../components/orderProductList/OrderProductList';
import IconTransfer from '/icons/analytics/IconTransfer.svg';
import IconCampaign from '/icons/analytics/campaign.png';

export default function OrdersInTable({
  headers,
  data,
  tab,
  page,
  setPage,
  limit,
  setLimit,
}) {
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {headers.map((item, index) => (
              <TableCell key={index}>
                <FieldHead title={t(item?.title)} />
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body'>
          {data?.data?.length > 0 ? (
            data?.data?.map((row, i) => (
              <OrdersOutRow
                row={row}
                key={i}
                t={t}
                headers={headers}
                tab={tab}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: '2rem' }} colSpan={6}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={data?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function OrdersOutRow({ row, t, headers, tab }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [statusLabel, statusColor] = orderInStatus(row.status);

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        className='mi_collapse_visible_row'
      >
        {headers.map((column) => (
          <React.Fragment key={column.key}>
            {column.key === 'product_quantity' && (
              <TableCell align='center'>
                <div className='table__field_two_data'>
                  <FieldBody
                    text={row.products.length + ' ' + 'Product(s)'}
                    color='bold'
                  />
                </div>
              </TableCell>
            )}
            {column.key === 'order_sent_by' && (
              <TableCell align='center'>
                <FieldBody
                  text={
                    row.createdBy?.user?.firstName +
                    ' ' +
                    row.createdBy?.user?.lastName
                  }
                  color='bold'
                />
              </TableCell>
            )}
            {column.key === 'orderId' && (
              <TableCell align='center'>
                <FieldBody text={row.id} />
              </TableCell>
            )}
            {column.key === 'order_placed_on' && (
              <TableCell align='center'>
                <FieldBody
                  text={new Date(row.createdAt).toLocaleDateString()}
                />
              </TableCell>
            )}
            {column.key === 'delivery_location' && (
              <TableCell align='center'>
                <FieldBody text={row.source?.name} />
              </TableCell>
            )}

            {column.key === 'status' && (
              <TableCell>
                <StatusLabel status={statusLabel} tone={statusColor} />
              </TableCell>
            )}
          </React.Fragment>
        ))}
        <TableCell align='center'>
          {collapseOpen ? (
            <i
              className='fa-solid fa-chevron-up table_collapse_icon mi_link'
              onClick={() => setCollapseOpen(!collapseOpen)}
            ></i>
          ) : (
            <i
              className='fa-solid fa-chevron-down table_collapse_icon mi_link'
              onClick={() => setCollapseOpen(!collapseOpen)}
            ></i>
          )}
        </TableCell>
      </TableRow>
      <TableRow className='mi_table__body_No_padding'>
        <TableCell style={{ padding: 0 }} colSpan={9}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <div className='Collapse__table'>
              <div className='Collapse__row single_column'>
                <article className='Collapse__column_list'>
                  <OrderProductList products={row.products} tab='inbound' />
                </article>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
