import React from "react";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import { Tooltip } from "@mui/material";

export default function ShipmentDetails({ shipment, t }) {
  let statusLabel = shipment?.status;
  let statusColor = "grey";

  switch (shipment?.status) {
    case "CREATED":
      statusLabel = "Shipped";
      statusColor = "blue";
      break;

    case "RECEIVED":
      statusLabel = "Delivered";
      statusColor = "green";
      break;

    case "PARTIALLY_RECEIVED":
      statusLabel = "Partially Received";
      statusColor = "pink";
      break;
    default:
      statusLabel = shipment?.status;
      statusColor = "grey";
      break;
  }

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const copyToClipboard = (copy) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setTooltipOpen(true);
      return navigator.clipboard.writeText(copy);
    }
    return Promise.reject(
      "This browser does not support the Clipboard API. Please use a modern browser!"
    );
  };

  return (
    <div className="ShipmentDetails__card">
      <div className="ShipmentDetails__headline">
        <div className="ContentCol_flex">
          <h1 className="page__body_fs">{t("shipment_id")}</h1>
          <h1 className="page__body_heading_fs">{shipment?.id}</h1>
        </div>
        {shipment?.order?.id && (
          <div className="ContentCol_flex">
            <h1 className="page__body_fs">{t("order_id")}</h1>
            <h1 className="page__body_heading_fs">{shipment?.order?.id}</h1>
          </div>
        )}
        <StatusLabel status={statusLabel} tone={statusColor} />
      </div>
      <div className="ShipmentDetails__body">
        <div className="ShipmentDetails__body_column">
          {shipment?.transitNo && (
            <div className="ContentCol_flex">
              <h1 className="page__body_fs">{t("transit_no")}</h1>
            <div style={{display:"flex", alignItems:"center", gap:"0.6rem"}}>  
            <h1 className="page__body_heading_fs">{shipment?.transitNo}</h1>
             <Tooltip
               title="Copied"
               open={tooltipOpen}
               leaveDelay={1000}
               onClose={() => setTooltipOpen(false)}
             >
               <div
                 className="copy_icon mi_link"
                 onClick={() => copyToClipboard(shipment?.transitNo)}
               >
                 <i className="fa-regular fa-copy"></i>
               </div>
             </Tooltip>
            </div>
            </div>
          )}
          <div className="ContentCol_flex">
            <h1 className="page__body_fs">
              {t("shipment_created_by")}{" "}
            </h1>
            <h1 className="page__body_heading_fs mi_bold">
              {shipment?.createdBy?.user?.firstName +
                " " +
                shipment?.createdBy?.user?.lastName}{" "}
              at {shipment?.createdBy?.location?.name}
            </h1>
          </div>
          <div className="ContentCol_flex">
            <h1 className="page__body_fs">{t("shipped_location")}</h1>
            <h1 className="page__body_heading_fs mi_bold">
              {shipment?.source?.name}
            </h1>
          </div>
          <div className="ContentCol_flex">
            <h1 className="page__body_fs">{t("shipment_delivered_to")}</h1>
            <h1 className="page__body_heading_fs mi_bold">
              {shipment?.destination?.name}
            </h1>
          </div>
          <div className="ShipmentDetails__body_update">
            <div className="ContentCol_flex">
              <h1 className="page__body_fs">
                {t("shipment_created_date")}
              </h1>
              <h1 className="page__body_heading_fs mi_bold">
                {new Date(shipment?.createdAt).toLocaleDateString()}
              </h1>
            </div>
            <br />
            <div className="ContentCol_flex">
              <h1 className="page__note_fs">
                {t("last_updated_by")} :
              </h1>
              <div className="order__updated_list">
                {shipment?.updatedBy?.map((item, index) => (
                  <UpdatedInfo key={index} item={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function UpdatedInfo({ item }) {
  return (
    <div className="updated_space column">
      <h1 className="page__body_heading_fs mi_bold">
        {item?.user?.firstName + item?.user?.lastName} at {item?.location?.name}
      </h1>
    </div>
  );
}
