import axios from 'axios';
import { API_URL } from '../../config';
import { buildParams } from '../../utils/helper';

// Units Utilized Data Visualization
export const getUnitsUtilizedData = async (data, page, limit) => {
  const params = buildParams(data, page, limit);
  return (
    await axios.get(`${API_URL.inventoryUrlv2}/utilized`, {
      params,
    })
  ).data.data;
};

// Beneficiaries Data Visualization
export const getBeneficiariesData = async (data, isToday, page, limit) => {
  return (
    await axios.get(`${API_URL.beneficiariesUrlv2}`, {
      params,
    })
  ).data.data;
};
