import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { Checkbox } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";

export default function ProductListTable({
  productList,
  products,
  selectedProducts,
  handleToggleProduct,
  page,
  limit,
  handleChangePage,
  handleChangeRowsPerPage,
  pageType,
  t,
}) {
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 485 }} aria-label="simple table">
          <TableHead className="mi_table__head">
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <FieldHead title={t("category")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("name")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("product_id")} />
              </TableCell>
              {pageType !== "Adjustment" && (
                <TableCell align="center">
                  <FieldHead title={t("stock")} align={"center"} />
                </TableCell>
              )}
              {pageType !== "Adjustment" && (
                <TableCell align="center">
                  <FieldHead title={t("no_of_batches")} align={"center"} />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody className="mi_table__body">
            {products?.length > 0 ? (
              <>
                {" "}
                {products?.map((row) => {
                  const prodInfo =
                    pageType === "Adjustment"
                      ? row?.productDetails
                      : row?.product;
                  return (
                    <SelectBatchRow
                      key={row.product?.id}
                      row={row}
                      isSelected={selectedProducts?.some(
                        (selectedProduct) => selectedProduct._id === prodInfo?._id
                      )}
                      onToggleProduct={handleToggleProduct}
                      pageType={pageType}
                    />
                  );
                })}
              </>
            ) : (
              <TableRow className="mi_table__body_No_padding">
                <TableCell style={{ padding: "2rem" }} colSpan={6}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={productList?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}

function SelectBatchRow({ row, isSelected, onToggleProduct, pageType }) {
  const valueProd =
    pageType === "Adjustment" ? row?.productDetails : row?.product;

  const handleToggle = () => {
    onToggleProduct(row);
  };

  return (
    <>
      <React.Fragment>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell>
            <Checkbox checked={isSelected} onChange={handleToggle} />
          </TableCell>
          <TableCell>
            <FieldBody text={valueProd?.type} color="bold" />
          </TableCell>

          <TableCell>
            <ProductIcon
              type={valueProd?.type}
              name={valueProd?.name}
              size="tiny"
              fallback={<FieldBody text={valueProd?.name} color="bold" />}
            />
          </TableCell>
          <TableCell>
            <FieldBody text={valueProd?.id} color="bold" />
          </TableCell>
          {pageType !== "Adjustment" && (
            <TableCell align="center">
              <FieldBody
                text={row?.quantity + " " + row?.product?.units}
                color="brown"
              />
            </TableCell>
          )}

          {pageType !== "Adjustment" && (
            <TableCell align="center">
              <FieldBody text={row?.batches?.length} color="bold" />
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    </>
  );
}
