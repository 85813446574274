import React from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";

export default function ReOrderQuantity(props) {
  const { onClose, open, setReOrderCount, setOpenEditPopup, ...other } = props;

  const [QuantityCount, setQuantityCount] = React.useState(0);
  const [QuickBtnClick, setQuickBtnClick] = React.useState(null);

  const handleSubmitQuantity = (e) => {
    e.preventDefault();
    setReOrderCount(QuantityCount);
    onClose();
    setOpenEditPopup(false);
  };

  return (
    <>
      <React.Fragment>
        <Dialog
          sx={{
            "& .MuiDialog-paper": { width: "100%" },
          }}
          className="ModalPopup__dialog"
          maxWidth="sm"
          open={open}
          {...other}
        >
          <DialogContent
            sx={{ padding: "0 !important", borderRadius: "0rem !important" }}
          >
            <div className="EditOrderInventory__Popup">
              <div className="QuantityEntry__wrapper no_space">
                <QuantityField
                  QuantityCount={QuantityCount}
                  setQuantityCount={setQuantityCount}
                  QuickBtnClick={QuickBtnClick}
                  setQuickBtnClick={setQuickBtnClick}
                  onClose={onClose}
                  handleSubmitQuantity={handleSubmitQuantity}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </>
  );
}

function QuantityField({
  QuantityCount,
  setQuantityCount,
  QuickBtnClick,
  setQuickBtnClick,
  onClose,
  handleSubmitQuantity,
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="QuantityField__space">
        <div className="QuantityField__label">
          <h1 className="QuantityField__label_fs">{t("quantity")}</h1>
        </div>
        <div className="QuantityField__measure">
          <div
            className={`QuantityField__measure_btn ${
              QuantityCount === 0 && "disabled"
            } `}
            onClick={() => {
              if (QuantityCount > 0) {
                setQuantityCount(QuantityCount - 1);
              }
            }}
          >
            <i className="fa-solid fa-minus"></i>
          </div>
          <div className="QuantityField__measure_input">
            <input
              type="number"
              value={QuantityCount}
              onChange={(e) => setQuantityCount(e.target.value)}
              required
            />
          </div>
          <div
            className="QuantityField__measure_btn"
            onClick={() => {
              setQuantityCount(QuantityCount + 1);
            }}
          >
            <i className="fa-solid fa-plus"></i>
          </div>
        </div>
        <div className="QuantityField__quick_count">
          <div className="QuantityField__quick_count_header">
            {QuantityCount > 0 ? (
              <button
                className="QuantityField__quick_count_btn"
                onClick={() => {
                  setQuantityCount(0);
                  setQuickBtnClick(null);
                }}
              >
                {t("reset")}
              </button>
            ) : (
              <button className="QuantityField__quick_count_btn">
                {t("units")}
              </button>
            )}
          </div>
          <div className="QuantityField__quick_count_body">
            <div className="QuantityField__quick_count_row">
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 50 && "active"
                }`}
                onClick={() => {
                  setQuantityCount(50);
                  setQuickBtnClick(50);
                }}
              >
                50
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 100 && "active"
                }`}
                onClick={() => {
                  setQuantityCount(100);
                  setQuickBtnClick(100);
                }}
              >
                100
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 200 && "active"
                }`}
                onClick={() => {
                  setQuantityCount(200);
                  setQuickBtnClick(200);
                }}
              >
                200
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 500 && "active"
                }`}
                onClick={() => {
                  setQuantityCount(500);
                  setQuickBtnClick(500);
                }}
              >
                500
              </button>
            </div>
            <div className="QuantityField__quick_count_row">
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 1000 && "active"
                }`}
                onClick={() => {
                  setQuantityCount(1000);
                  setQuickBtnClick(1000);
                }}
              >
                1000
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 1500 && "active"
                }`}
                onClick={() => {
                  setQuantityCount(1500);
                  setQuickBtnClick(1500);
                }}
              >
                1500
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 5000 && "active"
                }`}
                onClick={() => {
                  setQuantityCount(5000);
                  setQuickBtnClick(5000);
                }}
              >
                5000
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 10000 && "active"
                }`}
                onClick={() => {
                  setQuantityCount(10000);
                  setQuickBtnClick(10000);
                }}
              >
                10000
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="QuantityEntry__actions">
        <button
          onClick={(e) => handleSubmitQuantity(e)}
          disabled={QuantityCount == 0}
          className={`mi_btn mi_btn_medium ${
            QuantityCount === 0 ? "mi_btn_disabled" : "mi_btn_primary"
          }`}
        >
          <span>
            {t("add_quantity")}
          </span>
        </button>
        <button
          className={`mi_btn mi_btn_medium mi_btn_secondary`}
          onClick={onClose}
        >
          <span>{t("cancel")}</span>
        </button>
      </div>
    </React.Fragment>
  );
}
