import React from "react";
import "./FileUpload.css";

const FileUpload = ({t}) => {
  return (
    <>
      <div className="file-card">
        <div className="file-inputs">
          <input type="file" />
          <button>
            <i className="fa-solid fa-cloud-arrow-up"></i>
            {t("upload_files")}
          </button>
        </div>

        <p className="main">{t("supported_files")}</p>
        <p className="info">{t("excel")}, {t("csv")}</p>
      </div>
    </>
  );
};

export default FileUpload;
