import React, { useEffect, useState } from 'react';
import './AlertPage.css';
import SwitchButton from '../../../../admin/common/switchButton/SwitchButton';
import {
  getUserInfo,
  updateAlertPreference,
} from '../../../../redux/userActions';
import { camelToNormalCase } from '../../../../shared/utils/utils';
import { toast } from 'react-hot-toast';

export default function AlertPage({ t }) {
  const [alertSettings, setAlertSettings] = useState(null);
  const [btnEnable, setBtnEnable] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getUserInfo();
      setAlertSettings(res.data.alertPreferences);
    };
    fetchData();
  }, []);

  const handleAlertSettingChange = (name, value) => {
    setAlertSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
    setBtnEnable(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await updateAlertPreference(alertSettings);
    if (res.success) {
      toast.success('Preference Updated');
      setBtnEnable((prev) => !prev);
    }
  };

  return (
    <React.Fragment>
      <section className='Profile__page_container'>
        <article className='Profile_Info'>
          <div className='AlertPage__container'>
            <div className='alerts__grid_container'>
              <h1 className='page__body_heading_fs'>{t('alerts')}</h1>
              <h1 className='page__body_heading_fs'>{t('preferences')}</h1>
            </div>
            {alertSettings &&
              Object.entries(alertSettings).map(([type, value]) => (
                <AlertPageTrigger
                  key={type}
                  title={camelToNormalCase(type)}
                  value={value}
                  onChange={(newValue) =>
                    handleAlertSettingChange(type, newValue)
                  }
                />
              ))}
          </div>

          <div className='Collapse__accept_actions'>
            <button
              className={`mi_btn mi_btn_medium ${
                btnEnable ? 'mi_btn_primary' : 'mi_btn_disabled'
              } `}
              disabled={btnEnable ? false : true}
              onClick={(e) => handleSubmit(e)}
            >
              <span>{t('update_preference')}</span>
            </button>
          </div>
        </article>
      </section>
    </React.Fragment>
  );
}

function AlertPageTrigger({ title, value, onChange }) {
  const handleCheckedChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className='alerts__grid_container'>
      <h1 className='page__body_fs text_upper'>{title}</h1>
      <SwitchButton value={value} handleChange={handleCheckedChange} />
    </div>
  );
}
