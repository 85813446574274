import React, { useEffect } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { getUserLocations } from '../../../../redux/inventoryActions';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

export default function ShipmentForm({
  ordersData,
  orderID,
  setOrderID,
  from,
  setFrom,
  to,
  setTo,
  shipmentDate,
  setShipmentDate,
  deliveryDate,
  setDeliveryDate,
  fromDefault,
  toDefault,
  campaigns,
  campaignID,
  setCampaignID,
  orderIdParams,
  selectedOrder,
  setFromLocations,
  setToLocations,
  t,
}) {
  const { userInfo } = useSelector((state) => state.auth);
  // Order ID List Form Order Data
  const orderIdsList = ordersData?.map((order) => ({
    _id: order?._id,
    id: order?.id,
  }));

  // Location Dropdown States & Values
  const [fromLocation, setFromLocation] = React.useState();
  const [toLocation, setToLocation] = React.useState();

  const fetchData = async () => {
    const locationResult = await getUserLocations(); // Getting Location Info
    const allParentLocations =
      locationResult.data.locations.map((item) => item.parentLocation) || []; // Parent Locations
    setFromLocation(locationResult.data.locations);
    setToLocation(
      Array.from(new Set(allParentLocations)).concat(
        locationResult.data.childLocations
      )
    );
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const fromLocationList = fromLocation?.map((item) => ({
    _id: item?._id,
    name: item?.name,
  }));

  const toLocationList = toLocation?.map((item) => ({
    _id: item?._id,
    name: item?.name,
  }));

  // Getting Selected Order Locations
  const fromDefaultList = fromDefault && [
    {
      _id: fromDefault?._id,
      name: fromDefault?.name,
    },
  ];

  const toDefaultList = toDefault && [
    {
      _id: toDefault?._id,
      name: toDefault?.name,
    },
  ];

  // Options for with & without order ID
  const formOptions = orderID ? fromDefaultList : fromLocationList;

  const toOptions = orderID ? toDefaultList : toLocationList;

  useEffect(() => {
    const locIds = fromLocation?.find(
      (loc) => loc?._id === userInfo?.locationId
    );
    if (locIds) {
      setFrom(locIds?._id);
    }
  }, [fromLocation]);

  useEffect(() => {
    const locIds = fromLocation?.find((loc) => loc?._id === from);
    if (locIds) {
      setFromLocations(locIds);
    }
  }, [from]);

  useEffect(() => {
    const locIds = toLocation?.find((loc) => loc?._id === to);
    if (locIds) {
      setToLocations(locIds);
    }
  }, [to]);

  return (
    <section className='AddShipment__form'>
      <div className='ModalPopup__form_container'>
        <div className='ModalPopup__form_section'>
          <div className='Input__table'>
            <div className='Input__row two_column'>
              <div className='Input__column'>
                <p className='Input__label_fs'>{t('order_id')}</p>
                <FormControl fullWidth>
                  <Select
                    className='mui_custom_input_field'
                    size='small'
                    name='orderID'
                    value={orderID || ''}
                    onChange={(event) => {
                      setOrderID(event.target.value);
                    }}
                  >
                    {orderIdsList?.map((order, index) => (
                      <MenuItem key={index} value={order._id}>
                        {order.id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <p className='Input__label_fs note_fs'>
                  {t('add_shipment_note')}
                </p>
              </div>
              <div className='Input__column'>
                <p className='Input__label_fs'>
                  {t('campaign_name')} & {t('campaign_id')}{' '}
                </p>

                <>
                  {selectedOrder?.[0]?.campaign?.id ? (
                    <input
                      type='text'
                      className='mi_custom_textfield date_field'
                      id='shipmentDate'
                      value={selectedOrder?.[0]?.campaign?.id}
                      disabled
                    />
                  ) : (
                    <FormControl fullWidth>
                      <Select
                        className='mui_custom_input_field'
                        size='small'
                        name='orderID'
                        value={campaignID || ''}
                        onChange={(event) => {
                          setCampaignID(event.target.value);
                        }}
                        disabled={
                          orderIdParams
                            ? selectedOrder?.[0]?.campaign?.id
                              ? false
                              : true
                            : false
                        }
                      >
                        {campaigns?.map((campaign, index) => (
                          <MenuItem key={index} value={campaign?._id}>
                            {campaign?.name} - {campaign?.id}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>

        <div className='ModalPopup__form_section'>
          <div className='Input__row two_column'>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t('from')}</p>
              <FormControl fullWidth>
                <Select
                  className='mui_custom_input_field'
                  size='small'
                  name='from'
                  value={from || ''}
                  onChange={(event) => {
                    setFrom(event.target.value);
                  }}
                  disabled={orderID !== null ? true : false}
                >
                  {formOptions?.length ? (
                    formOptions?.map((loc) => (
                      <MenuItem key={loc._id} value={loc._id}>
                        {loc.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Option</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t('to')}</p>
              <FormControl fullWidth>
                <Select
                  className='mui_custom_input_field'
                  size='small'
                  name='to'
                  value={to || ''}
                  onChange={(event) => {
                    setTo(event.target.value);
                  }}
                  disabled={orderID !== null ? true : false}
                >
                  {toOptions?.length ? (
                    toOptions?.map((loc) => (
                      <MenuItem key={loc._id} value={loc._id}>
                        {loc.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Option</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div className='ModalPopup__form_section'>
          <div className='ModalPopup__label_header'>
            <h1 className='ModalPopup__form_section_heading_fs'>
              {t('delivery_details')}
            </h1>
          </div>

          <div className='Input__row two_column'>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t('shipment_date')}</p>
              <input
                type='date'
                className='mi_custom_textfield date_field'
                id='shipmentDate'
                name='shipmentDate'
                min={!deliveryDate && dayjs().format('YYYY-MM-DD')}
                max={deliveryDate}
                value={shipmentDate}
                onChange={(event) => {
                  setShipmentDate(event.target.value);
                }}
              />
            </div>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t('estimated_delivery_date')}</p>
              <input
                type='date'
                className='mi_custom_textfield date_field'
                id='deliveryDate'
                name='deliveryDate'
                min={shipmentDate}
                value={deliveryDate}
                onChange={(event) => {
                  setDeliveryDate(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
