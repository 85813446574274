import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import FieldBody from '../../../shared/utils/tableField/FieldBody';
import FieldHead from '../../../shared/utils/tableField/FieldHead';
import EmptyTable from '../../../shared/utils/emptyTable/EmptyTable';
import { capitalToNormalCase } from '../../../shared/utils/utils';
import ProductIcon from '../../../shared/utils/productIcon/ProductIcon';

export default function OrderProductList({ products, tab }) {
  const { t } = useTranslation();

  return (
    <section className='Table_border'>
      <TableContainer>
        <Table sx={{ minWidth: 485 }} aria-label='simple table'>
          <TableHead className='mi_table__head'>
            <TableRow>
              <TableCell>
                <FieldHead title={t('category')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('product')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('product_id')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('quantity')} />
              </TableCell>
              {tab === 'inbound' && (
                <TableCell>
                  <FieldHead title={t('child_location_current_stock')} />
                </TableCell>
              )}
              {tab === 'inbound' && (
                <TableCell>
                  <FieldHead title={t('Min')} />
                </TableCell>
              )}
              {tab === 'inbound' && (
                <TableCell>
                  <FieldHead title={t('Max')} />
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody className='mi_table__body'>
            {products?.length > 0 ? (
              products.map((product, i) => (
                <OrderProductRow product={product} t={t} tab={tab} />
              ))
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: '2rem' }} colSpan={5}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
}

function OrderProductRow({ product, t, tab }) {

  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <FieldBody text={capitalToNormalCase(product?.product?.type)} />
        </TableCell>
        <TableCell>
          <ProductIcon
            type={product?.product?.type}
            name={product?.product?.name}
            size='tiny'
            fallback={<FieldBody text={product?.product?.name} color='bold' />}
          />
        </TableCell>
        <TableCell>
          <FieldBody text={product?.product?.id} />
        </TableCell>
        <TableCell>
          <FieldBody
            text={product?.quantity + ' ' + product?.product?.units}
            color={'green'}
          />
        </TableCell>

        {tab === 'inbound' && (
          <TableCell align='center'>
            <FieldBody
              text={product?.currentStock + ' ' + product?.product?.units}
            />
          </TableCell>
        )}

        {tab === 'inbound' && (
          <TableCell>
            <FieldBody text={'1000'} />
          </TableCell>
        )}
        {tab === 'inbound' && (
          <TableCell>
            <FieldBody text={'5000'} />
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}
