import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import TablePagination from '@mui/material/TablePagination';
import { rejectedInventory } from '../../../../redux/inventoryActions';
import { useTranslation } from 'react-i18next';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';
import { capitalToNormalCase } from '../../../../shared/utils/utils';

export default function InvRejected({
  inventory,
  filteredColumns,
  tableRef,
  typePage,
}) {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [data, setData] = React.useState();

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await rejectedInventory(page, limit);
      setData(res.data);
    };
    if (typePage !== 'dataVisualization') {
      fetchData();
    }
  }, [page, limit]);

  const { t } = useTranslation();

  const apiData = typePage === 'dataVisualization' ? inventory : data;
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 486 }} ref={tableRef}>
          <TableHead className='mi_table__head'>
            <TableRow>
              {/* <TableCell>
                <FieldHead title="Product Category" />
              </TableCell>
              <TableCell>
                <FieldHead title="Product Name" />
              </TableCell>
              <TableCell align="center">
                <FieldHead title="Product ID" />
              </TableCell>

              <TableCell align="center">
                <FieldHead title="Total Quantity" />
              </TableCell>
              <TableCell align="center">
                <FieldHead title="Total Batches" />
              </TableCell>
              <TableCell align="center">
                <FieldHead title="View Batch Details" />
              </TableCell> */}
              {filteredColumns.map((column) => (
                <TableCell key={column.key}>
                  <FieldHead
                    title={t(column.title)}
                    align={column.key === 'viewBatch' ? 'center' : 'start'}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body'>
            {apiData?.data ? (
              <>
                {apiData?.data?.length > 0 ? (
                  <>
                    {apiData?.data?.map((row) => (
                      <InvWastedRow
                        row={row}
                        key={row._id}
                        filteredColumns={filteredColumns}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: '2rem' }} colSpan={6}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell
                  style={{ padding: '2rem' }}
                  colSpan={6}
                  align='center'
                >
                  <div className='Loading_message'>
                    <EmptyTable />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={apiData?.totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}

function InvWastedRow({ row, filteredColumns }) {
  const reasons = row?.status === 'OTHER' ? row?.comment : row?.status;
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {filteredColumns.map((column) => (
        <>
          {column.key === 'productCategory' && (
            <TableCell>
              <FieldBody text={capitalToNormalCase(row?.productId?.type)} />
            </TableCell>
          )}
          {column.key === 'productName' && (
            <TableCell>
              <ProductIcon
                type={row?.productId?.type}
                name={row?.productId?.name}
                size='tiny'
                fallback={
                  <FieldBody text={row?.productId?.name} color='bold' />
                }
              />
            </TableCell>
          )}
          {column.key === 'shipmentID' && (
            <TableCell>
              <FieldBody text={row?.lastShipment?.id} color='bold' />
            </TableCell>
          )}
          {column.key === 'batchNo' && (
            <TableCell>
              <FieldBody text={row?.batchNo} color='bold' />
            </TableCell>
          )}
          {column.key === 'manufacturer' && (
            <TableCell>
              <FieldBody text={row?.manufacturer?.name} />
            </TableCell>
          )}
          {column.key === 'rejectedLocation' && (
            <TableCell>
              <FieldBody text={'Rajbhari district'} />
            </TableCell>
          )}
          {column.key === 'rejectedDate' && (
            <TableCell>
              <FieldBody text={new Date(row?.updatedAt).toLocaleDateString()} />
            </TableCell>
          )}
          {column.key === 'rejectedQty' && (
            <TableCell>
              <FieldBody
                text={row?.quantity + ' ' + row?.productId?.units}
                color='red'
              />
            </TableCell>
          )}
          {column.key === 'rejectedReason' && (
            <TableCell>
              <FieldBody text={capitalToNormalCase(reasons)} color='red' />
            </TableCell>
          )}
          {/* {column.key === "rejectedReason" && (
            <TableCell>
              {row?.lastShipment?.rejectedProducts?.[0]?.batches.map((item) => {
                const reasons =
                  item?.type === "OTHER" ? item?.comment : item?.type;
                return (
                  <div className="reason_flex">
                    <FieldBody text={reasons} />
                    <FieldBody
                      text={
                        row?.lastShipment?.rejectedProducts?.[0]?.batches
                          ?.length > 1 && ","
                      }
                    />
                  </div>
                );
              })}
            </TableCell>
          )} */}
        </>
      ))}
    </TableRow>
  );
}
