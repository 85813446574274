import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { Collapse } from "@mui/material";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";

export default function ShipmentProdTable({ shipment, t }) {
  // campaign
  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }}>
        <TableHead className="mi_table__head">
          <TableRow>
            <TableCell>
              <FieldHead title={t("product_id")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("name")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("batch")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("campaign")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("ordered_qty")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("Shipped_qty")} />
            </TableCell>
            {shipment?.receivedProducts?.length > 0 && (
              <TableCell>
                <FieldHead title={t("received_qty")} />
              </TableCell>
            )}
            {shipment?.rejectedProducts?.length > 0 && (
              <TableCell>
                <FieldHead title={t("rejected_qty")} />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody className="mi_table__body mi_table_collapse_body">
          {shipment ? (
            shipment?.products?.length ? (
              shipment?.products.map((row) => (
                <ProductInfoRow
                  key={row._id}
                  row={row}
                  ordered={shipment?.order?.products}
                  received={shipment?.receivedProducts}
                  rejected={shipment?.rejectedProducts}
                  campaign={shipment?.campaign}
                  t={t}
                />
              ))
            ) : (
              <TableRow className="mi_table__body_No_padding">
                <TableCell style={{ padding: "2rem" }} colSpan={6}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )
          ) : (
            <TableRow className="mi_table__body_No_padding">
              <TableCell style={{ padding: 0 }} colSpan={6} align="center">
                <div className="Loading_message">
                  <p className="page__note_fs">{t("loading")}...</p>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ProductInfoRow({ ordered, rejected, received, row, campaign, t }) {
  const [collapseOpen, setCollapseOpen] = useState(false);

  // Filter Shipments
  const orderedProd = ordered?.find(
    (item) => item?.productId === row?.productId
  );

  const receivedProd = received?.find(
    (item) => item?.productId === row?.productId
  );

  const rejectedProd = rejected?.find(
    (item) => item?.productId === row?.productId
  );

  // Filter Mapping
  let shippedQty = 0;
  for (let i = 0; i < row?.batches?.length; i++) {
    shippedQty += row.batches[i]?.quantity;
  }

  const filtBatch = (id) => {
    const batch = row?.batches?.find((b) => b.atomId === id);
    return batch?.atom?.batchNo;
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <FieldBody text={row?.product?.id} color="blue" />
        </TableCell>
        <TableCell>
          <ProductIcon
            type={row?.product?.type}
            name={row?.product?.name}
            size="tiny"
            fallback={<FieldBody text={row?.product?.name} color="bold" />}
          />
        </TableCell>
        <TableCell>
          <FieldBody
            text={row?.batches?.[0]?.atom?.batchNo}
            subtext={
              row?.batches?.length > 1
                ? `+ ${row?.batches?.length - 1} Batch(s)`
                : null
            }
            color="bold"
          />
        </TableCell>
        <TableCell>
          <FieldBody text={campaign?.id ? campaign?.id : "---"} color="bold" />
        </TableCell>
        <TableCell>
          <FieldBody
            text={
              orderedProd?.quantity
                ? orderedProd?.quantity + " " + row?.product?.units
                : "---"
            }
            color="bold"
          />
        </TableCell>
        <TableCell>
          <FieldBody
            text={shippedQty + " " + row?.product?.units}
            color="bold"
          />
        </TableCell>
        {receivedProd && (
          <TableCell>
            <FieldBody
              text={receivedProd?.quantity + " " + row?.product?.units}
              color="bold"
            />
          </TableCell>
        )}

        {rejectedProd !== undefined ? (
          <TableCell>
            <div className="table__field_two_data" style={{ display: "flex" }}>
              <FieldBody text={rejectedProd?.quantity} color="bold" />
              {rejectedProd?.batches?.length > 0 && (
                <div
                  onClick={() => {
                    setCollapseOpen(!collapseOpen);
                  }}
                  className="mi_btn mi_btn_medium mi_btn_secondary"
                >
                  {collapseOpen ? (
                    <span>{t("hide_reason")}</span>
                  ) : (
                    <span>{t("view_reason")}</span>
                  )}
                </div>
              )}
            </div>
          </TableCell>
        ) : null}
      </TableRow>

      <TableRow className="mi_table__body_No_padding">
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <div className="Collapse__table">
              <h1
                className="page__body_fs mi_bold"
                style={{ paddingBottom: "0.3rem" }}
              >
                {t("rejected_reasons")}
              </h1>
              <div className="Collapse__row single_column">
                <article className="Collapse__column_list">
                  {rejectedProd?.batches?.map((batch, i) => {
                    const comments = batch?.type === "OTHER" && batch?.comment;
                    return (
                      <div className="table__field_two_data" key={i}>
                        {comments ? (
                          <FieldBody
                            color="blue"
                            text={
                              "(Batch: " +
                              filtBatch(batch?.atomId) +
                              ") " +
                              batch?.quantity +
                              " Units - " +
                              batch?.type +
                              " - " +
                              comments
                            }
                          />
                        ) : (
                          <FieldBody
                            color="blue"
                            text={
                              "(Batch: " +
                              filtBatch(batch?.atomId) +
                              ") " +
                              batch?.quantity +
                              " Units - " +
                              batch?.type
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </article>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
