import React from 'react';
import '../newShipment/NewShipment.css';
import BreadCrumb from '../../../shared/utils/breadCrumb/BreadCrumb';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { callPopup } from '../../../store/slices/popupSlice';
import { Alert } from '@mui/material';
import toast from 'react-hot-toast';
import AssetLocationForm from './AssetLocationForm';
import AssetSendingTable from './AssetSendingTable';
import FetchAssets from './fetchAssets/FetchAssets.jsx';
import { useCreateAssetTransfer } from '../../../services/coldchain-services/mutations.js';

export default function CreateAssetTransfer() {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = React.useState();

  const [to, setTo] = React.useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Product List Popup
  const [openProductPopup, setOpenProductPopup] = React.useState(false);

  const handleOpenProductPopup = () => {
    setOpenProductPopup(true);
  };

  const handleCloseProductPopup = () => {
    setOpenProductPopup(false);
  };

  //  Product Batch List Selection
  const [selectedAssetList, setSelectedAssetList] = React.useState([]);

  //  Delete Rows
  const handleRemoveProducts = (id) => {
    const updatedSelectedProductList = selectedAssetList
      ?.flat()
      ?.filter((item) => item?._id !== id);

    setSelectedAssetList(updatedSelectedProductList);
  };

  const assetIds = selectedAssetList?.flat()?.map((asset) => asset?._id);

  //  Final Data
  const createAssetTransferData = {
    source: userInfo?.locationId,
    destination: to,
    assetIds: assetIds,
  };

  const { mutate } = useCreateAssetTransfer();

  const handleAPIcall = (e) => {
    e.preventDefault();
    mutate(createAssetTransferData, {
      onSuccess: () => {
        navigate('/user/assets-transfer');
        dispatch(
          callPopup({
            open: true,
            type: 'success',
            page: 'assets',
            message: t('Asset Transferred Successfully'),
            action: '/user/assets-transfer',
          })
        );
      },
      onError: (error) => {
        callPopup({
          open: true,
          type: 'warn',
          page: 'orders',
          title: 'Something went Wrong',
          message: error,
        });
      },
    });
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <div
              className='back_Link'
              onClick={() => navigate('/user/assets-transfer')}
            >
              <i className='bx bx-arrow-back icon_blue'></i>
            </div>

            <h1 className='Page__headline_title_fs'>
              {t('create') + ' ' + t('asset_transfer')}
            </h1>
          </div>
          <BreadCrumb
            url1='/user/assets-transfer'
            Link1={t('asset')}
            Link2={t('asset_transfer')}
          />
        </div>

        <form className='ModalPopup__form_container'>
          <div className='CreateShipment__grid_layout'>
            <div className='CreateShipment__space'>
              <AssetLocationForm to={to} setTo={setTo} t={t} />

              <div className='CreateShipment__table'>
                <div className='Select__tab_headline'>
                  <h1 className='page__body_heading_fs'>{t('Asset List')} :</h1>

                  <p className='Input__note_fs body_fs'>
                    {t('adjustment_inv_note')}
                  </p>
                </div>

                <AssetSendingTable
                  assets={selectedAssetList}
                  handleRemoveProducts={handleRemoveProducts}
                />

                <div className='ShipProdTable__addmore'>
                  <div
                    className='ShipProdTable__addmore_btn'
                    onClick={handleOpenProductPopup}
                  >
                    <i className='fa-solid fa-plus'></i>
                  </div>
                </div>
              </div>

              <section className='Create__shipment_action Create__shipment_action_space'>
                <div>
                  {errorMessage && (
                    <Alert severity='error'>{errorMessage}</Alert>
                  )}
                </div>
                <div className='Page__left_actions'>
                  <Link
                    to='/user/inventory'
                    type='button'
                    className='mi_btn mi_btn_medium mi_btn_outline'
                  >
                    <span>{t('back')}</span>
                  </Link>

                  <button
                    type='submit'
                    className={`mi_btn mi_btn_medium mi_btn_primary`}
                    onClick={(e) => handleAPIcall(e)}
                  >
                    <span>{t('confirm')}</span>
                  </button>
                </div>
              </section>
            </div>
          </div>
        </form>
      </section>
      <FetchAssets
        keepMounted
        from={'6609d613ce6524ba7365a406'}
        open={openProductPopup}
        onClose={handleCloseProductPopup}
        selectedAssetList={selectedAssetList}
        setSelectedAssetList={setSelectedAssetList}
        t={t}
      />
    </React.Fragment>
  );
}
