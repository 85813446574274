import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertPopup({ open, setOpen, handleClickOpen, formik, reOpened, t }) {
  const handleClose = () => {
    setOpen(false);
  };
  
  const beneficiaryField =
    formik.values.batchNo.count - formik?.values?.beneficiaryDetails?.length <=
    0;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ backgroundColor: "#F8E6E6" }}>
          <DialogTitle id="alert-dialog-title">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="page__body_heading_fs">
                <i
                  className="fa-solid fa-circle-exclamation"
                  style={{ color: " rgb(249, 121, 121)", fontSize: "18px" }}
                ></i>
                {t("vial_wastage")}
              </p>

              <div className="ModalPopup__close_icon" onClick={handleClose}>
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {reOpened ? (
                <p className="page__body_heading_fs">
                {(reOpened?.quantityPerUnit - reOpened?.quantityUtilized) -
                  formik?.values?.beneficiaryDetails?.length >
                0
                  ? (reOpened?.quantityPerUnit - reOpened?.quantityUtilized) -
                    formik?.values?.beneficiaryDetails?.length +" "+
                    t("benefs_yet_to_add_msg")
                  : t("no_more_benefs_added_msg") }
              </p>
              ):(
                <p className="page__body_heading_fs">
                {formik.values.batchNo.count -
                  formik?.values?.beneficiaryDetails?.length >
                0
                  ? formik.values.batchNo.count -
                    formik?.values?.beneficiaryDetails?.length +" "+
                    t("benefs_yet_to_add_msg")
                  : t("no_more_benefs_added_msg") }
              </p>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="mi_btn mi_btn_medium mi_btn_primary"
              onClick={handleClose}
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              className="mi_btn mi_btn_medium mi_btn_primary"
              onClick={formik.handleSubmit}
            >
              {reOpened ? (
                (reOpened?.quantityPerUnit - reOpened?.quantityUtilized) -
                  formik?.values?.beneficiaryDetails?.length > 0
                  ? t("save_open")
                  : t("complete") 
              ):(
                 formik.values.batchNo.count -
                  formik?.values?.beneficiaryDetails?.length > 0
                  ? t("save_open")
                  : t("complete")
              )}
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
