import React from "react";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import "./ReviewInventory.css";
import ReviewProducts from "../../components/reviewProducts/ReviewProducts";
import { useLocation, useNavigate } from "react-router-dom";
import { addInventory } from "../../../redux/inventoryActions";
import { useDispatch } from "react-redux";
import { callPopup } from "../../../store/slices/popupSlice";
import { useTranslation } from "react-i18next";
import Inventory from "/icons/all/inventory/Current Stock.svg";

export default function ReviewInventory() {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [products, setProducts] = React.useState();

  const [inventoryCompleted, setInventoryCompleted] = React.useState(false);
  const dispatch = useDispatch();

  const params = useLocation();
  React.useEffect(() => {
    setProducts(params.state.products);
  }, [params]);

  const removeProduct = (id, batchNo, mfgDate, expDate) => {
    const AfterDelete = products?.filter(
      (item) =>
        item.productId !== id ||
        item.batchNo !== batchNo ||
        item.mfgDate !== mfgDate ||
        item.expDate !== expDate
    );
    setProducts(AfterDelete);
  };

  const goBack = () => {
    Navigate("/user/inventory/add-inventory", {
      state: { products: products },
    });
  };

  const addInv = async () => {
    const res = await addInventory({ products: products });
    if (res.success == true) {
      dispatch(
        callPopup({
          open: true,
          type: "success",
          page: "inventory",
          message: t("inventory_updated_msg"),
          action: "/user/inventory",
        })
      );
      setInventoryCompleted(true);
    } else {
      dispatch(
        callPopup({
          open: true,
          type: "error",
          page: "orders",
          title: res.data.message,
          message: res.data.data,
        })
      );
      setTimeout(() => {
        setAlertPopup({
          open: false,
          type: "",
          page: "",
          message: "",
        });
      }, 2000);
    }
  };

  return (
    <>
      <section className="Page__main_wrapper">
        <div className="Page__headline_wrapper">
          <div className="Page__headline">
            <img src={Inventory} className="Campaign__icon" />
            <h1 className="Page__headline_title_fs">{t("review_inventory")}</h1>
          </div>
          <BreadCrumb
            url1="/user/inventory"
            Link1={t("inventory")}
            Link2={t("add_inventory")}
            Link3={t("review_inventory")}
          />
        </div>

        <div className="Page__select_tab_wrapper">
          <div className="Page__select_tab_header">
            <div className="Select__tab_headline">
              <h1 className="page__body_heading_fs">{t("product_list")}</h1>
            </div>
          </div>
          <div className="Review__product_list">
            {products?.map((row, i) => (
              <ReviewProducts
                key={i}
                row={row}
                i={i}
                type="inventory"
                products={products}
                removeProductInventory={removeProduct}
                t={t}
              />
            ))}
          </div>
        </div>
        <section className="InventoryOrders__actions">
          {inventoryCompleted ? (
            <button
              onClick={() => Navigate("/user/inventory")}
              className="mi_btn mi_btn_medium mi_btn_primary"
            >
              <span>{t("go_to_inventory")}</span>
            </button>
          ) : (
            <div className="Page__left_actions">
              <button
                onClick={goBack}
                className="mi_btn mi_btn_medium mi_btn_outline"
              >
                <span>{t("back")}</span>
              </button>
              <button
                onClick={addInv}
                className="mi_btn mi_btn_medium mi_btn_primary"
              >
                <span>{t("confirm")} </span>
              </button>
            </div>
          )}
        </section>
      </section>
    </>
  );
}
