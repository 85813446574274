import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { TablePagination } from '@mui/material';
import { calculateAge } from '../../../../shared/common/commonFunctions';

export default function TotalBeneficiaries({
  beneficiariesList,
  page,
  filteredColumns,
  setPage,
  limit,
  setLimit,
  currentTab,
  t,
  tableRef,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }} ref={tableRef}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {filteredColumns.map((column, index) => (
              <TableCell key={column.key}>
                <FieldHead title={t(column.title)} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body'>
          {beneficiariesList?.data ? (
            <>
              {beneficiariesList?.data?.length > 0 ? (
                <>
                  {beneficiariesList?.data?.map((row, i) => (
                    <BenefSummaryRow
                      row={row}
                      key={i}
                      i={i}
                      currentTab={currentTab}
                      filteredColumns={filteredColumns}
                    />
                  ))}
                </>
              ) : (
                <TableRow className='mi_table__body_No_padding'>
                  <TableCell style={{ padding: '2rem' }} colSpan={7}>
                    <EmptyTable />
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: 0 }} colSpan={7} align='center'>
                <div className='Loading_message'>
                  <p className='page__note_fs'>Loading...</p>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={beneficiariesList?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function BenefSummaryRow({ row, i, currentTab, filteredColumns }) {
  const age = calculateAge(row?.dob);

  return (
    <TableRow
      key={i}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      {filteredColumns.map((column) => (
        <React.Fragment key={column?.key}>
          {column.key === 'vaccine' && (
            <TableCell>
              <FieldBody text={row?.productDetails?.name} />
            </TableCell>
          )}

          {column.key === 'batch_no' && (
            <TableCell>
              <FieldBody text={row?.batchNo} color={'bold'} />
            </TableCell>
          )}

          {column.key === 'dose_number' && (
            <TableCell>
              <FieldBody text={row?.doseNo ? row?.doseNo : '-'} />
            </TableCell>
          )}

          {column.key === 'beneficiary_id' && (
            <TableCell>
              <FieldBody text={'ID:' + row?.externalId} />
            </TableCell>
          )}

          {column.key === 'vaccinated_date' && (
            <TableCell>
              <FieldBody text={new Date(row?.createdAt).toLocaleDateString()} />
            </TableCell>
          )}
        </React.Fragment>
      ))}
    </TableRow>
  );
}
