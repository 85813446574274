import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../shared/utils/tableField/FieldBody";
import FetchBatch from "../newShipment/fetchBatch/FetchBatch";
import { Collapse } from "@mui/material";
import { t } from "i18next";

export default function ProductsTable({
  products,
  handleRemoveProducts,
  setSelectedBatchList,
  selectedBatchList,
  campaignID,
  t,
}) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }}>
        <TableHead className="mi_table__head br-top">
          <TableRow>
            <TableCell>
              <FieldHead title={t("category")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("name")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("stock")} />
            </TableCell>

            <TableCell>
              <FieldHead title={t("batch_no")+"."} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("sending_qty")} />
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="mi_table__body">
          {products?.map((product, index) => (
            <TableRowCard
              index={index}
              product={product}
              handleRemoveProducts={handleRemoveProducts}
              selectedBatchList={selectedBatchList}
              setSelectedBatchList={setSelectedBatchList}
              campaignID={campaignID}
              t={t}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TableRowCard({
  index,
  product,
  handleRemoveProducts,
  selectedBatchList,
  setSelectedBatchList,
  campaignID,
  t
}) {
  const valueProd = product;

  // Collapse Table
  const [collapseOpen, setCollapseOpen] = useState(false);

  // Fetch Popup States
  const [productId, setProductId] = React.useState();
  const [openBatchPopup, setOpenBatchPopup] = React.useState(false);

  const handleOpenBatchPopup = (product) => {
    setProductId(product?._id);
    setOpenBatchPopup(true);
  };

  const handleCloseBatchPopup = () => {
    setOpenBatchPopup(false);
  };

  // Getting Batch Details
  const newSelectedProducts = selectedBatchList
    ?.flat()
    ?.filter((prod) => prod?.productId === valueProd?._id);

  const batchInfo = newSelectedProducts?.[0]?.batches;

  const totalQuantity = batchInfo?.reduce(
    (acc, item) => acc + item?.quantity,
    0
  );

  return (
    <React.Fragment key={index}>
      <TableRow
        key={index}
        sx={{ "& > *": { borderBottom: batchInfo?.length > 1 && "unset" } }}
        className="mi_collapse_visible_row"
        onClick={() => setCollapseOpen(!collapseOpen)}
      >
        <TableCell>
          <FieldBody text={valueProd?.type} />
        </TableCell>
        <TableCell>
          <FieldBody text={valueProd?.name} />
        </TableCell>
        <TableCell>
          <FieldBody
            text={
              valueProd?.quantity
                ? valueProd?.quantity + " " + valueProd?.units
                : "--"
            }
            color="cyan"
          />
        </TableCell>

        {batchInfo?.length > 1 ? (
          <TableCell>
            <FieldBody text={"+" + batchInfo?.length} />
          </TableCell>
        ) : (
          <TableCell>
            <FieldBody text={batchInfo?.[0]?.batchNo} />
          </TableCell>
        )}

        <TableCell>
          <FieldBody
            text={totalQuantity ? totalQuantity + " " + valueProd?.units : ""}
          />
        </TableCell>

        <TableCell>
          {/* <div className="table__field_two_data end">
            <button
              type="button"
              className="mi_btn mi_btn_small mi_btn_outline"
              onClick={() => handleOpenBatchPopup(valueProd)}
            >
              {batchInfo === undefined ? (
                <>
                  <i className="fa-solid fa-plus"></i>
                  <span>{t("add")}</span>
                </>
              ) : (
                <>
                  <i className="fa-solid fa-pen"></i>
                  <span>{t("edit")}</span>
                </>
              )}
            </button>
            {campaignID === null ? (
              <div className="Review__action_ico delete_ico">
                <i
                  onClick={() => {
                    handleRemoveProducts(valueProd?._id);
                  }}
                  className="fa-solid fa-trash-can"
                ></i>
              </div>
            ) : null}
          </div> */}
        </TableCell>
      </TableRow>
      {batchInfo?.length > 1 && (
        <TableRow className="mi_table__body_No_padding">
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <div className="Collapse__table">
                <div className="Collapse__row">
                  <article className=" Collapse__column_list_row">
                    {batchInfo?.map((batch) => (
                      <div className="table__field_two_data_sm batch_divider">
                        <FieldBody subtext={"Batch No. :"} />
                        <FieldBody text={batch?.batchNo} color={"bold"} />
                        <p className="page__note_fs mi_blue">
                          ({t("quantity")}: {batch?.quantity + " " + valueProd?.units})
                        </p>
                      </div>
                    ))}
                  </article>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

    </React.Fragment>
  );
}
