import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { Checkbox } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import { assetIcon } from "../../../../shared/utils/utils";

export default function AssetListTable({
  equipmentData,
  assets,
  selectedAssetList,
  handleToggleProduct,
  page,
  limit,
  handleChangePage,
  handleChangeRowsPerPage,
  pageType,
  t,
}) {
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 485 }} aria-label="simple table">
          <TableHead className="mi_table__head">
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <FieldHead title={t("Asset")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("Model")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("Asset ID")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("serial_no")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("equipment_type")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("PQS Status")} />
              </TableCell>

              <TableCell>
                <FieldHead title={t("Status")} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mi_table__body">
            {assets?.length > 0 ? (
              <>
                {" "}
                {assets?.map((row) => {
                  return (
                    <SelectBatchRow
                      key={row.id}
                      row={row}
                      isSelected={selectedAssetList?.some(
                        (selectedItem) => selectedItem._id === row?._id
                      )}
                      onToggleProduct={handleToggleProduct}
                    />
                  );
                })}
              </>
            ) : (
              <TableRow className="mi_table__body_No_padding">
                <TableCell style={{ padding: "2rem" }} colSpan={6}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={equipmentData?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}

function SelectBatchRow({ row, isSelected, onToggleProduct }) {
  const handleToggle = () => {
    onToggleProduct(row);
  };

  return (
    <>
      <React.Fragment>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell>
            <Checkbox checked={isSelected} onChange={handleToggle} />
          </TableCell>
          <TableCell>
            <div className="asset_banner_small">
              <img src={assetIcon(row?.model)} alt="asset" />
            </div>
          </TableCell>
          <TableCell>
            <FieldBody text={row?.model} color="bold" />
          </TableCell>

          <TableCell>
            <FieldBody text={row?.id} color="bold" />
          </TableCell>
          <TableCell>
            <FieldBody text={row?.serialNumber} color="bold" />
          </TableCell>
          <TableCell>
            <FieldBody text={row?.type} />
          </TableCell>
          <TableCell>
            <FieldBody text={row?.pqsStatus} />
          </TableCell>
          <TableCell>
            <FieldBody text={row?.status} />
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>
  );
}
