import React from "react";
import "./ProductTable.css";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";

export default function ProductTable({ products }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="ProductTable__wrapper">
        <h1 className="page__body_heading_fs">
          {t("product_details")}
        </h1>
      </div>
      {products?.map((row, i) => (
        <ProductTableRow row={row} key={i} i={i} t={t} />
      ))}
    </React.Fragment>
  );
}

function ProductTableRow({ row, i, t }) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const copyToClipboard = (copy) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setTooltipOpen(true);
      return navigator.clipboard.writeText(copy);
    }
    return Promise.reject(
      "This browser does not support the Clipboard API. Please use a modern browser!"
    );
  };
  return (
    <div className="ProductTableRow__grid">
      <div className="product__no">
        <h1 className="page__body_fs mi_bold">{i + 1}.</h1>
      </div>
      <div className="product__info">
        <div className="Order__flex">
          <h1 className="page__body_fs mi_bold">
            {t("product_id")} - {row.product.id}
          </h1>
          <Tooltip
            title="Copied"
            open={tooltipOpen}
            leaveDelay={1000}
            onClose={() => setTooltipOpen(false)}
          >
            <div
              className="copy_icon mi_link"
              onClick={() => copyToClipboard(row.product.id)}
            >
              <i className="fa-regular fa-copy"></i>
            </div>
          </Tooltip>
        </div>
        <div className="product__info_row four_column">
          <div className="product__info_column">
            <h1 className="page__body_fs mi_bold"> {t("category")} :</h1>
            <p className="page__body_fs ">{row.product.type}</p>
          </div>
          <div className="product__info_column">
            <h1 className="page__body_fs mi_bold">{t("product")} :</h1>
            <ProductIcon
              type={row?.product?.type}
              name={row?.product?.name}
              size="tiny"
              fallback={<p className="page__body_fs ">{row?.product?.name}</p>}
            />
          </div>
          <div className="product__info_column">
            <h1 className="page__body_fs mi_bold">{t("quantity")} :</h1>
            <p className="page__body_fs ">
              {row.quantity + " " + row.product.units}
            </p>
          </div>
          <div></div>
          {/* <div className="product__info_column">
            <h1 className="page__body_fs mi_bold">Manufacturer :</h1>
            <p className="page__body_fs ">{row.productId.manufacturer.name}</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
