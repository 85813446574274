import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BreadCrumb from '../../../../shared/utils/breadCrumb/BreadCrumb';
import { getProducts } from '../../../../redux/userActions';
import { FormControl, MenuItem, Select } from '@mui/material';

import '../AutoIndent.css';
import { getNeedForCastData } from '../../../../services/inventory-services/api';
import toast from 'react-hot-toast';

export default function MinMaxIndentForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [product, setProduct] = React.useState(null);

  const [productList, setProductList] = React.useState(null);
  const [productInfo, setProductInfo] = React.useState(null);

  const [min, setMin] = React.useState(null);
  const [max, setMax] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await getProducts('VACCINE');
      setProductList(res.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData(id) {
      if (id) {
        const res = await getNeedForCastData(product?._id);
        setProductInfo(res);
        setMin(res?.threshold?.min);
        setMax(res?.threshold?.max);
      }
    }
    fetchData(product?._id);
  }, [product?._id]);

  const oneWeekData = min / 3;
  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <div className='back_Link' onClick={() => navigate(-1)}>
              <i className='bx bx-arrow-back '></i>
            </div>
            <h1 className='Page__headline_title_fs'>
              {t('minimum_maximum_based_indenting')}
            </h1>
          </div>
          {/* <BreadCrumb
            url1="/user/inventory/recall"
            Link1={`${t("recall")}`}
            Link2={`${t("create_recall")}`}
          /> */}
        </div>
      </section>
      <div className='auto_indenting_container'>
        <div className='auto_indent_grid'>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t('Vaccines')} <b>*</b>
            </p>
            <FormControl fullWidth>
              <Select
                className='mui_custom_input_field'
                value={product || ''}
                onChange={(event) => {
                  setProduct(event.target.value);
                }}
              >
                {productList &&
                  productList?.map((prod, index) => (
                    <MenuItem key={index} value={prod}>
                      {prod?.name?.length > 39
                        ? prod?.name.substring(0, 40) + '...'
                        : prod?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <article className='Input__column'>
            <p className='Input__label_fs'>
              {t('recommended_number_of_doses')}
            </p>
            <h1 className='TableData__bodyInfo'>
              {' '}
              {product?.dosePerBeneficiary ? product?.dosePerBeneficiary : '--'}
            </h1>
          </article>
        </div>

        <div className='auto_indent_grid'>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t('minimum_limit')}
              <b>*</b>
            </p>
            <input
              type='text'
              className='mi_custom_textfield'
              value={min}
              onChange={(e) => setMin(e.target.value)}
            />
          </div>
          <article className='Input__column'>
            <p className='Input__label_fs'>{t('estimated_wastage_rate')}</p>
            <h1 className='TableData__bodyInfo'>
              {product?.estimatedWastage ? product?.estimatedWastage : '--'}
            </h1>
          </article>
        </div>

        <div className='auto_indent_grid'>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t('maximum_limit')} <b>*</b>
            </p>
            <input
              type='text'
              className='mi_custom_textfield'
              value={max}
              onChange={(e) => setMax(e.target.value)}
            />
          </div>
          <article className='Input__column'>
            <p className='Input__label_fs'>{t('estimated_coverage_rate')}</p>
            <h1 className='TableData__bodyInfo '>
              {product?.estimatedCoverage ? product?.estimatedCoverage : '--'}
            </h1>
          </article>
        </div>

        <div className='auto_indent_grid'>
          <article className='Input__column'>
            <p className='Input__label_fs'>{t('total_requirements_doses')}</p>
            <h1 className='TableData__bodyInfo value'>
              {' '}
              {Math.round(oneWeekData * 3)}
            </h1>
          </article>
          <article className='Input__column'></article>
        </div>

        <div className='auto_indent_grid'>
          <article className='Input__column'>
            <p className='Input__label_fs'>{t('buffer_doses_for_3months')}</p>
            <h1 className='TableData__bodyInfo value'>
              {' '}
              {Math.round(oneWeekData * 4)}
            </h1>
          </article>
          <div className=''></div>
        </div>

        <div className='auto_indent_grid'>
          <article className='Input__column'>
            <p className='Input__label_fs'>
              {t('monthly_total_requirements_doses')}
            </p>
            <h1 className='TableData__bodyInfo value'>
              {' '}
              {Math.round(oneWeekData * 5)}
            </h1>
          </article>
          <div className=''></div>
        </div>

        <section className='top_space'>
          <div className='Page__left_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={() => {
                if (product?._id) {
                  toast.success('Data saved successfully');
                  navigate('/user/auto-indent');
                }
              }}
            >
              <span>{t('submit')}</span>
            </button>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
