import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import toast from "react-hot-toast";
import { MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



const PARform2 = ({ setFormComplete, setData, storedData }) => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      Question1: storedData?.documents?.invoice || true,
      Question2: storedData?.documents?.packingList || true,
      Question3: storedData?.documents?.productArrivalReport || true,
      Question4: storedData?.documents?.releaseCertificate || true,
      comment: storedData?.documents?.comment || "",
      options: storedData?.documents?.type || "good",
      Question5: storedData?.documents?.labelsAttached || true,
      other_comment: storedData?.documents?.other_comment || "",
    },
    onSubmit: (values) => {
      const finalData = {
        invoice: values.Question1,
        packingList: values.Question2,
        productArrivalReport: values.Question3,
        releaseCertificate: values.Question4,
        comment: values.comment,
        labelsAttached: values.Question5,
        type: values.options,
        other_comment: values.other_comment,
      };
      setData(2, finalData);
      navigate("?form=part3");
      setFormComplete(3);

      toast.success(t("PAR_part2_saved"));
    },
  });

  useEffect(()=>{
    if(storedData?.documents?.invoice){
      formik.setFieldValue("Question1", storedData?.documents?.invoice)
      formik.setFieldValue("Question2", storedData?.documents?.packingList)
      formik.setFieldValue("Question3", storedData?.documents?.productArrivalReport)
      formik.setFieldValue("Question4", storedData?.documents?.releaseCertificate)
    }
  }, [])


  const handlePrev = () => {
    navigate("?form=part1");
    setFormComplete(1);
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} className="VARform">
        <div className="VARform__header">
          <h1 className="page__body_bigheading_fs">
            {t("PAR_part2_title")}
          </h1>
        </div>
        <div className="VARform__body">
          <div className="VARform__table">
            <article className="VARform__row max_width">
              <div className="VARform__column">
                <div className="VARradio__questions two_column">
                  <p className="page__body_fs mi_black">{t("invoice")}</p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question1")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="VARradio__questions two_column">
                  <p className="page__body_fs mi_black">{t("packing_list")}</p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question2")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="VARradio__questions two_column">
                  <p className="page__body_fs mi_black">
                    {t("product_arrival_report")}
                  </p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question3")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="VARradio__questions two_column">
                  <p className="page__body_fs mi_black">{t("release_certificate")}</p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question4")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="VARradio__questions two_column">
                  <p className="page__body_fs mi_black">{t("other")}</p>

                  <div className="VARradio__questions two_column">
                    <input
                      type="text"
                      className="mi_custom_textfield"
                      {...formik.getFieldProps("comment")}
                    />
                  </div>
                </div>

                <div className="VARradio__questions two_column">
                  <p className="page__body_fs mi_black">
                   {t("PAR_part2_question1")}
                  </p>

                  <div className="VARradio__questions two_column">
                    <FormControl fullWidth>
                      <Select
                        {...formik.getFieldProps("options")}
                        size="small"
                        className="mui_custom_input_field"
                      >
                        <MenuItem value={"good"}>Good</MenuItem>
                        <MenuItem value={"bad"}>Bad</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="VARradio__questions two_column extra_y_space">
                  <p className="page__body_fs mi_black">
                    {t("PAR_part2_question2")}
                  </p>
                  <FormControl>
                    <RadioGroup row {...formik.getFieldProps("Question5")}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="VARradio__questions two_column">
                  <div className="Input__column">
                    <p className="Input__label_fs">
                      {" "}
                      {t("PAR_part2_comments")}
                    </p>
                    <textarea
                      className="mi_custom_textarea"
                      {...formik.getFieldProps("other_comment")}
                    />
                    {formik.touched.comment && formik.errors.comment && (
                      <span className="Input__error_fs">
                        {formik.errors.comment}
                      </span>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className="VARform__footer">
          <div className="Input__column_action">
            <button
              type="button"
              className="mi_btn mi_btn_medium mi_btn_secondary"
              onClick={handlePrev}
            >
              <span>{t("prev")}</span>
            </button>
            <button
              className="mi_btn mi_btn_medium mi_btn_primary"
              type="submit"
            >
              <span> {t("save_continue")} </span>
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default PARform2;
