import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  acceptAssetTransfer,
  addAsset,
  cancelAssetTransfer,
  createAssetTransfer,
} from './api';

export const useAddAsset = () => {
  return useMutation({
    mutationFn: (data) => addAsset(data),
  });
};

export const useCreateAssetTransfer = () => {
  return useMutation({
    mutationFn: (data) => createAssetTransfer(data),
  });
};

export const useAcceptAssetTransfer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (transferId) => acceptAssetTransfer(transferId),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['assetTransfers'] });
    },
  });
};

export const useCancelAssetTransfer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (transferId) => cancelAssetTransfer(transferId),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['assetTransfers'] });
    },
  });
};
