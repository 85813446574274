import * as React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

export function TemperatureGraph({ points }) {
  const dataPoints = points?.map((point) => ({
    ...point,
    timestamp: new Date(point.timestamp).toLocaleTimeString(),
  }));
  return (
    <LineChart width={850} height={450} data={dataPoints}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis label='Time' dataKey='timestamp' aria-label='Time' />
      <YAxis label='˚C' aria-label='Temperature in Celsius' />
      <Tooltip />
      <Legend />
      <Line
        type='monotone'
        name='Temperature'
        dataKey='temperature'
        stroke='#006400'
        activeDot={{ r: 7 }}
      />
     
    </LineChart>
  );
}
