import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';

export default function ProductInvTable({
  products,
  removeProductInventory,
  handleOpenEditPopup,
  setEditedProduct,
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className='ProductTable__wrapper'>
        <div className='Page__select_tab_wrapper'>
          <div className='Page__select_tab_header'>
            <div className='Select__tab_headline'>
              <h1 className='page__body_heading_fs'>
                {t('selected_product(s)')}:
              </h1>
            </div>
          </div>
          <div className='Page__product_table'>
            <TableContainer>
              <Table sx={{ minWidth: 485 }}>
                <TableHead className='mi_table__head br-top'>
                  <TableRow>
                    <TableCell>
                      <FieldHead title={t('product_name')} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t('quantity')} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t('batch_no')} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t('mfg_date')} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t('exp_date')} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t('value_per_dose')} />
                    </TableCell>
                    {/* <TableCell>
                      <FieldHead title={t('total') + ' ' + t('value')} />
                    </TableCell> */}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='mi_table__body'>
                  {products?.map((row, i) => (
                    <TableRowCard
                      row={row}
                      key={i}
                      i={i}
                      removeProductInventory={removeProductInventory}
                      handleOpenEditPopup={handleOpenEditPopup}
                      setEditedProduct={setEditedProduct}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function TableRowCard({
  row,
  i,
  removeProductInventory,
  handleOpenEditPopup,
  setEditedProduct,
}) {
  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <ProductIcon
            type={row?.type}
            name={row?.name}
            size='tiny'
            fallback={<FieldBody subtext={row.name} />}
          />
        </TableCell>
        <TableCell>
          <FieldBody subtext={row?.quantity + ' ' + row?.units} />
        </TableCell>
        <TableCell>
          <FieldBody subtext={row?.batchNo} />
        </TableCell>

        <TableCell>
          <FieldBody subtext={new Date(row?.mfgDate).toLocaleDateString()} />
        </TableCell>
        <TableCell>
          {row?.expDate ? (
            <FieldBody subtext={new Date(row?.expDate).toLocaleDateString()} />
          ) : (
            <FieldBody subtext={'- - -'} />
          )}
        </TableCell>
        <TableCell>
          <FieldBody
            subtext={row?.valueIndividual ? row?.valueIndividual : '---'}
          />
        </TableCell>
        {/* <TableCell>
          <FieldBody subtext={row?.value} />
        </TableCell> */}
        <TableCell>
          <div className='ReviewProducts__actions'>
            <div
              className='Review__action_ico edit_ico'
              onClick={() => {
                setEditedProduct(row);
                handleOpenEditPopup();
              }}
            >
              {' '}
              <i className='fa-solid fa-pencil icon_blue'></i>
            </div>

            <div
              className='Review__action_ico delete_ico'
              onClick={() => {
                removeProductInventory(row?.productId, row?.batchNo);
                toast.success('Product removed from the list');
              }}
            >
              <i className='fa-solid fa-trash-can icon_blue'></i>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
