import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import ReceiveBatch from "../receiveBatch/ReceiveBatch";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import { Collapse } from "@mui/material";
import Information from "/favicon/information.png";
import { useTranslation } from "react-i18next";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";

export default function AcceptShipTable({
  products,
  selectProductList,
  batchesList,
  setBatchesList,
  setShipmentAccepted,
  fullAcceptList,
  handleFullShipment,
  acceptStatus,
  setAcceptStatus,
}) {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }}>
        <TableHead className="mi_table__head">
          <TableRow>
            <TableCell>
              <FieldHead title={t("product_id")} />
            </TableCell>

            <TableCell>
              <FieldHead title={t("category")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("name")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("batch_no")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("quantity")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("status")} />
            </TableCell>

            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="mi_table__body mi_table_collapse_body">
          {products?.map((row, index) => (
            <TableBodyRow
              index={index}
              row={row}
              key={row._id}
              selectProductList={selectProductList}
              batchesList={batchesList}
              setBatchesList={setBatchesList}
              setShipmentAccepted={setShipmentAccepted}
              fullAcceptList={fullAcceptList}
              handleFullShipment={handleFullShipment}
              acceptStatus={acceptStatus[row._id] || "pending"}
              setAcceptStatus={setAcceptStatus}
              t={t}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TableBodyRow({
  index,
  row,
  setBatchesList,
  batchesList,
  setShipmentAccepted,
  fullAcceptList,
  handleFullShipment,
  acceptStatus,
  setAcceptStatus,
  t,
}) {
  // Product List Popup
  const [openProductPopup, setOpenProductPopup] = React.useState(false);
  const handleOpenProductPopup = () => {
    setOpenProductPopup(true);
  };

  const handleCloseProductPopup = () => {
    setOpenProductPopup(false);
  };

  const [checkStatus, setCheckStatus] = useState();
  const [checkPartialStatus, setCheckPartialStatus] = useState();

  useEffect(() => {
    if (fullAcceptList?.length > 0) {
      const data = fullAcceptList?.find(
        (prod) => prod?.productId === row?.product?._id
      );

      setCheckStatus(data?.productId);
    }
  }, [fullAcceptList, row?.product?._id]);

  useEffect(() => {
    if (batchesList?.length > 0) {
      const data = batchesList?.find(
        (prod) => prod?.productId === row?.product?._id
      );

      setCheckPartialStatus(data);
    }
  }, [batchesList, row?.product?._id]);

  // Collapse Table
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className="mi_collapse_visible_row mi_table__row_clickable"
      >
        <TableCell>
          <FieldBody text={row?.product?.id} color="blue" />
        </TableCell>

        <TableCell>
          <FieldBody text={row?.product?.type} />
        </TableCell>
        <TableCell>
          <ProductIcon
            type={row?.product?.type}
            name={row?.product?.name}
            size="tiny"
            fallback={<FieldBody text={row?.product?.name} />}
          />
        </TableCell>

        <TableCell>
          <div className="Accept_field">
            <FieldBody
              text={row?.batches?.[0]?.atom?.batchNo}
              subtext={
                row?.batches?.length > 1
                  ? `+ ${row?.batches?.length - 1} Batch(s)`
                  : null
              }
              tone="bold"
            />
            <img
              src={Information}
              alt="Icon"
              onClick={() => setCollapseOpen(!collapseOpen)}
              className="mi_table__row_clickable"
            />
          </div>
        </TableCell>
        <TableCell>
          <FieldBody text={row?.quantity + " " + row?.product.units} />
        </TableCell>

        <TableCell>
          {checkStatus === row?.product?._id && (
            <StatusLabel status={"Accepted"} tone="green" />
          )}
          {checkPartialStatus?.status === "Partially_Accepted" && (
            <StatusLabel status={"Partially Accepted"} tone="pink" />
          )}
          {checkStatus !== row?.product?._id &&
            checkPartialStatus?.status !== "Partially_Accepted" && (
              <StatusLabel status={"Pending"} tone="blue" />
            )}
        </TableCell>

        <TableCell align="center">
          {checkStatus !== row?.product?._id &&
          checkPartialStatus?.productId !== row?.product?._id ? (
            <div className="Table__actions end">
              {/* <button className='mi_btn mi_btn_small mi_btn_outline'>
                <span>Reject</span>
              </button> */}
              <button
                className="mi_btn mi_btn_small mi_btn_secondary"
                onClick={handleOpenProductPopup}
              >
                <span>{t("partial_accept")}</span>
              </button>
              <button
                className="mi_btn mi_btn_small mi_btn_primary"
                onClick={() => handleFullShipment(row?.product?._id)}
              >
                <span>{t("accept")}</span>
              </button>
            </div>
          ) : (
            <button
              className="mi_btn mi_btn_small mi_btn_secondary"
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              <span>{t("view_details")}</span>
            </button>
          )}

          {/* {checkStatus === row?._id ? (
            <div className="Table__actions end">
              <button className="mi_btn mi_btn_small mi_btn_outline">
                <span>Reject</span>
              </button>
              <button
                className="mi_btn mi_btn_small mi_btn_secondary"
                onClick={handleOpenProductPopup}
              >
                <span>Edit Reasons</span>
              </button>
            </div>
          ) : (
            <div className="Table__actions end">
              <button className="mi_btn mi_btn_small mi_btn_outline">
                <span>Reject</span>
              </button>
              <button
                className="mi_btn mi_btn_small mi_btn_secondary"
                onClick={handleOpenProductPopup}
              >
                <span>Partial Accept</span>
              </button>
              <button
                className="mi_btn mi_btn_small mi_btn_primary"
                onClick={() => handleFullShipment(row?.product?._id)}
              >
                <span>Accept</span>
              </button>
            </div>
          )} */}
        </TableCell>
      </TableRow>

      {checkPartialStatus?.batches ? (
        <TableRow className="mi_table__body_No_padding">
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <div className="Collapse__table flex__seperator">
                {/* <div className="Collapse__row bg_added">
                <article className=" Collapse__column_list_row">
                  <div
                    key={index}
                    className="table__field_two_data_sm batch_divider"
                  >
                    <FieldBody subtext={"Batch No :"} />
                    <FieldBody text={"123445"} color={"bold"} />
                  </div>
                  <div
                    key={index}
                    className="table__field_two_data_sm batch_divider"
                  >
                    <FieldBody subtext={"Received Qty:"} />
                    <FieldBody text={"30 Units"} color={"bold"} />
                  </div>
                  <div
                    key={index}
                    className="table__field_two_data_sm batch_divider"
                  >
                    <FieldBody subtext={"Accepted Qty:"} />
                    <FieldBody text={"20 Units"} color={"bold"} />
                  </div>
                  <div
                    key={index}
                    className="table__field_two_data_sm batch_divider"
                  >
                    <FieldBody subtext={"Rejected Qty:"} />
                    <FieldBody text={"10 Units"} color={"bold"} />
                  </div>
                  <div
                    key={index}
                    className="table__field_two_data_sm batch_divider"
                  >
                    <FieldBody subtext={"Reasons:"} />
                    <p className="page__note_fs mi_blue">
                      10 Units - {"Damaged"},
                    </p>
                    <p className="page__note_fs mi_blue">
                      10 Units - {"Damaged"}
                    </p>
                    <p className="page__note_fs mi_blue">
                      10 Units - {"Damaged"}
                    </p>
                  </div>
                </article>
              </div> */}
                {checkPartialStatus?.batches?.map((batch, index) => {
                  const totalPendingQty = batch?.rejections?.reduce(
                    (acc, rejection) => acc + parseInt(rejection.quantity),
                    0
                  );
                  return (
                    <div className="Collapse__row bg_added" key={index}>
                      <article className=" Collapse__column_list_row">
                        <div
                          key={index}
                          className="table__field_two_data_sm batch_divider"
                        >
                          <FieldBody subtext={"Batch No. :"} />
                          <FieldBody text={batch?.batchNo} color={"bold"} />
                        </div>
                        <div
                          key={index}
                          className="table__field_two_data_sm batch_divider"
                        >
                          <FieldBody subtext={"Received Qty:"} />
                          <FieldBody
                            text={batch?.reqQuantity + " " + row?.product.units}
                            color={"bold"}
                          />
                        </div>
                        <div
                          key={index}
                          className="table__field_two_data_sm batch_divider"
                        >
                          <FieldBody subtext={"Accepted Qty:"} />
                          <FieldBody
                            text={batch?.quantity + " " + row?.product.units}
                            color={"green"}
                          />
                        </div>
                        <div
                          key={index}
                          className="table__field_two_data_sm batch_divider"
                        >
                          <FieldBody subtext={"Rejected Qty:"} />
                          <FieldBody
                            text={totalPendingQty + " " + row?.product.units}
                            color={"red"}
                          />
                        </div>
                        <div
                          key={index}
                          className="table__field_two_data_sm batch_divider"
                        >
                          <FieldBody subtext={"Reasons:"} />
                          {batch?.rejections?.map((reject, index) => {
                            return reject?.type === "OTHER" ? (
                              <p className="page__note_fs mi_blue">
                                ({reject?.quantity + " " + row?.product.units})
                                - {reject?.name} {" - "}
                                {reject?.comment}
                                {batch?.rejections?.length - 1 !== index &&
                                  " ,"}
                              </p>
                            ) : (
                              <p className="page__note_fs mi_blue">
                                ({reject?.quantity + " " + row?.product.units})
                                - {reject?.name}
                                {batch?.rejections?.length - 1 !== index &&
                                  " ,"}
                              </p>
                            );
                          })}
                        </div>
                      </article>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow className="mi_table__body_No_padding">
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <div className="Collapse__table flex__seperator">
                {row?.batches?.map((batch) => {
                  const totalPendingQty = batch?.rejections?.reduce(
                    (acc, rejection) => acc + parseInt(rejection.quantity),
                    0
                  );
                  return (
                    <div className="Collapse__row bg_added">
                      <article className=" Collapse__column_list_row">
                        <div
                          key={index}
                          className="table__field_two_data_sm batch_divider"
                        >
                          <FieldBody subtext={"Batch No. :"} />
                          <FieldBody
                            text={batch?.atom?.batchNo}
                            color={"bold"}
                          />
                        </div>
                        <div
                          key={index}
                          className="table__field_two_data_sm batch_divider"
                        >
                          <FieldBody subtext={"Received Qty:"} />
                          <FieldBody
                            text={batch?.quantity + " " + row?.product.units}
                            color={"bold"}
                          />
                        </div>
                        {checkStatus === row?.product?._id && (
                          <div
                            key={index}
                            className="table__field_two_data_sm batch_divider"
                          >
                            <FieldBody subtext={"Accepted Qty:"} />
                            <FieldBody
                              text={batch?.quantity + " " + row?.product.units}
                              color={"green"}
                            />
                          </div>
                        )}
                      </article>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <ReceiveBatch
        keepMounted
        open={openProductPopup}
        onClose={handleCloseProductPopup}
        batches={row?.batches}
        product={row?.product}
        batchesList={batchesList}
        setBatchesList={setBatchesList}
        setShipmentAccepted={setShipmentAccepted}
        setAcceptStatus={setAcceptStatus}
      />
    </React.Fragment>
  );
}
