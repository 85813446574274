import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";

// Icons
import InfoIcon from "/icons/table/info.svg";
import ErrorIcon from "/icons/table/error.svg";
import { Link } from "react-router-dom";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";

export default function ShipOutDamagedTable() {
  const TotalUserData = [];

  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 486 }}>
          <TableHead className="mi_table__head">
            <TableRow>
              <TableCell>
                <FieldHead title="From" />
              </TableCell>
              <TableCell>
                <FieldHead title="Shipment Date" />
              </TableCell>
              <TableCell>
                <FieldHead title="Shipment ID" />
              </TableCell>

              <TableCell>
                <FieldHead title="To" />
              </TableCell>

              <TableCell>
                <FieldHead title="Status" />
              </TableCell>
              <TableCell align="center">
                <FieldHead title="View" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mi_table__body mi_table_collapse_body">
            {TotalUserData ? (
              <>
                {TotalUserData?.length > 0 ? (
                  <>
                    {TotalUserData.map((row) => (
                      <TableBodyRow row={row} />
                    ))}
                  </>
                ) : (
                  <TableRow className="mi_table__body_No_padding">
                    <TableCell style={{ padding: 0 }} colSpan={7}>
                      <EmptyTable progress={true} />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className="mi_table__body_No_padding">
                <TableCell style={{ padding: 0 }} colSpan={7} align="center">
                  <div className="Loading_message">
                    <p className="page__note_fs">Loading...</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function TableBodyRow({ row }) {
  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        sx={{ "& > *": { borderBottom: "unset" } }}
        className="mi_collapse_visible_row mi_table__row_clickable"
      >
        <TableCell>
          <FieldBody text="Serum Institute" />
        </TableCell>
        <TableCell>
          <FieldBody text="24/11/2023" />
        </TableCell>
        <TableCell>
          <FieldBody text="SH32743" color="blue" />
        </TableCell>

        <TableCell>
          <FieldBody text="Office" />
        </TableCell>

        <TableCell>
          {!row.accept ? (
            <StatusLabel status="Damaged" tone="red" />
          ) : (
            <StatusLabel status="Cold Chain Failure" tone="pink" />
          )}
        </TableCell>
        <TableCell align="center">
          <Link to="/user/shipments/view-shipments" className="Table__Icon">
            <img src={InfoIcon} alt="Icon" />
          </Link>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
