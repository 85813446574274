import React, { useEffect } from "react";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import { getVar, updateVAR } from "../../../redux/inventoryActions";
import ViewVARcard from "./ViewVARcard";
import { useParams, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import ProductIcon from "../../../shared/utils/productIcon/ProductIcon";
import StatusLabel from "../../../shared/utils/statusLabel/StatusLabel";
import toast from "react-hot-toast";
import PermissionRoute from "../../../web/routes/routeLayers/PermissionRoute";
import Printer from "../../../shared/printer/Printer";

export default function ViewVAR() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [VaccineList, setVaccineList] = React.useState();
  const [DiluentList, setDiluentList] = React.useState();
  const [AlertList, setAlertList] = React.useState();
  const [data, setData] = React.useState();
  const [display, setDisplay] = React.useState();
  const [varApproved, setVarApproved] = React.useState();
  useEffect(() => {
    const fetchData = async () => {
      const VAR = await getVar(id);
      setData(VAR.data);
    };
    fetchData();
  }, [id, varApproved]);

  const VARdata = {
    ...data,
    product: data?.shipmentDetails?.products?.[0]?.productId,
  };

  const handleEdit = () => {
    navigate("/user/arrival-report/vaccine/new?form=part1", {
      state: { value: VARdata },
    });
  };
  useEffect(() => {
    setDisplay([
      {
        title: t("general_information"),
        data: [
          {
            title: t("vaccine"),
            answer: (
              <ProductIcon
                type={data?.shipmentDetails?.products?.[0]?.product?.type}
                name={data?.shipmentDetails?.products?.[0]?.product?.name}
                size='tiny'
                fallback={
                  <span>
                    {data?.shipmentDetails?.products?.[0]?.product?.name}
                  </span>
                }
              />
            ),
          },

          {
            title: t("country") + " *",
            answer: data?.country,
          },
          {
            title: t("status"),
            answer: data?.status ? (
              <StatusLabel
                status={data?.status}
                tone={
                  data?.status === "ACCEPTED"
                    ? "green"
                    : data?.status === "REJECTED"
                    ? "red"
                    : "blue"
                }
              />
            ) : (
              "---"
            ),
          },
          {
            title: t("report_no") + ".",
            answer: data?.reportNo,
          },

          {
            title: t("date_of_report"),
            answer: new Date(data?.reportDate).toLocaleDateString(),
          },
          {
            title: t("place_of_inspection") + " *",
            answer: data?.placeOfInspection,
          },
          {
            title: t("inspection_date_time"),
            answer: new Date(data?.inspectionDateTime).toLocaleDateString(),
          },
          {
            title: t("time_of_inspection"),
            answer: new Date(data?.inspectionDateTime).toLocaleTimeString(),
          },
          {
            title: t("cold_storage"),
            answer: data?.storageName,
          },
        ],
      },
      {
        title: t("VAR_part1_title"),
        data: [
          {
            title: t("review_VAR_part2_subtitle1"),
            answer: new Date(
              data?.advanceNotice?.preAdviceDate,
            ).toLocaleDateString(),
          },
          {
            title: t("review_VAR_part2_subtitle2"),
            answer: new Date(
              data?.advanceNotice?.shippingNotificationDate,
            )?.toLocaleDateString(),
          },
          {
            title: t("copy_of_airway_bill") + " (Awb)",
            answer: data?.advanceNotice?.copyAwb?.toString(),
          },
          {
            title: t("copy_of_packing_list"),
            answer: data?.advanceNotice?.copyPackingList?.toString(),
          },
          {
            title: t("copy_of_invoice"),
            answer: data?.advanceNotice.copyInvoice.toString(),
          },
          {
            title: t("copy_of_release_certificate"),
            answer: data?.advanceNotice.copyReleaseCertificate.toString(),
          },
        ],
      },
      {
        title: t("VAR_part2_title"),
        data: [
          {
            title: t("awb_number"),
            answer: data?.flightArrivalReport.awb,
          },
          {
            title: t("flight_number"),
            answer: data?.flightArrivalReport.flightNo,
          },
          {
            title: t("airport_of_destination"),
            answer: data?.flightArrivalReport.destination,
          },
          {
            subtitle: t("eta_as_per_notification"),
            title: t("date_time"),
            answer: data?.flightArrivalReport.scheduledDateTime,
          },
          {
            subtitle: t("actual_time_of_arrival"),
            title: t("date_time"),
            answer: data?.flightArrivalReport.actualDateTime,
          },
          {
            title: t("name_of_clearing_agent"),
            answer: data?.flightArrivalReport.clearingAgent?.name,
          },
          {
            title: t("on_behalf_of"),
            answer: data?.flightArrivalReport.clearingAgent?.behalf,
          },
        ],
      },
      {
        title: t("VAR_part3_title"),
        data: [
          {
            title: t("purchase_order_number"),
            answer: data?.shipmentDetails?.poNo,
          },

          {
            title: t("country"),
            answer: data?.shipmentDetails?.originCountry,
          },
          {
            title: t("consignee"),
            answer: data?.shipmentDetails?.consignee,
          },

          {
            title: t("manufacturer"),
            answer: data?.shipmentDetails?.products[0]?.manufacturer?.name,
          },
          {
            title: t("PAR_part1_question1"),
            answer:
              data?.shipmentDetails?.shortShipment &&
              data?.shipmentDetails?.shortShipment.toString(),
          },
          {
            title: t("VAR_part1_question2"),
            answer:
              data?.shipmentDetails?.shortShipmentNotification &&
              data?.shipmentDetails?.shortShipmentNotification.toString(),
          },
          {
            title: t("comments"),
            answer: data?.shipmentDetails?.comments,
          },
        ],
      },
      {
        title: t("VAR_part4_title"),
        data: [
          {
            title: t("report_number"),
            answer: data?.reportNo,
          },
          {
            title: t("invoice"),
            answer: data?.documents.invoice.toString(),
          },
          {
            title: t("packing_list"),
            answer: data?.documents.packingList.toString(),
          },
          {
            title: t("vaccine_arrival_report"),
            answer: data?.documents.vaccineArrivalReport.toString(),
          },
          {
            title: t("release_certificate"),
            answer: data?.documents.releaseCertificate.toString(),
          },

          {
            title: t("comments"),
            answer: data?.documents.comment,
          },
        ],
      },
      {
        title: t("VAR_part5_title"),
        data: [
          {
            title: t("total_no_of_boxes_inspected"),
            answer: data?.shippingIndicators?.noOfInspected,
          },
          {
            title: t("invoice_amount"),
            answer: data?.shippingIndicators?.invoiceAmount,
          },
          {
            title: t("coolant_type"),
            answer: data?.shippingIndicators?.coolantType,
          },
          {
            title: t("temperature_monitors_present"),
            answer: data?.shippingIndicators?.tempMonitors
              ?.map((key) => key)
              ?.join(", "),
          },
        ],
      },

      {
        title: t("VAR_part6_title"),
        data: [
          {
            title: t("VAR_part6_options"),
            answer: data?.conditions.type,
          },
          {
            title: t("VAR_part6_question1"),
            answer: data?.conditions.labelsAttached.toString(),
          },
          {
            title: t("VAR_part6_comment"),
            answer: data?.conditions.comment,
          },
        ],
      },

      {
        title: t("VAR_part7_title"),
        data: [
          {
            title: t("authorized_inspection_supervisor"),
            answer: t("sign_generated_msg"),
          },
          {
            title: t("authorized_inspection_date"),
            answer: new Date(
              data?.signatures?.inspectionSupervisor?.date,
            ).toLocaleDateString(),
          },
          {
            title: t("central_store_epi_manger"),
            answer: t("sign_generated_msg"),
          },
          {
            title: t("date"),
            answer: new Date(
              data?.signatures.epiManager.date,
            ).toLocaleDateString(),
          },
          {
            subtitle: t("date_received_by_the_office_msg"),
            title: t("date_received_by_the_office"),
            answer: new Date(
              data?.signatures.receivedDate,
            ).toLocaleDateString(),
          },
          {
            title: t("contact_person"),
            answer: data?.signatures.contact,
          },
        ],
      },
    ]);

    setAlertList(data?.shippingIndicators.alerts);
    setVaccineList(data?.shipmentDetails?.products);
    setDiluentList(data?.shipmentDetails?.diluentDroppers);
  }, [data]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({ contentRef: componentRef });

  const handleManageVAR = async (status) => {
    const res = await updateVAR({
      varId: data?._id,
      status: status,
    });

    if (res.success) {
      setVarApproved((prev) => !prev);
      toast.success(`VAR ${status}`);
    }
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <div className='back_Link' onClick={() => navigate(-1)}>
            <i className='bx bx-arrow-back icon_blue'></i>
          </div>
          <h1 className='Page__headline_title_fs'>
            {t("view_vaccine_arrival_report")}
          </h1>
        </div>

        <BreadCrumb
          url1='/user/arrival-report/vaccine'
          Link1={t("vaccine_arrival_report")}
          Link2={t("view_VAR")}
        />
      </div>
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          <button
            className='mi_btn mi_btn_medium mi_btn_outline'
            onClick={handlePrint}
          >
            <i className='fa-solid fa-download'></i>
            <span>{t("generate_pdf")}</span>
          </button>
          {/* <button
            className="mi_btn mi_btn_medium mi_btn_outline"
            onClick={() => {
              handleEdit();
            }}
          >
            <i className="fa-solid fa-pencil icon_blue"></i>
            <span>{t("edit")}</span>
          </button> */}
        </div>
        <PermissionRoute allowed={"RESPOND_VAR"} type='actions'>
          {" "}
          {data?.status === "PENDING" && (
            <div className='Page__right_actions'>
              <button
                className='mi_btn mi_btn_medium mi_btn_approved'
                onClick={() => handleManageVAR("ACCEPTED ")}
              >
                <i className='fa-regular fa-circle-check'></i>
                <span>{t("accept")}</span>
              </button>

              <button
                className='mi_btn mi_btn_medium mi_btn_rejected'
                onClick={() => handleManageVAR("REJECTED")}
              >
                <i className='fa-regular fa-circle-xmark'></i>
                <span>{t("reject")}</span>
              </button>
            </div>
          )}
        </PermissionRoute>
      </div>
      <section className='ReviewVAR__list' ref={componentRef}>
        <Printer title={t("vaccine-arrival_report_details")}>
          <div className='printing_body column_flex'>
            {display?.map((row, index) => (
              <ViewVARcard
                key={index}
                row={row}
                AlertList={AlertList}
                VaccineList={VaccineList}
                DiluentList={DiluentList}
              />
            ))}
          </div>
        </Printer>
      </section>
    </section>
  );
}
