import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../shared/utils/tableField/FieldBody';
import StatusLabel from '../../../shared/utils/statusLabel/StatusLabel';
import { useTranslation } from 'react-i18next';

export default function ProductInfo({ product, setSendingQty }) {
  const { t } = useTranslation();

  const [acceptingQty, setAcceptingQty] = useState(null);
  const [acceptingBtn, setAcceptingBtn] = useState(false);

  function fetchData() {
    setAcceptingQty(product?.quantity);
    setSendingQty(product?.quantity);
  }
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }}>
        <TableHead className='mi_table__head br-top'>
          <TableRow>
            <TableCell>
              <FieldHead title={t('category')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('name')} />
            </TableCell>

            <TableCell>
              <FieldHead title={t('batch_no')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('manufacturer')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('status')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('quantity')} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body'>
          <TableRow
            sx={{ '& > *': { borderBottom: 'unset' } }}
            className='mi_collapse_visible_row'
          >
            <TableCell>
              <FieldBody text={product?.productId?.type} />
            </TableCell>
            <TableCell>
              <FieldBody text={product?.productId?.name} />
            </TableCell>
            <TableCell>
              <FieldBody text={product?.batchNo} />
            </TableCell>
            <TableCell>
              <FieldBody text={product?.manufacturer?.name} />
            </TableCell>
            <TableCell>
              <StatusLabel status={product?.status} tone='grey' />
            </TableCell>
            <TableCell>
              <FieldBody text={product?.quantity} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
