import React, { useEffect, useState } from 'react';
import { getAdjustmentInventoryOutbound } from '../../../../redux/inventoryActions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
} from '@mui/material';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { useTranslation } from 'react-i18next';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import Information from '/favicon/information.png';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';
import { updateAdjustmentInventory } from '../../../../redux/inventoryActions';
import { useDispatch } from 'react-redux';
import { callPopup } from '../../../../store/slices/popupSlice';
import backupIcon from '/icons/image.svg';
import ImagePopup from '../ImagePopup';

export default function OutboundAdjustment({ tableRef, filteredColumns }) {
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [analyticUpdated, setAnalyticUpdated] = useState(false);

  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAdjustmentInventoryOutbound(page, limit);
      setData(res.data);
    };
    fetchData();
  }, [page, limit, analyticUpdated]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label='simple table' ref={tableRef}>
        <TableHead className='mi_table__head'>
          <TableRow>
            {filteredColumns.map((column) => (
              <TableCell key={column.key}>
                <FieldHead
                  title={t(column.title)}
                  align={column.key === 'action' ? 'start' : 'start'}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className='mi_table__body'>
          {data?.data?.length > 0 ? (
            data?.data?.map((row) => (
              <RecallAdjustBodyRow
                row={row}
                t={t}
                setAnalyticUpdated={setAnalyticUpdated}
                filteredColumns={filteredColumns}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: '2rem' }} colSpan={5}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={data?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function RecallAdjustBodyRow({ t, row, setAnalyticUpdated, filteredColumns }) {
  const [collapseOpen, setCollapseOpen] = React.useState('');

  const [open, setOpen] = useState(false);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleUpdate = async () => {
    const data = {
      requestId: row?._id,
      status: 'CANCELLED',
    };

    const res = await updateAdjustmentInventory(data);
    if (res?.success === true) {
      dispatch(
        callPopup({
          open: true,
          type: 'success',
          page: 'adjustment-inventory',
          message: 'Adjustment Request Cancelled',
          action: '/user/inventory/adjustment/outbound',
        })
      );
      setAnalyticUpdated((prev) => !prev);
    } else {
      dispatch(
        callPopup({
          open: true,
          type: 'error',
          page: 'adjustment-inventory',
          title: res.data.message,
          message: res.data.data,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow
        key={row?.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {filteredColumns?.map((column) => (
          <>
            {column.key === 'products' && (
              <TableCell>
                <FieldBody
                  text={row?.products?.length + ' ' + t('products')}
                  color='blue'
                />
              </TableCell>
            )}
            {column.key === 'from' && (
              <TableCell>
                <FieldBody text={row?.fromStatus} color='bold' />
              </TableCell>
            )}
            {column.key === 'to' && (
              <TableCell>
                <FieldBody text={row?.toStatus} color='bold' />
              </TableCell>
            )}
            {column.key === 'date' && (
              <TableCell>
                <FieldBody
                  text={new Date(row?.adjustmentDate).toLocaleDateString()}
                />
              </TableCell>
            )}
            {column.key === 'status' && (
              <TableCell>
                <StatusLabel
                  status={row?.status}
                  tone={
                    row?.status === 'ACCEPTED'
                      ? 'green'
                      : row?.status === 'REJECTED'
                      ? 'red'
                      : row?.status === 'CANCELLED'
                      ? 'grey'
                      : 'blue'
                  }
                />
              </TableCell>
            )}
            {column.key === 'view' && (
              <TableCell>
                <div className='Accept_field'>
                  <img
                    src={Information}
                    alt='Icon'
                    onClick={() => setCollapseOpen(!collapseOpen)}
                    className='mi_table__row_clickable'
                  />
                </div>
              </TableCell>
            )}
            {column.key === 'action' && (
              <TableCell align='center'>
                {row?.status === 'PENDING' && (
                  <button
                    onClick={handleUpdate}
                    className='mi_btn mi_btn_small mi_btn_outline'
                  >
                    {t('cancel')}
                  </button>
                )}
              </TableCell>
            )}
          </>
        ))}
      </TableRow>

      <TableRow
        className='mi_table__body_No_padding'
        style={{ border: '1px solid #ddd', width: '100%' }}
      >
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <div className='Collapse__table'>
              {true && (
                <>
                  {row?.products?.map((product) => (
                    <div>
                      <div className='Collapse__row'>
                        <article className=' Collapse__column_list_row'>
                          <div className='table__field_two_data_sm batch_divider'>
                            <FieldHead title={t('product_name') + ' :'} />
                            <ProductIcon
                              type={product?.productDetails?.type}
                              name={product?.productDetails?.name}
                              size='tiny'
                              fallback={
                                <FieldBody
                                  text={product?.productDetails?.name}
                                />
                              }
                            />
                            <FieldHead title={t('product_id') + ' :'} />
                            <p className='FieldBody__subtitle_fs'>
                              {product?.productDetails?.id}{' '}
                            </p>
                          </div>
                        </article>
                      </div>
                      <div className='Collapse__row'>
                        <article
                          className=' Collapse__column_list_row'
                          style={{ marginTop: '0.3rem' }}
                        >
                          <div className=' Collapse__column_list_row'>
                            <FieldHead title={t('batches') + ' :'} />
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                gap: '0.5rem',
                              }}
                            >
                              {product?.batches?.map((batch) => (
                                <>
                                  <p className='FieldBody__subtitle_fs'>
                                    ({t('batch_no')} : {batch?.batchNo} ,{' '}
                                    {batch?.quantity}{' '}
                                    {product?.productDetails?.units}),
                                  </p>
                                </>
                              ))}
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className='Collapse__row'>
                        <article className=' Collapse__column_list_row'>
                          <div
                            className='table__field_two_data_sm batch_divider'
                            style={{ marginTop: '0.5rem' }}
                          >
                            <div className='reason_card'>
                              <FieldHead title={t('reason') + ' :'} />
                              <p className='page__gray_fs'>{product?.reason}</p>
                            </div>
                          </div>
                        </article>
                      </div>
                      {product?.photoIds?.length > 0 && (
                        <div className='Collapse__row'>
                          <article className=' Collapse__column_list_row'>
                            <div
                              className='table__field_two_data_sm batch_divider'
                              style={{ marginTop: '0.5rem' }}
                            >
                              <div className='reason_card'>
                                <FieldHead title={'Images' + ' :'} />
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                  <img
                                    src={backupIcon}
                                    style={{ height: '30px' }}
                                    alt=''
                                  />
                                  <p
                                    className={`FieldBody__title_fs blue`}
                                    onClick={() => setOpen((prev) => !prev)}
                                    style={{
                                      cursor: 'pointer',
                                      whiteSpace: 'nowrap',
                                      marginBlock: 'auto',
                                    }}
                                  >
                                    {t('view_images')}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </article>
                          <ImagePopup
                            open={open}
                            setOpen={setOpen}
                            onClose={handleClosePopup}
                            imagesList={product?.photoIds}
                            t={t}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
