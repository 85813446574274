import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./VARform.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { t } from "i18next";

export default function VARform7({
  setFormTab,
  setFormComplete,
  setData,
  storedData,
}) {
  const navigate = useNavigate();
  const [saveBtn, setSaveBtn] = useState()

  const formik = useFormik({
    initialValues: {
      authorisedDate:
        dayjs(storedData?.signatures?.inspectionSupervisor?.date).format("YYYY-MM-DD") ||
        dayjs().format("YYYY-MM-DD"),
      receivedDate:
        dayjs(storedData?.signatures?.receivedDate).format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD"),
      epiDate:
        dayjs(storedData?.signatures?.epiManager?.date).format("YYYY-MM-DD") ||
        dayjs().format("YYYY-MM-DD"),
      contactPerson: storedData?.signatures?.contact || "",
    },
    onSubmit: (values) => {
      setData(7, {
        inspectionSupervisor: {
          name: "Supervisor",

          date: values.authorisedDate,
        },
        epiManager: {
          name: "EPI",

          date: values.epiDate,
        },
        receivedDate: values.receivedDate,
        contact: values.contactPerson,
      });

      toast.success(t("VAR_part7_title") + " " + t("saved"));
      setSaveBtn(true)
    },
  });

  const handlePrev = () => {
    navigate("?form=part6");
    setFormComplete(6);
  };

  const sumitData =
    storedData?.product &&
    storedData?.advanceNotice?.preAdviceDate &&
    storedData?.flightArrivalReport?.awb &&
    storedData?.shipmentDetails?.poNo &&
    storedData?.documents?.invoice &&
    storedData?.shippingIndicators?.invoiceAmount &&
    storedData?.conditions?.labelsAttached &&
    storedData?.signatures?.contact;

  const handleFinalSubmit = () => {
    setData(8, {});
  };

  useEffect(()=>{
    setSaveBtn(false)
  }, [formik.values.contactPerson])

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} className="VARform">
        <div className="VARform__header">
          <h1 className="page__body_bigheading_fs">{t("VAR_part7_title")}</h1>
        </div>
        <div className="VARform__body">
          <article className="VARform__row three_column">
            <div className="VARform__column">
              {/* <p className="Input__label_fs">
                Authorized Inspection Supervisor <b>*</b>
              </p> */}
              {/* <div className="Input__column">
                <textarea
                  {...formik.getFieldProps("authorised")}
                  // required
                  disabled
                  className="mi_custom_textarea extra_height"
                />
              </div> */}
              <div className="Input__column extra_top_space">
                <p className="Input__label_fs">
                  {t("authorized_sign_date")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("authorisedDate")}
                  required
                  type="date"
                  className="mi_custom_textfield date_field"
                />
                <span className="Input__error_fs d_none"></span>
              </div>
              <div className="Input__column extra_top_space">
                <h2 className="page__tiny_fs mi_sky">
                  {t("date_received_by_the_office_msg")}
                </h2>

                <p className="Input__label_fs">
                  {t("date_received_by_the_office")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("receivedDate")}
                  required
                  type="date"
                  className="mi_custom_textfield date_field"
                />
                <span className="Input__error_fs d_none"></span>
              </div>
            </div>
            <div className="VARform__column">
              {/* <p className="Input__label_fs">
                Central store or EPI Manager <b>*</b>
              </p> */}
              {/* <div className="Input__column">
                <textarea
                  {...formik.getFieldProps("epi")}
                  // required
                  disabled
                  className="mi_custom_textarea extra_height"
                />
              </div> */}
              <div className="Input__column extra_top_space">
                <p className="Input__label_fs">
                  {t("epi_signed_date")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("epiDate")}
                  required
                  type="date"
                  className="mi_custom_textfield date_field"
                />
                <span className="Input__error_fs d_none"></span>
              </div>
              <div className="Input__column extra_top_space margin_match">
                <p className="Input__label_fs">
                  {t("contact_person")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("contactPerson")}
                  required
                  type="text"
                  className="mi_custom_textfield date_field"
                />
                <span className="Input__error_fs d_none"></span>
              </div>
            </div>
            <div></div>
          </article>
        </div>
        <div className="VARform__footer">
          <div className="Input__column_action">
            <button
              type="button"
              className="mi_btn mi_btn_medium mi_btn_secondary"
              onClick={handlePrev}
            >
              <span>{t("prev")}</span>
            </button>
            <button
              className="mi_btn mi_btn_medium mi_btn_primary"
              type="submit"
              disabled={saveBtn}
            >
              <span>{t("save")}</span>
            </button>
            <button
              className="mi_btn mi_btn_medium mi_btn_primary"
              type="button"
              onClick={handleFinalSubmit}
              disabled={!sumitData}
            >
              <span>{t("submit_form")}</span>
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
