import React, { useState, useEffect } from 'react';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle, Divider } from '@mui/material';
import ProductListTable from './ProductListTable';
import {
  getAllInventoryProducts,
  getLocationProducts,
} from '../../../../redux/inventoryActions';
import { useLocation } from 'react-router-dom';

export default function FetchProduct(props) {
  const {
    onClose,
    open,
    from,
    type,
    url,
    productsListWOID,
    setProductsListWOID,
    selectedProducts,
    setSelectedProducts,
    pageType,
    t,
    ...other
  } = props;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const location = useLocation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Getting Batch Info
  const [productList, setProductList] = React.useState();

  useEffect(() => {
    const fetchProductList = async () => {
      if (pageType) {
        if (pageType === 'Adjustment') {
          if (url === 'request') {
            const res = await getAllInventoryProducts(null, null, page, limit);

            setProductList(res.data);
          } else if (url === 'status') {
            const res = await getAllInventoryProducts(
              null,
              'VACCINE',
              page,
              limit
            );

            setProductList(res.data);
          }
        }
      } else {
        const res = await getLocationProducts(null, page, limit, type);

        setProductList(res.data);
      }
    };
    fetchProductList(from);
  }, [page, limit]);

  // Getting Selected Products
  const handleToggleProduct = (product) => {
    const prodInfo =
      pageType === 'Adjustment' ? product?.productDetails : product?.product;

    const isSelected = selectedProducts?.some(
      (selectedProduct) => selectedProduct._id === prodInfo?._id
    );

    let newSelectedProducts;

    if (!isSelected) {
      newSelectedProducts = [
        ...selectedProducts,
        { ...prodInfo, quantity: product.quantity },
      ];
    } else {
      newSelectedProducts = selectedProducts?.filter(
        (selectedProduct) => selectedProduct._id !== prodInfo?._id
      );
    }

    setSelectedProducts(newSelectedProducts);
  };

  const handleClose = () => {
    if (
      location?.pathname?.includes('/new-shipments') &&
      productsListWOID !== null
    ) {
      setSelectedProducts(productsListWOID);
    }
    onClose();
  };

  const handleSubmit = () => {
    setProductsListWOID(selectedProducts);
    onClose();
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': { width: '100%' },
      }}
      className='ModalPopup__dialog'
      maxWidth='md'
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: '0 !important' }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>
            {t('product_list')}
          </div>
          <div className='ModalPopup__close_icon' onClick={handleClose}>
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: '0 !important', borderRadius: '0.6rem !important' }}
      >
        <div className='ModalPopup__body'>
          <section className='Page__main_wrapper'>
            <div className='Page__table_space'>
              <ProductListTable
                productList={productList}
                products={productList?.data}
                selectedProducts={selectedProducts}
                handleToggleProduct={handleToggleProduct}
                page={page}
                limit={limit}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                t={t}
                pageType={pageType}
              />
            </div>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className='InventoryOrders__actions'>
          <div className='Page__left_actions'>
            <button
              type='submit'
              className={`mi_btn mi_btn_medium ${
                selectedProducts?.length < 1
                  ? 'mi_btn_disable'
                  : 'mi_btn_primary'
              }`}
              onClick={handleSubmit}
              disabled={selectedProducts?.length < 1 ? true : false}
            >
              <span>{t('confirm')}</span>
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}
