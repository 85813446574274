// import "./Inventory.css";
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import AnalyticTile from '../../../../shared/utils/analyticTile/AnalyticTile';
import IconWasted from '/icons/analytics/IconWasted.svg';

import InvSummary from '../../inventory/inventoryTables/InvSummary';
import InvCurrent from '../../inventory/inventoryTables/InvCurrent';
import InvStockOut from '../../inventory/inventoryTables/InvStockOut';
import InvNearExpiry from '../../inventory/inventoryTables/InvNearExpiry';
import InvExpired from '../../inventory/inventoryTables/InvExpired';
import InvNetUtils from '../../inventory/inventoryTables/InvNetUtils';
import InvMinMax from '../../inventory/inventoryTables/InvMinMax';
import InvWasted from '../../inventory/inventoryTables/InvWasted';
import Disposed from '/icons/all/inventory/Disposed.svg';

//icons
import CurrentStock from '/icons/all/inventory/Inventory.svg';
import Expired from '/icons/all/inventory/Expired.svg';
import MinMax from '/icons/all/inventory/Min & Max.svg';
import NearExpiry from '/icons/all/inventory/Near Expiry.svg';
import StockOut from '/icons/all/inventory/Stock Out.svg';
import NetUtilization from '/icons/all/inventory/Net Utilization.svg';
import RejectedQuantity from '/icons/all/inventory/Rejected Quantity.svg';
import Summary from '/icons/all/summary.svg';

import {
  InvCurrentcolumns,
  InvCurrentinitialColumns,
  InvDisposedColumns,
  InvDisposedInitialColumns,
  InvExpiredcolumns,
  InvExpiredinitialColumns,
  InvMinMaxcolumns,
  InvMinMaxinitialColumns,
  InvNearExpirycolumns,
  InvNearExpiryinitialColumns,
  InvNetUtilscolumns,
  InvNetUtilsinitialColumns,
  InvQuarantineColumns,
  InvQuarantineInitialColumns,
  InvRejectedColumns,
  InvRejectedinitialColumns,
  InvStockOutcolumns,
  InvStockOutinitialColumns,
  InvSummarycolumns,
  InvSummaryinitialColumns,
  InvWastedcolumns,
  InvWastedinitialColumns,
} from '../../inventory/data/initalColumns';

import { useSelector } from 'react-redux';
import InvRejected from '../../inventory/inventoryTables/InvRejected';
import { useTranslation } from 'react-i18next';
import InvQuarantine from '../../inventory/inventoryTables/InvQuarantine';
import {
  useGetCurrentStockData,
  useGetInventoryEventsData,
  useGetOutOfStockData,
  useGetNearExpiryData,
  useGetExpiredStockData,
  useGetRejectedStockData,
  useGetUnitsWastedData,
  useGetUnitsQuarantinedData,
  useGetUnitsDisposedData,
} from '../../../../services/inventory-services/queries.js';
import { useSearchParams } from 'react-router-dom';
import InvDisposed from '../../inventory/inventoryTables/InvDisposed.jsx';

export default function InventoryTable() {
  const { t } = useTranslation();

  const { permissions } = useSelector((state) => state.auth);
  const [currentTab, setCurrentTab] = useState('currentStock');

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const tableRef = useRef();

  const apiBody = {
    locations: searchParams.get('locations').split(','),
  };

  let data;
  switch (currentTab) {
    case 'currentStock':
      ({ data } = useGetCurrentStockData(apiBody, page, limit));
      break;
    case 'summary':
      ({ data } = useGetInventoryEventsData(apiBody, page, limit));
      break;
    case 'stockOut':
      ({ data } = useGetOutOfStockData(apiBody, page, limit));
      break;
    case 'nearExpiry':
      ({ data } = useGetNearExpiryData(apiBody, page, limit));
      break;
    case 'expired':
      ({ data } = useGetExpiredStockData(apiBody, page, limit));
      break;
    case 'rejected':
      ({ data } = useGetRejectedStockData(apiBody, page, limit));
      break;
    case 'netUtilization':
      ({ data } = useGetCurrentStockData(apiBody, page, limit));
      break;
    case 'quarantine':
      ({ data } = useGetUnitsQuarantinedData(apiBody, page, limit));
      break;
    case 'disposed':
      ({ data } = useGetUnitsDisposedData(apiBody, page, limit));
      break;
    case 'wasted':
      ({ data } = useGetUnitsWastedData(apiBody, page, limit));
      break;
    default:
      data = null;
  }

  let initialColumns = {};
  let columns = [];

  if (currentTab === 'summary') {
    initialColumns = InvSummaryinitialColumns;
    columns = InvSummarycolumns;
  } else if (currentTab === 'currentStock') {
    initialColumns = InvCurrentinitialColumns;
    columns = InvCurrentcolumns;
  } else if (currentTab === 'stockOut') {
    initialColumns = InvStockOutinitialColumns;
    columns = InvStockOutcolumns;
  } else if (currentTab === 'nearExpiry') {
    initialColumns = InvNearExpiryinitialColumns;
    columns = InvNearExpirycolumns;
  } else if (currentTab === 'expired') {
    initialColumns = InvExpiredinitialColumns;
    columns = InvExpiredcolumns;
  } else if (currentTab === 'netUtilization') {
    initialColumns = InvNetUtilsinitialColumns;
    columns = InvNetUtilscolumns;
  } else if (currentTab === 'minmax') {
    initialColumns = InvMinMaxinitialColumns;
    columns = InvMinMaxcolumns;
  } else if (currentTab === 'wasted') {
    initialColumns = InvWastedinitialColumns;
    columns = InvWastedcolumns;
  } else if (currentTab === 'rejected') {
    initialColumns = InvRejectedinitialColumns;
    columns = InvRejectedColumns;
  } else if (currentTab === 'quarantine') {
    initialColumns = InvQuarantineInitialColumns;
    columns = InvQuarantineColumns;
  } else if (currentTab === 'disposed') {
    initialColumns = InvDisposedInitialColumns;
    columns = InvDisposedColumns;
  }

  const [selectedColumns, setSelectedColumns] = useState(initialColumns);

  useEffect(() => {
    setSelectedColumns(initialColumns);
  }, [currentTab]);

  const filteredColumns = columns?.filter(
    (column) => selectedColumns[column.key]
  );

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__Analytic_list'>
        <AnalyticTile
          state='currentStock'
          icon={CurrentStock}
          title={t('current_stocks')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='nearExpiry'
          icon={NearExpiry}
          title={t('near_expiry')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='expired'
          icon={Expired}
          title={t('expired')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='stockOut'
          icon={StockOut}
          title={t('stock_out')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='summary'
          icon={Summary}
          title={t('summary')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='quarantine'
          icon={RejectedQuantity}
          title={t('quarantine')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='disposed'
          icon={Disposed}
          title={t('disposed')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        <AnalyticTile
          state='netUtilization'
          icon={NetUtilization}
          title={t('net_utilization')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        {/* <AnalyticTile
          state="minmax"
          icon={MinMax}
          title={t("min_max")}
          tab={currentTab}
          setTab={setCurrentTab}
        /> */}
        <AnalyticTile
          state='rejected'
          icon={RejectedQuantity}
          title={t('rejected_qty')}
          tab={currentTab}
          setTab={setCurrentTab}
        />
        {permissions.includes('LAST_MILE') && (
          <AnalyticTile
            state='wasted'
            icon={IconWasted}
            title={t('wasted')}
            tab={currentTab}
            setTab={setCurrentTab}
          />
        )}
      </div>

      <div className='Page__table_space'>
        {currentTab === 'summary' && (
          <InvSummary
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            columns={columns}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
          />
        )}
        {currentTab === 'currentStock' && (
          <InvCurrent
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
          />
        )}
        {currentTab === 'stockOut' && (
          <InvStockOut
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
            typePage='dataVisualization'
          />
        )}
        {currentTab === 'nearExpiry' && (
          <InvNearExpiry
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
          />
        )}
        {currentTab === 'expired' && (
          <InvExpired
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
            typePage='dataVisualization'
          />
        )}
        {currentTab === 'netUtilization' && (
          <InvNetUtils
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
          />
        )}
        {/* {currentTab === "minmax" && (
          <InvMinMax
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            tableRef={tableRef}
          />
        )} */}
        {currentTab === 'wasted' && (
          <InvWasted
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
            typePage='dataVisualization'
          />
        )}
        {currentTab === 'rejected' && (
          <InvRejected
            inventory={data?.data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
            typePage='dataVisualization'
          />
        )}

        {currentTab === 'quarantine' && (
          <InvQuarantine
            inventory={data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            tableRef={tableRef}
            typePage='dataVisualization'
          />
        )}

        {currentTab === 'disposed' && (
          <InvDisposed
            inventory={data}
            filteredColumns={filteredColumns}
            totalRecords={data?.totalRecords}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            typePage='dataVisualization'
          />
        )}
      </div>
    </section>
  );
}
