export const active_list = [
  { name: 'OPEN_VIAL', key: 'OPEN' },
  { name: 'DAMAGED', key: 'DAMAGED' },
  { name: 'LOST', key: 'LOST' },
  { name: 'COLD_CHAIN_FAILURE', key: 'COLD_CHAIN_FAILURE' },
  { name: 'RECALL', key: 'RECALL' },
  { name: 'QUARANTINE', key: 'QUARANTINE' },
];

export const expired_list = [
  { name: 'DISPOSED', key: 'DISPOSED' },
  { name: 'RECALL', key: 'RECALL' },
];

export const expired_in_transit_list = [
  { name: 'DISPOSED', key: 'DISPOSED' },
  { name: 'RECALL', key: 'RECALL' },
];

export const open_list = [
  { name: 'DAMAGED', key: 'DAMAGED' },
  { name: 'LOST', key: 'LOST' },
  { name: 'COLD_CHAIN_FAILURE', key: 'COLD_CHAIN_FAILURE' },
  { name: 'RECALL', key: 'RECALL' },
  { name: 'QUARANTINE', key: 'QUARANTINE' },
];

export const damaged_list = [
  { name: 'ACTIVE', key: 'ACTIVE' },
  { name: 'DISPOSED', key: 'DISPOSED' },
  { name: 'RECALL', key: 'RECALL' },
];

export const lost_list = [
  { name: 'ACTIVE', key: 'ACTIVE' },
  { name: 'DISPOSED', key: 'DISPOSED' },
  { name: 'RECALL', key: 'RECALL' },
];

export const cold_chain_failure_list = [
  { name: 'ACTIVE', key: 'ACTIVE' },
  { name: 'DISPOSED', key: 'DISPOSED' },
  { name: 'RECALL', key: 'RECALL' },
];

export const recall_list = [
  { name: 'LOST', key: 'LOST' },
  { name: 'DISPOSED', key: 'DISPOSED' },
];

export const quarantine_list = [
  { name: 'ACTIVE', key: 'ACTIVE' },
  { name: 'DAMAGED', key: 'DAMAGED' },
  { name: 'LOST', key: 'LOST' },
  { name: 'COLD_CHAIN_FAILURE', key: 'COLD_CHAIN_FAILURE' },
  { name: 'RECALL', key: 'RECALL' },
  { name: 'DISPOSED', key: 'DISPOSED' },
];
