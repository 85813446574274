import React, { useEffect, useRef, useState } from "react";
import BreadCrumb from "../../../../shared/utils/breadCrumb/BreadCrumb";
import { TemperatureGraph } from "./TemperatureGraph";
import { getTemperatureHistory } from "../../../../redux/inventoryActions";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

export default function HistoricalData() {
  const { id } = useParams();
  const TIME_SLOTS = [
    "12:00AM - 1:00AM",
    "1:00AM - 2:00AM",
    "2:00AM - 3:00AM",
    "3:00AM - 4:00AM",
    "4:00AM - 5:00AM",
    "5:00AM - 6:00AM",
    "6:00AM - 7:00AM",
    "7:00AM - 8:00AM",
    "8:00AM - 9:00AM",
    "9:00AM - 10:00AM",
    "10:00AM - 11:00AM",
    "11:00AM - 12:00PM",
    "12:00PM - 1:00PM",
    "1:00PM - 2:00PM",
    "2:00PM - 3:00PM",
    "3:00PM - 4:00PM",
    "4:00PM - 5:00PM",
    "5:00PM - 6:00PM",
    "6:00PM - 7:00PM",
    "7:00PM - 8:00PM",
    "8:00PM - 9:00PM",
    "9:00PM - 10:00PM",
    "10:00PM - 11:00PM",
    "11:00PM - 12:00AM",
  ];
  const [data, setData] = useState({
    allTemperatures: [],
    hourlyAggregate: [],
  });
  useEffect(() => {
    const fetchData = async (id) => {
      const res = await getTemperatureHistory(id, new Date().toISOString());
      setData({
        allTemperatures: res?.data?.allTemperatures,
        hourlyAggregate: res?.data?.hourlyAggregate,
      });
    };
    fetchData(id);
  }, [id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({ contentRef: componentRef });

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <i className='bx bx-box'></i>
          <h1 className='Page__headline_title_fs'>View Historical Data</h1>
        </div>
        <BreadCrumb
          url1='/user/cold-chain'
          Link1='Cold Chain'
          Link2='View Historical Data'
        />
      </div>
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          <button
            onClick={handlePrint}
            className='mi_btn mi_btn_medium mi_btn_secondary bg_green'
          >
            <i className='fa-solid fa-download'></i>
            <span>Download</span>
          </button>
        </div>
      </div>

      <div className='Page__main_wrapper shipment__print' ref={componentRef}>
        <div className='printedPage__container'>
          <div className='printedPage__header'>
            <img
              src={`https://test.unicef.vaccineledger.com/emailIcons/EmailBranding.png`}
              alt='Plaid'
            />
          </div>

          <div className='shipment__print_spacing'>
            <div className='printedPage__subheader'>
              <h1> Historical Data </h1>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>Report Information</h1>
            </div>

            <div className='FunctionalData__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>Reporting Period Date</p>
                  <h1 className='TableData__bodyInfo'>
                    {new Date().toLocaleDateString()}
                  </h1>
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>Reporting Time</p>
                  <h1 className='TableData__bodyInfo'>
                    {new Date().toLocaleTimeString()}
                  </h1>
                </article>
                <article></article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>Temperature History</h1>
            </div>
            <div className='FunctionalData__body extra__bottom_space'>
              <div className='Temperature__graph_area'>
                <div className='Temperature__Info'>
                  <article className='Input__column extra_space'>
                    <p className='Input__label_fs'>Time Period</p>
                    <div className='Temperature__column'>
                      {data?.hourlyAggregate?.map((hourData, index) => (
                        <h1 key={index} className='TableData__bodyInfo'>
                          {TIME_SLOTS[hourData?._id?.date?.hour]}
                        </h1>
                      ))}
                    </div>
                  </article>
                  <article className='Input__column extra_space'>
                    <p className='Input__label_fs'>Temperature</p>
                    <div className='Temperature__column'>
                      {data?.hourlyAggregate?.map((hourData, index) => (
                        <h1 key={index} className='TableData__bodyInfo'>
                          {Number.parseFloat(hourData?.avgTemperature).toFixed(
                            2,
                          )}
                          °C
                        </h1>
                      ))}
                    </div>
                  </article>
                  <article className='Input__column extra_space'>
                    <p className='Input__label_fs'>Ambient Temp.</p>
                    <div className='Temperature__column'>
                      {data?.hourlyAggregate?.map((hourData, index) => (
                        <h1 key={index} className='TableData__bodyInfo'>
                          {Number.parseFloat(
                            hourData?.avgAmbientTemperature,
                          ).toFixed(2)}
                          °C
                        </h1>
                      ))}
                    </div>
                  </article>
                  <article className='Input__column extra_space'>
                    <p className='Input__label_fs'>Humidity</p>
                    {data?.hourlyAggregate?.map((hourData, index) => (
                      <h1 key={index} className='TableData__bodyInfo'>
                        {Number.parseFloat(hourData?.avgHumidity).toFixed(2)}°C
                      </h1>
                    ))}
                  </article>
                </div>
                <article>
                  <div className='FunctionalData__row two_column'>
                    <TemperatureGraph points={data?.allTemperatures} />
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>Asset Information</h1>
            </div>
            <div className='FunctionalData__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>Facility ID</p>
                  <h1 className='TableData__bodyInfo'>DF3245</h1>
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>Refrigerator ID</p>
                  <h1 className='TableData__bodyInfo'>FD123456</h1>
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    FT2 Data: Were the data downloaded?
                  </p>
                  <h1 className='TableData__bodyInfo'>Yes</h1>
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>Functional Status</h1>
            </div>
            <div className='FunctionalData__body extra__bottom_space'>
              <section className='FunctionalData__row_group'>
                <div className='FunctionalData__row'>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>
                      Heat alarms with over 10 - hour duration above 8 degree
                      Celsius are recorded in the temperature log from the first
                      day of the month to the last day of the month
                    </p>

                    <h1 className='TableData__bodyInfo'>0</h1>
                  </article>
                </div>
                <div className='FunctionalData__row'>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>
                      Freeze alarms with over 1 - hour duration below - 0.5
                      degree Celsius are recorded in the temperature log from
                      the first day of the month to the last day of the month
                    </p>
                    <h1 className='TableData__bodyInfo'>0</h1>
                  </article>
                </div>
                <div className='FunctionalData__row'>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>
                      Heat alarms with 48 - hour or longer duration are recorded
                      in the temperature log from the first day of the month to
                      the last day of the month
                    </p>
                    <h1 className='TableData__bodyInfo'>0</h1>
                  </article>
                </div>
              </section>

              <section className='FunctionalData__row_group'>
                <div className='FunctionalData__row three_column'>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>Equipment working status</p>
                    <h1 className='TableData__bodyInfo'>
                      Equipment working status
                    </h1>
                  </article>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>
                      Refrigerator Failure Reason
                    </p>
                    <h1 className='TableData__bodyInfo'>
                      Refrigerator Failure Reason
                    </h1>
                  </article>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>Area Failed</p>
                    <h1 className='TableData__bodyInfo'>Area Failed</h1>
                  </article>
                </div>
                <div className='FunctionalData__row three_column'>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>Action Taken</p>
                    <h1 className='TableData__bodyInfo'>Action Taken</h1>
                  </article>
                  <article className='Input__column'>
                    <p className='Input__label_fs'>Parts Replaced</p>
                    <h1 className='TableData__bodyInfo'>Parts Replaced</h1>
                  </article>
                  <article></article>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className='poweredSponser'>
          <p className='page__note_fs'>Powered By StaTwig &copy;2024</p>
        </div>
      </div>
    </section>
  );
}
