import React, { useState } from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import ReceiveBatchTable from "./ReceiveBatchTable";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function ReceiveBatch(props) {
  const {
    onClose,
    open,
    batches,
    setBatchesList,
    batchesList,
    product,
    setShipmentAccepted,
    setAcceptStatus,
    ...other
  } = props;

  const [acceptFinalData, setAcceptFinalData] = useState();

  const { t } = useTranslation();

  const handleSubmit = () => {
    if (acceptFinalData?.length > 0) {
      const data = {
        productId: product?._id,
        status: "Partially_Accepted",
        batches: acceptFinalData,
      };
      setBatchesList([...(batchesList || []), data]);
      setShipmentAccepted(true);
      setAcceptStatus("partial");
      toast.success("Batch Partially Accepted");
      onClose();
    } else {
      toast.error("If Rejecting, Please add the reasons");
    }
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className="ModalPopup__dialog"
      maxWidth="lg"
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className="ModalPopup__header">
          <div className="ModalPopup__header__title_fs">
            {t("received_batches")}
          </div>
          <div className="ModalPopup__close_icon" onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="ModalPopup__body more_bottom_space">
          <section className="Page__main_wrapper">
            <div className="Page__table_space">
              <ReceiveBatchTable
                batches={batches}
                product={product}
                acceptFinalData={acceptFinalData}
                setAcceptFinalData={setAcceptFinalData}
                t={t}
              />
            </div>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className="InventoryOrders__actions">
          <div className="Page__left_actions">
            {/* <button
              type="submit"
              className={`mi_btn mi_btn_medium  ${
                batches?.length === acceptFinalData?.length
                  ? "mi_btn_primary"
                  : "mi_btn_disable"
              }`}
              onClick={() => {
                if (batches?.length === acceptFinalData?.length) {
                  handleSubmit();
                }
              }}
              disabled={
                batches?.length === acceptFinalData?.length ? false : true
              }
            >
              <span>{t("confirm")}</span>
            </button> */}
            <button
              type="submit"
              className={`mi_btn mi_btn_medium mi_btn_primary`}
              onClick={handleSubmit}
            >
              <span>{t("proceed_to_accept_partially")}</span>
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}
