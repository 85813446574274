import React from 'react';
import { Link, useParams } from 'react-router-dom';

//icons
import NeedForcasting from '/icons/all/need-forcasting.svg';

import PermissionRoute from '../../../web/routes/routeLayers/PermissionRoute';
import { useTranslation } from 'react-i18next';
import AutoIndentTable from './AutoForm/AutoIndentTable';
import MinMaxTable from './minMaxForm/MinMaxTable';
import ConsumptionTable from './consumptionForm/ConsumptionTable';

export default function AutoIndent() {
  const { t } = useTranslation();

  const { tab } = useParams();

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <Link
          to='/user/need-forecasting/draft/request'
          className='mi_btn mi_btn_medium mi_btn_primary'
        >
          <i className='fa-solid fa-file-lines'></i>
          <span> {`${t('View Auto Indents')}`}</span>
        </Link>
        <div className='tab__btn_group'>
          <Link to={'/user/need-forecasting/auto-indent'}>
            <div className={`tab__button ${tab === 'auto-indent' && 'active'}`}>
              <h1 className='tab__btn_text_fs'>{t('auto_indenting')}</h1>
            </div>
          </Link>
          <Link to={'/user/need-forecasting/minmax'}>
            <div className={`tab__button ${tab === 'minmax' && 'active'}`}>
              <h1 className='tab__btn_text_fs'>{t('minimum_maximum')}</h1>
            </div>
          </Link>
          <Link to={'/user/need-forecasting/consumption'}>
            <div className={`tab__button ${tab === 'consumption' && 'active'}`}>
              <h1 className='tab__btn_text_fs'>{t('consumption')}</h1>
            </div>
          </Link>
        </div>
      </div>

      <div className='Page__table_wrapper'>
        {tab === 'auto-indent' && <AutoIndentTable />}
        {tab === 'minmax' && <MinMaxTable />}
        {tab === 'consumption' && <ConsumptionTable />}
      </div>
    </section>
  );
}
