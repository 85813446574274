import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function MainVAR({
  products,
  countries,
  reportNum,
  setMainFormData,
  storedData,
  setNData,
  setMainForm,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [changeProduct, setChangeProduct] = useState(false);
  const VaccineList = products.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  const CountryList = countries;
  const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"));
  React.useEffect(() => {
    setCurrentDate(dayjs().format("YYYY-MM-DD"));
  }, []);

  const formattedCurrentDate = dayjs().format("YYYY-MM-DD");

  const validate = (values) => {
    const errors = {};
    if (!values.country)
      errors.country = t("please_select") + " " + t("country");

    if (!values.vaccine)
      errors.vaccine = t("please_select") + " " + t("vaccine");

    if (!values.place_of_inception)
      errors.place_of_inception =
        t("please_enter") + " " + t("place_of_inspection");

    if (!values.cold_storage)
      errors.cold_storage = t("please_enter") + " " + t("cold_storage");

    if (!values.time_of_inspection)
      errors.time_of_inspection =
        t("please_enter") + " " + t("time_of_inspection");

    if (!values.report_no)
      errors.report_no = t("please_enter") + " " + t("report_no");

    return errors;
  };

  const [date, timeWithZone] = storedData?.inspectionDateTime
    ? storedData?.inspectionDateTime.split("T")
    : "";

  const formik = useFormik({
    initialValues: {
      vaccine: storedData?.product || "",
      country: storedData?.country || "Bangladesh",
      place_of_inception: storedData?.placeOfInspection || "Central Authority",
      cold_storage: storedData?.storageName || "",
      report_no: storedData?.reportNo || reportNum,
      date_of_inspection: date || formattedCurrentDate,
      report_date: storedData?.reportDate || formattedCurrentDate,
      time_of_inspection:
        timeWithZone?.substring(0, 5) || dayjs().format("HH:mm"),
    },
    validate,
    onSubmit: async (values) => {
      setMainFormData(0, values);
      setIsSubmitted(true);
      setChangeProduct(true);
      setMainForm(true)
      navigate("?form=part1");
      toast.success(t("general_info_saved"));
    },
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeProduct = () => {
    formik.resetForm();
    setIsSubmitted(false);
    setChangeProduct(false);
    setNData({});
    toast.success("Report Cleared");
    navigate("/user/arrival-report/vaccine/new");
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className='VAR__card'>
        <form
          className='ModalPopup__form_container'
          onSubmit={formik.handleSubmit}
        >
          {/* Form Row */}
          <article className='ModalPopup__form_section'>
            <div className='Input__table'>
              <div className='Input__row three_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("vaccine")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      disabled={changeProduct}
                      className='mui_custom_input_field'
                      id='vaccine'
                      name='vaccine'
                      value={formik.values.vaccine}
                      onChange={formik.handleChange}
                    >
                      {VaccineList?.map((vaccine, index) => (
                        <MenuItem key={index} value={vaccine._id}>
                          {vaccine?.name?.length > 39
                          ? vaccine?.name.substring(0, 40) + "..."
                          : vaccine?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.vaccine && formik.touched.vaccine && (
                    <span className='Input__error_fs'>
                      {formik.errors.vaccine}
                    </span>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("country")} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      disabled={isSubmitted}
                      className='mui_custom_input_field'
                      id='country'
                      name='country'
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    >
                      {CountryList?.map((country, index) => (
                        <MenuItem key={index} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.country && formik.touched.country && (
                    <span className='Input__error_fs'>
                      {formik.errors.country}
                    </span>
                  )}
                </div>

                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("place_of_inspection")} <b>*</b>
                  </p>
                  <input
                    type='text'
                    disabled
                    className={`mi_custom_textfield `}
                    id='place_of_inception'
                    name='place_of_inception'
                    value={formik.values.place_of_inception}
                    onChange={formik.handleChange}
                    style={{ opacity: "0.5" }}
                  />
                  {formik.errors.place_of_inception &&
                    formik.touched.place_of_inception && (
                      <span className='Input__error_fs'>
                        {formik.errors.place_of_inception}
                      </span>
                    )}
                </div>
              </div>
            </div>
          </article>

          <article className='ModalPopup__form_section'>
            <div className='Input__table'>
              <div className='Input__row three_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("cold_storage")} <b>*</b>
                  </p>
                  <input
                    disabled={isSubmitted}
                    type='text'
                    className='mi_custom_textfield'
                    id='cold_storage'
                    name='cold_storage'
                    onChange={formik.handleChange}
                    value={formik.values.cold_storage}
                  />
                  {formik.errors.cold_storage &&
                    formik.touched.cold_storage && (
                      <span className='Input__error_fs'>
                        {formik.errors.cold_storage}
                      </span>
                    )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t("report_no")}. <b>*</b>
                  </p>
                  <input
                    disabled={isSubmitted}
                    type='text'
                    className='mi_custom_textfield'
                    id='report_no'
                    name='report_no'
                    onChange={formik.handleChange}
                    value={formik.values.report_no}
                  />
                  {formik.errors.report_no && formik.touched.report_no && (
                    <span className='Input__error_fs'>
                      {formik.errors.report_no}
                    </span>
                  )}
                </div>
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t("date_of_report")}</p>
                  <input
                    disabled={isSubmitted}
                    type='date'
                    className='mi_custom_textfield date_field'
                    id='report_date'
                    name='report_date'
                    onChange={formik.handleChange}
                    value={formik.values.report_date}
                  />
                  {formik.errors.report_date && formik.touched.report_date && (
                    <span className='Input__error_fs'>
                      {formik.errors.report_date}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </article>

          <article className='ModalPopup__form_section'>
            <div className='Input__table'>
              <div className='Input__row two_column'>
                <div className='VARform__column'>
                  <h2 className='page__body_fs mi_sky'>
                    {t("inspection_date_time")}
                  </h2>
                  <div className='Input__row two_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("date_of_inspection")}
                      </p>
                      <input
                        disabled={isSubmitted}
                        type='date'
                        className='mi_custom_textfield date_field'
                        id='date_of_inspection'
                        name='date_of_inspection'
                        onChange={formik.handleChange}
                        value={formik.values.date_of_inspection}
                      />
                      {formik.errors.date_of_inspection &&
                        formik.touched.date_of_inspection && (
                          <span className='Input__error_fs'>
                            {formik.errors.date_of_inspection}
                          </span>
                        )}
                    </div>

                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("time_of_inspection")} <b>*</b>
                      </p>
                      <input
                        disabled={isSubmitted}
                        type='time'
                        className='mi_custom_textfield date_field'
                        id='time_of_inspection'
                        name='time_of_inspection'
                        onChange={formik.handleChange}
                        value={formik.values.time_of_inspection}
                      />
                      {formik.errors.time_of_inspection &&
                        formik.touched.time_of_inspection && (
                          <span className='Input__error_fs'>
                            {formik.errors.time_of_inspection}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="VARform__column">
                  <h2 className="page__body_fs mi_sky">
                    # When Vaccine entered the cold store
                  </h2>
                  <div className="Input__row two_column">
                    <div className="Input__column">
                      <p className="Input__label_fs">Date of Vaccine Entered</p>
                      <input
                        type="date"
                        className="mi_custom_textfield date_field"
                        id="date_of_vaccine_entered"
                        name="date_of_vaccine_entered"
                        onChange={formik.handleChange}
                        value={formik.values.date_of_vaccine_entered}
                      />
                      {formik.errors.date_of_vaccine_entered &&
                        formik.touched.date_of_vaccine_entered && (
                          <span className="Input__error_fs">
                            {formik.errors.date_of_vaccine_entered}
                          </span>
                        )}
                    </div>
                    <div className="Input__column">
                      <p className="Input__label_fs">Time of Vaccine Entered</p>
                      <input
                        type="time"
                        className="mi_custom_textfield date_field"
                        id="time_of_vaccine_entered"
                        name="time_of_vaccine_entered"
                        onChange={formik.handleChange}
                        value={formik.values.time_of_vaccine_entered}
                      />
                      {formik.errors.time_of_vaccine_entered &&
                        formik.touched.time_of_vaccine_entered && (
                          <span className="Input__error_fs">
                            {formik.errors.time_of_vaccine_entered}
                          </span>
                        )}
                    </div>
                  </div>
                </div> */}
            </div>
          </article>
          <div className='Mainvar_form_action'>
            <div className='Mainvar_button_actions'>
              {changeProduct && (
                <button
                  className='mi_btn mi_btn_medium mi_btn_secondary'
                  type='button'
                  onClick={handleClickOpen}
                >
                  <span>{t("change_vaccine")}</span>
                </button>
              )}

              {isSubmitted && (
                <button
                  className='mi_btn mi_btn_medium mi_btn_secondary'
                  type='button'
                  onClick={() => setIsSubmitted(false)}
                >
                  <span>{t("edit")}</span>
                </button>
              )}

              <button
                disabled={isSubmitted}
                className={`mi_btn mi_btn_medium ${
                  isSubmitted ? "mi_btn_disabled" : "mi_btn_primary"
                } `}
                type='submit'
              >
                <span>{t("save_continue")}</span>
              </button>
            </div>
          </div>
        </form>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {t("change_vaccine_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t("change_vaccine_subtitle")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className='mi_btn mi_btn_medium mi_btn_secondary'
            type='button'
            onClick={handleClose}
          >
            <span>{t("cancel")}</span>
          </button>

          <button
            className={`mi_btn mi_btn_medium mi_btn_primary`}
            type='button'
            onClick={handleChangeProduct}
          >
            <span>{t("yes_reset")}</span>
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
