import React from "react";

export default function ReviewVARcard({
  key,
  row,
  VaccineList,
  AlertList,
  DiluentList,
  t={t}
}) {
  return (
    <div className="ReviewVAR_card" key={key}>
      <div className="VARform__header">
        <h1 className="page__body_bigheading_fs">{row?.title}</h1>
      </div>
      <div className="ReviewVARcard__body">
        {row?.data?.map((answer, index) => (
          <AnswerRibbon
            key={index}
            title={answer?.title}
            answer={answer?.answer}
            subtitle={answer?.subtitle && answer?.subtitle}
          />
        ))}

        {row?.title === t("VAR_part3_title") && (
          <>
            {VaccineList?.map((item, index) => (
              <>
                <div className="AnswerRibbon__subheading">
                  <p className="page__tiny_fs mi_sky">{t("vaccine")} {index + 1}</p>
                </div>
                <VaccineValue key={index} item={item} t={t} />
              </>
            ))}
          </>
        )}

        {row?.title === t("VAR_part3_title") && (
          <>
            {DiluentList?.map((item, index) => (
              <>
                <div className="AnswerRibbon__subheading">
                  <p className="page__tiny_fs mi_sky">
                    {t("diluent_droppers")} {index + 1}
                  </p>
                </div>
                <DiluentDroppers key={index} item={item} t={t} />
              </>
            ))}
          </>
        )}

        {row?.title === t("VAR_part5_title") && (
          <>
            {AlertList?.map((item, index) => (
              <>
                <div className="AnswerRibbon__subheading">
                  <p className="page__tiny_fs mi_sky">
                    {t("for_batch_box")} {index + 1}
                  </p>
                </div>
                <AlertValue key={index} item={item} t={t} />
              </>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

function AnswerRibbon({ subtitle, title, answer, key }) {
  return (
    <div className="AnswerRibbon" key={key}>
      {subtitle && (
        <div className="AnswerRibbon__subheading">
          <p className="page__tiny_fs mi_sky">{subtitle}</p>
        </div>
      )}

      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{title}</h1>
        <p className="page__body_fs text_caps">{answer}</p>
      </div>
    </div>
  );
}

function VaccineValue({ item, key, t }) {
  return (
    <div className="AnswerRibbon" key={key}>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("lot_number")}</h1>
        <p className="page__body_fs text_caps">{item?.batchNo}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("no_of_boxes")}</h1>
        <p className="page__body_fs text_caps">{item?.noOfBoxes}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("no_of_unit")}</h1>
        <p className="page__body_fs text_caps">{item?.noOfUnits}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("quantity_per_unit")}</h1>
        <p className="page__body_fs text_caps">{item?.quantityPerUnit}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("manufacturing_date")}</h1>
        <p className="page__body_fs text_caps">{item?.mfgDate}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("expiry_date")}</h1>
        <p className="page__body_fs text_caps">{item?.expDate}</p>
      </div>
    </div>
  );
}

function DiluentDroppers({ item, key, t }) {
  return (
    <div className="AnswerRibbon" key={key}>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("lot_number")}</h1>
        <p className="page__body_fs text_caps">{item?.batchNo}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("no_of_boxes")}</h1>
        <p className="page__body_fs text_caps">{item?.noOfBoxes}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("no_of_unit")}</h1>
        <p className="page__body_fs text_caps">{item?.noOfUnits}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("quantity_per_unit")}</h1>
        <p className="page__body_fs text_caps">{item?.quantityPerUnit}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("manufacturing_date")}</h1>
        <p className="page__body_fs text_caps">{item?.mfgDate}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("expiry_date")}</h1>
        <p className="page__body_fs text_caps">{item?.expDate}</p>
      </div>
    </div>
  );
}

function AlertValue({ item, key, t }) {
  return (
    <div className="AnswerRibbon" key={key}>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("batch_no")}</h1>
        <p className="page__body_fs text_caps">{item.batchNo}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("inspection_date_time")}</h1>
        <p className="page__body_fs text_caps">{item.dateOfInspection} : {item.timeOfInspection}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("vaccine_vial_management")}</h1>
        <p className="page__body_fs text_caps">{item.vvmStatus}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("alarm_in_electronic_device")}</h1>
        <p className="page__body_fs text_caps">{item.temperature}</p>
      </div>
    </div>
  );
}
