import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Checkbox, Chip, FormGroup, MenuItem, Select } from "@mui/material";

export default function VARform5({ setFormComplete, setData, storedData }) {
  const [totalBoxes, setTotalBoxes] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Calculate sum of noOfBoxes when component mounts or data changes
    const sum = storedData?.shipmentDetails?.products?.reduce(
      (acc, item) => acc + item.noOfBoxes,
      0,
    );
    setTotalBoxes(sum); // Update state with the sum
  }, [storedData?.shipmentDetails?.products]);

  const [inspectionDate, timeWithZone] = storedData?.inspectionDateTime
    ? storedData?.inspectionDateTime.split("T")
    : "";

  const [isProblems, setIsProblems] = useState(false);
  const [isAlertDeleted, setIsAlertDeleted] = useState(false);

  useEffect(() => {
    if (storedData?.shippingIndicators?.alerts?.[0]?.batchNo) {
      setIsProblems(true);
    }
  }, [storedData?.shippingIndicators?.alerts?.[0]?.batchNo]);

  const validate = (values) => {
    const errors = {};
    if (!values.boxInspected) errors.boxInspected = t("box_inspected_error");

    if (values.boxInspected) {
      if (values.boxInspected > totalBoxes) {
        errors.boxInspected = t("box_inspected_exceed_error");
      }
    }

    if (!values.invoice) errors.invoice = t("invoice_amount_error");

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      boxInspected: storedData?.shippingIndicators?.noOfInspected || "",
      invoice: storedData?.shippingIndicators?.invoiceAmount || "",
      Question1: storedData?.shippingIndicators?.coolantType || "Dry Ice",
      Question2: storedData?.shippingIndicators?.tempMonitors || [],

      boxDetails: storedData?.shippingIndicators?.alerts
        ? storedData?.shippingIndicators?.alerts?.map((detail) => ({
            batchNo: detail?.batchNo || "",
            serialNo: detail?.serialNo || "",
            temperature: detail?.temperature || "",
            vvmStatus: detail?.vvmStatus || "1",
            dateOfInspection:
              dayjs(detail?.dateOfInspection).format("YYYY-MM-DD") || "",
            timeOfInspection:
              dayjs(detail?.inspectionDateTime).format("HH:mm") || "",
            inspectionDateTime:
              `${detail?.dateOfInspection}:${detail?.timeOfInspection}` || "",
            temperatureManual:
              detail?.temperature === "other" ? detail?.temperatureManual : "",
          }))
        : [
            {
              batchNo: "",
              serialNo: "",
              temperature: "45 °C",
              vvmStatus: "1",
              dateOfInspection: "",
              timeOfInspection: "",
              inspectionDateTime: "",
              temperatureManual: "",
            },
          ],
    },
    validate: validate,
    onSubmit: (values) => {
      const updatedBoxDetails = values.boxDetails.map((detail) => ({
        ...detail,
        inspectionDateTime:
          detail.dateOfInspection + "T" + detail.timeOfInspection,
      }));

      setData(5, {
        noOfInspected: parseInt(values.boxInspected),
        invoiceAmount: values.invoice,
        coolantType: values.Question1,
        tempMonitors: values.Question2,
        alerts: isProblems ? updatedBoxDetails : [],
      });
      navigate("?form=part6");
      setFormComplete(6);

      toast.success(t("VAR_part5_saved"));
    },
  });

  const handlePrev = () => {
    navigate("?form=part4");
    setFormComplete(4);
  };

  const addNewRow = () => {
    formik.setValues({
      ...formik.values,
      boxDetails: [
        ...formik.values.boxDetails,

        {
          batchNo: "",
          serialNo: "",
          temperature: "",
          vvmStatus: "1",
          dateOfInspection: inspectionDate || "",
          timeOfInspection: "",
          temperatureManual: "",
        },
      ],
    });
  };

  const removeRow = (indexOfObject) => {
    formik.setValues({
      ...formik.values,
      boxDetails: formik.values.boxDetails.filter(
        (_, index) => index !== indexOfObject,
      ),
    });
    setIsAlertDeleted(true);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      formik.setFieldValue("Question2", [...formik.values.Question2, name]);
    } else {
      formik.setFieldValue(
        "Question2",
        formik.values.Question2.filter((value) => value !== name),
      );
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} className='VARform'>
        <div className='VARform__header'>
          <h1 className='page__body_bigheading_fs'>{t("VAR_part5_title")}</h1>
        </div>
        <div className='VARform__body'>
          <div className='VARform__row three_column'>
            <div className='Input__column'>
              <p className='Input__label_fs'>{t("total_number_of_boxes")}</p>
              <input
                type='text'
                className='mi_custom_textfield'
                value={totalBoxes}
                disabled
              />
            </div>
            <div className='Input__column'>
              <p className='Input__label_fs'>
                {t("total_number_of_boxes_inspected")} <b>*</b>
              </p>
              <input
                type='number'
                className='mi_custom_textfield'
                {...formik.getFieldProps("boxInspected")}
              />

              {formik.errors.boxInspected && formik.touched.boxInspected && (
                <span className='Input__error_fs'>
                  {formik.errors.boxInspected}
                </span>
              )}
            </div>

            <div className='Input__column'>
              <p className='Input__label_fs'>
                {t("invoice_amount")} <b>*</b>
              </p>
              <input
                type='number'
                className='mi_custom_textfield'
                {...formik.getFieldProps("invoice")}
              />

              {formik.errors.invoice && formik.touched.invoice && (
                <span className='Input__error_fs'>{formik.errors.invoice}</span>
              )}
            </div>
            <div></div>
          </div>
          <div className='VARradio__questions one_isto_one_half_column extra_top_space'>
            <p className='page__body_fs mi_black'>{t("coolant_type")}</p>
            <FormControl>
              <RadioGroup row {...formik.getFieldProps("Question1")}>
                <FormControlLabel
                  value='Dry Ice'
                  control={<Radio />}
                  label='Dry Ice'
                />
                <FormControlLabel
                  value='Ice Packs'
                  control={<Radio />}
                  label='Ice Packs'
                />
                <FormControlLabel
                  value='Gel Packs'
                  control={<Radio />}
                  label='Gel Packs'
                />
                <FormControlLabel
                  value='No Coolants'
                  control={<Radio />}
                  label='No Coolants'
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='VARradio__questions one_isto_one_half_column '>
            <p className='page__body_fs mi_black'>
              {t("temperature_monitors_present")}
            </p>
            <FormControl component='fieldset'>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='VVM'
                      checked={formik.values.Question2.includes("VVM")}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label='VVM'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='Electronic Device'
                      checked={formik.values.Question2.includes(
                        "Electronic Device",
                      )}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label='Electronic Device'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='Fridge Tag'
                      checked={formik.values.Question2.includes("Fridge Tag")}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label='Fridge Tag'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='Freeze Tag'
                      checked={formik.values.Question2.includes("Freeze Tag")}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label='Freeze Tag'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='Temp Logger'
                      checked={formik.values.Question2.includes("Temp Logger")}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label='Temp Logger'
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className='VARradio__questions extra_top_space flex_space'>
            <div className='Input__column'>
              <p className='page__note_fs mi_red'>*{t("VAR_part5_hint")}</p>
              <p className='page__note_fs'>({t("VAR_part5_hint_msg")})</p>
            </div>
            <button
              type='button'
              className='mi_btn mi_btn_medium mi_btn_secondary'
              onClick={() => {
                if (isAlertDeleted) {
                  addNewRow();
                }
                setIsProblems(true);
              }}
            >
              <span>{t("add")}</span>
            </button>
          </div>
        </div>
        {isProblems && (
          <div className='VARgreen__form'>
            {formik.values.boxDetails.map((boxDetail, index) => (
              <GreenFormRow
                key={index}
                formik={formik}
                index={index}
                boxDetail={boxDetail}
                removeRow={removeRow}
                inspectionDate={inspectionDate}
                isProblems={isProblems}
                storedData={storedData}
                t={t}
              />
            ))}
          </div>
        )}

        <div className='VARform__footer'>
          <div className='Input__column_space_between_action'>
            {isProblems ? (
              <button
                className='mi_btn mi_btn_medium mi_btn_white'
                type='button'
                onClick={addNewRow}
                disabled={
                  storedData?.shipmentDetails?.products?.length ==
                  formik?.values?.boxDetails?.length
                }
              >
                <span>{t("add_new_row_button")}</span>
              </button>
            ) : (
              <div></div>
            )}

            <div className='flex_row'>
              <button
                type='button'
                className='mi_btn mi_btn_medium mi_btn_secondary'
                onClick={handlePrev}
              >
                <span>{t("prev")}</span>
              </button>
              <button
                className='mi_btn mi_btn_medium mi_btn_primary'
                type='submit'
              >
                <span>{t("save_continue")}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}

function GreenFormRow({
  formik,
  index,
  boxDetail,
  removeRow,
  inspectionDate,
  isProblems,
  storedData,
  t,
}) {
  const [VVMstatus, setVVMstatus] = useState(1);

  return (
    <div key={index} className='GreenFormRow'>
      <article className='VARform__row three_column  VAR5__relative'>
        <div className='VARform__column'>
          <div className='Input__row two_column'>
            <div className='Input__column'>
              <p className='Input__label_fs'>
                {t("lot_number")} <b>*</b>
              </p>

              {/* <input
                type="text"
                name={`boxDetails[${index}].boxNo`}
                className="mi_custom_textfield"
                onChange={formik.handleChange}
                value={boxDetail.boxNo}
                required={isProblems ? true : false}
              /> */}

              <FormControl fullWidth>
                <Select
                  size='small'
                  className='mui_custom_input_field'
                  name={`boxDetails[${index}].batchNo`}
                  onChange={formik.handleChange}
                  value={boxDetail.batchNo}
                  required={isProblems ? true : false}
                >
                  {storedData?.shipmentDetails?.products?.map((lot, index) => (
                    <MenuItem key={index} value={lot?.batchNo}>
                      {lot?.batchNo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span className='Input__error_fs d_none'></span>
            </div>
            <div className='Input__column'>
              <p className='Input__label_fs' style={{ whiteSpace: "nowrap" }}>
                {t("serial_number_electronic_device")}
              </p>

              <input
                type='text'
                name={`boxDetails[${index}].serialNo`}
                className='mi_custom_textfield'
                onChange={formik.handleChange}
                value={boxDetail.serialNo}
              />
            </div>
          </div>
        </div>
        {formik.values.boxDetails.length > 0 && (
          <button
            onClick={() => removeRow(index)}
            type='button'
            className='VAR5__delete_btn mi_link'
          >
            <i className='fa-solid fa-trash'></i>
          </button>
        )}
        <div className='VARform__column'>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t("alarm_in_electronic_device")} {`( >= )`}
            </p>
            {
              <div className='Input__column'>
                <input
                  type='number'
                  className='mi_custom_textfield'
                  name={`boxDetails[${index}].temperature`}
                  value={boxDetail.temperature}
                  onChange={formik.handleChange}
                  required={isProblems ? true : false}
                />
              </div>
            }
          </div>
          <div className='Input__column'>
            <p className='Input__label_fs'>{t("vaccine_vial_management")}</p>
            <FormControl>
              <RadioGroup
                row
                name={`boxDetails[${index}].vvmStatus`}
                value={boxDetail.vvmStatus}
                onChange={(e) => {
                  formik.handleChange(e);
                  setVVMstatus(e.target.value);
                }}
              >
                <FormControlLabel value={1} control={<Radio />} label='1' />
                <FormControlLabel value={2} control={<Radio />} label='2' />
                <FormControlLabel value={3} control={<Radio />} label='3' />
                <FormControlLabel value={4} control={<Radio />} label='4' />
              </RadioGroup>
            </FormControl>

            {VVMstatus && (
              <p className='Input__label_fs vvm__status'>
                <strong>{t("status")}:</strong>
                {VVMstatus == 1 && <Chip label={t("usable")} color='success' />}
                {VVMstatus == 2 && <Chip label={t("usable")} color='success' />}
                {VVMstatus == 3 && (
                  <Chip label={t("non_usable")} color='error' />
                )}
                {VVMstatus == 4 && (
                  <Chip label={t("non_usable")} color='error' />
                )}
              </p>
            )}
          </div>
          {/* <FormControl>
              <RadioGroup
                row
                name={`boxDetails[${index}].use`}
                value={boxDetail.use}
                // onChange={formik.handleChange}
              >
                <FormControlLabel value='usable' control={<Radio />} label='usable' />
                <FormControlLabel value='unusable' control={<Radio />} label='unusable' />
              </RadioGroup>
            </FormControl> */}
        </div>
        <div className='VARform__column'>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t("date_of_inspection")}
              <b>*</b>
            </p>
            <input
              type='date'
              name={`boxDetails[${index}].dateOfInspection`}
              className='mi_custom_textfield'
              max={inspectionDate}
              onChange={formik.handleChange}
              value={boxDetail.dateOfInspection}
              required={isProblems ? true : false}
            />
          </div>
          <div className='Input__column'>
            <p className='Input__label_fs'>
              {t("time_of_inspection")} <b>*</b>
            </p>
            <input
              type='time'
              name={`boxDetails[${index}].timeOfInspection`}
              className='mi_custom_textfield'
              onChange={formik.handleChange}
              value={boxDetail.timeOfInspection}
              required={isProblems ? true : false}
            />
          </div>
        </div>
      </article>
    </div>
  );
}
