import React from 'react';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

import { DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UpdatePersonalInfo from './UpdateComponents/UpdatePersonalInfo';
import UpdateAccount from './UpdateComponents/UpdateAccount';

export default function UpdateProfile(props) {
  const { orgLevels, locations, open, user, page, ...other } = props;

  const navigate = useNavigate();

  const onClose = () => {
    navigate('/user/services/profile');
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': { width: '100%' },
      }}
      className='ModalPopup__dialog'
      maxWidth='sm'
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: '0 !important' }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>Update Profile</div>
          <div className='ModalPopup__close_icon' onClick={onClose}>
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <form className='ModalPopup__form_container'>
        <DialogContent
          sx={{ padding: '0 !important', borderRadius: '0.6rem !important' }}
        >
          {page === 'update' && (
            <UpdatePersonalInfo user={user} onClose={onClose} />
          )}
          {page === 'account' && (
            <UpdateAccount user={user} onClose={onClose} />
          )}
        </DialogContent>
      </form>
    </Dialog>
  );
}
