import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import { TablePagination } from '@mui/material';
import { toast } from 'react-hot-toast';
import { getProducts, updateMinMax } from '../../../../redux/userActions';
import { useTranslation } from 'react-i18next';
import { getNeedForCastData } from '../../../../services/inventory-services/api';

export default function ConsumptionTable() {
  const { t } = useTranslation();

  const [productList, setProductList] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await getProducts('VACCINE');
      setProductList(res.data);
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__table_space'>
          <TableContainer>
            <Table sx={{ minWidth: 485 }} aria-label='simple table'>
              <TableHead className='mi_table__head'>
                <TableRow>
                  <TableCell>
                    <FieldHead title={t('product_name')} />
                  </TableCell>

                  <TableCell align='center'>
                    <FieldHead
                      title={t('consumption_for_4_weeks')}
                      align='center'
                    />
                  </TableCell>

                  <TableCell align='center'>
                    <FieldHead
                      title={t('buffer_doses_for_1_week')}
                      align='center'
                    />
                  </TableCell>

                  <TableCell align='center'>
                    <FieldHead
                      title={t('monthly_total_requirements_doses')}
                      align='center'
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {productList?.map((product) => (
                    <ThresholdBody product={product} />
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </React.Fragment>
  );
}

function ThresholdBody({ product }) {
  const [consumption, setConsumption] = React.useState(null);

  useEffect(() => {
    async function fetchData(id) {
      if (id) {
        const res = await getNeedForCastData(product?._id);
        setConsumption(res?.openingBalance);
      }
    }
    fetchData(product?._id);
  }, [product?._id]);

  const oneWeekData = consumption / 4;

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        <FieldBody text={product?.name} />
      </TableCell>

      <TableCell align='center'>
        <FieldBody text={consumption} />
      </TableCell>

      <TableCell align='center'>
        <FieldBody text={oneWeekData ? Math.round(oneWeekData * 5) : '--'} />
      </TableCell>
      <TableCell align='center'>
        <FieldBody text={oneWeekData ? Math.round(oneWeekData * 6) : '--'} />
      </TableCell>
    </TableRow>
  );
}
