import React, { useEffect, useState } from 'react';
import { getEquipmentList } from '../../../../redux/inventoryActions';
import EquipmentTable from './EquipmentTable';

export default function ColdChainTable({ t }) {
  const [equipmentData, setEquipmentData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async (page, limit) => {
      try {
        const res = await getEquipmentList(page, limit);
        setEquipmentData(res.data);
      } catch (error) {
        console.error('Error fetching equipment data:', error);
      }
    };
    fetchData(page, limit);
  }, [page, limit]);

  return (
    <section className='ColdChainTable'>
      <div className='ColdChainTable__body'>
        <EquipmentTable
          equipmentData={equipmentData}
          page={page}
          handleChangePage={handleChangePage}
          limit={limit}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </section>
  );
}
