import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ProductIcon from '../../../shared/utils/productIcon/ProductIcon';
import backupIcon from '/icons/image.svg';
import { API_URL } from '../../../config';

export default function OngoingCampaign({
  isOrders,
  data,
  campaignID,
  setCampaignID,
  level,
}) {
  const { t } = useTranslation();
  const myref = useRef();
  const [scrollClick, setScrollClicked] = useState(false);
  const scrollRight = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
    setScrollClicked(true);
  };

  const scrollLeft = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;

    if (myref.current.scrollLeft < 320) {
      setScrollClicked(false);
    }
  };

  return (
    <div className='Campaign__tile_wrapper'>
      <div className='Campaign__head_space'>
        <h1 className='page__body_heading_fs'>{`${t('ongoing_campaign')}`}:</h1>
        <div className='Campaign__scroll_btn_group'>
          {scrollClick && (
            <div
              className='Campaign__scroll_btn'
              onClick={() => scrollLeft(-300)}
            >
              <i className='fa-solid fa-arrow-left'></i>
            </div>
          )}
          <div
            className='Campaign__scroll_btn'
            onClick={() => scrollRight(300)}
          >
            <i className='fa-solid fa-arrow-right'></i>
          </div>
        </div>
      </div>

      <div className='Campaign__card_list' ref={myref}>
        {data?.map((row) => (
          <CampaignCard
            key={row._id}
            isOrders={isOrders}
            row={row}
            campaignID={campaignID}
            setCampaignID={setCampaignID}
            t={t}
            level={level}
          />
        ))}
      </div>
    </div>
  );
}

function CampaignCard({ isOrders, row, campaignID, setCampaignID, t, level }) {
  const navigate = useNavigate();

  const ImageWithFallback = ({ src, fallbackSrc, alt }) => {
    return (
      <img
        src={src}
        alt={alt}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackSrc;
        }}
      />
    );
  };

  const imageUrl = row?.logoId
    ? `${API_URL.imagesUrl}/${row.logoId}`
    : backupIcon;

  return (
    <div
      className={`CampaignCard ${isOrders && 'OrderCampaign'} ${
        campaignID?._id === row?._id && 'active'
      }`}
      onClick={() => {
        if (isOrders) {
          setCampaignID(row);
        }
      }}
    >
      <div className='CampaignCard__content'>
        <div className='CampaignCard__banner'>
          <ImageWithFallback
            src={imageUrl}
            fallbackSrc={backupIcon}
            alt='Campaign Icon'
          />
        </div>
        <div className='CampaignCard__details'>
          <p className='Campaign__body_fs'>
            {`${new Date(row?.startDate).toLocaleDateString()} to ${new Date(
              row?.endDate
            ).toLocaleDateString()}`}
          </p>
          <p className='Campaign__title_fs all_uppers'>{row?.name}</p>
          <div className='CampaignCard__info'>
            <p className='Campaign__note_fs'>{row?.id}</p>
            <p className='Campaign__note_fs flex'>
              {t('vaccine')}:{' '}
              <ProductIcon
                type={row?.productDetails?.type}
                name={row?.productDetails?.name}
                size='tiny'
                fallback={<span>{row?.productDetails?.name}</span>}
              />{' '}
            </p>
          </div>
        </div>
      </div>
      {!isOrders ? (
        <div className='CampaignCard__action'>
          <button
            className='mi_btn mi_btn_small CampaignCard__cyan_btn'
            onClick={() =>
              navigate('/user/shipments/new-shipments', {
                state: { campaignId: row?._id },
              })
            }
          >
            {t('send_shipments')}
          </button>
          <button
            className='mi_btn mi_btn_small CampaignCard__cyan_btn'
            onClick={() =>
              navigate('/user/orders/add-orders', {
                state: { campaign: row },
              })
            }
          >
            {t('send_orders')}
          </button>
          {level === 'EPI' && (
            <button
              onClick={() =>
                navigate('/user/campaign/edit', {
                  state: { editData: row },
                })
              }
              className='mi_btn mi_btn_small CampaignCard__purple_btn'
            >
              {t('edit')}
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
}
