import React from 'react';
import { useTranslation } from 'react-i18next';

export default function UtilizedExportTable({
  printData,
  filteredColumns,
  tableRef,
}) {
  const { t } = useTranslation();

  // Limit to the first 10 records
  const displayedData = printData?.slice(0, 10);

  return (
    <table style={tableStyle} ref={tableRef}>
      <thead>
        <tr>
          {filteredColumns?.map((column, index) => (
            <th key={index} style={thStyle}>
              {t(column?.title)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {displayedData?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {filteredColumns.map((column) => (
              <React.Fragment key={column?.key}>
                {column.key === 'vaccine' && (
                  <td style={thTdStyle}>{row?.vaccine}</td>
                )}

                {column.key === 'batch_no' && (
                  <td style={thTdStyle}>{row?.batch_no}</td>
                )}

                {column.key === 'dose_utilized' && (
                  <td style={thTdStyle}>{row?.dose_utilized}</td>
                )}

                {column.key === 'status' && (
                  <td style={thTdStyle}>
                    {row.status !== 'OPEN' ? 'completed' : 'Open'}
                  </td>
                )}

                {column.key === 'last_updated' && (
                  <td style={thTdStyle}>
                    {new Date(row?.last_updated).toLocaleDateString()}
                  </td>
                )}

                {column.key === 'action' && <td style={thTdStyle}>--</td>}
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const thTdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
  fontSize: '10px',
};

const thStyle = {
  ...thTdStyle,
  backgroundColor: '#f2f2f2',
};
