import React, { useState } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  Button,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAddAsset } from '../../../services/coldchain-services/mutations';
import { uploadImage } from '../../../redux/imageAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { callPopup } from '../../../store/slices/popupSlice';
import BreadCrumb from '../../../shared/utils/breadCrumb/BreadCrumb';

export default function AddAssets() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statusList = [
    { key: 'WORKING', name: 'WORKING' },
    { key: 'OUT_OF_SERVICE', name: 'OUT OF SERVICE' },
    { key: 'INTACT', name: 'INTACT' },
    { key: 'MAINTENANCE', name: 'MAINTENANCE' },
    { key: 'REPAIRABLE', name: 'REPAIRABLE' },
    { key: 'NOT_REPAIRABLE', name: 'NOT REPAIRABLE' },
    { key: 'DISPOSED', name: 'DISPOSED' },
    { key: 'TRANSIT', name: 'TRANSIT' },
  ];

  const capacityList = [
    { key: 'COLD', name: 'COLD' },
    { key: 'FREEZE', name: 'FREEZE' },
    { key: 'DEEP', name: 'DEEP' },
  ];

  const { theToken, userInfo } = useSelector((state) => state.auth);
  const { mutate, isPending } = useAddAsset();

  const validationSchema = Yup.object({
    model: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    status: Yup.string().required('Required'),
    manufacturer: Yup.string().required('Required'),
    pqsStatus: Yup.string().required('Required'),
    pqsCode: Yup.string().required('Required'),
    pqsType: Yup.string().required('Required'),
    yearOfManufacture: Yup.number().required('Required'),
    yearOfInstallation: Yup.number().required('Required'),
    sourceOfEnergy: Yup.string().required('Required'),
    capacity: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
        temperature: Yup.string().required('Required'),
        grossStorage: Yup.number().required('Required'),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      imageId: '',
      model: '',
      type: '',
      serialNumber: '',
      status: '',
      manufacturer: '',
      pqsStatus: '',
      pqsCode: '',
      pqsType: '',
      yearOfManufacture: '',
      yearOfInstallation: '',
      yearOfAnticipatedDisposal: '',
      yearOfDisposal: '',
      sourceOfEnergy: '',
      sourceOfFunding: '',
      capacity: [{ name: '', temperature: '', grossStorage: '' }],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const campaignLogoId = await uploadCampaignImage(values?.imageId);

      const apiData = {
        locationId: userInfo?.locationId,
        imageId: campaignLogoId,
        model: values?.model,
        type: values?.type || '',
        serialNumber: values?.serialNumber || '',
        status: values?.status || '',
        manufacturer: values?.manufacturer || '',
        pqsStatus: values?.pqsStatus || '',
        pqsCode: values?.pqsCode || '',
        pqsType: values?.pqsType || '',
        yearOfManufacture: values?.yearOfManufacture?.toString() || '',
        yearOfInstallation: values?.yearOfInstallation?.toString() || '',
        yearOfAnticipatedDisposal:
          values?.yearOfAnticipatedDisposal?.toString() || '',
        yearOfDisposal: values?.yearOfDisposal?.toString() || '',
        sourceOfEnergy: values?.sourceOfEnergy || '',
        sourceOfFunding: values?.sourceOfFunding || '',
        capacity: values?.capacity,
      };

      mutate(apiData, {
        onSuccess: () => {
          navigate('/user/cold-chain');
          dispatch(
            callPopup({
              open: true,
              type: 'success',
              page: 'assets',
              message: t('Asset Added Successfully'),
              action: '/user/cold-chain',
            })
          );
        },
        onError: (error) => {
          callPopup({
            open: true,
            type: 'warn',
            page: 'orders',
            title: 'Something went Wrong',
            message: error,
          });
        },
      });
    },
  });

  const uploadCampaignImage = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const imageKey = await uploadImage(formData, theToken);
        if (imageKey?.data) {
          return imageKey?.data;
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Image upload error');
      }
    }
  };

  const addCapacity = () => {
    formik.setFieldValue('capacity', [
      ...formik.values.capacity,
      { name: '', temperature: '', grossStorage: '' },
    ]);
  };

  const removeCapacity = (index) => {
    const newCapacity = formik.values.capacity.filter((_, i) => i !== index);
    formik.setFieldValue('capacity', newCapacity);
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <div
            className='back_Link'
            onClick={() => navigate('/user/cold-chain')}
          >
            <i className='bx bx-arrow-back icon_blue'></i>
          </div>
          <h1 className='Page__headline_title_fs'>{t('Add Asset')}</h1>
        </div>
        <BreadCrumb
          url1='/user/cold-chain'
          Link1={t('cold_chain')}
          Link2={t('Add Asset')}
        />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>
                {t('Asset Information')}
              </h1>
            </div>
            <div className='FunctionalData__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>{t('Asset Image')}</p>
                  <input
                    type='file'
                    name='imageId'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    onChange={(event) => {
                      formik.setFieldValue(
                        'imageId',
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('Model')} <b>*</b>
                  </p>
                  <input
                    type='text'
                    name='model'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('model')}
                  />
                  {formik.touched.model && formik.errors.model ? (
                    <div className='Input__error_fs'>{formik.errors.model}</div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('Type')} <b>*</b>
                  </p>
                  <input
                    type='text'
                    name='type'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('type')}
                  />
                  {formik.touched.type && formik.errors.type ? (
                    <div className='Input__error_fs'>{formik.errors.type}</div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>{t('serial_no')} </p>
                  <input
                    type='text'
                    name='serialNumber'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('serialNumber')}
                  />
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('status')} <b>*</b>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      name='status'
                      className='mui_custom_input_field'
                      {...formik.getFieldProps('status')}
                    >
                      {statusList.map((item, index) => (
                        <MenuItem key={index} value={item.key}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.status && formik.errors.status ? (
                    <div className='Input__error_fs'>
                      {formik.errors.status}
                    </div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('manufacturer')} <b>*</b>
                  </p>
                  <input
                    type='text'
                    name='manufacturer'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('manufacturer')}
                  />
                  {formik.touched.manufacturer && formik.errors.manufacturer ? (
                    <div className='Input__error_fs'>
                      {formik.errors.manufacturer}
                    </div>
                  ) : null}
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>
                {' '}
                {t('PQS Information')}
              </h1>
            </div>
            <div className='FunctionalData__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('PQS Status')} <b>*</b>
                  </p>
                  <input
                    type='text'
                    name='pqsStatus'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('pqsStatus')}
                  />
                  {formik.touched.pqsStatus && formik.errors.pqsStatus ? (
                    <div className='Input__error_fs'>
                      {formik.errors.pqsStatus}
                    </div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('PQS Code')} <b>*</b>
                  </p>
                  <input
                    type='text'
                    name='pqsCode'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('pqsCode')}
                  />
                  {formik.touched.pqsCode && formik.errors.pqsCode ? (
                    <div className='Input__error_fs'>
                      {formik.errors.pqsCode}
                    </div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('PQS Type')} <b>*</b>
                  </p>
                  <input
                    type='text'
                    name='pqsType'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('pqsType')}
                  />
                  {formik.touched.pqsType && formik.errors.pqsType ? (
                    <div className='Input__error_fs'>
                      {formik.errors.pqsType}
                    </div>
                  ) : null}
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>
                {t('Installation Information')}
              </h1>
            </div>
            <div className='FunctionalData__body'>
              <div className='FunctionalData__row three_column'>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('Year of Manufacture')} <b>*</b>
                  </p>
                  <input
                    name='yearOfManufacture'
                    placeholder='YYYY'
                    className='mi_custom_textfield'
                    type='number'
                    min='1900'
                    max={new Date().getFullYear()}
                    {...formik.getFieldProps('yearOfManufacture')}
                  />
                  {formik.touched.yearOfManufacture &&
                  formik.errors.yearOfManufacture ? (
                    <div className='Input__error_fs'>
                      {formik.errors.yearOfManufacture}
                    </div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('Year of Installation')} <b>*</b>
                  </p>
                  <input
                    name='yearOfInstallation'
                    type='number'
                    min='1900'
                    max={new Date().getFullYear()}
                    placeholder='YYYY'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('yearOfInstallation')}
                  />
                  {formik.touched.yearOfInstallation &&
                  formik.errors.yearOfInstallation ? (
                    <div className='Input__error_fs'>
                      {formik.errors.yearOfInstallation}
                    </div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('Year of Anticipated Disposal')}
                  </p>
                  <input
                    name='yearOfAnticipatedDisposal'
                    type='number'
                    min='1900'
                    placeholder='YYYY'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('yearOfAnticipatedDisposal')}
                  />
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'> {t('Year of Disposal')}</p>
                  <input
                    type='number'
                    min='1900'
                    placeholder='YYYY'
                    name='yearOfDisposal'
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('yearOfDisposal')}
                  />
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('Source of Energy')} <b>*</b>
                  </p>
                  <input
                    type='text'
                    name='sourceOfEnergy'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('sourceOfEnergy')}
                  />
                  {formik.touched.sourceOfEnergy &&
                  formik.errors.sourceOfEnergy ? (
                    <div className='Input__error_fs'>
                      {formik.errors.sourceOfEnergy}
                    </div>
                  ) : null}
                </article>
                <article className='Input__column'>
                  <p className='Input__label_fs'>{t('Source of Funding')}</p>
                  <input
                    type='text'
                    name='sourceOfFunding'
                    placeholder={t('Enter here...')}
                    className='mi_custom_textfield'
                    {...formik.getFieldProps('sourceOfFunding')}
                  />
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__form'>
          <div className='FunctionalData__card'>
            <div className='FunctionalData__header'>
              <h1 className='FunctionalData_heading_fs'>
                {t('Capacity Information')}
              </h1>
            </div>
            <div className='FunctionalData__body'>
              {formik.values.capacity.map((capacity, index) => (
                <div className='Capacity_container' key={index}>
                  <div className='Capacity__grid'>
                    <article className='Input__column'>
                      <p className='Input__label_fs'>
                        {t('Name')} <b>*</b>
                      </p>
                      <FormControl fullWidth>
                        <Select
                          size='small'
                          name={`capacity[${index}].name`}
                          className='mui_custom_input_field'
                          value={capacity.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {capacityList.map((item, i) => (
                            <MenuItem key={i} value={item.key}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.capacity &&
                      formik.touched.capacity[index] &&
                      formik.errors.capacity &&
                      formik.errors.capacity[index] &&
                      formik.errors.capacity[index].name ? (
                        <div className='Input__error_fs'>
                          {formik.errors.capacity[index].name}
                        </div>
                      ) : null}
                    </article>
                    <article className='Input__column'>
                      <p className='Input__label_fs'>
                        {t('Temperature')} <b>*</b>
                      </p>
                      <input
                        type='text'
                        name={`capacity[${index}].temperature`}
                        placeholder={t('Enter here...')}
                        className='mi_custom_textfield'
                        value={capacity.temperature}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.capacity &&
                      formik.touched.capacity[index] &&
                      formik.errors.capacity &&
                      formik.errors.capacity[index] &&
                      formik.errors.capacity[index].temperature ? (
                        <div className='Input__error_fs'>
                          {formik.errors.capacity[index].temperature}
                        </div>
                      ) : null}
                    </article>
                    <article className='Input__column'>
                      <p className='Input__label_fs'>
                        {t('Gross Storage')} <b>*</b>
                      </p>
                      <input
                        type='text'
                        name={`capacity[${index}].grossStorage`}
                        placeholder={t('Enter here...')}
                        className='mi_custom_textfield'
                        value={capacity.grossStorage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.capacity &&
                      formik.touched.capacity[index] &&
                      formik.errors.capacity &&
                      formik.errors.capacity[index] &&
                      formik.errors.capacity[index].grossStorage ? (
                        <div className='Input__error_fs'>
                          {formik.errors.capacity[index].grossStorage}
                        </div>
                      ) : null}
                    </article>
                  </div>

                  <div
                    className='delete__icon_wrap'
                    onClick={() => removeCapacity(index)}
                  >
                    <i class='fa-regular fa-trash-can'></i>
                  </div>
                </div>
              ))}

              <div>
                <button
                  className='mi_btn mi_btn_medium mi_btn_white'
                  type='button'
                  onClick={addCapacity}
                >
                  <span>{t('add_new_row_button')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='FunctionalData__actions'>
          <button
            type='submit'
            className='mi_btn mi_btn_medium mi_btn_primary'
            disabled={isPending}
          >
            <span>{isPending ? 'Submitting...' : 'Submit'}</span>
          </button>
        </div>
      </form>
    </section>
  );
}
