import { MuiOtpInput } from 'mui-one-time-password-input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sendOtp } from '../../../../redux/userActions';

export default function UpdateAccount({ user }) {
  const { t } = useTranslation();

  const [email, setEmail] = React.useState();
  const [isEmailUpdated, setIsEmailUpdated] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const [match, setMatch] = React.useState(true);
  const [resent, setResent] = React.useState(false);
  const [timer, setTimer] = React.useState(60);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setIsEmailUpdated(true);

    const sendOTPBody = {
      email: email,
      forgotPassword: true,
      resend: false,
    };

    const res = await sendOtp(sendOTPBody);


  };

  //   const resend = async () => {
  //     const sendOTPBody = {
  //       forgotPassword: false,
  //       resend: true,
  //     };
  //     if (values.email.length) {
  //       sendOTPBody['email'] = values?.email;
  //     }
  //     if (timer === 0) {
  //       const res = await sendOtp(sendOTPBody);
  //       if (res.data.success) {
  //         setResent(true);
  //         setTimeout(() => {
  //           setResent(false);
  //         }, 2000);
  //       }
  //       setTimer(60);
  //     }
  //   };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  return (
    <div className='ModalPopup__form_container'>
      <div className='ModalPopup__wrapper'>
        <form>
          <div className='ModalPopup__body'>
            <div className='ModalPopup__form_section'>
              <div className='ModalPopup__label_header'>
                <h1 className='Input__label_fs'>{t('update_user_subtitle')}</h1>
              </div>
              <div className='Input__row two_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>Existing Email Address</p>
                  <div className='otp_email_verify'>
                    <h1
                      style={{
                        fontSize: '12px',
                        marginTop: '-0.5rem',
                      }}
                    >
                      {user?.emailId}
                    </h1>
                  </div>
                </div>
                <div></div>
              </div>
              <div className='Input__table_email'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {t('new')} {t('email_address')}
                    <b>*</b>
                  </p>
                  <input
                    type='text'
                    className='mi_custom_textfield'
                    id='email'
                    name='email'
                    maxLength={50}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className='Email__form_action'>
                  <button
                    className='mi_btn mi_btn_medium mi_btn_primary'
                    onClick={(e) => handleSendOtp(e)}
                  >
                    <span>Update</span>
                  </button>
                </div>
              </div>

              <div
                className='Input__row single_column'
                style={{ position: 'relative' }}
              >
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t('otp')} ( OTP )</p>
                  <div className='Input_short_otp'>
                    <div className='Input__column'>
                      <MuiOtpInput
                        length={4}
                        value={otp}
                        onChange={handleChange}
                      />
                      <span className='Input__error_fs'>
                        {t('otp_not_matched')}
                      </span>
                    </div>

                    {/* <div className='short_resend_wrapper'>
                      <div onClick={resend}>
                        <span
                          style={{ color: 'blue' }}
                          className=' Input__label_fs'
                        >
                          {t('resend_otp')}{' '}
                          {timer > 0 &&
                            'in 00 : ' +
                              (timer < 10 ? '0' + timer : timer)}{' '}
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='UpdateProfile__form_action'>
            <button className='mi_btn mi_btn_medium mi_btn_primary'>
              <span>Verify</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
