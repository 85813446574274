import { API_URL } from '../config';
import axios from 'axios';

// Update & Get Images
export const uploadImage = async (data, token) => {
  try {
    if (!(data instanceof FormData)) {
      throw new Error('Data must be an instance of FormData.');
    }

    const result = await axios.post(API_URL.imagesUrl, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (e) {
    console.error('Error uploading image:', e);
    return e.response ? e.response.data : e.message;
  }
};

export const getImage = async (imageId, token) => {
  try {
    const response = await axios.get(`${API_URL.imagesUrl}/${imageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
};
