import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MaintenanceTable from './MaintenanceTable';
import BreadCrumb from '../../../shared/utils/breadCrumb/BreadCrumb';
import { getMaintenanceJobsList } from '../../../redux/inventoryActions';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export default function Maintenance() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getMaintenanceJobsList();
      setData(res.data);
    };
    fetchData();
  }, []);

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <div
            className='back_Link'
            onClick={() => navigate('/user/cold-chain')}
          >
            <i className='bx bx-arrow-back icon_blue'></i>
          </div>
          <h1 className='Page__headline_title_fs'>{t('maintenance')}</h1>
        </div>
        <BreadCrumb
          url1='/user/cold-chain'
          Link1={t('cold_chain')}
          Link2={t('maintenance')}
        />
      </div>
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          <Link
            to='/user/maintenance/job?view=new'
            className='mi_btn mi_btn_medium mi_btn_primary'
          >
            <i className='fa-solid fa-plus'></i>
            <span>{t('add_job_card')}</span>
          </Link>
        </div>
        <div className='Page__right_actions'>
          <article className='mi_search_bar'>
            <i className='fa-solid fa-magnifying-glass'></i>
            <input type='text' placeholder={t('search_by_shipment')} />
          </article>
          <button className='mi_btn mi_btn_medium mi_btn_outline'>
            <i className='fa-solid fa-sliders'></i>
            <span>{t('filter')}</span>
          </button>
        </div>
      </div>
      <div className='Page__table_wrapper'>
        <MaintenanceTable data={data} t={t} />
      </div>
    </section>
  );
}
