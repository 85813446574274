import React from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import BatchLastMileTable from "./BatchLastMileTable";

import { getBatchByProduct } from "../../../../redux/inventoryActions";

export default function FetchBatchLastMile(props) {
  const { onClose, open, formik, t, ...other } = props;

  const [productId, setProductId] = React.useState(formik.values.vaccine);
  const [batchList, setBatchList] = React.useState();

  React.useEffect(() => {
    setProductId(formik.values.vaccine);
  }, [formik.values.vaccine]);

  React.useEffect(() => {
    async function fetchData() {
      if (productId) {
        const res = await getBatchByProduct(productId, "ACTIVE");
        setBatchList(res.data);
      }
    }
    fetchData();
  }, [productId]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className="ModalPopup__dialog"
      maxWidth="lg"
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className="ModalPopup__header">
          <div className="ModalPopup__header__title_fs">
            <div className="modal__heading_group">
              <div className="modal__heading">
                <h1 className="page__body_subheading_fs">
                  {batchList?.product?.type} : {batchList?.product?.name} -{" "}
                  {batchList?.product?.id}
                </h1>
              </div>
            </div>
          </div>
          <div className="ModalPopup__close_icon" onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="ModalPopup__body ">
          <section className="Page__main_wrapper">
            <div className="Page__table_space">
              <BatchLastMileTable
                formik={formik}
                onClose={onClose}
                batchList={batchList}
                t={t}
              />
            </div>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        {/* <section className="InventoryOrders__actions_Popup">
          <div></div>
          <div className="Page__left_actions">
            <button
              type="submit"
              className="mi_btn mi_btn_medium mi_btn_primary"
            >
              <span>Confirm</span>
            </button>
          </div>
        </section> */}
      </DialogActions>
    </Dialog>
  );
}
