import React, { useState } from "react";
import IconSummary from "/icons/analytics/IconSummary.svg";
import IconCurrentStock from "/icons/analytics/IconCurrentStock.svg";
import IconExpired from "/icons/analytics/IconExpired.svg";
import AnalyticCard from "../../../../shared/utils/analyticCard/AnalyticCard";
import ShipmentInboundTable from "./ShipmentInboundTable";
import ShipInDamagedTable from "./ShipInDamagedTable";
import Shipped from "/icons/all/shipments/Shipped.svg";
import Delivered from "/icons/all/shipments/delivered.svg";
import Summary from "/icons/all/shipments/summary.svg";
import PartiallyReceived from "/icons/all/shipments/Received Shipment.svg"
import {
  getInBoundShipment,
  getInboundShipmentAnalytics,
} from "../../../../redux/shipmentActions";
import Fuse from "fuse.js";
import { formatNumber } from "../../../../shared/common/commonFunctions";
import { useTranslation } from "react-i18next";

export default function InboundShip({
  searchCapture,
  filteredColumns,
  currentShipment,
  setTab,
}) {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState("summary");
  const [shipments, setShipments] = useState();
  const [allShipments, setAllShipments] = useState();
  const [shipmentAnalytics, setShipmentAnalytics] = useState();

  const [pagination, setPagination] = useState({
    recordsPerPage: 0,
    totalRecords: 0,
    currentPage: 0,
  });
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const statusMap = {
    summary: null,
    delivered: "RECEIVED",
    shipped: "CREATED",
    partially_delivered: "PARTIALLY_RECEIVED",
  };

  const status = statusMap[currentTab] || null;

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getInBoundShipment(status, page, limit);
      setShipments(res.data.data);
      setAllShipments(res.data?.data);
      setPagination({
        recordsPerPage: res?.data?.limit,
        totalRecords: res?.data?.totalRecords,
        currentPage: res?.data?.page,
      });
      const result = await getInboundShipmentAnalytics();
      setShipmentAnalytics(result.data);
    };
    setTab(status)
    fetchData();
  }, [currentTab, page, limit, status]);

  React.useEffect(() => {
    setPage(0);
  }, [currentTab]);

  React.useEffect(() => {
    SearchList(searchCapture);
  }, [searchCapture]);

  const SearchList = (keyword) => {
    if (!keyword) {
      setShipments(allShipments);
      return;
    }

    const fuse = new Fuse(allShipments, {
      keys: ["source.name", "destination.name", "id", "createdAt"],
    });

    const result = fuse.search(keyword);

    const searchResult = [];
    if (result.length) {
      result.forEach((item) => {
        searchResult.push(item.item);
      });
      setShipments(searchResult);
    } else {
      setShipments([]);
    }
  };

  return (
    <React.Fragment>
      <div className="Page__Analytic_list">
        <AnalyticCard
          state="summary"
          icon={Summary}
          title={t("summary")}
          number={"-"}
          tab={currentTab}
          setTab={setCurrentTab}
          labelType="pink_label"
        />

        <AnalyticCard
          state="shipped"
          icon={Shipped}
          title={t("shipped")}
          number={formatNumber(shipmentAnalytics?.shippedInboundShipments || 0)}
          tab={currentTab}
          setTab={setCurrentTab}
          // label="20%"
          labelType="blue_label"
        />

        <AnalyticCard
          state="delivered"
          icon={Delivered}
          title={t("received")}
          number={formatNumber(
            shipmentAnalytics?.deliveredInboundShipments || 0
          )}
          tab={currentTab}
          setTab={setCurrentTab}
          // label="50%"
          labelType="green_label"
        />

        {/* <AnalyticCard
          state="damaged"
          icon={IconExpired}
          title={t("damaged_&_cold_chain_failure")}
          number={formatNumber(shipmentAnalytics?.otherInboundShipments || 0)}
          tab={currentTab}
          setTab={setCurrentTab}
          label="Alert"
          labelType="red_label"
        /> */}

        <AnalyticCard
          state="partially_delivered"
          icon={PartiallyReceived}
          title={`${t("partially_received")}`}
          number={formatNumber(
            shipmentAnalytics?.partiallyDeliveredInboundShipments
          )}
          tab={currentTab}
          setTab={setCurrentTab}
        />
      </div>

      <div className="Page__table_space">
        {currentTab === "damaged" ? (
          <ShipInDamagedTable />
        ) : (
          <ShipmentInboundTable
            shipments={shipments}
            filteredColumns={filteredColumns}
            pagination={pagination}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            currentShipment={currentShipment}
          />
        )}
      </div>
    </React.Fragment>
  );
}
