import React from 'react';
import { getUserLocationInfo } from '../../../redux/orderActions';
import { useTranslation } from 'react-i18next';

export default function OrderAddressCard({ editable }) {
  const { t } = useTranslation();
  const [location, setLocation] = React.useState();
  async function fetchData() {
    const result = await getUserLocationInfo();
    setLocation(result?.data);
  }
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <section className='Order__summary_card'>
      <div className='Order__summary_body'>
        <article className='Order__summary_body_list'>
          <div className='Order__flex address_flex'>
            <h1 className='page__body_heading_fs'>{t('supplier_location')}</h1>
            {editable && (
              <div className='ReviewProducts__actions'>
                <div className='Review__action_ico edit_ico'>
                  {' '}
                  <i className='fa-solid fa-pencil icon_blue'></i>
                </div>
                <div className='Review__action_ico delete_ico'>
                  <i className='fa-solid fa-trash-can icon_blue'></i>
                </div>
              </div>
            )}
          </div>
          <div className='summary_body_content_card'>
            <h1 className='page__body_fs mi_bold'>{t('type')}</h1>
            <h1 className='page__notion'>:</h1>
            <p className='page__body_fs '>
              {location?.parentLocation?.orgLevel}
            </p>
          </div>
          <div className='summary_body_content_card'>
            <h1 className='page__body_fs mi_bold'>{t('name')}</h1>
            <h1 className='page__notion'>:</h1>
            <p className='page__body_fs '>{location?.parentLocation?.name}</p>
          </div>
          <div className='summary_body_content_card'>
            <h1 className='page__body_fs mi_bold'>{t('address')}</h1>
            <h1 className='page__notion'>:</h1>
            <p className='page__body_fs max_width'>
              {location?.parentLocation?.postalAddress +
                ', ' +
                location?.parentLocation?.division +
                ', ' +
                location?.parentLocation?.district}
            </p>
          </div>
        </article>
        <article className='Order__summary_body_list'>
          <div className='Order__flex address_flex'>
            <h1 className='page__body_heading_fs'>{t('delivery_location')}</h1>
            {editable && (
              <div className='ReviewProducts__actions'>
                <div className='Review__action_ico edit_ico'>
                  {' '}
                  <i className='fa-solid fa-pencil icon_blue'></i>
                </div>
                <div className='Review__action_ico delete_ico'>
                  <i className='fa-solid fa-trash-can icon_blue'></i>
                </div>
              </div>
            )}
          </div>
          <div className='summary_body_content_card'>
            <h1 className='page__body_fs mi_bold'>{t('type')}</h1>
            <h1 className='page__notion'>:</h1>
            <p className='page__body_fs '>{location?.orgLevel}</p>
          </div>
          <div className='summary_body_content_card'>
            <h1 className='page__body_fs mi_bold'>{t('name')}</h1>
            <h1 className='page__notion'>:</h1>
            <p className='page__body_fs '>{location?.name}</p>
          </div>
          <div className='summary_body_content_card'>
            <h1 className='page__body_fs mi_bold'>{t('address')}</h1>
            <h1 className='page__notion'>:</h1>
            <p className='page__body_fs max_width'>
              {location?.postalAddress +
                ', ' +
                location?.division +
                ', ' +
                location?.district}
            </p>
          </div>
        </article>
      </div>
    </section>
  );
}
