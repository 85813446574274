import React, { useEffect, useState } from "react";
import { addProduct } from "../../../redux/userActions";
import { FormControl, Select, MenuItem } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import { ageCalculate, getDates } from "../../../shared/common/commonFunctions";
import toast from "react-hot-toast";
import { calculateAge } from "../../../shared/common/commonFunctions";
import { useTranslation } from "react-i18next";

export default function BeneficiaryDetails({
  formik,
  addProduct,
  editItem,
  error1,
  updatePatient,
  reOpened,
}) {
  const { t } = useTranslation();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [editDate, setEditDate] = useState(false);
  const [yesterday] = getDates();
  const gender = [
    { name: "Male", id: 1 },
    { name: "Female", id: 2 },
    { name: "Others", id: 3 },
  ];
  useEffect(() => {
    setDateOfBirth(formik.values.age);
  }, [formik.values.age]);
  const calculateMonth = (dob) => {
    let date1 = new Date();
    let date2 = new Date(dob);
    let diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
    let month = parseInt(diffDays / 30);
    if (month >= 1) {
      return month;
    }
  };
  const calculateDay = (dob) => {
    let date1 = new Date();
    let date2 = new Date(dob);
    let diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
    let day = parseInt(diffDays);
    if (day) {
      return day;
    }
  };
  // const age = calculateAge(formik.values.age);
  const [years, months] = ageCalculate(formik.values.age);

  function getDate130YearsAgo() {
    const currentDate = new Date();
    const year100YearsAgo = currentDate.getFullYear() - 130;
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = year100YearsAgo + "-" + month + "-" + day;
    return formattedDate;
  }
  const date130ago = getDate130YearsAgo();

  const addDateOfBirth = () => {
    if (dateOfBirth !== "") {
      if (calculateDay(dateOfBirth) < 31 || calculateMonth(dateOfBirth) < 6) {
        toast.error(t("age_validation_error"));
      }
      formik.setFieldValue("age", dateOfBirth);
      setEditDate(true);
    }
  };
  const beneficiaryField =
    formik.values.batchNo.count - formik?.values?.beneficiaryDetails?.length <=
    0;
  const pendingQty =
    reOpened?.quantityPerUnit -
      reOpened?.quantityUtilized -
      formik?.values?.beneficiaryDetails?.length <=
    0;
  return (
    <React.Fragment>
      <div className="Benef_container">
        <div className="beneficiary__section_title">
          <p className="Benef_title">{t("beneficiary_details")}</p>
          <p className="Benef_note_title">{t("beneficiary_details_msg")}</p>
        </div>
        <div className="ModalPopup__form_section">
          <div className="Input__table">
            <div className="Input__row two_column Benef_table">
              <div className="Input__column">
                <p className="Form__heading_fs">
                  {t("dose_number")} <b>*</b>
                </p>
                <input
                  type="number"
                  className="mi_custom_textfield date_field"
                  id="dose"
                  name="dose"
                  onChange={formik.handleChange}
                  value={formik.values.dose}
                  onWheel={(event) => event.currentTarget.blur()}
                  onFocus={(event) =>
                    event.currentTarget.addEventListener(
                      "wheel",
                      (e) => e.preventDefault(),
                      { passive: false }
                    )
                  }
                />
                {formik.errors.dose && formik.touched.dose && (
                  <span className="Input__error_fs">{formik.errors.dose}</span>
                )}
              </div>
              <div className="Input__column">
                <p className="Form__heading_fs">{t("beneficiary_id")}</p>
                <input
                  className="mi_custom_textfield date_field"
                  id="beneficiaryId"
                  name="beneficiaryId"
                  onChange={formik.handleChange}
                  onInput={(e)=>  e.target.value = e.target.value.replace(/\s/g, '')}
                  value={formik.values.beneficiaryId}
                />
                {formik.errors.beneficiaryId &&
                  formik.touched.beneficiaryId && (
                    <span className="Input__error_fs">
                      {formik.errors.beneficiaryId}
                    </span>
                  )}
              </div>
            </div>
            <div className="Input__row two_column Benef_table">
              <div></div>
              <div
                className="Input__row two_column"
                style={{ marginTop: "0.6em" }}
              >
                <div></div>
                {editItem ? (
                  <button
                    type="button"
                    style={{ height: "2.8rem" }}
                    className="mi_btn mi_btn_medium mi_btn_secondary"
                    onClick={() => {
                      updatePatient();
                      setDateOfBirth("");
                      setEditDate(false);
                    }}
                  >
                    <span>{t("update")}</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    style={{ height: "2.8rem" }}
                    className="mi_btn mi_btn_medium mi_btn_secondary"
                    onClick={() => {
                      addProduct();
                      setDateOfBirth("");
                      setEditDate(false);
                    }}
                    disabled={reOpened ? pendingQty : beneficiaryField}
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      {t("add_beneficiary")}
                    </span>
                  </button>
                )}
              </div>
              {error1 && (
                <span className="Input__error_fs Error_msg">
                  {t("please_fill_the_values")}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
