import { API_URL } from "../config";
import axios from "axios";

export const getInBoundShipment = async (status, page = 0, limit = 5) => {
  try {
    const result = await axios.get(API_URL.inboundShipmentsUrl, {
      params: { status, limit, page: page + 1 },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getInboundShipmentAnalytics = async (id) => {
  try {
    const result = await axios.get(API_URL.inboundShipmentAnalyticsUrl, {
      params: { locationId: id },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getOutboundShipments = async (status, page = 0, limit = 5) => {
  try {
    const result = await axios.get(API_URL.outboundShipmentsUrl, {
      params: { status, limit, page: page + 1 },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getOutboundShipmentsAnalytics = async (id) => {
  try {
    const result = await axios.get(API_URL.outboundShipmentAnalyticsUrl, {
      params: { locationId: id },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getShipmentDetails = async (id) => {
  try {
    const result = await axios.get(API_URL.shipmentDetailsUrl + id);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const respondShipment = async (data) => {
  try {
    const result = await axios.post(API_URL.respondOrderUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createShipment = async (data) => {
  try {
    const result = await axios.post(API_URL.createShipmentUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const receiveShipment = async (data) => {
  try {
    const result = await axios.post(API_URL.shipmentReceiveUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createCampaign = async (data) => {
  try {
    const result = await axios.post(API_URL.campaignUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const updateCampaign = async (data) => {
  try {
    const result = await axios.patch(API_URL.campaignUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getCampaigns = async (isActive, page = 0, limit = 5) => {
  try {
    const result = await axios.get(API_URL.campaignUrl, {
      params: { isActive: isActive, limit, page: page + 1 },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getTrips = async (status, page, limit) => {
  try {
    const result = await axios.get(API_URL.tripsUrl, {
      params: { status: status, page: page + 1, limit },
    });
    return result.data.data;
  } catch (e) {
    return e.response;
  }
};

export const getTripInfo = async (id) => {
  try {
    const result = await axios.get(`${API_URL.tripsUrl}/${id}`);
    return result.data.data;
  } catch (e) {
    return e.response;
  }
};

export const getTransitNo = async () => {
  try {
    const result = await axios.get(API_URL.transitShipmentUrl);
    return result.data.data;
  } catch (e) {
    return e.response;
  }
};
