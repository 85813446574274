import React from 'react';
import { vaccineIcon } from '../utils';
import './ProductIcon.css';

export default function ProductIcon({ type, name, size, fallback }) {
  return (
    <>
      {type === 'VACCINE' ? (
        <div
          className={`Icon_container ${size}  ${
            name?.length > 3 && 'long__name'
          } `}
          style={{
            background: `${vaccineIcon(name)}`,
          }}
        >
          {name?.length > 3 ? name?.substring(0, 5) : name}
        </div>
      ) : type === 'SYRINGE' ? (
        <div
          className={`Syringe_container ${size}  ${
            name?.length > 6 && 'long__name'
          } `}
          style={{
            background: `${vaccineIcon(name)}`,
          }}
        >
          <i className='fa-solid fa-syringe'></i>
          <span>{name?.length > 6 ? name?.substring(0, 7) : name}</span>
        </div>
      ) : type === 'STATIONERY_ITEM' ? (
        <div
          className={`Stationary_container ${size} ${
            name?.length > 6 && 'long__name'
          } `}
        >
          <i className='fa-solid fa-box-archive'></i>
          <span>{name?.length > 6 ? name?.substring(0, 7) : name}</span>
        </div>
      ) : (
        <>{fallback}</>
      )}
    </>
  );
}
