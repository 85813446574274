import { useEffect, useRef, useState, useMemo } from "react";
import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet/dist/leaflet.css";
import { API_URL } from "../../../../config";
import SourceIcon from "/markers/blue.png";
import DestinationIcon from "/markers/red.png";
import Alert from "@mui/material/Alert";

// Extend the OSRMv1 class
class CustomOSRMv1 extends L.Routing.OSRMv1 {
  buildRouteUrl(waypoints, options) {
    const coordinates = waypoints
      .map((wp) => wp.latLng.lng + "," + wp.latLng.lat)
      .join(";");
    const params = {
      alternatives: options.alternatives,
      steps: options.steps,
    };
    const queryString = L.Util.getParamString(params);
    return API_URL.routingUrl + "/" + coordinates + queryString;
  }
}

export default function ShipmentMap({ shipment }) {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const routingControl = useRef(null);
  const [eta, setEta] = useState({
    distance: null,
    time: null,
  });
  const [error, setError] = useState(null); // State to handle routing errors

  const markerSourceIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: SourceIcon,
        iconSize: [40, 40],
        iconAnchor: [17, 46],
        popupAnchor: [0, -46],
      }),
    [],
  );

  const markerDestinationIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: DestinationIcon,
        iconSize: [40, 40],
        iconAnchor: [17, 46],
        popupAnchor: [0, -46],
      }),
    [],
  );

  useEffect(() => {
    if (shipment?.source?.location && shipment?.destination?.location) {
      const mapElement = mapRef.current;
      if (mapElement) {
        // Clear previous map instance and routing control
        if (mapInstance.current) {
          mapInstance.current.remove();
          routingControl.current = null;
        }

        const newMap = L.map(mapElement).setView([23.8387, 90.24064], 7); // Default Bangladesh View
        mapInstance.current = newMap;

        L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
          { attribution: "&copy; OpenStreetMap" },
        ).addTo(newMap);

        // Add markers for source and destination
        const sourceLocation = shipment.source.location.coordinates;
        const destinationLocation = shipment.destination.location.coordinates;

        if (sourceLocation) {
          new L.marker(sourceLocation, {
            icon: markerSourceIcon,
          })
            .bindPopup(
              `${shipment.source.name}<br>${shipment.source.postalAddress}`,
            )
            .addTo(newMap);
        }

        if (destinationLocation) {
          new L.marker(destinationLocation, {
            icon: markerDestinationIcon,
          })
            .bindPopup(
              `${shipment.destination.name}<br>${shipment.destination.postalAddress}`,
            )
            .addTo(newMap);
        }

        // Attempt to add routing control
        routingControl.current = L.Routing.control({
          router: new CustomOSRMv1(),
          waypoints: [L.latLng(sourceLocation), L.latLng(destinationLocation)],
          lineOptions: {
            styles: [{ color: "#0078ff", weight: 4 }],
          },
          createMarker: (i, waypoint) => {
            return L.marker(waypoint.latLng, {
              icon: i === 0 ? markerSourceIcon : markerDestinationIcon,
              draggable: false,
            });
          },
        }).addTo(newMap);

        routingControl.current.on("routesfound", (e) => {
          const { totalDistance, totalTime } = e.routes?.[0].summary;
          setEta({
            distance: Math.round(totalDistance / 1000),
            time: `${Math.round(totalTime / 30)} minutes`,
          });
        });

        routingControl.current.on("routingerror", (error) => {
          setError("Unable to find a route between locations");
        });
      }
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
        mapInstance.current = null;
        routingControl.current = null;
      }
    };
  }, [shipment, markerSourceIcon, markerDestinationIcon]);

  return (
    <div className='ShipmentMap__container'>
      {eta.distance != null && eta.time != null && (
        <Alert
          variant='filled'
          severity='info'
          style={{ marginBottom: "10px" }}
        >
          Total Distance: around {eta.distance} Kms | ETA: around {eta.time}
        </Alert>
      )}
      {error && (
        <Alert
          variant='filled'
          severity='error'
          style={{ marginBottom: "10px" }}
        >
          {error}
        </Alert>
      )}
      <div
        ref={mapRef}
        style={{ zIndex: 1, height: "450px", position: "relative" }}
      />
    </div>
  );
}
