import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import FetchBatch from "../fetchBatch/FetchBatch";
import { Collapse } from "@mui/material";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";

export default function ShipmentTable({
  selectedOrder,
  t,
  setSelectedBatchList,
  selectedBatchList,
  productList,
  setProductList,
}) {
  const handleDelete = (productId) => {
    const updatedProductList = productList?.filter(
      (product) => product?.product?._id !== productId
    );

    const updatedSelectedProductList = selectedBatchList
      ?.flat()
      ?.filter((product) => product?.productId !== productId);

    setProductList(updatedProductList);
    setSelectedBatchList(updatedSelectedProductList);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }}>
        <TableHead className="mi_table__head br-top">
          <TableRow>
            <TableCell>
              <FieldHead title={t("category")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("name")} />
            </TableCell>
            {/* <TableCell>
              <FieldHead title="Stock" />
            </TableCell> */}
            <TableCell>
              <FieldHead title={t("required_qty")} />
            </TableCell>

            <TableCell>
              <FieldHead title={t("batch_no")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("sending_qty")} />
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="mi_table__body">
          {productList?.map((product) => (
            <TableRowCard
              key={product.product._id}
              product={product}
              selectedBatchList={selectedBatchList}
              setSelectedBatchList={setSelectedBatchList}
              onDelete={handleDelete}
              productList={productList}
              t={t}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TableRowCard({
  product,
  selectedBatchList,
  setSelectedBatchList,
  onDelete,
  productList,
  t,
}) {
  const value = product?.product;

  // Collapse Table
  const [collapseOpen, setCollapseOpen] = useState(false);

  // Fetch Popup States
  const [productId, setProductId] = useState();
  const [openBatchPopup, setOpenBatchPopup] = useState(false);

  const handleOpenBatchPopup = () => {
    setProductId(value._id);
    setOpenBatchPopup(true);
  };

  const handleCloseBatchPopup = () => {
    setOpenBatchPopup(false);
  };

  // Getting Batch Details
  const newSelectedProducts = selectedBatchList
    ?.flat()
    ?.filter((prod) => prod?.productId === value?._id);

  const batchInfo = newSelectedProducts[0]?.batches;

  const totalQuantity = batchInfo?.reduce(
    (acc, item) => acc + item?.quantity,
    0
  );

  const handleDeleteClick = (event) => {
    event.stopPropagation(); // Prevents the row collapse event from triggering
    onDelete(value._id);
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: batchInfo?.length > 1 && "unset" } }}
        className="mi_collapse_visible_row"
        onClick={() => setCollapseOpen(!collapseOpen)}
      >
        <TableCell>
          <FieldBody text={value?.type} />
        </TableCell>
        <TableCell>
          <ProductIcon
            type={value?.type}
            name={value?.name}
            size="tiny"
            fallback={<FieldBody text={value?.name} color="bold" />}
          />
        </TableCell>
        {/* <TableCell>
          <FieldBody
            text={product?.currentStock + " " + value?.units}
            color="blue"
          />
        </TableCell> */}
        <TableCell>
          <FieldBody
            text={product?.remainingQuantity + " " + value?.units}
            color="brown"
          />
        </TableCell>
        {batchInfo?.length > 1 ? (
          <TableCell>
            <FieldBody text={"+" + batchInfo?.length} />
          </TableCell>
        ) : (
          <TableCell>
            <FieldBody text={batchInfo?.[0]?.batchNo} />
          </TableCell>
        )}
        <TableCell>
          <FieldBody text={totalQuantity} />
        </TableCell>
        <TableCell>
          <div className="table__field_two_data end">
            <button
              type="button"
              className="mi_btn mi_btn_small mi_btn_outline"
              onClick={handleOpenBatchPopup}
            >
              {batchInfo === undefined ? (
                <>
                  <i className="fa-solid fa-plus"></i>
                  <span>{t("add")}</span>
                </>
              ) : (
                <>
                  <i className="fa-solid fa-pen"></i>
                  <span>{t("edit")}</span>
                </>
              )}
            </button>
            {productList?.length > 1 && (
              <div
                className="Review__action_ico delete_ico"
                onClick={handleDeleteClick}
              >
                <i className="fa-solid fa-trash-can icon_blue"></i>
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>

      {batchInfo?.length > 1 && (
        <TableRow className="mi_table__body_No_padding">
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <div className="Collapse__table">
                <div className="Collapse__row">
                  <article className=" Collapse__column_list_row">
                    {batchInfo?.map((batch, index) => (
                      <div
                        key={index}
                        className="table__field_two_data_sm batch_divider"
                      >
                        <FieldBody subtext={"Batch No. :"} />
                        <FieldBody text={batch?.batchNo} color={"bold"} />
                        <p className="page__note_fs mi_blue">
                          ({t("quantity")}: {batch?.quantity})
                        </p>
                      </div>
                    ))}
                  </article>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <FetchBatch
        keepMounted
        productId={productId}
        open={openBatchPopup}
        reqQuantity={product?.remainingQuantity}
        onClose={handleCloseBatchPopup}
        selectedBatchList={selectedBatchList}
        setSelectedBatchList={setSelectedBatchList}
      />
    </React.Fragment>
  );
}
