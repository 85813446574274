import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Printer from "../../../shared/printer/Printer";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ExportTable(props) {
  const {
    onClose,
    open,
    ordersPrint,
    tableRef,
    tab,
    currentOrder,
    xport,
    ...other
  } = props;

  const { t } = useTranslation();

  const OrderOutboundColumns = [
    { key: "supplier_location", title: "supplier_location" },
    { key: "delivery_location", title: "delivery_location" },
    { key: "products_quantity", title: "products_quantity" },
    { key: "orderId", title: "order_id" },
    { key: "created_date", title: "created_date" },
    { key: "status", title: "status" },
    { key: "updated_date", title: "updated_date" },
    { key: "others", title: "others" },
  ];

  const handleExport = () => {
    xport();
    onClose();
  };

  const pdfPrintRef = useRef();
  const handlePrint = useReactToPrint({ contentRef: pdfPrintRef });

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className='ModalPopup__dialog'
      maxWidth={"xl"}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>
            <div className='modal__heading_group'>
              <div className='modal__heading'>
                <h1 className='page__body_subheading_fs'>Preview Table Data</h1>
              </div>

              <div className='modal__quanity'> </div>
            </div>
          </div>
          <div
            className='ModalPopup__close_icon'
            onClick={() => {
              onClose();
            }}
          >
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className='ModalPopup__body '>
          <section className='Page__main_wrapper' ref={pdfPrintRef}>
            <Printer title='Indent List'>
              <div className='printing_body'>
                <table style={tableStyle} ref={tableRef}>
                  <thead>
                    <tr>
                      {OrderOutboundColumns?.map((column) => (
                        <th style={thStyle}>{t(column?.title)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {ordersPrint?.map((row) => (
                      <tr>
                        <td style={thTdStyle}>
                          {row.destination.name +
                            ", " +
                            row.destination.postalAddress}
                        </td>
                        <td style={thTdStyle}>
                          {row.source.name + ", " + row.source.postalAddress}
                        </td>
                        <td style={thTdStyle}>
                          {row.products.map((product) => (
                            <p>
                              {product?.product?.name}[{product?.quantity}],{" "}
                            </p>
                          ))}
                        </td>
                        <td style={thTdStyle}>{row?.id}</td>
                        <td style={thTdStyle}>
                          {new Date(row.createdAt).toLocaleDateString()}
                        </td>
                        <td style={thTdStyle}>{row?.status}</td>
                        <td style={thTdStyle}>
                          {new Date(row.updatedAt).toLocaleDateString()}
                        </td>
                        <td style={thTdStyle}>
                          {row?.campaignId
                            ? t("campaign_id") + ": " + row?.campaign?.id
                            : row?.isTransfer == true
                            ? t("transfer_order")
                            : "---"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Printer>
          </section>
        </div>
      </DialogContent>

      <Divider />
      <DialogActions>
        <section className='InventoryOrders__actions_Popup'>
          <div>
            Total {currentOrder} indents {}
            {tab !== null ? <> [{tab}] </> : ""} : {ordersPrint?.length}
          </div>
          <div className='Page__left_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handlePrint}
            >
              <i className='fa-solid fa-download'></i>
              <span>{t("generate_PDF")}</span>
            </button>
            <button
              onClick={handleExport}
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              Download
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}

const tableStyle = {
  borderCollapse: "collapse",
  width: "100%",
};

const thTdStyle = {
  border: "1px solid black",
  padding: "8px",
  textAlign: "left",
  fontSize: "10px",
};

const thStyle = {
  ...thTdStyle,
  backgroundColor: "#f2f2f2",
};
