import { useEffect, useRef, useMemo } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import { API_URL } from '../../../config';
import TruckMarker from '/icons/truck.svg';
import sourceMarker from '/icons/home.svg';
import LocationMarker from '/icons/location.svg';

class CustomOSRMv1 extends L.Routing.OSRMv1 {
  buildRouteUrl(waypoints, options) {
    const coordinates = waypoints
      .map((wp) => wp.latLng.lng + ',' + wp.latLng.lat)
      .join(';');
    const params = {
      alternatives: options.alternatives,
      steps: options.steps,
    };
    const queryString = L.Util.getParamString(params);
    return API_URL.routingUrl + '/' + coordinates + queryString;
  }
}

export default function TransportMap({ tripInfo, locationTrace }) {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const routingControl = useRef(null);

  const markerSourceIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: sourceMarker,
        iconSize: [40, 40],
        iconAnchor: [17, 46],
        popupAnchor: [0, -46],
      }),
    []
  );

  const markerCurrentIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: TruckMarker,
        iconSize: [40, 40],
        iconAnchor: [17, 46],
        popupAnchor: [0, -46],
      }),
    []
  );

  const markerDestinationIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: LocationMarker,
        iconSize: [40, 40],
        iconAnchor: [17, 46],
        popupAnchor: [0, -46],
      }),
    []
  );

  const markerDeliveredIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: LocationMarker,
        iconSize: [40, 40],
        iconAnchor: [17, 46],
        popupAnchor: [0, -46],
      }),
    []
  );

  useEffect(() => {
    const sourceLocation = tripInfo?.sourceLocation?.coordinates || [];
    const currentLocation = tripInfo?.currentLocation?.coordinates || [];

    const { deliveredLocations, undeliveredLocations, totalDeliveryLocations } =
      (tripInfo?.shipments || []).reduce(
        (acc, shipment) => {
          const location = shipment?.location?.coordinates;
          if (location) {
            if (shipment?.isDelivered) {
              acc.deliveredLocations.push(location);
            } else {
              acc.undeliveredLocations.push(location);
            }
            acc.totalDeliveryLocations.push(location);
          }
          return acc;
        },
        {
          deliveredLocations: [],
          undeliveredLocations: [],
          totalDeliveryLocations: [],
        }
      );

    const mapElement = mapRef.current;

    if (mapElement) {
      if (mapInstance.current) {
        mapInstance.current.remove();
        routingControl.current = null;
      }

      try {
        const newMap = L.map(mapElement).setView(
          sourceLocation.length ? sourceLocation : [23.8387, 90.24064],
          7
        ); // Default to source or Bangladesh

        mapInstance.current = newMap;

        L.tileLayer(
          'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
          { attribution: '&copy; OpenStreetMap' }
        ).addTo(newMap);

        // Add markers
        if (sourceLocation.length) {
          new L.marker(sourceLocation, {
            icon: markerSourceIcon,
          }).addTo(newMap);
        }

        if (currentLocation.length) {
          const currentMarker = new L.marker(currentLocation, {
            icon: markerCurrentIcon,
          }).addTo(newMap);

          // Bind and open popup
          currentMarker
            .bindPopup('<b>Current Location</b><br>Truck is here.')
            .openPopup();

          // Ensure it opens on click
          currentMarker.on('click', () => {
            // currentMarker.openPopup();
          });
        }

        if (deliveredLocations.length) {
          deliveredLocations.forEach((loc) => {
            new L.marker(loc, {
              icon: markerDeliveredIcon,
            }).addTo(newMap);
          });
        }

        if (undeliveredLocations.length) {
          undeliveredLocations.forEach((loc) => {
            new L.marker(loc, {
              icon: markerDestinationIcon,
            }).addTo(newMap);
          });
        }

        // Attempt to add routing control
        if (currentLocation.length && undeliveredLocations.length) {
          routingControl.current = L.Routing.control({
            router: new CustomOSRMv1(),
            waypoints: [currentLocation, ...undeliveredLocations],
            lineOptions: {
              styles: [{ color: '#0078ff', weight: 4 }],
            },
            addWaypoints: false,
            routeWhileDragging: true,
            createMarker: (i, waypoint) => {
              return L.marker(waypoint.latLng, {
                icon: i === 0 ? markerCurrentIcon : markerDestinationIcon,
                draggable: false,
              });
            },
          }).addTo(newMap);
        }
        if (locationTrace?.length > 0) {
          routingControl.current = L.Routing.control({
            router: new CustomOSRMv1(),
            waypoints: locationTrace,
            lineOptions: {
              styles: [{ color: '#99CF63', weight: 2 }],
            },
            addWaypoints: false,
            routeWhileDragging: true,
            createMarker: () => {
              return null;
            },
          }).addTo(newMap);
        }
      } catch (error) {
        console.error('Routing error:', error);
        // Show only source and destination markers and zoom on source
        const fallbackMap = L.map(mapElement).setView(
          sourceLocation.length ? sourceLocation : [23.8387, 90.24064],
          7
        );

        L.tileLayer(
          'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
          { attribution: '&copy; OpenStreetMap' }
        ).addTo(fallbackMap);

        if (sourceLocation.length) {
          new L.marker(sourceLocation, {
            icon: markerSourceIcon,
          }).addTo(fallbackMap);
        }

        if (totalDeliveryLocations.length) {
          totalDeliveryLocations.forEach((loc) => {
            new L.marker(loc, {
              icon: markerDestinationIcon,
            }).addTo(newMap);
          });
        }

        mapInstance.current = fallbackMap;
      }
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
        mapInstance.current = null;
        routingControl.current = null;
      }
    };
  }, [tripInfo, markerSourceIcon, markerDestinationIcon]);

  return (
    <div className='ShipmentMap__container'>
      <div
        ref={mapRef}
        style={{ zIndex: 1, height: '64vh', position: 'relative' }}
      />
    </div>
  );
}
