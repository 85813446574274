import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInboundOrdersAnalytics } from '../../../redux/orderActions';
import { getInboundShipmentAnalytics } from '../../../redux/shipmentActions';
import { getInventoryAnalytics } from '../../../redux/inventoryActions'; // Adjust the import path accordingly
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const MarkerPopup = ({ location }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    inventory: null,
    inboundOrders: null,
    inboundShipments: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [inboundOrdersData, inboundShipmentsData, inventoryData] =
          await Promise.all([
            getInboundOrdersAnalytics(location._id),
            getInboundShipmentAnalytics(location._id),
            getInventoryAnalytics(location._id),
          ]);
        setData({
          inboundOrders: inboundOrdersData?.data,
          inboundShipments: inboundShipmentsData?.data,
          inventory: inventoryData?.data,
        });
      } catch (error) {
        toast.error('Failed to fetch analytics data');
      }
    };
    fetchData();
  }, [location]);



  return (
    <div className='Geo__analytics'>
      <h2 className='page__body_fs mi_bold'>{location?.name}</h2>
      <h4 className='geo__body_fs'>{location?.postalAddress}</h4>
      <div className='geo__analytic_body'>
        <h1 className='page__body_fs'>{t('current_stocks')}</h1>
        <p className='page__body_fs mi_bold'>: {data?.inventory?.inStock}</p>
      </div>
      <div className='geo__analytic_body'>
        <h1 className='page__body_fs'>{t('total_expired')}</h1>
        <p className='page__body_fs mi_bold'>
          : {data?.inventory?.expiredStock}
        </p>
      </div>
      <div className='geo__analytic_body'>
        <h1 className='page__body_fs'>{t('total_stock_out')}</h1>
        <p className='page__body_fs mi_bold'>: {data?.inventory?.outOfStock}</p>
      </div>
      <div className='geo__analytic_body'>
        <h1 className='page__body_fs'>{t('pending_orders')}</h1>
        <p className='page__body_fs mi_bold'>
          : {data?.inboundOrders?.receivedOrders}
        </p>
      </div>
      <div className='geo__analytic_body'>
        <h1 className='page__body_fs'>{t('pending_shipments')}</h1>
        <p className='page__body_fs mi_bold'>
          : {data?.inboundShipments?.shippedInboundShipments}
        </p>
      </div>
      <div className='top_space'>
        {' '}
        <Link
          to={`/user/data-visualization/info?orgLevel=${location?.orgLevel}&locations=${location?._id}`}
          className='mi_btn mi_btn_small mi_btn_primary'
        >
          <i className='fa-solid fa-eye'></i>
          <span>{t('view')}</span>
        </Link>
      </div>
    </div>
  );
};

export default MarkerPopup;
