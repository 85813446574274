import React from 'react';
import './ReviewProducts.css';
import ReOrderQuantity from '../reOrderQuantity/ReOrderQuantity';
import ProductIcon from '../../../shared/utils/productIcon/ProductIcon';

export default function ReviewProducts({
  type,
  row,
  i,
  removeProduct,
  removeProductInventory,
  products,
  reOrderCount,
  setReOrderCount,
  newProd,
  setNewProd,
  t,
}) {
  const handleRemoveInvProducts = (id, batchNo, mfgDate, expDate) => {
    removeProductInventory(id, batchNo, mfgDate, expDate);
  };
  const handleRemoveOrderProducts = (id) => {
    removeProduct(id);
  };

  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const handleOpenEditPopup = () => {
    setOpenEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
  };
  return (
    <React.Fragment>
      <div className='ReviewProducts__container'>
        <div className='ReviewProducts__left_layout'>
          <div className='ReviewProducts__serial_no'>
            <h1 className='page__body_fs'>{i + 1}</h1>
          </div>
          <div className='ReviewProducts__product_info'>
            <div className='ReviewProducts__info_card'>
              <h1 className='page__body_fs'>{t('product_category')} :</h1>
              <p className='page__body_fs '>{row?.type}</p>
            </div>
            <div className='ReviewProducts__info_card'>
              <h1 className='page__body_fs'>{t('product')} :</h1>
              <ProductIcon
                type={row?.type}
                name={row?.name}
                size='tiny'
                fallback={<p className='page__body_fs mi_bold'>{row?.name}</p>}
              />
            </div>
            {/* <div className="ReviewProducts__info_card">
              <h1 className="page__body_fs">Manufacturer :</h1>
              <p className="page__body_fs ">{row.manufacturer.name}</p>
            </div> */}
            {type === 'inventory' && (
              <>
                <div className='ReviewProducts__info_card'>
                  <h1 className='page__body_fs'>{t('mfg_date')} :</h1>
                  <p className='page__body_fs '>
                    {new Date(row.mfgDate).toLocaleDateString()}
                  </p>
                </div>
                {row.expDate && (
                  <div className='ReviewProducts__info_card'>
                    <h1 className='page__body_fs'>{t('exp_date')} :</h1>
                    <p className='page__body_fs '>
                      {new Date(row.expDate).toLocaleDateString()}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className='ReviewProducts__right_layout'>
          <div className='ReviewProducts__product_info'>
            {row?.fromPage ? (
              <>
                {' '}
                <div className='add_quantity_btn'>
                  <h1 className='page__body_fs'>{t('quantity')} :</h1>
                  {reOrderCount > 0 ? (
                    <>
                      {newProd?.productId == row?.productId ? (
                        <p className='page__body_fs '>
                          {reOrderCount + ' ' + row?.units}
                        </p>
                      ) : (
                        <p className='page__body_fs '>
                          {row?.quantity + ' ' + row?.units}
                        </p>
                      )}
                      <div
                        onClick={() => {
                          handleOpenEditPopup();
                          setNewProd(row);
                        }}
                      >
                        <i className='fa-solid fa-pencil icon_blue'></i>
                      </div>
                    </>
                  ) : (
                    <button
                      className={`mi_btn mi_btn_small mi_btn_primary`}
                      onClick={() => {
                        handleOpenEditPopup();
                        setNewProd(row);
                      }}
                    >
                      {t('add_quantity')}
                    </button>
                  )}
                </div>
              </>
            ) : (
              <div className='add_quantity_btn'>
                <h1 className='page__body_fs'>{t('quantity')} :</h1>
                <p className='page__body_fs '>
                  {row.quantity + ' ' + row?.units}
                </p>
              </div>
            )}

            {type === 'inventory' && (
              <>
                <div className='add_quantity_btn'>
                  <h1 className='page__body_fs'>{t('batch_no')} :</h1>
                  <p className='page__body_fs '>{row?.batchNo}</p>
                </div>
                {row?.valueIndividual && (
                  <div className='add_quantity_btn'>
                    <h1 className='page__body_fs'>{t('value_per_dose')} :</h1>
                    <p className='page__body_fs '>{row?.valueIndividual}</p>
                  </div>
                )}

                {row?.value && (
                  <div className='add_quantity_btn'>
                    <h1 className='page__body_fs'>
                      {t('total') + ' ' + t('value')} :
                    </h1>
                    <p className='page__body_fs '>{row?.value}</p>
                  </div>
                )}

                {row?.serialNo && (
                  <div className='ReviewProducts__info_card'>
                    <h1 className='page__body_fs'>{t('serial_no')} :</h1>
                    <p className='page__body_fs '>{row?.serialNo}</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='ReviewProducts__actions'>
            {/* <div className="Review__action_ico edit_ico">
              {" "}
              <i className="fa-solid fa-pencil"></i>
            </div> */}
            {products?.length > 1 && (
              <div className='Review__action_ico delete_ico'>
                <i
                  onClick={() => {
                    if (type === 'inventory') {
                      handleRemoveInvProducts(
                        row?.productId,
                        row?.batchNo,
                        row?.mfgDate,
                        row?.expDate
                      );
                    } else {
                      handleRemoveOrderProducts(row?.productId);
                    }
                  }}
                  className='fa-solid fa-trash-can icon_blue'
                ></i>
              </div>
            )}
          </div>
        </div>
      </div>
      <ReOrderQuantity
        keepMounted
        open={openEditPopup}
        onClose={handleCloseEditPopup}
        reOrderCount={reOrderCount}
        setReOrderCount={setReOrderCount}
        setOpenEditPopup={setOpenEditPopup}
      />
    </React.Fragment>
  );
}
