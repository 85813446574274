import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import FetchBatch from '../../newShipment/fetchBatch/FetchBatch';
import { Collapse } from '@mui/material';
import { t } from 'i18next';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';
import AddReason from '../addReason/AddReason';
import UpdateVvmDetails from '../vvmDetails/UpdateVvmDetails';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import dayjs from 'dayjs';
import Information from '/favicon/information.png';
import ImagePopup from '../../inventoryAdjustment/ImagePopup';
import backupIcon from '/icons/image.svg';

export default function InvProductTable({
  products,
  handleRemoveProducts,
  setSelectedBatchList,
  selectedBatchList,
  vvmUpdateData,
  setVvmUpdateData,
  url,
  from,
  t,
}) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }}>
        <TableHead className='mi_table__head br-top'>
          <TableRow>
            <TableCell>
              <FieldHead title={t('category')} />
            </TableCell>
            <TableCell>
              <FieldHead title={t('name')} />
            </TableCell>

            <TableCell>
              <FieldHead title={t('batch_no')} />
            </TableCell>

            {url === 'status' && (
              <TableCell>
                <FieldHead title={t('quantity')} />
              </TableCell>
            )}

            {url !== 'status' && (
              <TableCell>
                <FieldHead title={t('sending_qty')} />
              </TableCell>
            )}
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body'></TableBody>

        <TableBody className='mi_table__body'>
          {products?.length > 0 ? (
            <>
              {products?.map((product, index) => (
                <TableRowCard
                  key={product?._id}
                  index={index}
                  product={product}
                  handleRemoveProducts={handleRemoveProducts}
                  selectedBatchList={selectedBatchList}
                  setSelectedBatchList={setSelectedBatchList}
                  vvmUpdateData={vvmUpdateData}
                  setVvmUpdateData={setVvmUpdateData}
                  url={url}
                  from={from}
                  t={t}
                />
              ))}
            </>
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: '2rem' }} colSpan={6}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TableRowCard({
  index,
  product,
  handleRemoveProducts,
  selectedBatchList,
  setSelectedBatchList,
  vvmUpdateData,
  setVvmUpdateData,
  from,
  url,
  t,
}) {
  const valueProd = product;
  const vvmBatches = vvmUpdateData.filter(
    (batch) => batch?.productId == product?._id
  );

  // Collapse Table
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);

  // Fetch Popup States
  const [productId, setProductId] = React.useState();
  const [openBatchPopup, setOpenBatchPopup] = React.useState(false);
  const [openAddReasonPopup, setOpenAddReasonPopup] = React.useState(false);
  const [openVvmDetailsPopup, setOpenVvmDetailsPopup] = React.useState(false);

  const handleOpenAddReasonPopup = () => {
    setOpenAddReasonPopup(true);
  };

  const handleCloseAddReasonPopup = () => {
    setOpenAddReasonPopup(false);
  };

  const handleOpenVvmDetailsPopup = (product) => {
    setProductId(product?._id);
    setOpenVvmDetailsPopup(true);
  };

  const handleCloseVvmDetailsPopup = () => {
    setOpenVvmDetailsPopup(false);
  };

  const handleOpenBatchPopup = (product) => {
    setProductId(product?._id);
    setOpenBatchPopup(true);
  };

  const handleCloseBatchPopup = () => {
    setOpenBatchPopup(false);
  };

  // Getting Batch Details
  const newSelectedProducts = selectedBatchList
    ?.flat()
    ?.filter((prod) => prod.productId === valueProd._id);

  const batchInfo = newSelectedProducts[0]?.batches;

  const totalQuantity = batchInfo?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  const [open, setOpen] = useState(false);

  const handleClosePopup = () => {
    setOpen(false);
  };

 

  return (
    <React.Fragment key={index}>
      <TableRow
        key={index}
        sx={{ '& > *': { borderBottom: batchInfo?.length > 1 && 'unset' } }}
        className='mi_collapse_visible_row'
        // onClick={() => setCollapseOpen(!collapseOpen)}
      >
        <TableCell>
          <FieldBody text={valueProd?.type} />
        </TableCell>
        <TableCell>
          <ProductIcon
            type={valueProd?.type}
            name={valueProd?.name}
            size='tiny'
            fallback={<FieldBody text={valueProd?.name} color='bold' />}
          />
        </TableCell>

        {url === 'status' && (
          <TableCell
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenCollapse(!openCollapse)}
          >
            {vvmBatches?.length > 0 && (
              <>
                <div className='Accept_field'>
                  <FieldBody
                    text={vvmBatches?.[0]?.batchNo}
                    subtext={
                      vvmBatches?.length > 1
                        ? `+ ${vvmBatches?.length - 1} Batch(s)`
                        : null
                    }
                    tone='bold'
                  />
                  <img
                    src={Information}
                    alt='Icon'
                    className='mi_table__row_clickable'
                  />
                </div>
              </>
            )}
          </TableCell>
        )}

        {url !== 'status' && (
          <TableCell>
            {batchInfo?.length > 0 && (
              <div className='Accept_field'>
                <FieldBody
                  text={batchInfo?.[0]?.batchNo}
                  subtext={
                    batchInfo?.length > 1
                      ? `+ ${batchInfo?.length - 1} Batch(s)`
                      : null
                  }
                  tone='bold'
                />
                <img
                  src={Information}
                  alt='Icon'
                  onClick={() => setCollapseOpen(!collapseOpen)}
                  className='mi_table__row_clickable'
                />
              </div>
            )}
          </TableCell>
          // (batchInfo?.length > 0 && (
          //   <TableCell>
          //     <FieldBody text={"+" + batchInfo?.length} />
          //   </TableCell>
          // ) : (
          //   <TableCell>
          //     <FieldBody text={batchInfo?.[0]?.batchNo} />
          //   </TableCell>
        )}

        {url !== 'status' && (
          <TableCell>
            <FieldBody
              text={totalQuantity ? totalQuantity + ' ' + valueProd?.units : ''}
            />
          </TableCell>
        )}

        {url === 'status' && (
          <TableCell>
            <FieldBody text={product?.quantity + ' ' + product?.units} />
          </TableCell>
        )}

        <TableCell>
          <div className='table__field_two_data end'>
            {url === 'request' && (
              <button
                type='button'
                className='mi_btn mi_btn_small mi_btn_outline'
                onClick={() => handleOpenBatchPopup(valueProd)}
              >
                {batchInfo === undefined ? (
                  <>
                    <i className='fa-solid fa-plus'></i>
                    <span>{t('add')}</span>
                  </>
                ) : (
                  <>
                    <i className='fa-solid fa-pen'></i>
                    <span>{t('edit')}</span>
                  </>
                )}
              </button>
            )}

            {url === 'status' && (
              <button
                type='button'
                className='mi_btn mi_btn_small mi_btn_outline'
                onClick={() => handleOpenVvmDetailsPopup(valueProd)}
              >
                {batchInfo === undefined ? (
                  <>
                    <i className='fa-solid fa-plus'></i>
                    <span>{t('add')}</span>
                  </>
                ) : (
                  <>
                    <i className='fa-solid fa-pen'></i>
                    <span>{t('edit')}</span>
                  </>
                )}
              </button>
            )}

            {url === 'request' && (
              <button
                type='button'
                className='mi_btn mi_btn_small mi_btn_secondary'
                onClick={() => handleOpenAddReasonPopup()}
                disabled={!batchInfo}
              >
                {' '}
                {t('add_reason')}
              </button>
            )}

            {/* {url === "status" && (
              <button
                type="button"
                className="mi_btn mi_btn_small mi_btn_secondary"
                onClick={() => handleOpenVvmDetailsPopup()}
                disabled={!batchInfo}
              >
                {" "}
                {t("add")} VVM Details
              </button>
            )} */}

            <div className='Review__action_ico delete_ico'>
              <i
                onClick={() => {
                  handleRemoveProducts(valueProd?._id);
                }}
                className='fa-solid fa-trash-can icon_blue'
              ></i>
            </div>
          </div>
        </TableCell>
      </TableRow>

      {url === 'status' && vvmBatches?.length > 0 && (
        <TableRow className='mi_table__body_No_padding'>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={openCollapse} timeout='auto' unmountOnExit>
              <TableContainer>
                <Table size='small' aria-label='nested table'>
                  <TableHead className=' br-top'>
                    <TableRow sx={{ '& > *': { borderBottom: 0 } }}>
                      <TableCell>
                        <FieldHead title={t('batch_no')} />
                      </TableCell>
                      <TableCell>
                        <FieldHead title={t('current_vvm_status')} />
                      </TableCell>
                      <TableCell>
                        <FieldHead title={t('updated_vvm_status')} />
                      </TableCell>
                      <TableCell>
                        <FieldHead title={t('temperature')} />
                      </TableCell>
                      <TableCell>
                        <FieldHead title={t('date')} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vvmBatches?.map((row) => (
                      <TableRow sx={{ '& > *': { borderBottom: 0 } }}>
                        <TableCell>
                          <FieldBody text={row?.batchNo} />
                        </TableCell>
                        <TableCell>
                          <FieldBody
                            text={
                              row?.currentVvmStatus == 1 ||
                              row?.currentVvmStatus == 2
                                ? row?.currentVvmStatus + '-Usable'
                                : row?.currentVvmStatus + '-Not Usable'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <FieldBody
                            text={
                              row?.vvmStatus == 1 || row?.vvmStatus == 2
                                ? row?.vvmStatus + '-Usable'
                                : row?.vvmStatus + '-Not Usable'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <FieldBody text={row?.temperature} />
                        </TableCell>
                        <TableCell>
                          <FieldBody
                            text={dayjs(row?.timestamp).format('DD-MM-YYYY')}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {url !== 'status' && (
        <TableRow className='mi_table__body_No_padding'>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
              <div className='Collapse__table'>
                {batchInfo?.length > 1 && (
                  <div className='Collapse__row'>
                    <article className=' Collapse__column_list_row'>
                      {batchInfo?.map((batch, index) => (
                        <div
                          key={index}
                          className='table__field_two_data_sm batch_divider'
                        >
                          <FieldBody subtext={t('batch_no') + ' :'} />
                          <FieldBody text={batch?.batchNo} color={'bold'} />
                          <p className='page__note_fs mi_blue'>
                            ({t('quantity')}:{' '}
                            {batch?.quantity + ' ' + valueProd?.units})
                          </p>
                        </div>
                      ))}
                    </article>
                  </div>
                )}
                {newSelectedProducts && (
                  <div className='Collapse__row flex_row_column'>
                    <div
                      key={index}
                      className='table__field_two_data_sm '
                      style={{ marginTop: '0.5rem' }}
                    >
                      <div className='reason_card'>
                        <FieldHead title={t('reason') + ' :'} />
                        <p className='page__gray_fs'>
                          {newSelectedProducts?.[0]?.reason}
                        </p>
                      </div>
                    </div>
                    {newSelectedProducts?.[0]?.photoIds?.length > 0 && (
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <img
                          src={backupIcon}
                          style={{ height: '30px' }}
                          alt=''
                        />
                        <p
                          className={`FieldBody__title_fs blue`}
                          onClick={() => setOpen((prev) => !prev)}
                          style={{
                            cursor: 'pointer',
                            whiteSpace: 'nowrap',
                            marginBlock: 'auto',
                          }}
                        >
                          {t('view_images')}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <ImagePopup
        open={open}
        setOpen={setOpen}
        onClose={handleClosePopup}
        imagesList={newSelectedProducts?.[0]?.photoIds}
        t={t}
      />

      <AddReason
        open={openAddReasonPopup}
        onClose={handleCloseAddReasonPopup}
        setSelectedBatchList={setSelectedBatchList}
        batchInfo={batchInfo}
        totalQuantity={totalQuantity}
        product={product}
        selectedProduct={newSelectedProducts}
        selectedBatchList={selectedBatchList}
        t={t}
      />

      <UpdateVvmDetails
        productId={productId}
        open={openVvmDetailsPopup}
        onClose={handleCloseVvmDetailsPopup}
        selectedBatchList={selectedBatchList}
        setSelectedBatchList={setSelectedBatchList}
        selectedProduct={newSelectedProducts}
        vvmUpdateData={vvmUpdateData}
        setVvmUpdateData={setVvmUpdateData}
      />

      <FetchBatch
        keepMounted
        productId={productId}
        open={openBatchPopup}
        onClose={handleCloseBatchPopup}
        selectedBatchList={selectedBatchList}
        setSelectedBatchList={setSelectedBatchList}
        from={from}
        pageType='Adjustment'
      />
    </React.Fragment>
  );
}
