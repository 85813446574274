import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import { TablePagination } from '@mui/material';
import { toast } from 'react-hot-toast';
import { getProducts, updateMinMax } from '../../../../redux/userActions';
import { useTranslation } from 'react-i18next';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { getNeedForCastData } from '../../../../services/inventory-services/api';

export default function MinMaxTable() {
  const { t } = useTranslation();

  const [productList, setProductList] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await getProducts('VACCINE');
      setProductList(res.data);
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   async function fetchData(id) {
  //     if (id) {
  //       const res = await getNeedForCastData(product?._id);
  //       setProductInfo(res);
  //     }
  //   }
  //   fetchData(product?._id);
  // }, [product?._id]);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__table_space'>
          <TableContainer>
            <Table sx={{ minWidth: 485 }} aria-label='simple table'>
              <TableHead className='mi_table__head'>
                <TableRow>
                  <TableCell>
                    <FieldHead title={t('product_name')} />
                  </TableCell>

                  <TableCell>
                    <FieldHead title={t('minimum')} />
                  </TableCell>

                  <TableCell>
                    <FieldHead title={t('maximum')} />
                  </TableCell>

                  <TableCell>
                    <FieldHead title={t('requirements_doses')} />
                  </TableCell>

                  <TableCell>
                    <FieldHead title={t('buffer_doses')} />
                  </TableCell>

                  <TableCell>
                    <FieldHead title={t('monthly_total_doses')} />
                  </TableCell>

                  <TableCell align='center'>
                    <FieldHead title={t('action')} align={'center'} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {productList?.map((product) => (
                    <ThresholdBody product={product} />
                  ))}
                </>
              </TableBody>
            </Table>
            {/* <TablePagination
              component='div'
              count={allProducts?.totalRecords}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </TableContainer>
        </div>
      </section>
    </React.Fragment>
  );
}

function ThresholdBody({ product }) {
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  const [oneWeekData, setOneWeekData] = useState(null);

  const [productInfo, setProductInfo] = React.useState(null);

  useEffect(() => {
    async function fetchData(id) {
      if (id) {
        const res = await getNeedForCastData(product?._id);
        setProductInfo(res);
        setMinValue(res?.threshold?.min);
        setMaxValue(res?.threshold?.max);
      }
    }
    fetchData(product?._id);
  }, [product?._id]);

  const handleCalculate = (e) => {
    e.preventDefault();
    const data = minValue ? minValue / 3 : 0;
    setOneWeekData(data);
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        <FieldBody text={product?.name} />
      </TableCell>
      <TableCell>
        <div className='edit_input_wrap'>
          <input
            type='text'
            value={minValue}
            onChange={(e) => setMinValue(e.target.value)}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className='edit_input_wrap'>
          <input
            type='text'
            value={maxValue}
            onChange={(e) => setMaxValue(e.target.value)}
          />
        </div>
      </TableCell>
      <TableCell>
        <FieldBody text={oneWeekData ? Math.round(oneWeekData * 3) : '--'} />
      </TableCell>
      <TableCell>
        <FieldBody text={oneWeekData ? Math.round(oneWeekData * 4) : '--'} />
      </TableCell>
      <TableCell>
        <FieldBody text={oneWeekData ? Math.round(oneWeekData * 5) : '--'} />
      </TableCell>
      <TableCell>
        {/* <div className='Table__actions'>
          {btnStatus ? (
            <button
              className='mi_btn mi_btn_small mi_btn_secondary'
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              <span>{t('submit')}</span>
            </button>
          ) : (
            <button
              className='mi_btn mi_btn_small mi_btn_secondary'
              onClick={(e) => {
                handleEdit(e);
              }}
            >
              <span>{t('edit')}</span>
            </button>
          )}
        </div> */}

        <button
          className={`mi_btn mi_btn_small  ${
            minValue && maxValue ? 'mi_btn_secondary' : 'mi_btn_disabled'
          }`}
          onClick={(e) => {
            handleCalculate(e);
          }}
          disabled={minValue && maxValue ? false : true}
        >
          <span>Submit</span>
        </button>
      </TableCell>
    </TableRow>
  );
}
