import { useInfiniteQuery } from "@tanstack/react-query";
import { getNotificationData } from "./api";

export const useGetNotificationData = () => {
  return useInfiniteQuery({
    queryKey: ["notifications"],
    queryFn: getNotificationData,
    initialPageParam: 0,
    getNextPageParam: (lastPage, __, lastPageParam) => {
      if (lastPage?.notifications?.length === 0) {
        return undefined;
      } else {
        return lastPageParam + 1;
      }
    },
    getPreviousPageParam: (_, allPages, firstPageParam) => {
      if (allPages?.length <= 1) {
        return undefined;
      } else {
        return firstPageParam - 1;
      }
    },
  });
};
