import React from 'react';
import PermissionRoute from '../../../web/routes/routeLayers/PermissionRoute';
import IconInv from '/icons/all/inventory/Current Stock.svg';
import Export from '/icons/all/Export.svg';
import Filter from '/icons/all/Filter.svg';
import Search from '/icons/all/Search.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
} from '@mui/material';
import FieldBody from '../../../shared/utils/tableField/FieldBody';
import FieldHead from '../../../shared/utils/tableField/FieldHead';
import EmptyTable from '../../../shared/utils/emptyTable/EmptyTable';

export default function UpdateVVM() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data = [{ id: 1 }, { id: 2 }];
  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <div className='back_Link' onClick={() => navigate(-1)}>
              <i className='bx bx-arrow-back icon_blue'></i>
            </div>
            <h1 className='Page__headline_title_fs'>{t('VVM_status')}</h1>
          </div>
        </div>
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <Link
              to='/user/inventory/adjustment/new/status'
              className='mi_btn mi_btn_medium mi_btn_secondary bg_green'
            >
              <i className='fa-regular fa-pen-to-square'></i>
              <span>{t('update_vvm_status')}</span>
            </Link>
          </div>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              {/* <i className="fa-solid fa-magnifying-glass"></i> */}
              <img src={Search} className='Search__icon' />
              <input type='text' placeholder={t('search')} />
            </article>
            <button className='mi_btn mi_btn_medium mi_btn_outline'>
              {/* <i className="fa-solid fa-sliders"></i> */}
              <img src={Filter} style={{ height: '18px' }} />
              <span>{t('filter')}</span>
            </button>
          </div>
        </div>
      </section>

      <div className='Table__wrapper'>
        <TableContainer>
          <Table sx={{ minWidth: 485 }} aria-label='simple table'>
            <TableHead className='mi_table__head'>
              <TableRow>
                <TableCell>
                  <FieldHead title={t('product_category')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('product_name')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('batch_no')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('temperature')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('current_vvm_status')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('updated_vvm_status')} />
                </TableCell>

                <TableCell>
                  <FieldHead title={t('updated_date')} />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className='mi_table__body'>
              {data?.length > 0 ? (
                data?.map((row) => <UpdateVVMRow />)
              ) : (
                <TableRow className='mi_table__body_No_padding'>
                  <TableCell style={{ padding: '2rem' }} colSpan={5}>
                    <EmptyTable />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
}

function UpdateVVMRow() {
  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <FieldBody text={'Vaccine'} color='blue' />
        </TableCell>
        <TableCell>
          <FieldBody text={'BCG'} />
        </TableCell>

        <TableCell>
          <FieldBody text={'BCG001'} />
        </TableCell>
        <TableCell>
          <FieldBody text={'10'} />
        </TableCell>

        <TableCell>
          <FieldBody text={'1 - Usable'} color='bold' />
        </TableCell>
        <TableCell>
          <FieldBody text={'3 - Non Usable'} color='bold' />
        </TableCell>
        <TableCell>
          <FieldBody text={'25/06/24'} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
