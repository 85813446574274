import React, { useState, useEffect } from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { uploadImage } from "../../../../redux/imageAction";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { API_URL } from "../../../../config";

export default function AddReason(props) {
  const {
    open,
    onClose,
    t,
    setSelectedBatchList,
    batchInfo,
    totalQuantity,
    selectedProduct,
    selectedBatchList,
    product,
    ...other
  } = props;
  const [rejectReason, setRejectReason] = useState("");
  const [damageImages, setDamageImages] = useState([]);

  const { theToken } = useSelector((state) => state.auth);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    const reasonImages = await uploadReasonImage(damageImages);
    const updatedProd = selectedBatchList.map((prod) =>
      prod.productId === selectedProduct?.[0]?.productId
        ? { ...prod, reason: rejectReason, photoIds: reasonImages }
        : prod
    );

    setSelectedBatchList(updatedProd);
    onClose();
  };

  const uploadReasonImage = async (files) => {
    if (files && files?.length > 0) {
      const imageKeys = [];

      for (const file of files) {
        const formData = new FormData();
        formData.append("image", file);

        try {
          const imageKey = await uploadImage(formData, theToken);
          if (imageKey?.data) {
            imageKeys.push(imageKey?.data);
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          toast.error("Image upload error");
        }
      }
      return imageKeys;
    }
  };

  const ImageWithFallback = ({ src, fallbackSrc, alt }) => {
    return (
      <img
        src={src}
        alt={alt}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackSrc;
        }}
      />
    );
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className="ModalPopup__dialog"
      maxWidth="sm"
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className="ModalPopup__header">
          <div className="ModalPopup__header__title_fs">{t("add_reason")}</div>
          <div className="ModalPopup__close_icon" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="ModalPopup__body">
          <section className="Page__main_wrapper">
            <div className="Order__summary_body_list">
              <div className="summary_body_content_card">
                <h1 className="page__body_fs mi_bold">{t("total_quantity")}</h1>
                <h1 className="page__notion">:</h1>
                <p className="page__body_fs ">{totalQuantity}</p>
              </div>
              <div className="summary_body_content_card">
                <h1 className="page__body_fs mi_bold">{t("batches")}</h1>
                <h1 className="page__notion">:</h1>
                <div className="Collapse__row">
                  <article className=" Collapse__column_list_row">
                    {batchInfo?.map((batch, index) => (
                      <div
                        key={index}
                        className="table__field_two_data_sm batch_divider"
                      >
                        <FieldBody subtext={"Batch No. :"} />
                        <FieldBody text={batch?.batchNo} color={"bold"} />
                        <p className="page__note_fs mi_blue">
                          ({t("quantity")}:{" "}
                          {batch?.quantity + " " + product?.units})
                        </p>
                      </div>
                    ))}
                  </article>
                </div>
              </div>
            </div>

            <div className="Collapse__table accept_ship">
              <div className="reason__full_view">
                {/* Add Reason Card */}
                <div className="addReason__card">
                  <div className="Input__column">
                    <p className="Input__label_fs">
                      {t("adjustment_quantity_reason")}
                    </p>
                    <textarea
                      name="rejectReason"
                      type="number"
                      rows={4}
                      className="mi_custom_textfield"
                      placeholder={t("type_reason") + "..."}
                      value={rejectReason}
                      onChange={(e) => {
                        setRejectReason(e.target.value);
                      }}
                    />
                    <span className="Input__error_fs d_none"></span>
                  </div>
                  <div className="Input__column">
                    <p className="Input__label_fs">{t("upload_images")}</p>
                    <input
                      type="file"
                      className="mi_custom_textfield"
                      id="damageImages"
                      name="damageImages"
                      multiple
                      onChange={(e) => {
                        setDamageImages(e.currentTarget.files);
                      }}
                    />
                    <span className="Input__error_fs d_none"></span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className="InventoryOrders__actions">
          <div className="Page__left_actions">
            <button
              type="submit"
              className="mi_btn mi_btn_medium mi_btn_primary"
              onClick={handleSubmit}
            >
              <span>{t("confirm")}</span>
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}
