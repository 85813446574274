
export const data = [
    {
      "name": "Location 1",
      "inventory": 4000,
      "orders": 2400,
      "shipments": 2500,
    },
    {
      "name": "Location 2",
      "inventory": 3000,
      "orders": 1398,
      "shipments": 3000,
    },
    {
      "name": "Location 3",
      "inventory": 2000,
      "orders": 9800,
      "shipments": 4500,
    },
    {
      "name": "Location 4",
      "inventory": 2780,
      "orders": 3908,
      "shipments": 1500,
    },
    {
      "name": "Location 5",
      "inventory": 1890,
      "orders": 4800,
      "shipments": 1000,
    },
    {
      "name": "Location 6",
      "inventory": 2390,
      "orders": 3800,
      "shipments": 8500,
    },
    {
      "name": "Location 7",
      "inventory": 3490,
      "orders": 4300,
      "shipments": 6000,
    }
  ]