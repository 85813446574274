
import "./ViewProduct.css"

export default function ViewProduct(){

    const data = [
        {title: "Type", subtitle: "Haemophilus influenzae type b"},
        {title: "Commercial Name", subtitle: "Act-HIB"},
        {title: "Manufacturer", subtitle: "Sanofi Pasteur"},
        {title: "URL", subtitle: "https://www.sanofipasteur.com/en/immunization-essentials/#preventable"},
        {title: "Responsible NRA", subtitle: "Agence nationale de sécurité du médicament et des produits de santé"},
        {title: "Country", subtitle: "France"},
    ]

    return(
        <>
         <section>
           <h1 className="View__product_title">Vaxin Bangla</h1>
           <img src="https://picsum.photos/200" style={{height:'140px', width:"250px"}} alt="" />
            <div  className="View__product_container">
             <h1 className="Page__headline_title_fs view__product_subtitle">Product Overview</h1>
             <article className="Order__summary_body_list">
              {data?.map((item)=>(
                <CategoryCard 
                  title={item.title}
                  subtitle={item.subtitle}
                />
               ))}
             </article>
            </div>
            <div className="View__product_container">
             <h1 className="Page__headline_title_fs view__product_subtitle">Prequalification</h1>
             <article className="Order__summary_body_list">
              {data?.map((item)=>(
                <CategoryCard 
                  title={item.title}
                  subtitle={item.subtitle}
                />
               ))}
             </article>
            </div>
            <div className="View__product_container">
             <h1 className="Page__headline_title_fs view__product_subtitle">Product Description</h1>
             <article className="Order__summary_body_list">
              {data?.map((item)=>(
                <CategoryCard 
                  title={item.title}
                  subtitle={item.subtitle}
                />
               ))}
             </article>
            </div>
         </section>
        </>
    )
}


const CategoryCard = ({title, subtitle})=>{
    return(
        <>
          <div className="summary_body_content_card">
            <h1 className="page__body_fs mi_bold">{title}</h1>
            <h1 className="page__notion">:</h1>
            <p className="page__body_fs ">
              {subtitle}
            </p>
          </div>
        </>
    )
}