import { Link } from "react-router-dom";
import VARtable from "./VARtable/VARtable";
import PARtable from "../productReport/PARtable/PARtable";
import { useTranslation } from "react-i18next";

import VaccineReport from "/icons/all/Vaccine Report.svg";
import ProductReport from "/icons/all/Product Report.svg";

export default function VaccineArrivalReport({ currentTab }) {
  const {t} = useTranslation()
  return (
    <section className="Page__main_wrapper">
      {/* <div className="Page__headline_wrapper">
        <div className="Page__headline">
          {currentTab === "VAR" ? <img src={VaccineReport} className="Campaign__icon" /> 
             : <img src={ProductReport} className="Campaign__icon" />}
          <h1 className="Page__headline_title_fs">
            {currentTab === "VAR" && t("vaccine_arrival_report")}
            {currentTab === "PAR" && t("product_arrival_report")}</h1>
        </div>
      </div> */}
      <div className="Page__action_wrapper">
        <div className="Page__left_actions">
          {currentTab === "VAR" ? (
            <Link
              to="/user/arrival-report/vaccine/new"
              className="mi_btn mi_btn_medium mi_btn_primary"
            >
              <i className="fa-solid fa-plus"></i>
              <span>{t("add_vaccine_report")}</span>
            </Link>
          ) : (
            currentTab === "PAR" && (
              <Link
                to="/user/arrival-report/product/new"
                className="mi_btn mi_btn_medium mi_btn_primary"
              >
                <i className="fa-solid fa-plus"></i>
                <span>{t("add_product_report")}</span>
              </Link>
            )
          )}
        </div>
        <div className="Page__right_actions">
          {/* <article className="mi_search_bar">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input type="text" placeholder="Search by products" />
          </article> */}

          {/* <button className='mi_btn mi_btn_medium mi_btn_outline'>
            <i className='fa-solid fa-file-export'></i>
            <span>Export</span>
          </button> */}
        </div>
      </div>
      {/* <div className="Page__tab_select_space">
        <div className="tab__btn_group">
          <Link
            to="/user/arrival-report/vaccine"
            className={`tab__button ${currentTab === "VAR" && "active"}`}
          >
            <h1 className="tab__btn_text_fs">Vaccine Arrival Report</h1>
          </Link>
          <Link
            to="/user/arrival-report/product"
            className={`tab__button ${currentTab === "PAR" && "active"}`}
          >
            <h1 className="tab__btn_text_fs">Product Arrival Report</h1>
          </Link>
        </div>
      </div> */}
      <div className="Page__table_wrapper">
        {currentTab === "VAR" ? (
          <VARtable />
        ) : (
          currentTab === "PAR" && <PARtable />
        )}
      </div>
    </section>
  );
}
