import React from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
import { TemperatureGraph } from "../../coldChain/historicalData/TemperatureGraph";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";

export default function TemperatureModal(props) {
  const { onClose, open, allTemperatures, currentTemperature, ...other } =
    props;

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className='ModalPopup__dialog'
      maxWidth={"md"}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>
            Current Temperature :{" "}
            {currentTemperature ? `${currentTemperature} °C` : "N/A"}
          </div>
          <div className='ModalPopup__close_icon' onClick={onClose}>
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className='ModalPopup__body'>
          <section className='Page__main_wrapper'>
            <div className='Page__table_space'>
              <div className='graph_container'>
                {allTemperatures?.length > 0 ? (
                  <TemperatureGraph points={allTemperatures} />
                ) : (
                  <div className='no_data'>
                    <EmptyTable />
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </DialogContent>
    </Dialog>
  );
}
