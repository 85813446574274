import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../shared/utils/tableField/FieldBody';
import StatusLabel from '../../../shared/utils/statusLabel/StatusLabel';
import { TablePagination, Tooltip } from '@mui/material';
import EmptyTable from '../../../shared/utils/emptyTable/EmptyTable';
import { getTrips } from '../../../redux/shipmentActions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function TripHistory() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [shipments, setShipments] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getTrips(null, page, limit);

      setShipments(res);
    };
    fetchData();
  }, [page, limit]);

  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 486 }}>
          <TableHead className='mi_table__head'>
            <TableRow>
              <TableCell>
                <FieldHead title={t('transit_no')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('source')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('delivery_location')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('shipments')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('completed_at')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('status')} />
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body mi_table_collapse_body'>
            {shipments?.data?.length > 0 ? (
              shipments?.data?.map((row, i) => (
                <TableBodyRow row={row} key={i} i={i} />
              ))
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: '2rem' }} colSpan={6}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={shipments?.totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}

function TableBodyRow({ row }) {
  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const copyToClipboard = (copy) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setTooltipOpen(true);
      return navigator.clipboard.writeText(copy);
    }
    return Promise.reject(
      'This browser does not support the Clipboard API. Please use a modern browser!'
    );
  };

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      className=' mi_table__row_clickable'
    >
      <React.Fragment key={row?._id}>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.6rem' }}>
            <FieldBody text={row.transitNo} />
            <Tooltip
              title='Copied'
              open={tooltipOpen}
              leaveDelay={1000}
              onClose={() => setTooltipOpen(false)}
            >
              <div
                className='copy_icon mi_link'
                onClick={() => copyToClipboard(row?.transitNo)}
              >
                <i className='fa-regular fa-copy'></i>
              </div>
            </Tooltip>
          </div>
        </TableCell>

        <TableCell>
          <FieldBody text={row?.sourceLocation?.name} />
        </TableCell>

        <TableCell>
          <FieldBody text={row.shipments?.[0].location?.name} />
        </TableCell>

        <TableCell>
          <FieldBody text={row?.shipments?.length} color='blue' />
        </TableCell>

        <TableCell>
          <FieldBody text={new Date(row.createdAt).toLocaleDateString()} />
        </TableCell>

        <TableCell>
          <StatusLabel
            status={row?.status}
            tone={row?.status == 'COMPLETED' ? 'green' : 'blue'}
          />
        </TableCell>
        <TableCell>
          <div className='Table__actions'>
            <Link
              to={`/user/geo-tracking/history/${row?._id}`}
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              <span>{t('view') + ' ' + t('trip')}</span>
            </Link>
          </div>
        </TableCell>
      </React.Fragment>
    </TableRow>
  );
}
