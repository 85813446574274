import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";

export default function RecallLocations({
  data,
  page,
  limit,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  t
}) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label="simple table">
        <TableHead className="mi_table__head">
          <TableRow>
            <TableCell>
              <FieldHead title={t("batch_no")} />
            </TableCell>

            <TableCell>
              <FieldHead title={t("quantity")} />
            </TableCell>

            <TableCell>
              <FieldHead title={t("status")} />
            </TableCell>

            <TableCell>
              <FieldHead title={t("location")} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 && data?.map((row) => <RecallBodyRow row={row} />)}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function RecallBodyRow({ t, row }) {
  return (
    <TableRow
      key={row?.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <FieldBody text={row?.batchNo} color="bold" />
      </TableCell>
      <TableCell>
        <FieldBody text={row?.quantity} />
      </TableCell>
      <TableCell>
        <StatusLabel status={row?.status} tone="grey" />
      </TableCell>
      <TableCell>
        <FieldBody text={row?.locationsDetails?.name} />
      </TableCell>
    </TableRow>
  );
}
