import { useQuery } from '@tanstack/react-query';
import { getUserInfo, getUserLocationInfo } from './api';

export const useGetUserInfo = () => {
  return useQuery({
    queryKey: ['userInfo'],
    queryFn: () => getUserInfo(),
  });
};

export const useGetUserLocationInfo = () => {
  return useQuery({
    queryKey: ['userLocationInfo'],
    queryFn: () => getUserLocationInfo(),
  });
};
