import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../shared/utils/tableField/FieldBody";
import StatusLabel from "../../../shared/utils/statusLabel/StatusLabel";
import { TablePagination } from "@mui/material";
import dayjs from "dayjs";
import { getCampaigns } from "../../../redux/shipmentActions";
import { useTranslation } from "react-i18next";
import ProductIcon from "../../../shared/utils/productIcon/ProductIcon";
import EmptyTable from "../../../shared/utils/emptyTable/EmptyTable";
import { useNavigate } from "react-router-dom";

export default function CampaignHistory({level}) {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({
    recordsPerPage: 0,
    totalRecords: 0,
    currentPage: 0,
  });
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getCampaigns(null, page, limit);
      setData(res.data.data);
      setPagination({
        recordsPerPage: res?.data?.limit,
        totalRecords: res?.data?.totalRecords,
        currentPage: res?.data?.page,
      });
    };
    fetchData();
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 486 }}>
        <TableHead className='mi_table__head'>
          <TableRow>
            <TableCell>
              <FieldHead title={t("campaign_name_id")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("vaccine_name")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("start_date")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("end_date")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("status")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("edit")} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 ? (
            data?.map((row) => <InvSummaryRow key={row?._id} t={t} row={row} level={level} />)
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: "2rem" }} colSpan={7}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={pagination?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function InvSummaryRow({ row, level, t }) {
  const navigate = useNavigate();
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>
        <FieldBody text={row?.name} subtext={row?.id} />
      </TableCell>
      <TableCell>
        <ProductIcon
          type={row?.productDetails?.type}
          name={row?.productDetails?.name}
          size='tiny'
          fallback={<FieldBody text={row?.productDetails?.name} />}
        />
      </TableCell>
      <TableCell>
        <FieldBody text={dayjs(row?.startDate).format("MM-DD-YYYY")} />
      </TableCell>
      <TableCell>
        <FieldBody text={dayjs(row?.endDate).format("MM-DD-YYYY")} />
      </TableCell>
      <TableCell>
        {row?.isActive ? (
          <StatusLabel status={"Open"} tone='green' />
        ) : (
          <StatusLabel status={"Closed"} tone='red' />
        )}
      </TableCell>
      <TableCell>
       {level === "EPI" && row?.isActive && (
          <div style={{cursor:"pointer"}}
            onClick={() =>
             navigate("/user/campaign/edit", {
              state: { editData: row },
             })
            }
          >
            <i className="fa-pencil fa-solid"> </i>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}
