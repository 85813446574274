import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { Collapse, MenuItem, Select } from "@mui/material";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { toast } from "react-hot-toast";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import { getInventories } from "../../../../redux/inventoryActions";
import { capitalToNormalCase } from "../../../../shared/utils/utils";

import { v4 as uuidv4 } from "uuid";

export default function ReceiveBatchTable({
  batches,
  product,
  acceptFinalData,
  setAcceptFinalData,
  t,
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead className='mi_table__head'>
          <TableRow>
            <TableCell>
              <FieldHead title={t("batch_no")} />
            </TableCell>
            <TableCell>
              <FieldHead title={"Dose(s) Per Vial"} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("shipped_qty")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("enter_qty")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("accepting_qty")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("rejected_qty")} />
            </TableCell>
            <TableCell align='center'>
              <FieldHead title='Action' align='center' />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='mi_table__body mi_table_collapse_body'>
          {batches?.map((row, index) => (
            <TableBodyRow
              row={row}
              key={row._id}
              product={product}
              index={index}
              acceptFinalData={acceptFinalData}
              setAcceptFinalData={setAcceptFinalData}
              t={t}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TableBodyRow({
  row,
  product,
  index,
  acceptFinalData,
  setAcceptFinalData,
  t,
}) {
  const RejectionList = [
    { name: t("transit_damage"), key: "DAMAGED" },
    { name: t("miscount"), key: "LOST" },
    { name: t("cold_chain_damage"), key: "COLD_CHAIN_FAILURE" },
    { name: "Unopened Vial Wastage", key: "QUARANTINE" },
    { name: t("other"), key: "OTHER" },
  ];

  const generateValues = (qtyPerUnit, qty, includeZero = true) => {
    const values = includeZero ? [0] : [];
    for (let i = qtyPerUnit; i <= qty; i += qtyPerUnit) {
      values.push(i);
    }
    return values;
  };

  const valueList = generateValues(
    row?.atom?.quantityPerUnit,
    row?.atom?.quantity,
  );

  const [acceptingQty, setAcceptingQty] = useState(null);
  const [acceptingBtn, setAcceptingBtn] = useState(false);

  const [pendingQty, setPendingQty] = useState(0);
  const [enterQty, setEnterQty] = useState(0);

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [rejectComment, setRejectComment] = useState("");
  const [remainingQty, setRemainingQty] = useState("");

  // State to store reasons for rejection
  const [reasonsList, setReasonsList] = useState([]);

  const calculateTotalQuantity = () => {
    let totalQuantity = 0;
    reasonsList.forEach((item) => {
      totalQuantity += parseInt(item.quantity);
    });
    return totalQuantity;
  };

  const reasonQty = calculateTotalQuantity();
  const balanceQty = pendingQty - reasonQty;

  let selectedOption = RejectionList?.find(
    (option) => option.key === rejectReason,
  );

  // Function to add reason to the list
  const addReasonToList = () => {
    if (balanceQty >= remainingQty) {
      if (rejectReason !== "" && remainingQty !== "") {
        const existingIndex = reasonsList?.findIndex(
          (reason) => reason.type === rejectReason?.key,
        );
        if (existingIndex !== -1) {
          const updatedReasonsList = [...reasonsList];

          if (rejectReason?.key === "OTHER") {
            const otherIndex = reasonsList?.findIndex(
              (reason) => reason.comment === rejectComment,
            );

            if (otherIndex !== -1) {
              updatedReasonsList[otherIndex] = {
                id: updatedReasonsList[otherIndex]?.id,
                type: rejectReason?.key,
                name: rejectReason?.name,
                comment: rejectComment,
                quantity:
                  parseInt(updatedReasonsList[otherIndex]?.quantity) +
                  parseInt(remainingQty),
              };
            } else {
              updatedReasonsList.push({
                type: rejectReason?.key,
                name: rejectReason?.name,
                comment: rejectComment,
                quantity: remainingQty,
              });
            }

            setRejectComment("");
            selectedOption = null;
          } else {
            updatedReasonsList[existingIndex] = {
              id: updatedReasonsList[existingIndex]?.id,
              type: rejectReason?.key,
              name: rejectReason?.name,
              quantity:
                parseInt(updatedReasonsList[existingIndex]?.quantity) +
                parseInt(remainingQty),
            };
          }
          setReasonsList(updatedReasonsList);
          handleSubmitBtn(updatedReasonsList);
          setCollapseOpen(true);
          selectedOption = null;
        } else {
          let newReason = {};
          if (rejectReason?.key === "OTHER") {
            newReason = {
              id: uuidv4(),
              type: rejectReason?.key,
              name: rejectReason?.name,
              comment: rejectComment,
              quantity: remainingQty,
            };
            setRejectComment("");
          } else {
            newReason = {
              id: uuidv4(),
              type: rejectReason?.key,
              name: rejectReason?.name,
              quantity: remainingQty,
            };
          }
          setReasonsList([...reasonsList, newReason]);
          handleSubmitBtn([...reasonsList, newReason]);
          setCollapseOpen(true);
        }
        setRejectReason("");
        setRemainingQty("");
        toast.success(t("reason_added"));
        setCollapseOpen(true);
        selectedOption = null;
      }
    } else {
      toast.error(t("you_cant_add_more"));
    }
  };

  // Function to delete reason from the list
  const deleteReasonFromList = (id) => {
    const newList = reasonsList.filter((reason) => reason?.id !== id);
    setReasonsList(newList);
    handleSubmitBtn(newList);
    setCollapseOpen(true);
  };

  // Function to edit reason from the list
  const editReasonFromList = (index) => {
    const reasonToEdit = reasonsList[index];
    setRejectReason(reasonToEdit.type);
    setRemainingQty(reasonToEdit.quantity);
  };

  const handleClearBtn = () => {
    setReasonsList([]);
  };

  const [storageInfo, setStorageInfo] = useState();

  // Getting Storage Info
  useEffect(() => {
    const fetchData = async () => {
      const data = await getInventories();
      setStorageInfo(data?.data);
    };
    fetchData();
  }, []);

  const handleAcceptButton = (atom) => {
    const qty = atom?.quantity;

    if (qty >= acceptingQty) {
      const pendingQtyValue = parseInt(qty) - parseInt(acceptingQty);
      setPendingQty(pendingQtyValue);
      setEnterQty(acceptingQty);
      setAcceptingBtn(true);
      toast.success(t("accepting_value_added"));
    } else {
      setAcceptingQty(null);
      toast.error(`${t("should_be_less_than")} " "  ${qty}`);
    }

    if (qty === parseInt(acceptingQty)) {
      const data = [
        ...(acceptFinalData || []),
        {
          batchNo: row?.atom?.batchNo,
          reqQuantity: row?.quantity,
          quantity: acceptingQty,
          inventoryId: storageInfo[0]?._id,
          atomId: row?.atom?._id,
          rejections: reasonsList,
        },
      ];
      setAcceptFinalData(data);
    }
  };

  const handleSubmitBtn = (rejectionData) => {
    const newArr = acceptFinalData?.length ? [...acceptFinalData] : [];

    const existingProductIndex = newArr?.findIndex(
      (item) => item?.atomId === row?.atom?._id,
    );

    if (existingProductIndex !== -1) {
      // If product exists, update the quantity

      const updatedProducts = [...newArr];
      updatedProducts[existingProductIndex].quantity = acceptingQty;
      updatedProducts[existingProductIndex].rejections = rejectionData;

      setAcceptFinalData(updatedProducts);
      setCollapseOpen(false);
      toast.success(t("reason_updated"));
    } else {
      newArr.push({
        batchNo: row?.atom?.batchNo,
        reqQuantity: row?.quantity,
        quantity: acceptingQty,
        inventoryId: storageInfo[0]?._id,
        atomId: row?.atom?._id,
        rejections: rejectionData,
      });

      setAcceptFinalData(newArr);
      setCollapseOpen(false);
      toast.success(t("reason_completed"));
    }
  };

  const [pendingList, setSendingList] = useState([]);
  useEffect(() => {
    if (pendingQty) {
      const pendingList = generateValues(
        row?.atom?.quantityPerUnit,
        pendingQty,
        false,
      );

      setSendingList(pendingList);
    }
  }, [pendingQty, row?.atom?.quantityPerUnit]);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className='mi_collapse_visible_row mi_table__row_clickable'
      >
        <TableCell>
          <FieldBody text={row?.atom?.batchNo} />
        </TableCell>
        <TableCell>
          <FieldBody text={row?.atom?.quantityPerUnit} />
        </TableCell>
        <TableCell>
          <FieldBody text={row?.atom?.quantity} color={"bold"} />
        </TableCell>

        <TableCell>
          <div className='accepting__input_space'>
            <div className='accepting__input'>
              {row?.atom?.quantityPerUnit === 1 ? (
                <input
                  type='number'
                  onChange={(e) => setAcceptingQty(e.target.value)}
                  disabled={acceptingBtn ? true : false}
                  onWheel={(event) => event.currentTarget.blur()}
                  onFocus={(event) =>
                    event.currentTarget.addEventListener(
                      "wheel",
                      (e) => e.preventDefault(),
                      { passive: false },
                    )
                  }
                  onBlur={(event) =>
                    event.currentTarget.removeEventListener("wheel", (e) =>
                      e.preventDefault(),
                    )
                  }
                  min='1'
                  step='1'
                  onKeyDown={(event) => {
                    if (
                      !(
                        event.key === "ArrowLeft" ||
                        event.key === "ArrowRight" ||
                        event.key === "ArrowUp" ||
                        event.key === "ArrowDown" ||
                        event.key === "Home" ||
                        event.key === "End" ||
                        event.key === "Backspace" ||
                        event.key === "Delete" ||
                        (event.key >= "0" && event.key <= "9")
                      )
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              ) : (
                <FormControl fullWidth>
                  <Autocomplete
                    size='small'
                    value={acceptingQty}
                    onChange={(event, newValue) => setAcceptingQty(newValue)}
                    disabled={acceptingBtn ? true : false}
                    options={valueList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size='small'
                        className='mui_custom_autocomplete'
                      />
                    )}
                  />
                </FormControl>
              )}

              {acceptingBtn ? (
                <button
                  className='accepting_button'
                  onClick={() => setAcceptingBtn(false)}
                >
                  <i className='fa-solid fa-pen'></i>
                </button>
              ) : (
                <button
                  className='accepting_button'
                  disabled={acceptingQty !== null ? false : true}
                  onClick={() => {
                    handleAcceptButton(row?.atom);
                    setReasonsList([]);
                    setCollapseOpen(true);
                  }}
                >
                  <i className='fa-solid fa-check'></i>
                </button>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell>
          <FieldBody text={enterQty} color={"green"} />
        </TableCell>
        <TableCell>
          <FieldBody text={pendingQty > 0 ? pendingQty : "Nil"} color={"red"} />
        </TableCell>
        <TableCell align='center'>
          <div
            className='Table__actions center'
            onClick={() => {
              if (pendingQty > 0) {
                setCollapseOpen(!collapseOpen);
              }
            }}
          >
            <button
              disabled={pendingQty > 0 ? false : true}
              className={`mi_btn mi_btn_small ${
                pendingQty > 0 ? "mi_btn_secondary" : "mi_btn_disabled"
              }`}
            >
              <span>{t("add_reasons")}</span>
            </button>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className='mi_table__body_No_padding'>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          {/* Collapse Content */}
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <div className='Collapse__table accept_ship'>
              <div className='accept__shipment_grid'>
                {/* Add Reason Card */}
                <div className='addReason__card'>
                  <div className='Input__column'>
                    <p className='Input__label_fs'>
                      {t("pending_quantity_reason")}
                    </p>
                    {row?.atom?.quantityPerUnit === 1 ? (
                      <input
                        name='remainingQty'
                        type='number'
                        className='mi_custom_textfield'
                        placeholder={t("quantity")}
                        value={remainingQty}
                        onChange={(e) => setRemainingQty(e.target.value)}
                        disabled={reasonQty === pendingQty ? true : false}
                        onWheel={(event) => event.currentTarget.blur()}
                        onFocus={(event) =>
                          event.currentTarget.addEventListener(
                            "wheel",
                            (e) => e.preventDefault(),
                            { passive: false },
                          )
                        }
                        onBlur={(event) =>
                          event.currentTarget.removeEventListener(
                            "wheel",
                            (e) => e.preventDefault(),
                          )
                        }
                        min='1'
                        step='1'
                        onKeyDown={(event) => {
                          if (
                            !(
                              event.key === "ArrowLeft" ||
                              event.key === "ArrowRight" ||
                              event.key === "ArrowUp" ||
                              event.key === "ArrowDown" ||
                              event.key === "Home" ||
                              event.key === "End" ||
                              event.key === "Backspace" ||
                              event.key === "Delete" ||
                              (event.key >= "0" && event.key <= "9")
                            )
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                    ) : (
                      <FormControl fullWidth>
                        {/* <Autocomplete
                          size='small'
                          value={remainingQty}
                          onChange={(event, newValue) =>
                            setRemainingQty(newValue)
                          }
                          disabled={reasonQty === pendingQty ? true : false}
                          options={pendingList}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              className='mui_custom_autocomplete'
                            />
                          )}
                        /> */}
                        <Select
                          size='small'
                          className='mui_custom_input_field'
                          value={remainingQty}
                          onChange={(event) =>
                            setRemainingQty(event.target.value)
                          }
                          disabled={reasonQty === pendingQty ? true : false}
                        >
                          {pendingList?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    <span className='Input__error_fs d_none'></span>
                  </div>

                  <div className='Input__column'>
                    <div className='white_bg'>
                      <FormControl fullWidth>
                        <Select
                          size='small'
                          className='mui_custom_input_field'
                          value={rejectReason} // Set to the entire object
                          onChange={(event) =>
                            setRejectReason(event.target.value)
                          }
                          renderValue={(selected) =>
                            selected ? selected.name : ""
                          }
                        >
                          {RejectionList?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <span className='Input__error_fs d_none'></span>
                  </div>
                  {rejectReason?.key === "OTHER" && (
                    <div className='Input__column'>
                      <div className='white_bg'>
                        <input
                          type='text'
                          className='mi_custom_textfield'
                          id='comment'
                          name='comment'
                          value={rejectComment}
                          onChange={(e) => setRejectComment(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    className={`mi_btn mi_btn_small ${
                      reasonQty === pendingQty
                        ? " mi_btn_disabled"
                        : "mi_btn_primary"
                    }`}
                    onClick={addReasonToList}
                    disabled={reasonQty === pendingQty ? true : false}
                  >
                    <span>{t("add_to_list")}</span>
                  </button>
                </div>
                {/* Reasons List */}
                <div className='addReason__list'>
                  <div className='addReason__list_space'>
                    <p className='Input__label_fs'>
                      {reasonsList?.length > 0 && t("reasons_list")}
                    </p>
                    <div className='reason__list_wrapper'>
                      {/* Render Reasons Data Cards */}
                      {reasonsList.map((reason, index) => (
                        <ReasonDataCard
                          key={index}
                          index={index}
                          reason={reason}
                          onDelete={deleteReasonFromList}
                          onEdit={editReasonFromList}
                        />
                      ))}
                    </div>
                  </div>
                  {/* Remaining Quantity and Confirm Buttons */}
                  <div className='addReason__list_action'>
                    {reasonQty === pendingQty ? (
                      <span className='Input__error_fs'>
                        *{t("you_cant_add_more_reason")}
                      </span>
                    ) : (
                      <p className='page__note_fs mi_bold'>
                        *{t("remaining_pending_quantity")}:{" "}
                        {pendingQty - reasonQty}
                      </p>
                    )}

                    {/* <div className="btn__group">
                      <button
                        className="mi_btn mi_btn_small mi_btn_secondary"
                        onClick={handleClearBtn}
                      >
                        <span>{t("clear")}</span>
                      </button>
                      <button
                        className="mi_btn mi_btn_small mi_btn_primary"
                        onClick={handleSubmitBtn}
                      >
                        <span>{t("confirm")}</span>
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ReasonDataCard({ index, reason, onDelete, onEdit }) {
  return (
    <div className='ReasonDataCard'>
      <p className='page__body_fs mi_bold'>{index + 1}.</p>
      <p className='page__body_fs mi_bold'>
        {reason?.quantity} Units - {reason?.name}{" "}
        {reason?.comment && `- ${reason?.comment}`}
      </p>

      <div className='page__body_fs mi_bold'>
        <div className='ReviewProducts__actions'>
          {/* Edit Button */}
          {/* <div
            className="Review__action_ico edit_ico"
            onClick={() => onEdit(index)}
          >
            <i className="fa-solid fa-pencil"></i>
          </div> */}
          {/* Delete Button */}
          <div
            className='Review__action_ico delete_ico'
            onClick={() => onDelete(reason?.id)}
          >
            <i className='fa-solid fa-trash-can icon_blue'></i>
          </div>
        </div>
      </div>
    </div>
  );
}
