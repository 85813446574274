import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import './QuantityEntry.css';
import { FormControl, MenuItem, Select } from '@mui/material';
import { getInventories } from '../../../redux/inventoryActions';
import { getDates } from '../../../shared/common/commonFunctions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function QuantityEntry({
  type,
  setAddedProducts,
  product,
  addedProducts,
  manufacturersList,
  defaultManufacturer,
  activeCategory,
}) {
  const { t } = useTranslation();
  const [QuantityCount, setQuantityCount] = useState(0);
  const [QuickBtnClick, setQuickBtnClick] = useState(null);

  const addProduct = (data) => {
    const newArr = addedProducts?.length ? [...addedProducts] : [];

    // Check if the product already exists in the array
    let existingProductIndex;
    let newQuantity = QuantityCount;

    if (type === 'order') {
      existingProductIndex = newArr.findIndex(
        (item) => item.name === product.name
      );
    }

    if (type === 'inventory') {
      existingProductIndex = newArr.findIndex(
        (item) =>
          item.name === product.name &&
          item.manufacturerId === data?.manufacturerId &&
          item.batchNo === data?.batchNo &&
          item.mfgDate === data?.mfgDate + 'T00:00:00.163Z' &&
          item.expDate === data?.expDate &&
          data?.expDate + 'T00:00:00.552Z'
      );

      newQuantity =
        Math.floor(QuantityCount / data?.quantityPerUnit) *
        data?.quantityPerUnit;
    }

    if (existingProductIndex !== -1) {
      // If product exists, update the quantity

      const updatedProducts = [...newArr];
      updatedProducts[existingProductIndex].quantity += parseInt(newQuantity);
      updatedProducts[existingProductIndex].value +=
        parseInt(newQuantity) * parseInt(data?.valueProd);

      setQuantityCount(0);
      setQuickBtnClick(null);
      setAddedProducts(updatedProducts);
      toast.success(t('product_updated_msg'));
    } else {
      // If product doesn't exist, push the new product into the array

      const dataToInclude = {
        productId: product._id,
        name: product.name,
        units: product.units,
        manufacturerId: data?.manufacturerId,
        inventoryId: data?.inventoryId,
        type: product.type,
        quantity: newQuantity,
        quantityPerUnit: data?.quantityPerUnit,
        volumePerUnit: data?.volumePerUnit,
        mfgDate: data?.mfgDate ? data.mfgDate + 'T00:00:00.163Z' : undefined,
        expDate: data?.expDate ? data.expDate + 'T00:00:00.552Z' : undefined,
        batchNo: data?.batchNo,
      };

      const newItem = {};

      // Iterate and check if the value is not undefined or null or empty
      for (const [key, value] of Object.entries(dataToInclude)) {
        if (value !== undefined && value !== null && value !== '') {
          newItem[key] = value;
        }
      }

      if (data?.valueProd) {
        newItem.value = parseInt(newQuantity) * parseInt(data?.valueProd);
        newItem.valueIndividual = parseInt(data?.valueProd);
      }

      newArr.push(newItem);

      setQuantityCount(0);
      setQuickBtnClick(null);
      setAddedProducts(newArr);
      toast.success(t('product_added_msg'));
    }
  };

  return (
    <div className='QuantityEntry__wrapper'>
      <QuantityField
        QuantityCount={QuantityCount}
        setQuantityCount={setQuantityCount}
        type={type}
        QuickBtnClick={QuickBtnClick}
        setQuickBtnClick={setQuickBtnClick}
        addProduct={addProduct}
        t={t}
      />
      {type === 'inventory' && (
        <ProductInfoField
          QuantityCount={QuantityCount}
          addProduct={addProduct}
          manufacturersList={manufacturersList}
          defaultManufacturer={defaultManufacturer}
          activeCategory={activeCategory}
          t={t}
        />
      )}
    </div>
  );
}

function QuantityField({
  type,
  QuantityCount,
  setQuantityCount,
  QuickBtnClick,
  addProduct,
  setQuickBtnClick,
  t,
}) {
  return (
    <React.Fragment>
      <div className='QuantityField__space'>
        <div className='QuantityField__label'>
          {type === 'VACCINE' ? (
            <h1 className='QuantityField__label_fs'>{t('quantity_doses')}</h1>
          ) : (
            <h1 className='QuantityField__label_fs'>{t('quantity')}</h1>
          )}
        </div>
        <div className='QuantityField__measure'>
          <div
            className={`QuantityField__measure_btn ${
              QuantityCount === 0 && 'disabled'
            } `}
            onClick={() => {
              if (QuantityCount > 0) {
                setQuantityCount(parseInt(QuantityCount) - 1);
              }
            }}
          >
            <i className='fa-solid fa-minus'></i>
          </div>
          <div className='QuantityField__measure_input'>
            <input
              type='number'
              value={QuantityCount}
              onChange={(e) => setQuantityCount(parseInt(e.target.value, 10))}
              onWheel={(event) => event.currentTarget.blur()}
              onFocus={(event) =>
                event.currentTarget.addEventListener(
                  'wheel',
                  (e) => e.preventDefault(),
                  { passive: false }
                )
              }
              onBlur={(event) =>
                event.currentTarget.removeEventListener('wheel', (e) =>
                  e.preventDefault()
                )
              }
              required
            />
          </div>
          <div
            className='QuantityField__measure_btn'
            onClick={() => {
              setQuantityCount(parseInt(QuantityCount) + 1);
            }}
          >
            <i className='fa-solid fa-plus'></i>
          </div>
        </div>
        <div className='QuantityField__quick_count'>
          <div className='QuantityField__quick_count_header'>
            {QuantityCount > 0 ? (
              <button
                className='QuantityField__quick_count_btn'
                onClick={() => {
                  setQuantityCount(0);
                  setQuickBtnClick(null);
                }}
              >
                {t('reset')}
              </button>
            ) : (
              <button className='QuantityField__quick_count_btn'>
                {' '}
                {t('units')}
              </button>
            )}
          </div>
          <div className='QuantityField__quick_count_body'>
            <div className='QuantityField__quick_count_row'>
              <button
                type='button'
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 50 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(50);
                  setQuickBtnClick(50);
                }}
              >
                50
              </button>
              <button
                type='button'
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 100 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(100);
                  setQuickBtnClick(100);
                }}
              >
                100
              </button>
              <button
                type='button'
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 200 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(200);
                  setQuickBtnClick(200);
                }}
              >
                200
              </button>
              <button
                type='button'
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 500 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(500);
                  setQuickBtnClick(500);
                }}
              >
                500
              </button>
            </div>
            <div className='QuantityField__quick_count_row'>
              <button
                type='button'
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 1000 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(1000);
                  setQuickBtnClick(1000);
                }}
              >
                1000
              </button>
              <button
                type='button'
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 1500 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(1500);
                  setQuickBtnClick(1500);
                }}
              >
                1500
              </button>
              <button
                type='button'
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 5000 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(5000);
                  setQuickBtnClick(5000);
                }}
              >
                5000
              </button>
              <button
                type='button'
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 10000 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(10000);
                  setQuickBtnClick(10000);
                }}
              >
                10000
              </button>
            </div>
          </div>
        </div>
      </div>
      {type === 'order' && (
        <div className='QuantityEntry__actions'>
          <button
            onClick={() => (QuantityCount > 0 ? addProduct({}) : null)}
            disabled={QuantityCount == 0}
            className={`mi_btn mi_btn_medium ${
              QuantityCount === 0 ? 'mi_btn_disabled' : 'mi_btn_primary'
            }`}
          >
            <span>{t('add_to_list')}</span>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

function ProductInfoField({
  QuantityCount,
  addProduct,
  manufacturersList,
  activeCategory,
  t,
}) {
  const [yesterday, tomorrow] = getDates();
  const [storageInfo, setStorageInfo] = useState();
  const validate = (values) => {
    const errors = {};
    if (!values.manufacturerId) errors.manufacturerId = 'Required';
    if (!values.inventoryId) errors.inventoryId = 'Required';
    if (!values.quantityPerUnit) errors.quantityPerUnit = 'Required';
    if (!values.batchNo) errors.batchNo = 'Required';
    if (!values.mfgDate) errors.mfgDate = 'Required';

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      batchNo: '',
      mfgDate: '' || dayjs().format('YYYY-MM-DD'),
      expDate: '',
      valueProd: '',
      manufacturerId: '',
      inventoryId: '',
      quantityPerUnit: '1',
      volumePerUnit: '',
    },
    validate,
    onSubmit: async (values) => {
      if (!values?.valueProd) delete values.valueProd;
      addProduct(values);

      formik.resetForm();
    },
  });

  const quantityPerUnitList = manufacturersList?.find(
    (org) => org?.id === formik.values.manufacturerId
  );

  // Getting Storage Info
  useEffect(() => {
    const fetchData = async () => {
      const data = await getInventories();
      setStorageInfo(data?.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    formik.setFieldValue(
      'expDate',
      activeCategory == 'vaccine' ? tomorrow : ''
    );
  }, [activeCategory]);

  useEffect(() => {
    const setInitialValues = () => {
      formik.setValues({
        ...formik.values,
        manufacturerId: manufacturersList?.[0]?.details?._id || '',
        inventoryId: storageInfo?.[0]?._id || '',
        quantityPerUnit: quantityPerUnitList?.quantityPerUnit?.[0] || '1',
      });
    };
    setInitialValues();
  }, [manufacturersList]);

  useEffect(() => {
    const selectedManufacturer = manufacturersList?.find(
      (org) => org?.id === formik?.values?.manufacturerId
    );

    const setInitialValues = () => {
      formik.setValues({
        ...formik.values,
        volumePerUnit: selectedManufacturer?.volume || '',
      });
    };

    setInitialValues();
  }, [formik?.values?.manufacturerId]);

  return (
    <form className='ModalPopup__form_container' onSubmit={formik.handleSubmit}>
      <div className='ProductInfoField__space'>
        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('storage_name')} *</p>
          <div className='input_bg'>
            <FormControl fullWidth>
              <Select
                disabled={storageInfo?.length > 1 ? false : true}
                className='mui_custom_input_field'
                size='small'
                id='inventoryId'
                name='inventoryId'
                onChange={formik.handleChange}
                value={formik.values.inventoryId}
              >
                {storageInfo?.length > 0 &&
                  storageInfo?.map((loc, index) => (
                    <MenuItem key={index} value={loc?._id}>
                      {loc?.name}
                    </MenuItem>
                  ))}
              </Select>
              {formik.errors.inventoryId && formik.touched.inventoryId && (
                <span className='Input__error_fs'>
                  {formik.errors.inventoryId}
                </span>
              )}
            </FormControl>
          </div>
        </div>
        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('manufacturer_name')} *</p>
          <div className='input_bg'>
            <FormControl fullWidth>
              <Select
                disabled={manufacturersList?.length > 1 ? false : true}
                className='mui_custom_input_field'
                size='small'
                id='manufacturerId'
                name='manufacturerId'
                onChange={formik.handleChange}
                value={formik.values.manufacturerId}
              >
                {manufacturersList &&
                  manufacturersList?.map((org, index) => (
                    <MenuItem key={index} value={org?.details?._id}>
                      <div className='truncate__text'>{org?.details?.name}</div>
                    </MenuItem>
                  ))}
              </Select>
              {formik.errors.manufacturerId &&
                formik.touched.manufacturerId && (
                  <span className='Input__error_fs'>
                    {formik.errors.manufacturerId}
                  </span>
                )}
            </FormControl>
          </div>
        </div>

        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('doses_per_vial')} *</p>
          <div className='input_bg'>
            <FormControl fullWidth>
              <Select
                disabled={
                  quantityPerUnitList?.quantityPerUnit?.length > 1
                    ? false
                    : true
                }
                className='mui_custom_input_field'
                size='small'
                id='quantityPerUnit'
                name='quantityPerUnit'
                onChange={formik.handleChange}
                value={formik.values.quantityPerUnit}
              >
                {quantityPerUnitList ? (
                  quantityPerUnitList?.quantityPerUnit?.map((qty, index) => (
                    <MenuItem key={index} value={qty}>
                      {qty}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={'1'} value={'1'}>
                    1
                  </MenuItem>
                )}
              </Select>
              {formik.errors.quantityPerUnit &&
                formik.touched.quantityPerUnit && (
                  <span className='Input__error_fs'>
                    {formik.errors.quantityPerUnit}
                  </span>
                )}
            </FormControl>
          </div>
        </div>

        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('volume')} *</p>
          <div className='input_bg'>
            <div className='ProductInfoField__input'>
              <input
                type='text'
                className='mi_custom_textfield'
                placeholder={t('value')}
                id='volumePerUnit'
                name='volumePerUnit'
                onChange={formik.handleChange}
                value={formik.values.volumePerUnit}
                disabled
              />
              {formik.errors.volumePerUnit && formik.touched.volumePerUnit && (
                <span className='Input__error_fs'>
                  {formik.errors.volumePerUnit}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t("product_info's")}</p>
          <input
            type='text'
            className='mi_custom_textfield'
            placeholder={t('batch_no')}
            id='batchNo'
            name='batchNo'
            onChange={formik.handleChange}
            value={formik.values.batchNo}
          />
          {formik.errors.batchNo && formik.touched.batchNo && (
            <span className='Input__error_fs'>{formik.errors.batchNo}</span>
          )}
        </div>
        <div className='ProductInfoField__input'>
          <input
            type='text'
            onFocus={(e) => (e.target.type = 'date')}
            onBlur={(e) => (e.target.type = 'text')}
            className='mi_custom_textfield date_field'
            placeholder={t('mfg_date')}
            id='mfgDate'
            name='mfgDate'
            max={yesterday}
            onChange={formik.handleChange}
            value={formik.values.mfgDate}
          />
          {formik.errors.mfgDate && formik.touched.mfgDate && (
            <span className='Input__error_fs'>{formik.errors.mfgDate}</span>
          )}
        </div>
        {!activeCategory?.includes('stationery') && (
          <div className='ProductInfoField__input'>
            <input
              type='text'
              onFocus={(e) => (e.target.type = 'date')}
              onBlur={(e) => (e.target.type = 'text')}
              className='mi_custom_textfield date_field'
              placeholder={t('exp_date')}
              id='expDate'
              name='expDate'
              min={tomorrow}
              onChange={formik.handleChange}
              value={formik.values.expDate}
            />
            {formik.errors.expDate && formik.touched.expDate && (
              <span className='Input__error_fs'>{formik.errors.expDate}</span>
            )}
          </div>
        )}

        {activeCategory?.includes('vaccine') && (
          <div className='ProductInfoField__input'>
            <input
              type='text'
              className='mi_custom_textfield'
              placeholder={t('value_per_dose')}
              id='valueProd'
              name='valueProd'
              onChange={formik.handleChange}
              value={formik.values.valueProd}
            />
            {formik.errors.valueProd && formik.touched.valueProd && (
              <span className='Input__error_fs'>{formik.errors.valueProd}</span>
            )}
          </div>
        )}
      </div>
      <div className='QuantityEntry__actions'>
        <button
          disabled={QuantityCount == 0}
          className={`mi_btn mi_btn_medium ${
            QuantityCount === 0 ? 'mi_btn_disabled' : 'mi_btn_primary'
          }`}
        >
          <span>{t('add_to_list')}</span>
        </button>
      </div>
    </form>
  );
}
