import React from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function PARform3({
  setFormTab,
  setFormComplete,
  setData,
  storedData,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      authorizedDate:
        dayjs(storedData?.signatures?.inspectionSupervisor?.date).format("YYYY-MM-DD") ||
        dayjs().format("YYYY-MM-DD"),
      receivedDate:
        dayjs(storedData?.signatures?.receivedDate).format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD"),
      epiDate:
        dayjs(storedData?.signatures?.epiManager?.date).format("YYYY-MM-DD") ||
        dayjs().format("YYYY-MM-DD"),
      contactPerson: storedData?.signatures?.contact || "",
    },
    onSubmit: (values) => {
      const sendingData = {
        inspectionSupervisor: {
          name: "Supervisor",
          date: values.authorizedDate,
        },
        epiManager: {
          name: "EPI",
          date: values.epiDate,
        },
        receivedDate: values.receivedDate,
        contact: values.contactPerson,
      };
      setData(3, sendingData);
      setFormComplete(4);
      toast.success(t("PAR_part3_saved"));
    },
  });

  const handlePrev = () => {
    navigate("?form=part2");
    setFormComplete(2);
  };

  const sumitData =
    storedData?.product &&
    storedData?.shipmentDetails?.poNo &&
    storedData?.shippingIndicators.invoiceAmount &&
    storedData?.signatures?.contact;

  const handleFinalSubmit = () => {
    setData(4, {});
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} className="VARform">
        <div className="VARform__header">
          <h1 className="page__body_bigheading_fs">{t("PAR_part3_title")}</h1>
        </div>
        <div className="VARform__body">
          <article className="VARform__row three_column">
            <div className="VARform__column">
              {/* <p className='Input__label_fs'>
                Authorized Inspection Supervisor <b>*</b>
              </p> */}
              {/* <div className='Input__column'>
                <textarea
                  {...formik.getFieldProps("authorized")}
                  required
                  className='mi_custom_textarea extra_height'
                />
              </div> */}
              <div className="Input__column extra_top_space">
                <p className="Input__label_fs">
                  {t("authorized_sign_date")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("authorizedDate")}
                  required
                  type="date"
                  className="mi_custom_textfield date_field"
                />
                <span className="Input__error_fs d_none"></span>
              </div>
              <div className="Input__column extra_top_space">
                <h2 className="page__tiny_fs mi_sky">
                  {t("date_received_by_the_office_msg")}
                </h2>

                <p className="Input__label_fs">
                  {t("date_received_by_the_office")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("receivedDate")}
                  required
                  type="date"
                  className="mi_custom_textfield date_field"
                />
                <span className="Input__error_fs d_none"></span>
              </div>
            </div>
            <div className="VARform__column">
              {/* <p className='Input__label_fs'>
                Central store or EPI Manager <b>*</b>
              </p> */}
              {/* <div className='Input__column'>
                <textarea
                  {...formik.getFieldProps("epi")}
                  required
                  className='mi_custom_textarea extra_height'
                />
              </div> */}
              <div className="Input__column extra_top_space">
                <p className="Input__label_fs">
                  {t("epi_signed_date")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("epiDate")}
                  required
                  type="date"
                  className="mi_custom_textfield date_field"
                />
                <span className="Input__error_fs d_none"></span>
              </div>
              <div className="Input__column extra_top_space margin_match">
                <p className="Input__label_fs">
                  {t("contact_person")} <b>*</b>
                </p>
                <input
                  {...formik.getFieldProps("contactPerson")}
                  required
                  type="text"
                  className="mi_custom_textfield date_field"
                />
                <span className="Input__error_fs d_none"></span>
              </div>
            </div>
            <div></div>
          </article>
        </div>
        <div className="VARform__footer">
          <div className="Input__column_action">
            <button
              type="button"
              className="mi_btn mi_btn_medium mi_btn_secondary"
              onClick={handlePrev}
            >
              <span>{t("prev")}</span>
            </button>
            <button
              className="mi_btn mi_btn_medium mi_btn_primary"
              type="submit"
            >
              <span>{t("save")}</span>
            </button>
            <button
              className="mi_btn mi_btn_medium mi_btn_primary"
              type="button"
              onClick={handleFinalSubmit}
              disabled={!sumitData}
            >
              <span>{t("submit_form")}</span>
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
