import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Export from '/icons/all/Export.svg';
import Filter from '/icons/all/Filter.svg';
import Search from '/icons/all/Search.svg';
import Shipment from '/icons/all/shipments/Shipment.svg';

import OutboundShip from './outboundShip/OutboundShip';
import InboundShip from './inboundShip/InboundShip';
import ConfigureTable from '../../../shared/configureTable/ConfigureTable';
import PermissionRoute from '../../../web/routes/routeLayers/PermissionRoute';
import { useTranslation } from 'react-i18next';

import { useRef, useCallback } from 'react';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import ShipmentExportTable from '../../components/shadowExport/ShipmentExportTable';
import {
  getInBoundShipment,
  getOutboundShipments,
} from '../../../redux/shipmentActions';

export default function Shipments({ currentShipment }) {
  const { t } = useTranslation();
  const ShipmentOutboundColumns = [
    { key: 'from', title: 'from' },
    { key: 'shipment_date', title: 'shipment_date' },
    { key: 'shipment_id', title: 'shipment_id' },
    { key: 'transit_no', title: 'transit_no' },
    { key: 'order_id', title: 'order_id' },
    { key: 'to', title: 'to' },
    { key: 'status', title: 'status' },
    { key: 'view', title: 'view' },
  ];

  const ShipmentInboundColumns = [
    { key: 'from', title: 'from' },
    { key: 'shipment_date', title: 'shipment_date' },
    { key: 'shipment_id', title: 'shipment_id' },
    { key: 'transit_no', title: 'transit_no' },
    { key: 'order_id', title: 'order_id' },
    { key: 'to', title: 'to' },
    { key: 'status', title: 'status' },
    { key: 'view', title: 'view' },
    { key: 'action', title: 'action' },
  ];

  const ShipmentOutboundInitialColumns = {
    from: true,
    shipment_date: true,
    shipment_id: true,
    transit_no: true,
    order_id: true,
    to: true,
    status: true,
    view: true,
  };

  const ShipmentInboundInitialColumns = {
    from: true,
    shipment_date: true,
    shipment_id: true,
    transit_no: true,
    order_id: true,
    to: true,
    status: true,
    view: true,
    action: true,
  };

  const navigate = useNavigate();
  const [searchCapture, setSearchCapture] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnData, setColumnData] = useState([]);
  const [columns, setColumns] = useState(
    currentShipment === 'outbound'
      ? ShipmentOutboundColumns
      : ShipmentInboundColumns
  );
  const [selectedColumns, setSelectedColumns] = useState(
    currentShipment === 'outbound'
      ? ShipmentOutboundInitialColumns
      : ShipmentInboundInitialColumns
  );

  const [openPrint, setOpenPrint] = useState(false);
  const [shipmentsPrint, setShipmentsPrint] = useState();
  const [tab, setTab] = useState();

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  useEffect(() => {
    const fetchColumn = () => {
      if (currentShipment === 'outbound') {
        setSelectedColumns(ShipmentOutboundInitialColumns);
        setColumns(ShipmentOutboundColumns);
      } else if (currentShipment === 'inbound') {
        setSelectedColumns(ShipmentInboundInitialColumns);
        setColumns(ShipmentInboundColumns);
      }
    };

    fetchColumn();
  }, [currentShipment]);

  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((column) => selectedColumns[column.key])
  );

  useEffect(() => {
    setFilteredColumns(columns.filter((column) => selectedColumns[column.key]));
  }, [columns]);

  const open = Boolean(anchorEl);

  const disableFilter =
    selectedColumns.from &&
    selectedColumns.shipment_date &&
    selectedColumns.shipment_id &&
    selectedColumns.to &&
    selectedColumns.status &&
    selectedColumns.view &&
    selectedColumns.report;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumnToggle = (columnName) => {
    selectedColumns[columnName] = !selectedColumns[columnName];
    setSelectedColumns(selectedColumns);
    setFilteredColumns(columns.filter((column) => selectedColumns[column.key]));
  };

  const handleResetColumns = () => {
    setSelectedColumns(
      currentShipment === 'outbound'
        ? ShipmentOutboundInitialColumns
        : ShipmentInboundInitialColumns
    );
    setFilteredColumns(columns);
  };

  const openPrintPopup = async () => {
    if (currentShipment === 'outbound') {
      const res = await getOutboundShipments(tab, 0, 0);
      if (res?.success === true) {
        setShipmentsPrint(res?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentShipment === 'inbound') {
      const result = await getInBoundShipment(tab, 0, 0);
      if (result?.success === true) {
        setShipmentsPrint(result?.data?.data);
        setOpenPrint(true);
      }
    }
  };

  const location = useLocation();
  const tableRef = useRef(null);

  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      if (location?.pathname?.includes('inbound')) {
        XLSX.writeFile(
          wb,
          `${'Inbound'}Supply${dayjs().format('DD-MM-YYYY')}.xlsx`
        );
      } else {
        XLSX.writeFile(
          wb,
          `${'Outbound'}Supply${dayjs().format('DD-MM-YYYY')}.xlsx`
        );
      }
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, [location]);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        {/* <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            
            <img src={Shipment} alt='' style={{ height: '26px' }} />
            <h1 className='Page__headline_title_fs'>{t('shipments')}</h1>
          </div>
        </div> */}
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <PermissionRoute allowed={'CREATE_SHIPMENT'} type='actions'>
              <Link
                to='/user/shipments/new-shipments'
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <i className='fa-solid fa-plus'></i>
                <span>{t('add_shipments')}</span>
              </Link>
            </PermissionRoute>
          </div>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              {/* <i className="fa-solid fa-magnifying-glass"></i> */}
              <img src={Search} className='Search__icon' />
              <input
                type='text'
                placeholder={t('search_by_shipments')}
                onInput={(e) => {
                  setSearchCapture(e.target.value);
                }}
              />
            </article>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              {/* <i className="fa-solid fa-sliders"></i> */}
              <img src={Filter} alt='' style={{ height: '18px' }} />
              <span>{t('filter')}</span>
            </button>

            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
              filters={disableFilter}
            />
            <PermissionRoute allowed={'EXPORT_SHIPMENT'} type='actions'>
              <button
                className='mi_btn mi_btn_medium mi_btn_outline'
                onClick={openPrintPopup}
              >
                {/* <i className="fa-solid fa-file-export"></i> */}
                <img src={Export} alt='' style={{ height: '18px' }} />
                <span>{t('export')}</span>
              </button>
            </PermissionRoute>
          </div>
        </div>
        <div className='Page__tab_select_space'>
          <div className='tab__btn_group'>
            <div
              onClick={() => {
                navigate('/user/shipments');
                setSearchCapture();
              }}
              className={`tab__button ${
                currentShipment === 'outbound' && 'active'
              }`}
            >
              <h1 className='tab__btn_text_fs'>{t('outbound_shipments')}</h1>
            </div>
            <div
              onClick={() => {
                navigate('/user/shipments/inbound');
                setSearchCapture();
              }}
              className={`tab__button ${
                currentShipment === 'inbound' && 'active'
              }`}
            >
              <h1 className='tab__btn_text_fs'>{t('inbound_shipments')}</h1>
            </div>
          </div>
        </div>
        {currentShipment === 'outbound' ? (
          <OutboundShip
            searchCapture={searchCapture}
            filteredColumns={filteredColumns}
            currentShipment={currentShipment}
            setTab={setTab}
          />
        ) : (
          currentShipment === 'inbound' && (
            <InboundShip
              searchCapture={searchCapture}
              filteredColumns={filteredColumns}
              currentShipment={currentShipment}
              setTab={setTab}
            />
          )
        )}
      </section>
      <ShipmentExportTable
        open={openPrint}
        onClose={handleClosePrintPopup}
        shipmentsPrint={shipmentsPrint}
        tableRef={tableRef}
        xport={xport}
        tab={tab}
        currentShipment={currentShipment}
      />
    </React.Fragment>
  );
}
