import React from 'react';
import BreadCrumb from '../../../shared/utils/breadCrumb/BreadCrumb';
import './ReviewVAR.css';
import ReviewVARcard from './ReviewVARcard';
import { useLocation, useNavigate } from 'react-router-dom';
import { createVar } from '../../../redux/inventoryActions';
import getReviewVAR from './data/getReviewVAR';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { callPopup } from '../../../store/slices/popupSlice';
import VaccineReport from '/icons/all/Vaccine Report.svg';

export default function ReviewVAR() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useLocation();
  const [data, setData] = React.useState();
  const [VaccineList, setVaccineList] = React.useState();
  const [DiluentList, setDiluentList] = React.useState();
  const [AlertList, setAlertList] = React.useState();
  const [display, setDisplay] = React.useState();
  getReviewVAR({
    navigate,
    params,
    setData,
    setDisplay,
    setVaccineList,
    setAlertList,
    setDiluentList,
  });

  const dispatch = useDispatch();

  const [varCompleted, setVarCompleted] = React.useState(false);

  const EditVARData = params?.state?.value;

  const handleEdit = () => {
    navigate('/user/arrival-report/vaccine/new?form=part1', {
      state: { value: EditVARData },
    });
  };

  const save = async () => {
    const res = await createVar(data);

    if (res?.success === true) {
      navigate('/user/arrival-report/vaccine');
      dispatch(
        callPopup({
          open: true,
          type: 'success',
          page: 'orders',
          message: t('VAR_created_msg'),
          action: '/user/arrival-report/vaccine',
        })
      );
      setVarCompleted(true);
    }
    if (res?.data?.success === false) {
      dispatch(
        callPopup({
          open: true,
          type: 'warn',
          page: 'orders',
          title: res?.data?.message,
          message: res?.data?.data || res?.data?.message,
        })
      );
    }
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img src={VaccineReport} className='Campaign__icon' />
          <h1 className='Page__headline_title_fs'>{t('VAR_review_title')}</h1>
        </div>
        <BreadCrumb
          url1='/user/arrival-report/vaccine'
          Link1={t('vaccine_arrival_report')}
          Link2={t('review_VAR')}
        />
      </div>
      <section className='ReviewVAR__list'>
        {display?.map((row, index) => (
          <ReviewVARcard
            key={index}
            row={row}
            AlertList={AlertList}
            VaccineList={VaccineList}
            DiluentList={DiluentList}
            t={t}
          />
        ))}
      </section>

      <div className='VARform__footer'>
        <div className='Input__column_action'>
          <button
            className='mi_btn mi_btn_medium mi_btn_white'
            onClick={handleEdit}
          >
            <span>{t('edit')}</span>
          </button>
          <button
            type='button'
            className='mi_btn mi_btn_medium mi_btn_primary'
            onClick={save}
          >
            <span>{t('submit')}</span>
          </button>
        </div>
      </div>
    </section>
  );
}
