import React, { useState, useEffect, Fragment } from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import { Box, IconButton, Grid } from "@mui/material";
import { API_URL } from "../../../config";

export default function ImagePopup(props) {
  const { open, onClose, imagesList, t, ...other } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClose = () => {
    onClose();
  };



  const imagesBackup = [
    "/icons/vaccine/vaccine1.jpg",
    "/icons/vaccine/vaccine2.jpg",
    "/icons/vaccine/vaccine3.jpg",
    "/icons/vaccine/vaccine4.jpg",
    "/icons/vaccine/vaccine5.jpg",
  ];

  const images = imagesList?.length > 0 ? imagesList : imagesBackup;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images?.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images?.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className="ModalPopup__dialog"
      maxWidth="sm"
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className="ModalPopup__header">
          <div className="ModalPopup__header__title_fs">
            {t("image_popup_title")}
          </div>
          <div className="ModalPopup__close_icon" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="ModalPopup__body">
          <div className="Page__main_wrapper" style={{ padding: "0 0 1rem 0" }}>
            <Box
              position="relative"
              width="80%"
              margin="0 auto"
              overflow="hidden"
            >
              <Box
                display="flex"
                transition="transform 0.5s ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {imagesList?.length > 0 && (
                  <Fragment>
                    {imagesList?.map((image, index) => {
                      const imageUrl = image
                        ? `${API_URL.imagesUrl}/${image}`
                        : backupIcon;
                      return (
                        <Box key={index} minWidth="100%">
                          <img
                            src={imageUrl}
                            alt={`Slide ${index + 1}`}
                            style={{
                              height: "300px",
                              width: "100%",
                              display: "block",
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Fragment>
                )}
              </Box>
              <IconButton
                onClick={handlePrev}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  color: "white",
                }}
              >
                <i className="fa fa-chevron-circle-left"></i>
              </IconButton>
              <IconButton
                onClick={handleNext}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  color: "white",
                }}
              >
                <i className="fa fa-chevron-circle-right"></i>
              </IconButton>
              <Grid
                container
                justifyContent="center"
                style={{ position: "absolute", bottom: "10px", width: "100%" }}
              >
                {images.map((_, index) => (
                  <Box
                    key={index}
                    onClick={() => setCurrentIndex(index)}
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor:
                        currentIndex === index ? "white" : "gray",
                      margin: "0 5px",
                      cursor: "pointer",
                    }}
                  />
                ))}
              </Grid>
            </Box>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
