import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { getBeneficiariesData, getUnitsUtilizedData } from './api';

export const useGetUnitsUtilizedData = (data, page, limit) => {
  return useQuery({
    queryKey: ['unitsUtilized', { data, page }],
    queryFn: () => getUnitsUtilizedData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetBeneficiariesData = (data, isToday, page, limit) => {
  return useQuery({
    queryKey: ['beneficiaries', { data, isToday, page }],
    queryFn: () => getBeneficiariesData(data, isToday, page, limit),
    placeholderData: keepPreviousData,
  });
};
