import React, { useEffect, useState } from 'react';
import {
  getAdjustmentInventoryInbound,
  updateAdjustmentInventory,
} from '../../../../redux/inventoryActions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
} from '@mui/material';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { useTranslation } from 'react-i18next';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import toast from 'react-hot-toast';
import Information from '/favicon/information.png';
import ProductIcon from '../../../../shared/utils/productIcon/ProductIcon';
import backupIcon from '/icons/image.svg';
import ImagePopup from '../ImagePopup';

export default function InboundAdjustment({ tableRef, filteredColumns }) {
  const [invalidateData, setInvalidateData] = useState();
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAdjustmentInventoryInbound(page, limit);
      setData(res.data);
    };
    fetchData();
  }, [page, limit, invalidateData]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label='simple table' ref={tableRef}>
        <TableHead className='mi_table__head'>
          {filteredColumns.map((column) => (
            <TableCell key={column.key}>
              <FieldHead
                title={t(column.title)}
                align={column.key === 'action' ? 'center' : 'start'}
              />
            </TableCell>
          ))}
        </TableHead>

        <TableBody className='mi_table__body'>
          {data?.data?.length > 0 ? (
            data?.data?.map((row, index) => (
              <RecallAdjustBodyRow
                index={index}
                row={row}
                t={t}
                setInvalidateData={setInvalidateData}
                filteredColumns={filteredColumns}
              />
            ))
          ) : (
            <TableRow className='mi_table__body_No_padding'>
              <TableCell style={{ padding: '2rem' }} colSpan={6}>
                <EmptyTable />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={data?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

function RecallAdjustBodyRow({
  index,
  t,
  row,
  setInvalidateData,
  filteredColumns,
}) {
  const [collapseOpen, setCollapseOpen] = React.useState('');

  const [open, setOpen] = useState(false);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const acceptReject = async (status) => {
    await updateAdjustmentInventory({
      requestId: row._id,
      status: status,
    });

    if (status === 'ACCEPTED') {
      toast.success(t('adjustment_accepted'));
      setInvalidateData((prev) => !prev);
    } else if (status === 'REJECTED') {
      toast.error(t('adjustment_rejected'));
      setInvalidateData((prev) => !prev);
    }
  };
  return (
    <React.Fragment>
      <TableRow
        key={row?.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {filteredColumns?.map((column) => (
          <>
            {column.key === 'from_location' && (
              <TableCell>
                <FieldBody text={row?.locationDetails?.name} />
              </TableCell>
            )}
            {column.key === 'products' && (
              <TableCell>
                <FieldBody
                  text={row?.products?.length + ' ' + t('products')}
                  color='blue'
                />
              </TableCell>
            )}
            {column.key === 'from' && (
              <TableCell>
                <FieldBody text={row?.fromStatus} color='bold' />
              </TableCell>
            )}
            {column.key === 'to' && (
              <TableCell>
                <FieldBody text={row?.toStatus} color='bold' />
              </TableCell>
            )}
            {column.key === 'date' && (
              <TableCell>
                <FieldBody
                  text={new Date(row?.adjustmentDate).toLocaleDateString()}
                />
              </TableCell>
            )}
            {column.key === 'status' && (
              <TableCell>
                <StatusLabel
                  status={row?.status}
                  tone={
                    row?.status === 'ACCEPTED'
                      ? 'green'
                      : row?.status === 'REJECTED'
                      ? 'red'
                      : row?.status === 'CANCELLED'
                      ? 'grey'
                      : 'blue'
                  }
                />
              </TableCell>
            )}
            {column.key === 'view' && (
              <TableCell>
                <div className='Accept_field'>
                  <img
                    src={Information}
                    alt='Icon'
                    onClick={() => setCollapseOpen(!collapseOpen)}
                    className='mi_table__row_clickable'
                  />
                </div>
              </TableCell>
            )}
            {column.key === 'action' && (
              <TableCell>
                {row.status === 'PENDING' && (
                  <div className='Table__actions'>
                    <button
                      className='mi_btn mi_btn_small mi_btn_outline'
                      onClick={() => acceptReject('REJECTED')}
                    >
                      <span>{t('reject_button')}</span>
                    </button>
                    <button
                      className='mi_btn mi_btn_small mi_btn_secondary'
                      onClick={() => acceptReject('ACCEPTED')}
                    >
                      <span>{t('accept')}</span>
                    </button>
                  </div>
                )}
              </TableCell>
            )}
          </>
        ))}
      </TableRow>

      <TableRow
        className='mi_table__body_No_padding'
        style={{ border: '1px solid #ddd', width: '100%' }}
      >
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <div className='Collapse__table'>
              {true && (
                <>
                  {row?.products?.map((product) => (
                    <div>
                      <div className='Collapse__row'>
                        <article className=' Collapse__column_list_row'>
                          <div className='table__field_two_data_sm batch_divider'>
                            <FieldHead title={t('product_name') + ' :'} />
                            <ProductIcon
                              type={product?.productDetails?.type}
                              name={product?.productDetails?.name}
                              size='tiny'
                              fallback={
                                <FieldBody
                                  text={product?.productDetails?.name}
                                />
                              }
                            />
                            <FieldHead title={t('product_id') + ' :'} />
                            <p className='FieldBody__subtitle_fs'>
                              {product?.productDetails?.id}{' '}
                            </p>
                          </div>
                        </article>
                      </div>
                      <div className='Collapse__row'>
                        <article
                          className=' Collapse__column_list_row'
                          style={{ marginTop: '0.3rem' }}
                        >
                          <div className=' Collapse__column_list_row'>
                            <FieldHead title={t('batches') + ' :'} />
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                gap: '0.5rem',
                              }}
                            >
                              {product?.batches?.map((batch) => (
                                <>
                                  <p className='FieldBody__subtitle_fs '>
                                    ({t('batch_no')} : {batch?.batchNo} ,{' '}
                                    {batch?.quantity}{' '}
                                    {product?.productDetails?.units}),
                                  </p>
                                </>
                              ))}
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className='Collapse__row'>
                        <article className=' Collapse__column_list_row'>
                          <div
                            className='table__field_two_data_sm batch_divider'
                            style={{ marginTop: '0.5rem' }}
                          >
                            <div className='reason_card'>
                              <FieldHead title={t('reason') + ' :'} />
                              <p className='page__gray_fs'>{product?.reason}</p>
                            </div>
                          </div>
                        </article>
                      </div>
                      {product?.photoIds?.length > 0 && (
                        <div className='Collapse__row'>
                          <article className=' Collapse__column_list_row'>
                            <div
                              className='table__field_two_data_sm batch_divider'
                              style={{ marginTop: '0.5rem' }}
                            >
                              <div className='reason_card'>
                                <FieldHead title={'Images' + ' :'} />
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                  <img
                                    src={backupIcon}
                                    style={{ height: '30px' }}
                                    alt=''
                                  />
                                  <p
                                    className={`FieldBody__title_fs blue`}
                                    onClick={() => setOpen((prev) => !prev)}
                                    style={{
                                      cursor: 'pointer',
                                      whiteSpace: 'nowrap',
                                      marginBlock: 'auto',
                                    }}
                                  >
                                    {t('view_images')}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </article>
                          <ImagePopup
                            open={open}
                            setOpen={setOpen}
                            onClose={handleClosePopup}
                            imagesList={product?.photoIds}
                            t={t}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
