import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ViewBatch from "../../../components/viewBatch/ViewBatch";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import TablePagination from "@mui/material/TablePagination";
import { useTranslation } from "react-i18next";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";
import { capitalToNormalCase } from "../../../../shared/utils/utils";

export default function InvNetUtils({
  inventory,
  filteredColumns,
  totalRecords,
  page,
  setPage,
  limit,
  setLimit,
  tableRef,
}) {
  const { t } = useTranslation();
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [openBatchPopup, setOpenBatchPopup] = React.useState(false);
  const [id, setID] = React.useState();
  const handleOpenBatchPopup = (id) => {
    setID(id);
    setOpenBatchPopup(true);
  };

  const handleCloseBatchPopup = () => {
    setOpenBatchPopup(false);
  };

  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 486 }} ref={tableRef}>
          <TableHead className='mi_table__head'>
            <TableRow>
              {filteredColumns?.map((column) => (
                <TableCell key={column.key}>
                  <FieldHead title={t(column.title)} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory?.length > 0 ? (
              inventory?.map((row, index) => (
                <InvSummaryRow
                  row={row}
                  key={index}
                  handleOpenBatchPopup={handleOpenBatchPopup}
                  filteredColumns={filteredColumns}
                />
              ))
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: "2rem" }} colSpan={7}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <ViewBatch
        keepMounted
        id={id}
        open={openBatchPopup}
        onClose={handleCloseBatchPopup}
      />
    </React.Fragment>
  );
}

function InvSummaryRow({ row, filteredColumns }) {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {filteredColumns.map((column) => (
        <React.Fragment key={column.key}>
          {column.key === "productCategory" && (
            <TableCell>
              <FieldBody text={capitalToNormalCase(row?.product?.type)} />
            </TableCell>
          )}
          {column.key === "productName" && (
            <TableCell>
              <ProductIcon
                type={row?.product?.type}
                name={row?.product?.name}
                size='tiny'
                fallback={<FieldBody text={row?.product?.name} color='bold' />}
              />
            </TableCell>
          )}
          {column.key === "productId" && (
            <TableCell>
              <FieldBody text={row?.product?.id} color='bold' />
            </TableCell>
          )}
          {column.key === "openingBalance" && (
            <TableCell>
              <FieldBody text={row?.openingBalance+" " + row?.product?.units} color='green' />
            </TableCell>
          )}
          {column.key === "utilizedQuantity" && (
            <TableCell>
              <FieldBody text={row?.quantityUtilized+" " + row?.product?.units} color='cyan' />
            </TableCell>
          )}
          {column.key === "currentBalance" && (
            <TableCell>
              <FieldBody text={row?.quantity +" " + row?.product?.units} color='brown' />
            </TableCell>
          )}
          {column.key === "minimum" && (
            <TableCell>
              <FieldBody text={ row?.threshold ? row?.threshold?.min +" " + row?.product?.units : "--"} color='brown' />
            </TableCell>
          )}
          {column.key === "maximum" && (
            <TableCell align='center'>
              <FieldBody text={ row?.threshold ? row?.threshold?.max +" " + row?.product?.units : "--"} color='cyan' />
            </TableCell>
          )}
        </React.Fragment>
      ))}
    </TableRow>
  );
}
