import { useEffect, useState } from 'react';
import { FormControl, Autocomplete, TextField } from '@mui/material';
import { getUserLocations } from '../../../redux/inventoryActions';
import { useTranslation } from 'react-i18next';

export default function OrderTransferCard({ formik }) {
  const { t } = useTranslation();
  const [LocationList, setLocationList] = useState();

  const [supplierLocation, setSupplierLocation] = useState();
  const [deliveryLocation, setDeliveryLocation] = useState();

  const [supplierLocationOption, setSupplierLocationOption] =
    useState(LocationList);
  const [deliveryLocationOption, setDeliveryLocationOption] =
    useState(LocationList);

  useEffect(() => {
    if (deliveryLocation) {
      const data = LocationList?.filter((loc) => loc._id !== deliveryLocation);
      setSupplierLocationOption(data);
    } else {
      setSupplierLocationOption(LocationList);
    }
  }, [LocationList, deliveryLocation]);

  useEffect(() => {
    if (supplierLocation) {
      const data = LocationList?.filter((loc) => loc._id !== supplierLocation);

      setDeliveryLocationOption(data);
    } else {
      setSupplierLocationOption(LocationList);
    }
  }, [LocationList, supplierLocation]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getUserLocations();
      setLocationList(res.data.childLocations);
      setSupplierLocationOption(res.data.childLocations);
      setDeliveryLocationOption(res.data.childLocations);
    };
    fetchData();
  }, []);

  return (
    <section className='Order__summary_card'>
      <div className='Order__summary_body'>
        <div className='Input__row two_column'>
          <div className='Input__column'>
            <h1 className='page__body_heading_fs location__heading'>
              {t('supplier_location')}
              <b>*</b>
            </h1>
            <FormControl fullWidth>
              <Autocomplete
                id='supplier'
                options={supplierLocationOption || []}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(event, value) => {
                  formik.setFieldValue('supplier', value);
                  setSupplierLocation(value?._id);
                }}
                value={formik.values.supplier || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('supplier_location')}
                    size='small'
                    variant='outlined'
                    className='mui_custom_input_field'
                  />
                )}
              />
            </FormControl>
            {formik.errors?.supplier && formik.touched?.supplier && (
              <span className='Input__error_fs'>{formik.errors?.supplier}</span>
            )}
          </div>
          <div className='Input__column'>
            <h1 className='page__body_heading_fs location__heading'>
              {t('delivery_location')}
              <b>*</b>
            </h1>
            <FormControl fullWidth>
              <Autocomplete
                id='delivery'
                options={deliveryLocationOption || []}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(event, value) => {
                  formik.setFieldValue('delivery', value);
                  setDeliveryLocation(value?._id);
                }}
                value={formik.values.delivery || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('delivery_location')}
                    size='small'
                    variant='outlined'
                    className='mui_custom_input_field'
                  />
                )}
              />
            </FormControl>
            {formik.errors?.delivery && formik.touched?.delivery && (
              <span className='Input__error_fs'>{formik.errors?.delivery}</span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
