import React, { Fragment } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TransportMap from './TransportMap';
import EmptyMap from './EmptyMap';
import StatusLabel from '../../../shared/utils/statusLabel/StatusLabel';
import TemperatureModal from './tempModal/TemperatureModal';

export default function TripLiveTracking({
  tripId,
  tripList,
  shipInfo,
  handleShipInfoChange,
  refetchTripInfo,
}) {
  const { t } = useTranslation();

  const [openBatchPopup, setOpenBatchPopup] = React.useState(false);

  const handleOpenBatchPopup = (id) => {
    setOpenBatchPopup(true);
  };

  const handleCloseBatchPopup = () => {
    setOpenBatchPopup(false);
  };

  const activeSource = tripList?.data?.map((item) => ({
    coordinates: item?.sourceLocation?.coordinates,
    transitNo: item?.transitNo,
    _id: item?._id,
  }));

  return (
    <Fragment>
      <div className='Transport__routing_container'>
        <div className='transit__info'>
          <div className='AddCampaign__container'>
            <div className='Input__table'>
              <div className='Trip__fetch_buttons'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t('transit_number')}</p>
                  <FormControl fullWidth>
                    <Select
                      size='small'
                      className='mui_custom_input_field'
                      value={tripId || ''}
                      onChange={(e) => handleShipInfoChange(e.target.value)}
                    >
                      {tripList?.data?.length > 0 &&
                        tripList?.data?.map((item, index) => (
                          <MenuItem key={index} value={item?._id}>
                            {item?.transitNo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='Input__column'>
                  {tripId && (
                    <button
                      className='mi_btn mi_btn_small mi_btn_primary'
                      onClick={refetchTripInfo}
                    >
                      <span>{t('Refetch')}</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`trip__details_info ${
            shipInfo?.tripInfo?.shipments?.length > 4 && 'long_list'
          } `}
        >
          {shipInfo?.tripInfo?.shipments?.map((ship) => (
            <div className='trip__info_card' key={ship?.location?.name}>
              <div className='trip__info_details'>
                <h1 className='page__body_heading_fs'>
                  {ship?.location?.name}
                </h1>

                <p className='page__note_fs'>{ship?.location?.postalAddress}</p>
              </div>

              <div className='trip__info_action'>
                <StatusLabel
                  status={ship?.isDelivered ? 'Delivered' : 'Active'}
                  tone={ship?.isDelivered ? 'green' : 'blue'}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='geo__map_view'>
        <div className='ShipmentMap__container'>
          {tripId ? (
            <TransportMap
              tripInfo={shipInfo?.tripInfo}
              locationTrace={shipInfo?.locationTrace}
            />
          ) : (
            <EmptyMap
              activeSource={activeSource}
              handleShipInfoChange={handleShipInfoChange}
            />
          )}
        </div>
        {tripId && (
          <div className='current_temp_btn'>
            <button
              className='mi_btn mi_btn_small mi_btn_primary'
              onClick={handleOpenBatchPopup}
            >
              Current Temperature:{' '}
              {shipInfo?.tripInfo?.currentLocation?.temperature
                ? `${shipInfo?.tripInfo?.currentLocation?.temperature} C`
                : 'NA'}
            </button>
          </div>
        )}
      </div>

      <TemperatureModal
        keepMounted
        open={openBatchPopup}
        onClose={handleCloseBatchPopup}
        allTemperatures={shipInfo?.temperatureTrace}
        currentTemperature={shipInfo?.tripInfo?.currentLocation?.temperature}
      />
    </Fragment>
  );
}
