import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { Checkbox } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";

export default function BatchListTable({
  batchList,
  batch,
  selectedRows,
  inputValues,
  sendingInputValues,
  handleCheckboxChange,
  handleInputChange,
  prodUnit,
  page,
  limit,
  handleChangePage,
  handleChangeRowsPerPage,
  category,
  t,
}) {
  const sorted = batch?.sort(
    (a, b) => new Date(a.expDate) - new Date(b.expDate)
  );

  const isVaccine = category === "VACCINE" ? true : false;
  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className="mi_table__head">
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <FieldHead title={t("batch_no")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("mfg_date")} />
              </TableCell>
              {isVaccine && (
                <TableCell>
                  <FieldHead title={t("exp_date")} />
                </TableCell>
              )}

              <TableCell align="center">
                <FieldHead title={t("actual_qty")} align={"center"} />
              </TableCell>
              {isVaccine && (
                <TableCell align="center">
                  <FieldHead title={t("VVM") + t("status")} align={"center"} />
                </TableCell>
              )}

              {isVaccine && (
                <TableCell align="center">
                  <FieldHead title={t("no_of_vials")} align={"center"} />
                </TableCell>
              )}

              <TableCell align="center">
                <FieldHead title={t("sending_qty")} align={"center"} />
              </TableCell>
              <TableCell align="center">
                <FieldHead title={t("enter_qty")} align={"center"} />
              </TableCell>

              <TableCell align="center">
                <FieldHead
                  title={
                    isVaccine ? t("dose_per_vial") : t("quantity_per_unit")
                  }
                  align={"center"}
                />
              </TableCell>

              <TableCell>
                <FieldHead title="Status" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mi_table__body">
            {sorted?.length > 0 ? (
              <>
                {sorted?.map((row, index) => (
                  <SelectBatchRow
                    key={row._id}
                    row={row}
                    isChecked={selectedRows.includes(row)}
                    inputValue={inputValues[row._id] || ""}
                    sendingInputValues={sendingInputValues}
                    onCheckboxChange={handleCheckboxChange}
                    onInputChange={handleInputChange}
                    prodUnit={prodUnit}
                    isVaccine={isVaccine}
                  />
                ))}
              </>
            ) : (
              <TableRow className="mi_table__body_No_padding">
                <TableCell style={{ padding: "2rem" }} colSpan={10}>
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={batchList?.totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        isVaccine={isVaccine}
      />
    </React.Fragment>
  );
}

function SelectBatchRow({
  row,
  isChecked,
  inputValue,
  sendingInputValues,
  onCheckboxChange,
  onInputChange,
  prodUnit,
  isVaccine,
}) {
  const filteredValue = Object.entries(sendingInputValues)
    .filter(([key]) => key === row?._id)
    .map(([_, value]) => value)[0];

  return (
    <React.Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <Checkbox
            checked={isChecked}
            onChange={() => onCheckboxChange(row)}
          />
        </TableCell>
        <TableCell>
          <FieldBody
            text={row?.batchNo}
            subtext={
              row?.currentCampaignDetails?.id
                ? row?.currentCampaignDetails?.id
                : null
            }
            color="bold"
          />
        </TableCell>

        <TableCell>
          <FieldBody text={new Date(row?.mfgDate).toLocaleDateString()} />
        </TableCell>
        {isVaccine && (
          <TableCell>
            {row?.expDate ? (
              <FieldBody
                text={new Date(row?.expDate).toLocaleDateString()}
                color="red"
              />
            ) : (
              <FieldBody subtext={"- - -"} />
            )}
          </TableCell>
        )}

        <TableCell align="center">
          <FieldBody text={row?.quantity + " " + prodUnit} color="brown" />
        </TableCell>

        {isVaccine && (
          <TableCell>
            {row?.vvmStatus ? (
              <FieldBody text={row?.vvmStatus} />
            ) : (
              <FieldBody subtext={"- - -"} />
            )}
          </TableCell>
        )}

        {isVaccine && (
          <TableCell>
            <FieldBody text={row?.noOfUnits+ " Vial(s)"} />
          </TableCell>
        )}

        <TableCell align="center">
          <FieldBody
            text={filteredValue ? filteredValue + " " + prodUnit : " "}
            color="blue"
          />
        </TableCell>

        <TableCell align="center">
          <input
            type="number"
            className="Quantity__Input"
            name="sendingQuantity"
            value={inputValue}
            onChange={(e) => onInputChange(e, row)}
            onWheel={(event) => event.currentTarget.blur()}
            onFocus={(event) =>
              event.currentTarget.addEventListener(
                "wheel",
                (e) => e.preventDefault(),
                { passive: false }
              )
            }
            onBlur={(event) =>
              event.currentTarget.removeEventListener("wheel", (e) =>
                e.preventDefault()
              )
            }
            min="1"
            step="1"
            onKeyDown={(event) => {
              if (
                !(
                  event.key === "ArrowLeft" ||
                  event.key === "ArrowRight" ||
                  event.key === "ArrowUp" ||
                  event.key === "ArrowDown" ||
                  event.key === "Home" ||
                  event.key === "End" ||
                  event.key === "Backspace" ||
                  event.key === "Delete" ||
                  (event.key >= "0" && event.key <= "9")
                )
              ) {
                event.preventDefault();
              }
            }}
            disabled={!isChecked}
          />
        </TableCell>

        <TableCell align="center">
          <FieldBody text={row?.quantityPerUnit} color="bold" />
        </TableCell>

        <TableCell>
          <StatusLabel status={row?.status} tone="grey" />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
