import { useQuery, keepPreviousData } from '@tanstack/react-query';
import {
  getCurrentStockData,
  getInventoryEventsData,
  getOutOfStockData,
  getAllInventoryProductsData,
  getNearExpiryData,
  getExpiredStockData,
  getRejectedStockData,
  getUnitsWastedData,
  getNeedForCastData,
  getUnitsDisposedData,
  getUnitsQuarantinedData,
} from './api';

export const useGetCurrentStockData = (data, page, limit) => {
  return useQuery({
    queryKey: ['currentStocks', { data, page }],
    queryFn: () => getCurrentStockData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetInventoryEventsData = (data, page, limit) => {
  return useQuery({
    queryKey: ['inventoryEvents', { data, page }],
    queryFn: () => getInventoryEventsData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetOutOfStockData = (data, page, limit) => {
  return useQuery({
    queryKey: ['outOfStock', { data, page }],
    queryFn: () => getOutOfStockData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetNearExpiryData = (data, page, limit) => {
  return useQuery({
    queryKey: ['nearExpiry', { data, page }],
    queryFn: () => getNearExpiryData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetExpiredStockData = (data, page, limit) => {
  return useQuery({
    queryKey: ['expiredStock', { data, page }],
    queryFn: () => getExpiredStockData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetRejectedStockData = (data, page, limit) => {
  return useQuery({
    queryKey: ['rejectedStock', { data, page }],
    queryFn: () => getRejectedStockData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetUnitsWastedData = (data, page, limit) => {
  return useQuery({
    queryKey: ['unitsWasted', { data, page }],
    queryFn: () => getUnitsWastedData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetUnitsQuarantinedData = (data, page, limit) => {
  return useQuery({
    queryKey: ['unitsQuarantined', { data, page }],
    queryFn: () => getUnitsQuarantinedData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetUnitsDisposedData = (data, page, limit) => {
  return useQuery({
    queryKey: ['unitsDisposed', { data, page }],
    queryFn: () => getUnitsDisposedData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useGetAllInventoryProductsData = (data, page, limit) => {
  return useQuery({
    queryKey: ['allInventoryProducts', { data, page }],
    queryFn: () => getAllInventoryProductsData(data, page, limit),
    placeholderData: keepPreviousData,
  });
};

export const useNeedForCastData = (id) => {
  return useQuery({
    queryKey: ['needForCast'],
    queryFn: () => getNeedForCastData(id),
  });
};
