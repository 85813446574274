import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../shared/utils/tableField/FieldBody";
import StatusLabel from "../../../shared/utils/statusLabel/StatusLabel";
import { useNavigate } from "react-router-dom";

export default function MaintenanceTable({ data, t }) {
  const varData = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label="simple table">
        <TableHead className="mi_table__head">
          <TableRow>
            <TableCell>
              <FieldHead title={t("date")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("equipment_number")} />
            </TableCell>

            <TableCell>
              <FieldHead title={t("model_number")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("serial_number")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("location")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("status")} />
            </TableCell>
            <TableCell align="center">
              <FieldHead title={t("view")} align="center" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data?.map((row, index) => (
            <TableBodyRow row={row} key={index} t={t} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TableBodyRow({ key, row, t }) {
  const navigate = useNavigate();

  const handleViewEdit = () => {
    navigate("/user/maintenance/job?view=update", {
      state: { value: row },
    });
  };

  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      key={key}
    >
      <TableCell>
        <FieldBody text={new Date(row?.date).toLocaleDateString()} />
      </TableCell>
      <TableCell>
        <FieldBody text={row?.assetDetails?.type} color="bold" />
      </TableCell>
      <TableCell>
        <FieldBody text={row?.assetDetails?.model} />
      </TableCell>

      <TableCell>
        <FieldBody text={row?.assetDetails?.serialNumber} color="bold" />
      </TableCell>
      <TableCell>
        <FieldBody text={row?.locationDetails?.name} color="blue" />
      </TableCell>
      <TableCell>
        <StatusLabel
          status={row?.status}
          tone={row?.status === "COMPLETED" ? "green" : "blue"}
        />
      </TableCell>
      <TableCell>
        <div className="Table__actions">
          <button
            className="mi_btn mi_btn_medium mi_btn_variant_blue"
            onClick={handleViewEdit}
          >
            <span>
              {t("view")} / {t("edit")}{" "}
            </span>
          </button>
        </div>
      </TableCell>
    </TableRow>
  );
}
