import React, { useEffect, useState } from "react";
import "./AcceptShipment.css";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import StatusLabel from "../../../shared/utils/statusLabel/StatusLabel";
import AcceptShipTable from "./AcceptTable/AcceptShipTable";
import { Link, useParams } from "react-router-dom";
import {
  getShipmentDetails,
  receiveShipment,
} from "../../../redux/shipmentActions";
import { callPopup } from "../../../store/slices/popupSlice";
import { useDispatch } from "react-redux";
import { getInventories } from "../../../redux/inventoryActions";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import Shipment from "/icons/all/shipments/Shipment.svg";
import { Tooltip } from "@mui/material";

export default function AcceptShipment() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [shipment, setShipment] = useState();
  const [shipmentReceived, setShipmentReceived] = React.useState(false);
  const [shipmentAccepted, setShipmentAccepted] = React.useState(false);

  const [acceptStatus, setAcceptStatus] = React.useState("pending");

  const [batchesList, setBatchesList] = React.useState([]);
  const [fullAcceptList, setFullAcceptList] = React.useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const res = await getShipmentDetails(id);
      setShipment(res?.data);
    }
    fetchData();
  }, [id]);

  const [storageInfo, setStorageInfo] = useState();

  // Getting Storage Info
  useEffect(() => {
    const fetchData = async () => {
      const data = await getInventories();
      setStorageInfo(data?.data);
    };
    fetchData();
  }, []);

  const handleFullShipment = (id) => {
    // Filter the shipment products based on the selected row's ID
    const updatedProducts = shipment?.products?.find(
      (product) => product?.productId === id
    );

    // Update the products state with the updated product list
    const batchList = updatedProducts?.batches?.map((item) => ({
      quantity: item.quantity,
      atomId: item.atomId,
      inventoryId: storageInfo[0]?._id,
    }));

    const dataFormat = {
      productId: updatedProducts?.productId,
      batches: batchList,
    };

    setFullAcceptList([...fullAcceptList, dataFormat]);
  };

  // Api Submit
  const handleReceiveShipment = async () => {
    let apiData = {};

    if (acceptStatus === "partial") {
      if (fullAcceptList?.length) {
        apiData = {
          shipmentId: shipment?._id,
          products: [...batchesList, ...fullAcceptList],
        };
      } else {
        apiData = {
          shipmentId: shipment?._id,
          products: batchesList,
        };
      }
    } else {
      if (batchesList?.length) {
        apiData = {
          shipmentId: shipment?._id,
          products: [...fullAcceptList, ...batchesList],
        };
      } else {
        apiData = {
          shipmentId: shipment?._id,
          products: fullAcceptList,
        };
      }
    }

    if (shipment?.products?.length === apiData?.products?.length) {
      const res = await receiveShipment(apiData);
      if (res.success == true) {
        dispatch(
          callPopup({
            open: true,
            type: "success",
            page: "shipments",
            message: t("shipment_received_msg"),
            action: "/user/shipments/inbound",
          })
        );
        setShipmentReceived(true);
      } else {
        dispatch(
          callPopup({
            open: true,
            type: "error",
            page: "orders",
            title: res?.data?.message,
            message: res?.data?.data,
          })
        );
      }
    } else {
      toast.error("Please accept all the products !");
    }
  };

  
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [ shipIdOpen, setShipIdOpen] = React.useState(false)
  const copyToClipboard = (copy) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      if(copy === shipment?.id){
        setShipIdOpen(true)
      } else{        
        setTooltipOpen(true);
      }
      return navigator.clipboard.writeText(copy);
    }
    return Promise.reject(
      "This browser does not support the Clipboard API. Please use a modern browser!"
    );
  };

  return (
    <section className="Page__main_wrapper">
      <div className="Page__headline_wrapper">
        <div className="Page__headline">
          <img src={Shipment} className="Campaign__icon" />
          <h1 className="Page__headline_title_fs">
            {t("accept_shipment")}{" "}
          </h1>
        </div>
        <BreadCrumb
          url1="/user/shipments/inbound"
          Link1={t("shipment")}
          Link2={`${t("accept")} ${t("shipment")}`}
        />
      </div>

      <div className="Order__summary_space">
        <div className="Order__summary_card">
          <div className="Order__summary_header">
            <div className="Order__flex">
              <h1 className="page__body_heading_fs">
                {t("shipment_id")} - {shipment?.id}
              </h1>
             <Tooltip
               title="Copied"
               open={shipIdOpen}
               leaveDelay={1000}
               onClose={() => setShipIdOpen(false)}
             >
               <div
                 className="copy_icon mi_link"
                 onClick={() => copyToClipboard(shipment?.id)}
               >
                 <i className="fa-solid fa-copy"></i>
               </div>
             </Tooltip>
            </div>
            <StatusLabel
              status={
                shipment?.status === "CREATED" ? "SHIPPED" : shipment?.status
              }
              tone="blue"
            />
          </div>
          <div className="Order__summary_body">
            <article className="Order__summary_body_list">
              <div className="summary_body_content_card">
                <h1 className="page__body_fs mi_bold">
                  {" "}
                  {t("shipment_created_by")}{" "}
                </h1>
                <h1 className="page__notion">:</h1>

                <div className="updated_space">
                  <p className="page__body_fs mi_bold">
                    {shipment?.createdBy?.user?.firstName +
                      " " +
                      shipment?.createdBy?.user?.lastName}
                  </p>
                  <p className="page__body_fs ">
                    {shipment?.createdBy?.location?.name}
                  </p>
                  <p className="page__body_fs ">
                    {shipment?.createdBy?.location?.orgLevel}
                  </p>
                </div>
              </div>
              <div className="summary_body_content_card">
                <h1 className="page__body_fs mi_bold">
                  {" "}
                  {t("delivered_to")}{" "}
                </h1>
                <h1 className="page__notion">:</h1>

                <div className="updated_space">
                  <p className="page__body_fs mi_bold">
                    {shipment?.destination?.name}
                  </p>
                  <p className="page__body_fs ">
                    {shipment?.destination?.orgLevel}
                  </p>
                  <p className="page__body_fs ">
                    {shipment?.destination?.district +
                      " " +
                      shipment?.destination?.division}
                  </p>
                </div>
              </div>
            </article>
            <article className="Order__summary_body_list">
              <div className="summary_body_content_card">
                <h1 className="page__body_fs mi_bold">
                  {t("shipment_created_date")}
                </h1>
                <h1 className="page__notion">:</h1>
                <p className="page__body_fs ">
                  {new Date(shipment?.createdAt).toLocaleDateString()}
                </p>
              </div>
              <div className="summary_body_content_card">
                <h1 className="page__body_fs mi_bold">{t("transit_no")}</h1>
                <h1 className="page__notion">:</h1>
            <div style={{display:"flex", alignItems:"center", gap:"0.6rem"}}>  
             <p className="page__body_fs">{shipment?.transitNo}</p>
             <Tooltip
               title="Copied"
               open={tooltipOpen}
               leaveDelay={1000}
               onClose={() => setTooltipOpen(false)}
             >
               <div
                 className="copy_icon mi_link"
                 onClick={() => copyToClipboard(shipment?.transitNo)}
               >
                 <i className="fa-regular fa-copy"></i>
               </div>
             </Tooltip>
            </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      <div className="ProductTable__wrapper">
        <h1 className="page__body_heading_fs">{t("product_list")}</h1>
        <AcceptShipTable
          products={shipment?.products}
          batchesList={batchesList}
          setBatchesList={setBatchesList}
          setShipmentAccepted={setShipmentAccepted}
          fullAcceptList={fullAcceptList}
          handleFullShipment={handleFullShipment}
          acceptStatus={acceptStatus}
          setAcceptStatus={setAcceptStatus}
        />
      </div>
      <section className="InventoryOrders__actions">
        {shipmentReceived ? (
          <Link
            to={"/user/shipments/inbound"}
            className="mi_btn mi_btn_medium mi_btn_primary"
          >
            <span>
              {t("go_to_shipments")}
            </span>
          </Link>
        ) : (
          <div className="Page__left_actions">
            <Link
              to={"/user/shipments/inbound"}
              className="mi_btn mi_btn_medium mi_btn_outline"
            >
              <span>{t("back")}</span>
            </Link>
            <button
              className="mi_btn mi_btn_medium mi_btn_primary"
              onClick={handleReceiveShipment}
            >
              <span>{t("confirm")}</span>
            </button>
          </div>
        )}
      </section>
    </section>
  );
}
