import { useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const getReviewVAR = ({
  navigate,
  params,
  setData,
  setDisplay,
  setAlertList,
  setVaccineList,
  setDiluentList,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (params?.state?.value == undefined) navigate("/");
    else {
      setData(params?.state?.value);
      const d = params?.state?.value;

      setDisplay([
        {
          title: t("general_information"),
          data: [
            {
              title: t("product_name") + " *",
              answer: d?.productInfo?.name + " (" + d?.productInfo?.id + ")",
            },
            {
              title: t("product_category") + " *",
              answer: d?.productInfo?.type,
            },
            {
              title: t("country") + " *",
              answer: d?.country,
            },
            {
              title: t("date_of_invoice"),
              answer: new Date(d?.date_of_invoice).toLocaleDateString(),
            },

            {
              title: t("date_of_shipment"),
              answer: new Date(d?.date_of_shipment).toLocaleDateString(),
            },

            {
              title: t("time_of_shipment"),
              answer: dayjs(`2000-01-01T${d?.time_of_shipment}`).format(
                "h:mm A"
              ),
            },
          ],
        },

        {
          title: t("PAR_part1_title"),
          data: [
            {
              title: t("purchase_order_number"),
              answer: d?.shipmentDetails?.poNo,
            },

            {
              title: t("country"),
              answer: d?.shipmentDetails?.originCountry,
            },
            {
              title: t("consignee"),
              answer: d?.shipmentDetails?.consignee,
            },
            {
              title: t("PAR_part1_question1"),
              answer:
                d?.shipmentDetails?.shortShipment &&
                d?.shipmentDetails?.shortShipment.toString(),
            },
            {
              title: t("PAR_part1_question2"),
              answer:
                d?.shipmentDetails?.shortShipmentNotification &&
                d?.shipmentDetails?.shortShipmentNotification.toString(),
            },
            {
              title: t("comments"),
              answer: d?.shipmentDetails?.comments,
            },
          ],
        },

        {
          title: t("PAR_part2_title"),
          data: [
            {
              title: t("invoice"),
              answer: d?.documents?.invoice?.toString(),
            },
            {
              title: t("packing_list"),
              answer: d?.documents?.packingList?.toString(),
            },
            {
              title: t("product_arrival_report"),
              answer: d?.documents?.productArrivalReport?.toString(),
            },
            {
              title: t("release_certificate"),
              answer: d?.documents?.releaseCertificate?.toString(),
            },
            {
              title: t("if_other_specify"),
              answer: d?.documents?.comment,
            },
            {
              title: t("PAR_part2_question1"),
              answer: d?.documents?.type,
            },
            {
              title: t("PAR_part2_question2"),
              answer: d?.documents?.labelsAttached?.toString(),
            },
            {
              title: t("comments"),
              answer: d?.documents?.other_comment,
            },
          ],
        },

        {
          title: t("PAR_part3_title"),
          data: [
            {
              title: t("authorized_inspection_supervisor"),
              answer: "This sign will be computer generated",
            },
            {
              title: t("authorized_inspection_date"),
              answer: new Date(
                d?.signatures?.inspectionSupervisor?.date
              ).toLocaleDateString(),
            },
            {
              title: t("central_store_inspection_manager"),
              answer: "This sign will be computer generated",
            },
            {
              title: t("date"),
              answer: new Date(
                d?.signatures?.epiManager?.date
              ).toLocaleDateString(),
            },
            {
              subtitle: t("date_received_by_the_office_msg"),
              title: t("date_received_by_the_office"),
              answer: new Date(
                d?.signatures?.receivedDate
              ).toLocaleDateString(),
            },
            {
              title: t("contact_person"),
              answer: d?.signatures?.contact,
            },
          ],
        },
      ]);

      setAlertList(d?.shippingIndicators?.alerts);
      setVaccineList(d?.shipmentDetails?.products);
      setDiluentList(d?.shipmentDetails?.diluentDroppers);
    }
  }, [
    navigate,
    params,
    setData,
    setDisplay,
    setAlertList,
    setVaccineList,
    setDiluentList,
  ]);
};

export default getReviewVAR;
