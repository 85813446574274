import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ExportRecallList({ printData, tableRef }) {
  const { t } = useTranslation();

  return (
    <div>
      <table style={tableStyle} ref={tableRef}>
        <thead className='mi_table__head'>
          <tr>
            <th style={thStyle}>{t('category')}</th>
            <th style={thStyle}>{t('product_name')}</th>
            <th style={thStyle}>{t('batch_no')}</th>
            <th style={thStyle}>{t('manufacturer')}</th>
            <th style={thStyle}>{t('date')}</th>
          </tr>
        </thead>
        <tbody>
          {printData?.length > 0 ? (
            printData?.map((row) => <TableBodyRow row={row} key={row?._id} />)
          ) : (
            <tr>
              <td colSpan='5' style={tdStyle}>
                No products available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

function TableBodyRow({ row }) {
  return (
    <tr>
      <td style={tdStyle}>{row?.productDetails?.type}</td>
      <td style={tdStyle}>{row?.productDetails?.name}</td>
      <td style={tdStyle}>{row?.batchNo}</td>
      <td style={tdStyle}>{row?.manufacturerDetails?.name}</td>
      <td style={tdStyle}>{new Date(row?.createdAt).toLocaleDateString()}</td>
    </tr>
  );
}

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const tdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
  fontSize: '10px',
};

const thStyle = {
  ...tdStyle,
  backgroundColor: '#f2f2f2',
};
