import React from 'react';
import PermissionRoute from '../../../web/routes/routeLayers/PermissionRoute';
import IconInv from '/icons/all/inventory/Current Stock.svg';
import Export from '/icons/all/Export.svg';
import Filter from '/icons/all/Filter.svg';
import Search from '/icons/all/Search.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import OutboundAdjustment from './outboundAdjustment/OutboundAdjustment';
import InboundAdjustment from './inboundAdjustment/InboundAdjustment';
import { useTranslation } from 'react-i18next';

import { useRef, useCallback } from 'react';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import ConfigureTable from '../../../shared/configureTable/ConfigureTable';
import DataExport from '../../../shared/dataExport/DataExport';
import {
  getAdjustmentInventoryInbound,
  getAdjustmentInventoryOutbound,
} from '../../../redux/inventoryActions';
import AdjOutboundExport from './adjExports/AdjOutboundExport';
import AdjInboundExport from './adjExports/AdjInboundExport';

export default function InventoryAdjustment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab: currentTab } = useParams();

  const [openPrint, setOpenPrint] = React.useState(false);
  const [printData, setShipmentsPrint] = React.useState();

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  const openPrintPopup = async () => {
    if (currentTab === 'outbound') {
      const res = await getAdjustmentInventoryOutbound(0, 0);
      if (res?.success === true) {
        setShipmentsPrint(res?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'inbound') {
      const result = await getAdjustmentInventoryInbound(0, 0);
      if (result?.success === true) {
        setShipmentsPrint(result?.data?.data);
        setOpenPrint(true);
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnData, setColumnData] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let initialColumns = {};
  let columns = [];

  const adjOutboundColumns = [
    { key: 'products', title: 'products' },
    { key: 'from', title: 'from_status' },
    { key: 'to', title: 'to_status' },
    { key: 'date', title: 'date' },
    { key: 'status', title: 'status' },
    { key: 'view', title: 'view' },
    { key: 'action', title: 'action' },
  ];

  const adjInboundColumns = [
    { key: 'from_location', title: 'from_location' },
    { key: 'products', title: 'products' },
    { key: 'from', title: 'from_status' },
    { key: 'to', title: 'to_status' },
    { key: 'date', title: 'date' },
    { key: 'status', title: 'status' },
    { key: 'view', title: 'view' },
    { key: 'action', title: 'action' },
  ];

  const adjOutboundInitialColumns = {
    products: true,
    from: true,
    to: true,
    date: true,
    status: true,
    view: true,
    action: true,
  };

  const adjInboundInitialColumns = {
    from_location: true,
    products: true,
    from: true,
    to: true,
    date: true,
    status: true,
    view: true,
    action: true,
  };

  if (currentTab === 'outbound') {
    initialColumns = adjOutboundInitialColumns;
    columns = adjOutboundColumns;
  } else if (currentTab === 'inbound') {
    initialColumns = adjInboundInitialColumns;
    columns = adjInboundColumns;
  }

  React.useEffect(() => {
    setSelectedColumns(initialColumns);
  }, [currentTab]);

  const [selectedColumns, setSelectedColumns] = React.useState(initialColumns);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns?.filter(
    (column) => selectedColumns[column.key]
  );

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(
        wb,
        `${currentTab}Adjustment_${dayjs().format('DD-MM-YYYY')}.xlsx`
      );
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, [currentTab]);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <img src={IconInv} style={{ height: '24px' }} />
            <h1 className='Page__headline_title_fs'>
              {t('inventory_adjustment')}
            </h1>
          </div>
        </div>
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <Link
              to='/user/inventory/adjustment/new/request'
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              <i className='fa-solid fa-plus'></i>
              <span>{t('create_adjustment_request')}</span>
            </Link>
          </div>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              {/* <i className="fa-solid fa-magnifying-glass"></i> */}
              <img src={Search} className='Search__icon' />
              <input type='text' placeholder={t('search')} />
            </article>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              <img src={Filter} style={{ height: '18px' }} />
              <span>{t('filter')}</span>
            </button>

            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
            />

            <PermissionRoute allowed={'EXPORT_ORDER'} type='actions'>
              <button
                onClick={openPrintPopup}
                className='mi_btn mi_btn_medium mi_btn_outline'
              >
                <img src={Export} style={{ height: '18px' }} />
                <span>{t('export')}</span>
              </button>
            </PermissionRoute>
          </div>
        </div>
        <div className='Page__tab_select_space'>
          <div className='tab__btn_group'>
            <div
              className={`tab__button ${currentTab === 'outbound' && 'active'}`}
              onClick={() => navigate('/user/inventory/adjustment/outbound')}
            >
              <h1 className='tab__btn_text_fs'>{t('outbound_adjustment')}</h1>
            </div>
            <div
              className={`tab__button ${currentTab === 'inbound' && 'active'}`}
              onClick={() => navigate('/user/inventory/adjustment/inbound')}
            >
              <h1 className='tab__btn_text_fs'>{t('inbound_adjustment')}</h1>
            </div>
          </div>
        </div>
        {currentTab === 'outbound' ? (
          <OutboundAdjustment
            tableRef={tableRef}
            filteredColumns={filteredColumns}
          />
        ) : (
          currentTab === 'inbound' && (
            <InboundAdjustment
              tableRef={tableRef}
              filteredColumns={filteredColumns}
            />
          )
        )}
      </section>

      <DataExport
        open={openPrint}
        onClose={handleClosePrintPopup}
        tableComponent={
          <>
            {currentTab === 'outbound' ? (
              <AdjOutboundExport
                filteredColumns={filteredColumns}
                tableRef={tableRef}
                printData={printData}
              />
            ) : (
              <AdjInboundExport
                filteredColumns={filteredColumns}
                tableRef={tableRef}
                printData={printData}
              />
            )}
          </>
        }
        title={
          currentTab === 'outbound'
            ? 'Outbound Inventory Adjustment'
            : 'Inbound Inventory Adjustment'
        }
        printData={printData}
        currentTab={currentTab}
        xport={xport}
      />
    </React.Fragment>
  );
}
