import './Inventory.css';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import AnalyticCard from '../../../shared/utils/analyticCard/AnalyticCard';
import IconWasted from '/icons/analytics/IconWasted.svg';
import InvSummary from './inventoryTables/InvSummary';
import InvCurrent from './inventoryTables/InvCurrent';
import InvStockOut from './inventoryTables/InvStockOut';
import InvNearExpiry from './inventoryTables/InvNearExpiry';
import InvExpired from './inventoryTables/InvExpired';
import InvNetUtils from './inventoryTables/InvNetUtils';
import InvMinMax from './inventoryTables/InvMinMax';
import InvWasted from './inventoryTables/InvWasted';

//icons
import CurrentStock from '/icons/all/inventory/Current Stock.svg';
import Expired from '/icons/all/inventory/Expired.svg';
import MinMax from '/icons/all/inventory/Min & Max.svg';
import NearExpiry from '/icons/all/inventory/Near Expiry.svg';
import StockOut from '/icons/all/inventory/Stock Out.svg';
import NetUtilization from '/icons/all/inventory/Net Utilization.svg';
import RejectedQuantity from '/icons/all/inventory/Rejected Quantity.svg';
import Export from '/icons/all/Export.svg';
import Filter from '/icons/all/Filter.svg';
import Search from '/icons/all/Search.svg';
import Summary from '/icons/all/orders/summary.svg';
import Disposed from '/icons/all/inventory/Disposed.svg';
import Quarantine from '/icons/all/inventory/Quarantine.svg';

import Fuse from 'fuse.js';
import {
  currentInventoryStock,
  expiredInventory,
  getInventory,
  getInventoryAnalytics,
  nearExpiryInventory,
  outOfStockInventory,
  getInventoryValue,
  wastedInventory,
  rejectedInventory,
  quarantineInventory,
  disposedInventory,
} from '../../../redux/inventoryActions';
import ConfigureTable from '../../../shared/configureTable/ConfigureTable';
import {
  InvCurrentcolumns,
  InvCurrentinitialColumns,
  InvDisposedColumns,
  InvDisposedInitialColumns,
  InvExpiredcolumns,
  InvExpiredinitialColumns,
  InvMinMaxcolumns,
  InvMinMaxinitialColumns,
  InvNearExpirycolumns,
  InvNearExpiryinitialColumns,
  InvNetUtilscolumns,
  InvNetUtilsinitialColumns,
  InvQuarantineColumns,
  InvQuarantineInitialColumns,
  InvRejectedColumns,
  InvRejectedinitialColumns,
  InvStockOutcolumns,
  InvStockOutinitialColumns,
  InvSummarycolumns,
  InvSummaryinitialColumns,
  InvWastedcolumns,
  InvWastedinitialColumns,
} from './data/initalColumns';
import { formatNumber } from '../../../shared/common/commonFunctions';
import { useSelector } from 'react-redux';
import InvRejected from './inventoryTables/InvRejected';
import PermissionRoute from '../../../web/routes/routeLayers/PermissionRoute';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import InvQuarantine from './inventoryTables/InvQuarantine';
import InvDisposed from './inventoryTables/InvDisposed';

import { useCallback } from 'react';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import ExportTable from '../../components/shadowExport/ExportTable';
import ExportCurrentStock from './exportTables/ExportCurrentStock';
import ExportInvSummary from './exportTables/ExportInvSummary';
import ExportNearExpiry from './exportTables/ExportNearExpiry';
import ExportExpired from './exportTables/ExportExpired';
import ExportStockOut from './exportTables/ExportStockOut';
import ExportQuarantine from './exportTables/ExportQuarantine';
import ExportNetUtils from './exportTables/ExportNetUtils';
import ExportDisposed from './exportTables/ExportDisposed';
import ExportRejected from './exportTables/ExportRejected';
import ExportWasted from './exportTables/ExportWasted';
import { formatCurrency } from '../../../shared/utils/utils';

export default function Inventory() {
  const { t } = useTranslation();
  const { permissions, userInfo } = useSelector((state) => state.auth);

  const [currentTab, setCurrentTab] = useState('currentStock');
  const [inventory, setInventory] = useState();
  const [SearchInvData, setSearchInvData] = useState();
  const [inventoryAnalytics, setInventoryAnalytics] = useState({
    inStock: 0,
    outOfStock: 0,
  });
  const [inventoryValue, setInventoryValue] = useState({
    inStockValue: 0,
    nearExpiryValue: 0,
    expiredValue: 0,
    quarantineValue: 0,
    disposedValue: 0,
    rejectedValue: 0,
    wastedValue: 0,
  });
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = React.useState(10);

  const [openPrint, setOpenPrint] = useState(false);
  const [inventoryPrint, setInventoryPrint] = useState();

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  const openPrintPopup = async () => {
    if (currentTab === 'summary') {
      const result = await getInventory(0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'currentStock') {
      const result = await currentInventoryStock(null, 0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'stockOut') {
      const result = await outOfStockInventory(0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'nearExpiry') {
      const result = await nearExpiryInventory(0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'expired') {
      const result = await expiredInventory(0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'netUtilization') {
      const result = await currentInventoryStock(null, 0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'minmax') {
      // const result = await getInventory(null, 0, 0);
      // if(result?.success === true){
      setInventoryPrint([]);
      setOpenPrint(true);
      // }
    } else if (currentTab === 'wasted') {
      const result = await wastedInventory(0, 10 ^ (10 ^ 100));
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'rejected') {
      const result = await rejectedInventory(0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'quarantine') {
      const result = await quarantineInventory(0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    } else if (currentTab === 'disposed') {
      const result = await disposedInventory(0, 0);
      if (result?.success === true) {
        setInventoryPrint(result?.data?.data);
        setOpenPrint(true);
      }
    }
  };

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(wb, `${currentTab}_${dayjs().format('DD-MM-YYYY')}.xlsx`);
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, [currentTab]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getInventoryAnalytics();
      setInventoryAnalytics({
        inStock: res?.data.inStock,
        outOfStock: res?.data.outOfStock,
      });
      const invValue = await getInventoryValue();
      setInventoryValue({
        inStockValue: invValue?.data.inStockValue || 0,
        nearExpiryValue: invValue?.data.nearExpiryValue || 0,
        expiredValue: invValue?.data.expiredValue || 0,
        quarantineValue: invValue?.data.quarantineValue || 0,
        disposedValue: invValue?.data.disposedValue || 0,
        rejectedValue: invValue?.data.rejectedValue || 0,
        wastedValue: invValue?.data.wastedValue || 0,
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let result;
      if (currentTab === 'summary') {
        result = await getInventory(page, limit);
        setInventory(result?.data?.data);
        setSearchInvData(result?.data?.data);
        setTotalRecords(result?.data?.totalRecords);
      } else if (currentTab === 'currentStock') {
        result = await currentInventoryStock(null, page, limit);
        setInventory(result?.data?.data);
        setSearchInvData(result?.data?.data);
        setTotalRecords(result?.data?.totalRecords);
      } else if (currentTab === 'stockOut') {
        result = await outOfStockInventory(page, limit);
        setInventory(result?.data?.data);
        setSearchInvData(result?.data?.data);
        setTotalRecords(result?.data?.totalRecords);
      } else if (currentTab === 'nearExpiry') {
        result = await nearExpiryInventory(page, limit);
        setInventory(result?.data?.data);
        setSearchInvData(result?.data?.data);
        setTotalRecords(result?.data?.totalRecords);
      } else if (currentTab === 'expired') {
        result = await expiredInventory(page, limit);
        setInventory(result?.data?.data);
        setSearchInvData(result?.data?.data);
        setTotalRecords(result?.data?.totalRecords);
      } else if (currentTab === 'netUtilization') {
        result = await currentInventoryStock(null, page, limit);
        setInventory(result?.data?.data);
        setSearchInvData(result?.data?.data);
        setTotalRecords(result?.data?.totalRecords);
      } else if (currentTab === 'minmax') {
        result = [];
        setInventory([]);
        setSearchInvData([]);
      } else if (currentTab === 'wasted') {
        result = await currentInventoryStock(null, page, limit);
        setInventory(result?.data?.data);
        setSearchInvData(result?.data?.data);
        setTotalRecords(result?.data?.totalRecords);
      }
    };
    fetchData();
  }, [currentTab, limit, page]);

  React.useEffect(() => {
    setPage(0);
  }, [currentTab]);

  // Search Function
  const SearchList = (keyword) => {
    if (!keyword) {
      setInventory(SearchInvData);
      return;
    }
    const fuse = new Fuse(SearchInvData, {
      keys:
        currentTab !== 'nearExpiry' &&
        currentTab !== 'expired' &&
        currentTab !== 'quarantine'
          ? ['product.type', 'product.name', 'product.id']
          : ['productId.type', 'productId.name', 'productId.id'],
      // keys: ["product.type", "product.name", "product.id"],
    });
    const result = fuse.search(keyword);
    const searchResult = [];
    if (result.length) {
      result.forEach((item) => {
        searchResult.push(item.item);
      });
      setInventory(searchResult);
    } else {
      setInventory([]);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnData, setColumnData] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let initialColumns = {};
  let columns = [];

  if (currentTab === 'summary') {
    initialColumns = InvSummaryinitialColumns;
    columns = InvSummarycolumns;
  } else if (currentTab === 'currentStock') {
    initialColumns = InvCurrentinitialColumns;
    columns = InvCurrentcolumns;
  } else if (currentTab === 'stockOut') {
    initialColumns = InvStockOutinitialColumns;
    columns = InvStockOutcolumns;
  } else if (currentTab === 'nearExpiry') {
    initialColumns = InvNearExpiryinitialColumns;
    columns = InvNearExpirycolumns;
  } else if (currentTab === 'expired') {
    initialColumns = InvExpiredinitialColumns;
    columns = InvExpiredcolumns;
  } else if (currentTab === 'netUtilization') {
    initialColumns = InvNetUtilsinitialColumns;
    columns = InvNetUtilscolumns;
  } else if (currentTab === 'minmax') {
    initialColumns = InvMinMaxinitialColumns;
    columns = InvMinMaxcolumns;
  } else if (currentTab === 'wasted') {
    initialColumns = InvWastedinitialColumns;
    columns = InvWastedcolumns;
  } else if (currentTab === 'rejected') {
    initialColumns = InvRejectedinitialColumns;
    columns = InvRejectedColumns;
  } else if (currentTab === 'quarantine') {
    initialColumns = InvQuarantineInitialColumns;
    columns = InvQuarantineColumns;
  } else if (currentTab === 'disposed') {
    initialColumns = InvDisposedInitialColumns;
    columns = InvDisposedColumns;
  }

  const [selectedColumns, setSelectedColumns] = useState(initialColumns);
  const [disableFilter, setDisableFilter] = useState(true);

  useEffect(() => {
    setSelectedColumns(initialColumns);
  }, [currentTab]);

  function areAllTrue(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== true) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    const result = areAllTrue(selectedColumns);
    setDisableFilter(result);
  }, [selectedColumns]);

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [columnName]: !prevSelectedColumns[columnName],
    }));
  };

  const handleResetColumns = () => {
    setSelectedColumns(initialColumns);
  };

  const filteredColumns = columns?.filter(
    (column) => selectedColumns[column.key]
  );

  const myref = useRef();
  const [scrollClick, setScrollClicked] = useState('left-end');

  const scrollRight = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
    setScrollClicked(true);
  };

  const scrollLeft = (scrollOffset) => {
    myref.current.scrollLeft += scrollOffset;
  };



  const totalValue =
    inventoryValue?.inStockValue +
    inventoryValue?.nearExpiryValue +
    inventoryValue?.expiredValue +
    inventoryValue?.quarantineValue;

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        {/* <div className='Page__headline_wrapper'>
          <div className='Page__headline'>
            <img src={IconInv} style={{ height: '24px' }} />
            <h1 className='Page__headline_title_fs'>{t('inventory')}</h1>
          </div>

          <p className='page__body_fs mi_bold'>
            {t('total_value_of_inventory')} : $
            {inventoryValue ? inventoryValue?.inStockValue : 0} USD
          </p>
        </div> */}
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <PermissionRoute allowed={'CREATE_INVENTORY'} type='actions'>
              <Link
                to='/user/inventory/add-inventory'
                className='mi_btn mi_btn_medium mi_btn_primary'
              >
                <i className='fa-solid fa-plus'></i>
                <span>{t('add_inventory')}</span>
              </Link>
            </PermissionRoute>

            {userInfo?.orgLevel === 'EPI' && (
              <Link
                to='/user/inventory/recall'
                state={{ transfer: true }}
                className='mi_btn mi_btn_medium mi_btn_secondary bg_green'
              >
                <i className='fa-solid fa-rotate-left'></i>
                <span>{t('recall')}</span>
              </Link>
            )}

            <Link
              to='/user/inventory/adjustment/outbound'
              state={{ transfer: true }}
              className='mi_btn mi_btn_medium mi_btn_secondary bg_green'
            >
              <i className='fa-solid fa-arrows-rotate'></i>
              <span>{t('adjustment')}</span>
            </Link>

            <Link
              to='/user/inventory/adjustment/new/status'
              className='mi_btn mi_btn_medium mi_btn_secondary bg_green'
            >
              <i className='fa-regular fa-pen-to-square'></i>
              <span>{t('VVM_status')}</span>
            </Link>

            <PermissionRoute allowed={'IMPORT_INVENTORY'} type='actions'>
              <button className='mi_btn mi_btn_medium mi_btn_outline'>
                <i className='fa-solid fa-download'></i>
                <span>{t('import')}</span>
              </button>
            </PermissionRoute>
          </div>
          <p className='page__body_fs mi_bold'>
            {t('total_value_of_inventory')} :{' '}
            {inventoryValue ? formatCurrency(totalValue) : 0} USD
          </p>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              {/* <i className='fa-solid fa-magnifying-glass'></i> */}
              <img src={Search} className='Search__icon' />
              <input
                type='text'
                placeholder={t('search_by_products')}
                onInput={(e) => {
                  SearchList(e.target.value);
                }}
              />
            </article>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handleClick}
            >
              {/* <i className='fa-solid fa-sliders'></i> */}
              <img src={Filter} style={{ height: '18px' }} />
              <span>{t('filter')}</span>
            </button>

            <ConfigureTable
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              value={columnData}
              setValue={setColumnData}
              columns={columns}
              selectedColumns={selectedColumns}
              onColumnToggle={handleColumnToggle}
              handleResetColumns={handleResetColumns}
              filters={disableFilter}
            />

            {/* <PermissionRoute allowed={"EXPORT_INVENTORY"} type="actions"> */}
            <button
              onClick={openPrintPopup}
              className='mi_btn mi_btn_medium mi_btn_outline'
            >
              {/* <i className='fa-solid fa-file-export'></i> */}
              <img src={Export} style={{ height: '18px' }} />
              <span>{t('export')}</span>
            </button>
            {/* </PermissionRoute> */}
          </div>
        </div>
        <div className={`Analytic__wrapper`}>
          <div className='Scroll__Analytic_list' ref={myref}>
            <AnalyticCard
              state='currentStock'
              icon={CurrentStock}
              title={t('current_stocks')}
              number={formatNumber(inventoryAnalytics.inStock)}
              value={formatNumber(inventoryValue.inStockValue)}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            <AnalyticCard
              state='nearExpiry'
              icon={NearExpiry}
              title={t('near_expiry')}
              value={formatNumber(inventoryValue.nearExpiryValue)}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            <AnalyticCard
              state='expired'
              icon={Expired}
              title={t('expired')}
              value={formatNumber(inventoryValue.expiredValue)}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            <AnalyticCard
              state='stockOut'
              icon={StockOut}
              title={t('stock_out')}
              number={formatNumber(inventoryAnalytics.outOfStock)}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            <AnalyticCard
              state='quarantine'
              icon={Quarantine}
              title={t('quarantine')}
              value={formatNumber(inventoryValue.quarantineValue)}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            <AnalyticCard
              state='summary'
              icon={Summary}
              title={t('ledger')}
              number={'-'}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            <AnalyticCard
              state='netUtilization'
              icon={NetUtilization}
              title={
                t('min_max') +
                ' ' +
                t('threshold') +
                ' & ' +
                t('net_utilization')
              }
              number={formatNumber(inventoryAnalytics.inStock)}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            {/* <AnalyticCard
            state="minmax"
            icon={MinMax}
            title={t("min_max")}
            number="-"
            tab={currentTab}
            setTab={setCurrentTab}
          /> */}
            <AnalyticCard
              state='disposed'
              icon={Disposed}
              title={t('disposed')}
              value={formatNumber(inventoryValue.disposedValue)}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            <AnalyticCard
              state='rejected'
              icon={RejectedQuantity}
              title={t('rejected_qty')}
              value={formatNumber(inventoryValue.rejectedValue)}
              tab={currentTab}
              setTab={setCurrentTab}
            />
            {permissions.includes('LAST_MILE') && (
              <AnalyticCard
                state='wasted'
                icon={IconWasted}
                title={t('wasted')}
                value={formatNumber(inventoryValue.wastedValue)}
                tab={currentTab}
                setTab={setCurrentTab}
              />
            )}
          </div>
          {scrollClick === 'right-end' && (
            <div
              className='scroll__analytic_btn left_btn'
              onClick={() => {
                scrollLeft(-1024);
                setScrollClicked('left-end');
              }}
            >
              <i className='fa-solid fa-chevron-left'></i>
            </div>
          )}

          {scrollClick === 'left-end' && (
            <div
              className='scroll__analytic_btn right_btn'
              onClick={() => {
                scrollRight(1024);
                setScrollClicked('right-end');
              }}
            >
              <i className='fa-solid fa-chevron-right'></i>
            </div>
          )}
        </div>

        <div className='Page__table_space'>
          {currentTab === 'summary' && (
            <InvSummary
              inventory={inventory}
              columns={columns}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
          {currentTab === 'currentStock' && (
            <InvCurrent
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
          {currentTab === 'stockOut' && (
            <InvStockOut
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
          {currentTab === 'nearExpiry' && (
            <InvNearExpiry
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
          {currentTab === 'expired' && (
            <InvExpired
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
          {currentTab === 'netUtilization' && (
            <InvNetUtils
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
          {/* {currentTab === "minmax" && (
          <InvMinMax
            inventory={inventory}
            filteredColumns={filteredColumns}
          />
        )} */}
          {currentTab === 'wasted' && (
            <InvWasted
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
          {currentTab === 'rejected' && (
            <InvRejected
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}

          {currentTab === 'quarantine' && (
            <InvQuarantine
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}

          {currentTab === 'disposed' && (
            <InvDisposed
              inventory={inventory}
              filteredColumns={filteredColumns}
              totalRecords={totalRecords}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
        </div>
      </section>

      {currentTab === 'summary' && (
        <ExportInvSummary
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'currentStock' && (
        <ExportCurrentStock
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'nearExpiry' && (
        <ExportNearExpiry
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'expired' && (
        <ExportExpired
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'stockOut' && (
        <ExportStockOut
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'quarantine' && (
        <ExportQuarantine
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'netUtilization' && (
        <ExportNetUtils
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'disposed' && (
        <ExportDisposed
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'rejected' && (
        <ExportRejected
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}

      {currentTab === 'wasted' && (
        <ExportWasted
          open={openPrint}
          onClose={handleClosePrintPopup}
          inventoryPrint={inventoryPrint}
          tableRef={tableRef}
          xport={xport}
          currentTab={currentTab}
        />
      )}
    </React.Fragment>
  );
}
