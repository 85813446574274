import React from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

import EditOrderInventory from "../editOrderInventory/EditOrderInventory";

export default function UpdateInvOrders(props) {
  const {
    onClose,
    open,
    product,
    type,
    addedProducts,
    setAddedProducts,
    manufacturersList,
    EditedProduct,

    ...other
  } = props;

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-paper": { width: "100%" },
        }}
        className="ModalPopup__dialog"
        maxWidth="sm"
        open={open}
        {...other}
      >
        <DialogContent
          sx={{ padding: "0 !important", borderRadius: "0rem !important" }}
        >
          <div className="EditOrderInventory__Popup edit__popup_relative">
            <div
              className="ModalPopup__close_icon edit__popup_icon"
              onClick={onClose}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
            <EditOrderInventory
              open={open}
              product={product}
              type={type}
              addedProducts={addedProducts}
              setAddedProducts={setAddedProducts}
              EditedProduct={EditedProduct}
              manufacturersList={manufacturersList}
              onClose={onClose}
            />
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
