import React from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import { getBatchByProduct } from "../../../../redux/inventoryActions";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";
import BatchesTable from "./BatchesTable";

export default function UpdateVvmDetails(props) {
  const {
    onClose,
    open,
    productId,
    reqQuantity,
    selectedBatchList,
    setSelectedBatchList,
    vvmUpdateData,
    setVvmUpdateData,
    ...other
  } = props;

  const { t } = useTranslation();
  // Getting Batch Details
  const [batchList, setBatchList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const [productData, setProductData] = React.useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    async function fetchData() {
      if (productId) {
        const res = await getBatchByProduct(productId, "ACTIVE", page, limit);
        setBatchList(res.data);
      }
    }
    fetchData();
  }, [productId, page, limit]);

  const handleSubmit = () => {
    setVvmUpdateData((prevData) => [...prevData, ...productData]);
    setProductData([]);
    onClose();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className="ModalPopup__dialog"
      maxWidth={batchList?.product?.type === "VACCINE" ? "lg" : "md"}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className="ModalPopup__header">
          <div className="ModalPopup__header__title_fs">
            <div className="modal__heading_group">
              <div className="modal__heading">
                <h1 className="page__body_subheading_fs">
                  {batchList?.product?.type} :{" "}
                  <ProductIcon
                    type={batchList?.product?.type}
                    name={batchList?.product?.name}
                    size="tiny"
                    fallback={<span>{batchList?.product?.name}</span>}
                  />
                </h1>
              </div>
            </div>
          </div>
          <div className="ModalPopup__close_icon" onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="ModalPopup__body ">
          <section className="Page__main_wrapper">
            <div className="Page__table_space">
              <BatchesTable
                batchList={batchList}
                batch={batchList?.data}
                category={batchList?.product?.type}
                page={page}
                limit={limit}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                vvmUpdateData={vvmUpdateData}
                setVvmUpdateData={setVvmUpdateData}
                productData={productData}
                setProductData={setProductData}
                t={t}
              />
            </div>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className="InventoryOrders__actions_Popup">
          <div></div>
          <div className="Page__left_actions">
            <button
              type="submit"
              className={`mi_btn mi_btn_medium  ${
                productData?.length > 0 || vvmUpdateData?.length > 0
                  ? "mi_btn_primary"
                  : "mi_btn_disable"
              }`}
              onClick={handleSubmit}
              disabled={
                productData?.length > 0 || vvmUpdateData?.length > 0
                  ? false
                  : true
              }
            >
              <span>{t("confirm")}</span>
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}
