import { t } from 'i18next';

export const InvSummarycolumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'productId', title: 'product_id' },
  { key: 'quantity', title: 'quantity' },
  { key: 'transactionDate', title: 'transaction_date' },
  { key: 'status', title: 'status' },
  { key: 'view', title: 'view' },
];

export const InvCurrentcolumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'productId', title: 'product_id' },
  { key: 'totalQuantity', title: 'total_quantity' },
  { key: 'totalBatch', title: 'total_batches' },
  { key: 'viewBatch', title: 'view_batch_details' },
];

export const InvStockOutcolumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'productId', title: 'product_id' },
  { key: 'stockOut', title: 'stock_out_days' },
  { key: 'action', title: 'actions' },
];

export const InvNearExpirycolumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'productId', title: 'product_id' },
  { key: 'manufacturer', title: 'manufacturer' },
  { key: 'quantity', title: 'quantity' },
  { key: 'batchNo', title: 'batch_no' },
  { key: 'expiryDate', title: 'expiry_date' },
];

export const InvExpiredcolumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'productId', title: 'product_id' },
  { key: 'manufacturer', title: 'manufacturer' },
  { key: 'quantity', title: 'quantity' },
  { key: 'batchNo', title: 'batch_no' },
  { key: 'expired', title: 'expired_no_of_days' },
  { key: 'action', title: 'actions' },
];

export const InvNetUtilscolumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'productId', title: 'product_id' },
  { key: 'openingBalance', title: 'opening_balance' },
  { key: 'utilizedQuantity', title: 'utilized_quantity' },
  { key: 'currentBalance', title: 'current_balance' },
  { key: 'minimum', title: 'minimum' },
  { key: 'maximum', title: 'maximum' },
];

export const InvMinMaxcolumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'productId', title: 'product_id' },
  { key: 'minimum', title: 'minimum' },
  { key: 'maximum', title: 'maximum' },
];

export const InvWastedcolumns = [
  { key: 'productName', title: 'product_name' },
  { key: 'batchNo', title: 'batch_no' },
  { key: 'doseUtilized', title: 'dose_utilized' },
  { key: 'doseWasted', title: 'dose_wasted' },
  { key: 'status', title: 'status' },
  { key: 'updatedDate', title: 'updated_date' },
];

export const InvRejectedColumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'shipmentID', title: 'shipment_id' },
  { key: 'batchNo', title: 'batch_no' },
  { key: 'manufacturer', title: 'manufacturer' },
  { key: 'rejectedLocation', title: 'rejected_location' },
  { key: 'rejectedDate', title: 'rejected_date' },
  { key: 'rejectedQty', title: 'rejected_quantity' },
  { key: 'rejectedReason', title: 'rejected_reason' },
];

export const InvQuarantineColumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'batchNo', title: 'batch_no' },
  { key: 'manufacturer', title: 'manufacturer' },
  { key: 'quantity', title: 'quantity' },
  { key: 'status', title: 'status' },
  { key: 'action', title: 'action' },
];

export const InvQuarantineInitialColumns = {
  productCategory: true,
  productName: true,
  batchNo: true,
  manufacturer: true,
  quantity: true,
  status: true,
  action: true,
};

export const InvDisposedColumns = [
  { key: 'productCategory', title: 'product_category' },
  { key: 'productName', title: 'product_name' },
  { key: 'batchNo', title: 'batch_no' },
  { key: 'manufacturer', title: 'manufacturer' },
  { key: 'quantity', title: 'quantity' },
  { key: 'status', title: 'status' },
];

export const InvDisposedInitialColumns = {
  productCategory: true,
  productName: true,
  batchNo: true,
  manufacturer: true,
  quantity: true,
  status: true,
};

export const InvWastedinitialColumns = {
  productName: true,
  batchNo: true,
  doseUtilized: true,
  doseWasted: true,
  status: true,
  updatedDate: true,
};

export const InvRejectedinitialColumns = {
  productCategory: true,
  productName: true,
  shipmentID: true,
  batchNo: true,
  manufacturer: true,
  rejectedQty: true,
  rejectedReason: true,
  rejectedLocation: true,
  rejectedDate: true,
};

export const InvSummaryinitialColumns = {
  productCategory: true,
  productName: true,
  productId: true,
  quantity: true,
  transactionDate: true,
  status: true,
  view: true,
};

export const InvCurrentinitialColumns = {
  productCategory: true,
  productName: true,
  productId: true,
  totalQuantity: true,
  totalBatch: true,
  viewBatch: true,
};

export const InvStockOutinitialColumns = {
  productCategory: true,
  productName: true,
  productId: true,
  stockOut: true,
  action: true,
};

export const InvNearExpiryinitialColumns = {
  productCategory: true,
  productName: true,
  productId: true,
  manufacturer: true,
  quantity: true,
  batchNo: true,
  expiryDate: true,
};

export const InvExpiredinitialColumns = {
  productCategory: true,
  productName: true,
  productId: true,
  manufacturer: true,
  quantity: true,
  batchNo: true,
  expired: true,
  action: true,
};

export const InvNetUtilsinitialColumns = {
  productCategory: true,
  productName: true,
  productId: true,
  openingBalance: true,
  utilizedQuantity: true,
  currentBalance: true,
  minimum: true,
  maximum: true,
};

export const InvMinMaxinitialColumns = {
  productCategory: true,
  productName: true,
  productId: true,
  minimum: true,
  maximum: true,
};
