import React from 'react';
import { useGetNotificationData } from '../../../services/other-services/queries';
import NotificationCard from '../../../shared/utils/notificationCard/NotificationCard';
import EmptyTable from '../../../shared/utils/emptyTable/EmptyTable';

export default function NotificationDetails() {
  const {
    data: NotificationData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetNotificationData();

  const notifications =
    NotificationData?.pages?.flatMap((page) => page?.notifications) || [];

  return (
    <section>
      <div>
        {notifications?.length > 0 ? (
          notifications?.map((notification) => (
            <NotificationCard
              data={notification}
              type={notification?.type}
              eventType={notification?.eventType}
              title={notification?.title}
              body={notification?.body}
              isCritical={notification?.isCritical}
              isRead={notification?.isRead}
              date={notification?.createdAt}
            />
          ))
        ) : (
          <EmptyTable message='No notifications available' />
        )}
      </div>
      <div className='center_align'>
        <button
          className='mi_btn mi_btn_small mi_btn_primary'
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? 'Loading Notifications...'
            : hasNextPage
            ? 'Load More'
            : 'No More Notifications'}
        </button>
      </div>
    </section>
  );
}
