import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AdjInboundExport({
  printData,
  filteredColumns,
  tableRef,
}) {
  const { t } = useTranslation();
  return (
    <table style={tableStyle} ref={tableRef}>
      <thead>
        <tr>
          {filteredColumns?.map((column, index) => (
            <th key={index} style={thStyle}>
              {t(column?.title)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {printData?.map((row) => (
          <tr>
            {filteredColumns.map((column) => (
              <React.Fragment key={column?.key}>
                {column.key === 'from_location' && (
                  <td style={thTdStyle}>{row?.locationDetails?.name}</td>
                )}
                {column.key === 'products' && (
                  <td style={thTdStyle}>
                    {row?.products?.length + ' ' + t('products')}
                  </td>
                )}
                {column.key === 'from' && (
                  <td style={thTdStyle}>{row?.fromStatus}</td>
                )}
                {column.key === 'to' && (
                  <td style={thTdStyle}>{row?.toStatus}</td>
                )}
                {column.key === 'date' && (
                  <td style={thTdStyle}>
                    {new Date(row?.adjustmentDate).toLocaleDateString()}
                  </td>
                )}
                {column.key === 'status' && (
                  <td style={thTdStyle}>{row?.status}</td>
                )}
                {column.key === 'view' && (
                  <td style={thTdStyle}>
                    <div className='Accept_field'>---</div>
                  </td>
                )}
                {column.key === 'action' && (
                  <td style={thTdStyle} align='center'>
                    ---
                  </td>
                )}
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const thTdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
  fontSize: '10px',
};

const thStyle = {
  ...thTdStyle,
  backgroundColor: '#f2f2f2',
};
