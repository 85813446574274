import { useEffect, useState } from "react";
import { getProducts } from "../../../../redux/userActions";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const getReviewVAR = ({
  navigate,
  params,
  setData,
  setDisplay,
  setAlertList,
  setVaccineList,
  setDiluentList,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (params?.state?.value == undefined) navigate("/");
    else {
      setData(params?.state?.value);
      const d = params?.state?.value;
      setDisplay([
        {
          title: t("general_information"),
          data: [
            {
              title: t("vaccine"),
              answer: d?.vaccineInfo?.name,
            },
            {
              title: t("country") + " *",
              answer: d?.country,
            },
            {
              title: t("report_no"),
              answer: d?.reportNo,
            },
            {
              title: t("date_of_report"),
              answer: new Date(d?.reportDate).toLocaleDateString(),
            },
            {
              title: t("place_of_inspection") + "*",
              answer: d?.placeOfInspection,
            },
            {
              title: t("inspection_date_time"),
              answer: dayjs(d?.inspectionDateTime).format("MM-DD-YYYY"),
            },
            {
              title: t("time_of_inspection"),
              answer: dayjs(d?.inspectionDateTime)
                .subtract(5, "hour")
                .subtract(30, "minute")
                .format("h:mm A"),
            },
            {
              title: t("cold_storage"),
              answer: d?.storageName,
            },
          ],
        },
        {
          title: t("VAR_part1_title"),
          data: [
            {
              title: t("review_VAR_part2_subtitle1"),
              answer: new Date(
                d?.advanceNotice?.preAdviceDate
              ).toLocaleDateString(),
            },
            {
              title: t("review_VAR_part2_subtitle2"),
              answer: new Date(
                d?.advanceNotice?.shippingNotificationDate
              ).toLocaleDateString(),
            },
            {
              title: t("copy_of_airway_bill") + " (Awb)",
              answer: d?.advanceNotice?.copyAwb?.toString(),
            },
            {
              title: t("copy_of_packing_list"),
              answer: d?.advanceNotice?.copyPackingList?.toString(),
            },
            {
              title: t("copy_of_invoice"),
              answer: d?.advanceNotice?.copyInvoice?.toString(),
            },
            {
              title: t("copy_of_release_certificate"),
              answer: d?.advanceNotice?.copyReleaseCertificate?.toString(),
            },
          ],
        },
        {
          title: t("VAR_part2_title"),
          data: [
            {
              title: t("awb_number"),
              answer: d?.flightArrivalReport?.awb,
            },
            {
              title: t("flight_number"),
              answer: d?.flightArrivalReport?.flightNo,
            },
            {
              title: t("airport_of_destination"),
              answer: d?.flightArrivalReport?.destination,
            },
            {
              subtitle: t("eta_as_per_notification"),
              title: t("date_time"),
              answer: d?.flightArrivalReport?.scheduledDateTime,
            },
            {
              subtitle: t("actual_time_of_arrival"),
              title: t("date_time"),
              answer: d?.flightArrivalReport?.actualDateTime,
            },
            {
              title: t("name_of_clearing_agent"),
              answer: d?.flightArrivalReport?.clearingAgent?.name,
            },
            {
              title: t("on_behalf_of"),
              answer: d?.flightArrivalReport?.clearingAgent?.behalf,
            },
          ],
        },
        {
          title: t("VAR_part3_title"),
          data: [
            {
              title: t("purchase_order_number"),
              answer: d?.shipmentDetails?.poNo,
            },

            {
              title: t("country"),
              answer: d?.shipmentDetails?.originCountry,
            },
            {
              title: t("consignee"),
              answer: d?.shipmentDetails?.Consignee,
            },

            {
              title: t("manufacturer"),
              answer: d?.vaccineInfo?.manufacturer,
            },
            {
              title: t("PAR_part1_question1"),
              answer:
                d?.shipmentDetails?.shortShipment &&
                d?.shipmentDetails?.shortShipment.toString(),
            },
            {
              title: t("VAR_part1_question2"),
              answer:
                d?.shipmentDetails?.shortShipmentNotification &&
                d?.shipmentDetails?.shortShipmentNotification.toString(),
            },
            {
              title: t("comments"),
              answer: d?.shipmentDetails?.comments,
            },
          ],
        },

        {
          title: t("VAR_part4_title"),
          data: [
            {
              title: t("invoice"),
              answer: d?.documents?.invoice?.toString(),
            },
            {
              title: t("packing_list"),
              answer: d?.documents?.packingList?.toString(),
            },
            {
              title: t("vaccine_arrival_report"),
              answer: d?.documents?.vaccineArrivalReport?.toString(),
            },
            {
              title: t("release_certificate"),
              answer: d?.documents?.releaseCertificate?.toString(),
            },
            // {
            //   title: "If Other, Please specify",
            //   answer: d?.documents,
            // },
            {
              title: t("comments"),
              answer: d?.documents?.comment,
            },
          ],
        },
        {
          title: t("VAR_part5_title"),
          data: [
            {
              title: t("total_no_of_boxes_inspected"),
              answer: d?.shippingIndicators?.noOfInspected,
            },
            {
              title: t("invoice_amount"),
              answer: d?.shippingIndicators?.invoiceAmount,
            },
            {
              title: t("coolant_type"),
              answer: d?.shippingIndicators?.coolantType,
            },
            {
              title: t("temperature_monitors_present"),
              answer: d?.shippingIndicators?.tempMonitors
                .map((key) => key)
                .join(", "),
            },
          ],
        },

        {
          title: t("VAR_part6_title"),
          data: [
            {
              title: t("VAR_part6_options"),
              answer: d?.conditions?.type,
            },
            {
              title: t("VAR_part6_question1"),
              answer: d?.conditions?.labelsAttached?.toString(),
            },
            {
              title: t("VAR_part6_comment"),
              answer: d?.conditions?.comment,
            },
          ],
        },

        {
          title: t("VAR_part7_title"),
          data: [
            {
              title: t("authorized_inspection_supervisor"),
              answer: t("sign_generated_msg"),
            },
            {
              title: t("authorized_inspection_date"),
              answer: new Date(
                d?.signatures?.inspectionSupervisor?.date
              ).toLocaleDateString(),
            },
            {
              title: t("central_store_epi_manger"),
              answer: t("sign_generated_msg"),
            },
            {
              title: t("date"),
              answer: new Date(
                d?.signatures?.epiManager?.date
              ).toLocaleDateString(),
            },
            {
              subtitle: t("date_received_by_the_office_msg"),
              title: t("date_received_by_the_office"),
              answer: new Date(
                d?.signatures?.receivedDate
              ).toLocaleDateString(),
            },
            {
              title: t("contact_person"),
              answer: d?.signatures?.contact,
            },
          ],
        },
      ]);

      setAlertList(d?.shippingIndicators?.alerts);
      setVaccineList(d?.shipmentDetails?.products);
      setDiluentList(d?.shipmentDetails?.diluentDroppers);
    }
  }, [
    navigate,
    params,
    setData,
    setDisplay,
    setAlertList,
    setVaccineList,
    setDiluentList,
  ]);
};

export default getReviewVAR;
