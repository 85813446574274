import React from "react";
import { useTranslation } from "react-i18next";

export default function ViewPARcard({
  key,
  row,
  VaccineList,
  AlertList,
  DiluentList,
}) {
  const {t} = useTranslation()
  return (
    <div className="ReviewVAR_card" key={key}>
      <div className="VARform__header">
        <h1 className="page__body_bigheading_fs">{row?.title}</h1>
      </div>
      <div className="ReviewVARcard__body">
        {row?.data?.map((answer, index) => (
          <AnswerRibbon
            key={index}
            title={answer?.title}
            answer={answer?.answer}
            subtitle={answer?.subtitle && answer?.subtitle}
          />
        ))}

        {row?.title === t("PAR_part1_title") && (
          <>
            {VaccineList?.map((item, index) => (
              <>
                <div className="AnswerRibbon__subheading">
                  <p className="page__tiny_fs mi_sky">{t("product")} {index + 1}</p>
                </div>
                <VaccineValue key={index} item={item} t={t} />
              </>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

function AnswerRibbon({ subtitle, title, answer, key }) {
  return (
    <div className="AnswerRibbon" key={key}>
      {subtitle && (
        <div className="AnswerRibbon__subheading">
          <p className="page__tiny_fs mi_sky">{subtitle}</p>
        </div>
      )}

      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{title}</h1>
        <p className="page__body_fs text_caps">{answer}</p>
      </div>
    </div>
  );
}

function VaccineValue({ item, key, t }) {
  return (
    <div className="AnswerRibbon" key={key}>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("lot_number")}</h1>
        <p className="page__body_fs text_caps">{item?.batchNo}</p>
      </div>
      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("no_of_boxes")}</h1>
        <p className="page__body_fs text_caps">{item?.noOfUnits}</p>
      </div>

      {item?.quantityPerUnit && (
        <div className="AnswerRibbon__body">
          <h1 className="page__body_fs mi_bold">{t("quantity_per_unit")}</h1>
          <p className="page__body_fs text_caps">{item?.quantityPerUnit}</p>
        </div>
      )}

      <div className="AnswerRibbon__body">
        <h1 className="page__body_fs mi_bold">{t("manufacturing_date")}</h1>
        <p className="page__body_fs text_caps">{item?.mfgDate}</p>
      </div>
      {item?.expDate && (
        <div className="AnswerRibbon__body">
          <h1 className="page__body_fs mi_bold">{t("expiry_date")}</h1>
          <p className="page__body_fs text_caps">{item?.expDate}</p>
        </div>
      )}
    </div>
  );
}
