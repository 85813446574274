import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import { toast } from "react-hot-toast";
import { ageCalculate, calculateAge } from "../../../../shared/common/commonFunctions";
import AnalyticCard from "../../../../shared/utils/analyticCard/AnalyticCard";
import { useTranslation } from "react-i18next";

export default function BeneficiaryTable({ formik, setEditItem, reOpened}) {
  const {t} = useTranslation()
  
  const removeRow =(indexOfObject)=> {
    formik.setValues({
    ...formik.values,
    beneficiaryDetails: formik.values.beneficiaryDetails.filter((_, index) => index !== indexOfObject),
    });
  }
  const editRow = (row, i)=>{    
    setEditItem(true)
    formik.setFieldValue("id", row.id)
    formik.setFieldValue("dose", row.doseNo)    
    formik.setFieldValue("beneficiaryId", row.beneficiaryId)    
  }
  return (
    <React.Fragment>
      <div className="ProductTable__wrapper">
        <div className="Page__select_tab_wrapper">
          <div style={{display:'flex', alignItems:'end',justifyContent:'space-between', gap:'1rem'}}>
            <div className="Select__tab_headline">
              {reOpened ? ( <h1 className="page__body_fs">
                *{formik?.values?.beneficiaryDetails?.length}/{reOpened?.quantityPerUnit - reOpened?.quantityUtilized}
                {" "} {t("benefs_added_msg")}</h1>
                ):( 
                <h1 className="page__body_fs">
                *{formik?.values?.beneficiaryDetails?.length}/{formik?.values?.batchNo.count}
                {" "} {t("benefs_added_msg")}</h1>
              )}
            </div>
           {reOpened && (
             <div style={{display:"flex", alignItems:'end', gap:'1rem'}}>
              <h1 className="page__body_fs mi_bold">{t("total_doses_to_be_vaccinated")}: {reOpened?.quantityPerUnit}</h1>
              <h1 className="page__body_fs mi_bold">{t("utilized_doses")}: {reOpened?.quantityUtilized + formik?.values?.beneficiaryDetails?.length}</h1>
              <h1 className="page__body_fs mi_bold">{t("pending_doses")}: {(reOpened?.quantityPerUnit - reOpened?.quantityUtilized)- formik?.values?.beneficiaryDetails?.length}</h1>
             </div>
           )}
          </div>
          <div className="Page__product_table">
            <TableContainer>
              <Table sx={{ minWidth: 485 }}>
                <TableHead className="mi_table__head br-top"
                 sx={{
                    "& th": {
                      color: "rgba(96, 96, 96)",
                      backgroundColor: "#E9F2F0"
                    }
                  }}>
                  <TableRow>
                    <TableCell>
                      <FieldHead title={t("vaccine")} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t("batch_no")} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t("beneficiary_id")} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t("dose_number")} />
                    </TableCell>
                    <TableCell>
                      <FieldHead title={t("action")} />
                      </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="mi_table__body">
                  {formik?.values?.beneficiaryDetails?.map((row, i) => (
                    <TableRowCard
                      row={row}
                      key={i}
                      i={i}
                      removeRow={removeRow}
                      editRow={editRow}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function TableRowCard({
  i,
  row,
  removeRow,
  editRow,
}) {
  
  const [years, months] = ageCalculate(row?.dob)

  // const gap = dayjs(row).toNow(true)
 
  return (
    <React.Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <FieldBody subtext={row.vaccine} />
        </TableCell>
        <TableCell>
          <FieldBody subtext={row.batchNo} />
        </TableCell>
        <TableCell>
            <FieldBody subtext={row.beneficiaryId} />
        </TableCell>
        <TableCell>
          <FieldBody subtext={row.doseNo}/>
        </TableCell>

        <TableCell>
          <div className="ReviewProducts__actions">
            <div
              className="Review__action_ico edit_ico"
              onClick={() => {
                editRow(row, i)
              }}
            >
              {" "}
              <i className="fa-solid fa-pencil icon_blue"></i>
            </div>

            <div
              className="Review__action_ico delete_ico"
              onClick={() => {
                removeRow(i)
                toast.success("Patient removed from the list");
              }}
            >
              <i className="fa-solid fa-trash-can icon_blue"></i>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
