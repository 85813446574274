import React from 'react';
import { useTranslation } from 'react-i18next';

export default function BeneficiariesExportTable({
  printData,
  filteredColumns,
  tableRef,
}) {
  const { t } = useTranslation();

  // Limit to the first 10 records
  const displayedData = printData?.slice(0, 10);

  return (
    <>
      <table style={tableStyle} ref={tableRef}>
        <thead>
          <tr>
            {filteredColumns?.map((column, index) => (
              <th key={index} style={thStyle}>
                {t(column?.title)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedData?.map((row) => (
            <tr>
              {filteredColumns.map((column) => (
                <React.Fragment key={column?.key}>
                  {column.key === 'vaccine' && (
                    <td style={thTdStyle}>{row?.vaccine} </td>
                  )}

                  {column.key === 'batch_no' && (
                    <td style={thTdStyle}>
                      {row?.batch_no} color={'bold'}{' '}
                    </td>
                  )}

                  {column.key === 'dose_number' && (
                    <td style={thTdStyle}>{row?.dose_number} </td>
                  )}

                  {column.key === 'beneficiary_id' && (
                    <td style={thTdStyle}>{'ID:' + row?.beneficiary_id} </td>
                  )}

                  {column.key === 'vaccinated_date' && (
                    <td style={thTdStyle}>
                      {new Date(row?.vaccinated_date).toLocaleDateString()}
                    </td>
                  )}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const thTdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
  fontSize: '10px',
};

const thStyle = {
  ...thTdStyle,
  backgroundColor: '#f2f2f2',
};
