import React, { useState } from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

// Icons
import FileUpload from "./FileUpload/FileUpload";
import FileList from "./FileList/FileList";
import { useTranslation } from "react-i18next";

export default function ImportPopup({ open, onClose }) {
  const file = false;
  const {t} = useTranslation()

  return (
    <Dialog open={open}>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="Import__popup">
          <div className="ModalPopup__header">
            <div className="ModalPopup__header__title_fs">
              {t("import_order_details")}
            </div>
            <div className="ModalPopup__close_icon" onClick={onClose}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="ModalPopup__wrapper">
            <div className="ModalPopup__body">
              <form className="ModalPopup__form_container">
                <div className="Popup-wrapper">
                  <FileUpload  t={t} />
                  {file && <FileList t={t} />}
                </div>
              </form>
            </div>
          </div>
          <div className="ModalPopup__footer_action">
            <button className="mi_btn mi_btn_medium mi_btn_outline">
              <i className="fa-solid fa-download"></i>
              <span>{t("download_template")}</span>
            </button>
            <button className="mi_btn mi_btn_medium mi_btn_outline">
              <i className="fa-solid fa-file-import"></i>
              <span>{t("import")}</span>
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
