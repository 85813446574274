import React, { useState, useCallback } from 'react';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import GeoTracking from '/icons/all/geo-tracking.svg';
import './TransportRoute.css';
import { getTripInfo, getTrips } from '../../../redux/shipmentActions';

import TripLiveTracking from './TripLiveTracking';
import { Link, useParams } from 'react-router-dom';
import TripHistory from './TripHistory';

export default function TransportRoute() {
  const { t } = useTranslation();
  const [tripId, setTripId] = useState();

  const handleShipInfoChange = useCallback((value) => {
    setTripId(value);
  }, []);

  const { data: tripList } = useQuery({
    queryKey: ['trips', 'ACTIVE'],
    queryFn: () => getTrips('ACTIVE'),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 10,
  });

  const { data: shipInfo } = useQuery({
    queryKey: ['tripInfo', tripId],
    queryFn: () => getTripInfo(tripId),
    enabled: !!tripId,
  });

  const { url: currentTab } = useParams();

  const queryClient = useQueryClient();

  const refetchTripInfo = () => {
    queryClient.invalidateQueries({ queryKey: ['tripInfo'] });
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        {/* <div className="Page__headline">
          <img
            src={GeoTracking}
            className="Lastmile__icon"
            alt="Last Mile Icon"
          />
          <h1 className="Page__headline_title_fs">{t("geo_tracking")}</h1>
        </div> */}
        <div className='tab__btn_group'>
          <Link to={'/user/geo-tracking/tracking'}>
            <div
              className={`tab__button ${currentTab === 'tracking' && 'active'}`}
            >
              <h1 className='tab__btn_text_fs'>{t('Trip Live Tracking')}</h1>
            </div>
          </Link>
          <Link to={'/user/geo-tracking/history'}>
            <div
              className={`tab__button ${currentTab === 'history' && 'active'}`}
            >
              <h1 className='tab__btn_text_fs'>{t('Trip History')}</h1>
            </div>
          </Link>
        </div>
      </div>

      {currentTab === 'tracking' && (
        <TripLiveTracking
          tripId={tripId}
          tripList={tripList}
          shipInfo={shipInfo}
          handleShipInfoChange={handleShipInfoChange}
          refetchTripInfo={refetchTripInfo}
        />
      )}

      {currentTab === 'history' && <TripHistory />}
    </section>
  );
}
