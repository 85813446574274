import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { FormControl, MenuItem, Select } from '@mui/material';
import { getInventories } from '../../../redux/inventoryActions';
import { getDates } from '../../../shared/common/commonFunctions';
import { useTranslation } from 'react-i18next';

export default function EditOrderInventory({
  type,
  open,
  EditedProduct,
  setAddedProducts,
  addedProducts,
  onClose,
  manufacturersList,
}) {
  const { t } = useTranslation();
  const [QuantityCount, setQuantityCount] = useState(
    open && EditedProduct.quantity
  );
  const [QuickBtnClick, setQuickBtnClick] = useState(null);

  const addProduct = (data) => {
    const newArr = addedProducts?.length ? [...addedProducts] : [];

    // Check if the product already exists in the array
    let existingProductIndex;
    let newQuantity = QuantityCount;

    if (type === 'order') {
      existingProductIndex = newArr.findIndex(
        (item) => item.name === EditedProduct.name
      );
    }

    if (type === 'inventory') {
      existingProductIndex = newArr.findIndex(
        (item) =>
          item.name === EditedProduct.name &&
          item.batchNo === EditedProduct.batchNo &&
          item.mfgDate === EditedProduct.mfgDate &&
          item.manufacturerId === EditedProduct.manufacturerId &&
          item.expDate === EditedProduct.expDate
      );

      newQuantity =
        Math.floor(QuantityCount / data?.quantityPerUnit) *
        data?.quantityPerUnit;
    }

    if (existingProductIndex !== -1) {
      // If product exists, update the quantity
      const updatedProducts = [...newArr];

      if (type === 'order') {
        updatedProducts[existingProductIndex].quantity = parseInt(newQuantity);
      } else {
        updatedProducts[existingProductIndex].quantity = parseInt(newQuantity);
        updatedProducts[existingProductIndex].batchNo = data?.batchNo;
        updatedProducts[existingProductIndex].mfgDate =
          data?.mfgDate + 'T00:00:00.163Z';
        updatedProducts[existingProductIndex].expDate =
          data?.expDate && data?.expDate + 'T00:00:00.552Z';
        updatedProducts[existingProductIndex].value =
          parseInt(newQuantity) * parseInt(data?.valueProd);
        updatedProducts[existingProductIndex].valueIndividual = parseInt(
          data?.valueProd
        );
        updatedProducts[existingProductIndex].manufacturerId =
          data?.manufacturerId;
        updatedProducts[existingProductIndex].inventoryId = data?.inventoryId;
        updatedProducts[existingProductIndex].quantityPerUnitList =
          data?.quantityPerUnitList;
      }

      setAddedProducts(updatedProducts);
      onClose();
      toast.success(t('product_updated_msg'));
    }
  };

  return (
    <div className='QuantityEntry__wrapper no_space'>
      <QuantityField
        QuantityCount={QuantityCount}
        setQuantityCount={setQuantityCount}
        type={type}
        QuickBtnClick={QuickBtnClick}
        setQuickBtnClick={setQuickBtnClick}
        addProduct={addProduct}
        EditedProduct={EditedProduct}
        onClose={onClose}
        t={t}
      />
      {type === 'inventory' && (
        <ProductInfoField
          QuantityCount={QuantityCount}
          addProduct={addProduct}
          EditedProduct={EditedProduct}
          onClose={onClose}
          manufacturersList={manufacturersList}
          t={t}
        />
      )}
    </div>
  );
}

function QuantityField({
  type,
  QuantityCount,
  setQuantityCount,
  QuickBtnClick,
  addProduct,
  setQuickBtnClick,
  EditedProduct,
  onClose,
  t,
}) {
  return (
    <React.Fragment>
      <div className='QuantityField__space'>
        <div className='QuantityField__label'>
          <h1 className='QuantityField__label_fs'>
            {t('update')} {EditedProduct.name}
          </h1>
        </div>
        <div className='QuantityField__measure'>
          <div
            className={`QuantityField__measure_btn ${
              QuantityCount === 0 && 'disabled'
            } `}
            onClick={() => {
              if (QuantityCount > 0) {
                setQuantityCount(parseInt(QuantityCount) - 1);
              }
            }}
          >
            <i className='fa-solid fa-minus'></i>
          </div>
          <div className='QuantityField__measure_input'>
            <input
              type='number'
              value={QuantityCount}
              onChange={(e) => setQuantityCount(e.target.value)}
              required
            />
          </div>
          <div
            className='QuantityField__measure_btn'
            onClick={() => {
              setQuantityCount(parseInt(QuantityCount) + 1);
            }}
          >
            <i className='fa-solid fa-plus'></i>
          </div>
          <div className='QuantityField__quick_count_header'>
            {QuantityCount > 0 ? (
              <button
                className='QuantityField__quick_count_btn'
                onClick={() => {
                  setQuantityCount(0);
                  setQuickBtnClick(null);
                }}
              >
                {t('reset')}
              </button>
            ) : (
              <button className='QuantityField__quick_count_btn'>
                {t('units')}
              </button>
            )}
          </div>
        </div>
        <div className='QuantityField__quick_count'>
          <div className='QuantityField__quick_count_body'>
            <div className='QuantityField__quick_count_row'>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 50 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(50);
                  setQuickBtnClick(50);
                }}
              >
                50
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 100 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(100);
                  setQuickBtnClick(100);
                }}
              >
                100
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 200 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(200);
                  setQuickBtnClick(200);
                }}
              >
                200
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 500 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(500);
                  setQuickBtnClick(500);
                }}
              >
                500
              </button>
            </div>
            <div className='QuantityField__quick_count_row'>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 1000 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(1000);
                  setQuickBtnClick(1000);
                }}
              >
                1000
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 1500 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(1500);
                  setQuickBtnClick(1500);
                }}
              >
                1500
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 5000 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(5000);
                  setQuickBtnClick(5000);
                }}
              >
                5000
              </button>
              <button
                className={`QuantityField__quick_count_btn ${
                  QuickBtnClick === 10000 && 'active'
                }`}
                onClick={() => {
                  setQuantityCount(10000);
                  setQuickBtnClick(10000);
                }}
              >
                10000
              </button>
            </div>
          </div>
        </div>
      </div>
      {type === 'order' && (
        <div className='QuantityEntry__actions'>
          <button
            onClick={() => (QuantityCount > 0 ? addProduct({}) : null)}
            disabled={QuantityCount == 0}
            className={`mi_btn mi_btn_medium ${
              QuantityCount === 0 ? 'mi_btn_disabled' : 'mi_btn_primary'
            }`}
          >
            <span>{t('save_the_changes')}</span>
          </button>
          <button
            className={`mi_btn mi_btn_medium mi_btn_secondary`}
            onClick={onClose}
          >
            <span>{t('cancel')}</span>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

function ProductInfoField({
  QuantityCount,
  addProduct,
  EditedProduct,
  onClose,
  manufacturersList,
  t,
}) {
  const [yesterday, tomorrow] = getDates();
  const [storageInfo, setStorageInfo] = useState();

  // Getting Storage Info
  useEffect(() => {
    const fetchData = async () => {
      const data = await getInventories();
      setStorageInfo(data?.data);
    };
    fetchData();
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.batchNo) errors.batchNo = t('required');

    if (!values.mfgDate) errors.mfgDate = t('required');

    if (!values.manufacturerId) errors.manufacturerId = t('required');

    return errors;
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().split('T')[0];
    return formattedDate;
  };

  const formik = useFormik({
    initialValues: {
      batchNo: EditedProduct.batchNo,
      mfgDate: formatDate(EditedProduct.mfgDate),
      expDate: EditedProduct.expDate ? formatDate(EditedProduct.expDate) : '',
      valueProd: EditedProduct.valueIndividual,
      manufacturerId: EditedProduct.manufacturerId,
      inventoryId: EditedProduct.inventoryId,
      quantityPerUnit: EditedProduct.quantityPerUnit,
    },
    validate,
    onSubmit: async (values) => {
      addProduct(values);
      formik.resetForm();
    },
  });

  const quantityPerUnitList = manufacturersList?.find(
    (org) => org?.id === formik.values.manufacturerId
  );

  return (
    <form
      className='ModalPopup__form_container '
      onSubmit={formik.handleSubmit}
    >
      <div className='ProductInfoField__space'>
        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('storage_name')} *</p>
          <div className='input_bg'>
            <FormControl fullWidth>
              <Select
                disabled={storageInfo?.length > 1 ? false : true}
                className='mui_custom_input_field'
                size='small'
                id='inventoryId'
                name='inventoryId'
                onChange={formik.handleChange}
                value={formik.values.inventoryId}
              >
                {storageInfo?.map((loc, index) => (
                  <MenuItem key={index} value={loc?._id}>
                    {loc?.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.inventoryId && formik.touched.inventoryId && (
                <span className='Input__error_fs'>
                  {formik.errors.inventoryId}
                </span>
              )}
            </FormControl>
          </div>
        </div>
        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('manufacturer_name')} *</p>
          <div className='input_bg'>
            <FormControl fullWidth>
              <Select
                // disabled={manufacturersList?.length > 1 ? false : true}
                className='mui_custom_input_field'
                size='small'
                id='manufacturerId'
                name='manufacturerId'
                onChange={formik.handleChange}
                value={formik.values.manufacturerId}
              >
                {manufacturersList?.map((org, index) => (
                  <MenuItem key={index} value={org?.details?._id}>
                    {org?.details?.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.manufacturerId &&
                formik.touched.manufacturerId && (
                  <span className='Input__error_fs'>
                    {formik.errors.manufacturerId}
                  </span>
                )}
            </FormControl>
          </div>
        </div>
        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('doses_per_vial')} *</p>
          <div className='input_bg'>
            <FormControl fullWidth>
              <Select
                disabled={
                  quantityPerUnitList?.quantityPerUnit?.length > 1
                    ? false
                    : true
                }
                className='mui_custom_input_field'
                size='small'
                id='quantityPerUnit'
                name='quantityPerUnit'
                onChange={formik.handleChange}
                value={formik.values.quantityPerUnit}
              >
                {quantityPerUnitList?.quantityPerUnit?.map((qty, index) => (
                  <MenuItem key={index} value={qty}>
                    {qty}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.quantityPerUnit &&
                formik.touched.quantityPerUnit && (
                  <span className='Input__error_fs'>
                    {formik.errors.quantityPerUnit}
                  </span>
                )}
            </FormControl>
          </div>
        </div>

        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('batch_no')} *</p>
          <input
            type='text'
            className='mi_custom_textfield'
            placeholder={t('batch_no')}
            id='batchNo'
            name='batchNo'
            onChange={formik.handleChange}
            value={formik.values.batchNo}
          />
          {formik.errors.batchNo && formik.touched.batchNo && (
            <span className='Input__error_fs'>{formik.errors.batchNo}</span>
          )}
        </div>
        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('mfg_date')} *</p>
          <input
            type='text'
            onFocus={(e) => (e.target.type = 'date')}
            onBlur={(e) => (e.target.type = 'text')}
            className='mi_custom_textfield date_field'
            placeholder={t('mfg_date')}
            id='mfgDate'
            name='mfgDate'
            max={yesterday}
            onChange={formik.handleChange}
            value={formik.values.mfgDate}
          />
          {formik.errors.mfgDate && formik.touched.mfgDate && (
            <span className='Input__error_fs'>{formik.errors.mfgDate}</span>
          )}
        </div>
        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('exp_date')} *</p>
          <input
            type='text'
            onFocus={(e) => (e.target.type = 'date')}
            onBlur={(e) => (e.target.type = 'text')}
            className='mi_custom_textfield date_field'
            placeholder={t('exp_date')}
            id='expDate'
            name='expDate'
            min={tomorrow}
            onChange={formik.handleChange}
            value={formik.values.expDate}
          />
          {formik.errors.expDate && formik.touched.expDate && (
            <span className='Input__error_fs'>{formik.errors.expDate}</span>
          )}
        </div>
        <div className='ProductInfoField__input'>
          <p className='input__small_label'>{t('value_per_dose')}</p>
          <input
            type='text'
            className='mi_custom_textfield'
            placeholder={t('value_per_dose')}
            id='valueProd'
            name='valueProd'
            onChange={formik.handleChange}
            value={formik.values.valueProd}
          />
          {formik.errors.valueProd && formik.touched.valueProd && (
            <span className='Input__error_fs'>{formik.errors.valueProd}</span>
          )}
        </div>
      </div>
      <div className='QuantityEntry__actions'>
        <button
          type='submit'
          disabled={QuantityCount == 0}
          className={`mi_btn mi_btn_medium ${
            QuantityCount === 0 ? 'mi_btn_disabled' : 'mi_btn_primary'
          }`}
        >
          <span>{t('save_the_changes')}</span>
        </button>
        <button
          className={`mi_btn mi_btn_medium mi_btn_secondary`}
          onClick={onClose}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}
