import React from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import BatchListTable from "./BatchListTable";
import { getBatchByProduct } from "../../../../redux/inventoryActions";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import ProductIcon from "../../../../shared/utils/productIcon/ProductIcon";

export default function FetchBatch(props) {
  const {
    onClose,
    open,
    productId,
    reqQuantity,
    selectedBatchList,
    setSelectedBatchList,
    pageType,
    from,
    ...other
  } = props;

  const { t } = useTranslation();
  // Getting Batch Details
  const [batchList, setBatchList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    async function fetchData() {
      if (productId) {
        const statusProps =
          pageType === "Adjustment" ? from : ["ACTIVE", "OPEN"];
        const res = await getBatchByProduct(
          productId,
          statusProps,
          page,
          limit
        );

        setBatchList(res.data);
      }
    }
    fetchData();
  }, [productId, page, limit]);

  // Getting the Selected Batch
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [inputValues, setInputValues] = React.useState({});
  const [sendingInputValues, setSendingInputValues] = React.useState({});

  const handleCheckboxChange = (row) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter((item) => item !== row));
      setInputValues((prevInputValues) => {
        const { [row._id]: removedValue, ...rest } = prevInputValues;
        return rest;
      });
      setSendingInputValues((prevInputValues) => {
        const { [row._id]: removedValue, ...rest } = prevInputValues;
        return rest;
      });
    } else {
      setSelectedRows([...selectedRows, row]);
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [row._id]: "",
      }));
      setSendingInputValues((prevInputValues) => ({
        ...prevInputValues,
        [row._id]: 0,
      }));
    }
  };

  const handleInputChange = (event, row) => {
    const { name, value } = event.target;

    const unit = row?.quantityPerUnit || 1;

    const newValue = Math.floor(value / unit) * unit;

    let sendingValue = newValue;
    if (newValue > row?.quantity) {
      toast.error(
        `You cannot enter a quantity greater than ${row?.quantity}. Please check the quantity.`
      );
      sendingValue = row?.quantity;
    }

    // if (newValue > reqQuantity) {
    //   sendingValue = newValue - row?.quantityPerUnit;
    // }

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [row._id]: value,
    }));

    setSendingInputValues((prevInputValues) => ({
      ...prevInputValues,
      [row._id]: sendingValue,
    }));
  };

  // Getting the Batch to required Format
  const getSelectedBatches = () => {
    return selectedRows.map((row) => ({
      batchNo: row.batchNo,
      quantity: parseInt(sendingInputValues[row._id] || 0),
      atomId: row._id,
    }));
  };

  const handleBatch = () => {
    const batches = getSelectedBatches();

    let product = [];

    product.push({
      productId: selectedRows && selectedRows?.[0]?.productId,
      batches,
    });

    // Validating the Array
    const newArr = selectedBatchList?.length ? [...selectedBatchList] : [];

    let existingProductIndex;

    existingProductIndex = newArr
      .flat()
      .findIndex((item) => item.productId === product?.[0]?.productId);

    if (existingProductIndex !== -1) {
      const updatedProducts = [...newArr];
      const flatingProduct = updatedProducts.flat();

      flatingProduct[existingProductIndex].batches = product?.[0]?.batches;

      setSelectedBatchList(flatingProduct);
      onClose();
    } else {
      setSelectedBatchList([...newArr, product?.[0]]);
      onClose();
    }
  };

  // (Math.floor(current / units) * units)

  // Getting Total Quantity
  const totalQuantityList = Object.values(sendingInputValues);
  const [totalQty, setTotal] = React.useState(0);

  React.useEffect(() => {
    const sum = totalQuantityList?.reduce((acc, current) => {
      return acc + parseInt(current);
    }, 0);
    setTotal(sum);
  }, [totalQuantityList]);

  const [isObjectNotEmpty, setIsObjectNotEmpty] = React.useState(false);

  React.useEffect(() => {
    const checkObjectNotEmpty = (obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === "") {
          return false;
        } else if (obj.hasOwnProperty(key) && obj[key] === "0") {
          return false;
        } else if (obj.hasOwnProperty(key) && obj[key] / 1 === 0) {
          return false;
        }
      }
      return true;
    };

    if (inputValues) {
      const isEmpty = checkObjectNotEmpty(inputValues);
      setIsObjectNotEmpty(isEmpty);
    }
  }, [inputValues]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className="ModalPopup__dialog"
      maxWidth={batchList?.product?.type === "VACCINE" ? "xl" : "md"}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className="ModalPopup__header">
          <div className="ModalPopup__header__title_fs">
            <div className="modal__heading_group">
              <div className="modal__heading">
                <h1 className="page__body_subheading_fs">
                  {batchList?.product?.type} :{" "}
                  <ProductIcon
                    type={batchList?.product?.type}
                    name={batchList?.product?.name}
                    size="tiny"
                    fallback={<span>{batchList?.product?.name}</span>}
                  />
                </h1>
              </div>

              <div className="modal__quanity">
                {reqQuantity && (
                  <div className={`AnalyticCard__label blue_label`}>
                    <p className="AnalyticCard__label_fs ">
                      {t("req_quantity")} : {reqQuantity}{" "}
                      {batchList?.product?.units}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ModalPopup__close_icon" onClick={()=>{
            onClose()
            setSelectedRows(selectedBatchList)
          }}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="ModalPopup__body ">
          <section className="Page__main_wrapper">
            <div className="Page__table_space">
              <BatchListTable
                batchList={batchList}
                batch={batchList?.data}
                selectedRows={selectedRows}
                inputValues={inputValues}
                sendingInputValues={sendingInputValues}
                handleCheckboxChange={handleCheckboxChange}
                handleInputChange={handleInputChange}
                prodUnit={batchList?.product?.units}
                page={page}
                limit={limit}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                category={batchList?.product?.type}
                t={t}
              />
              {batchList?.product?.type === "VACCINE" && (
                <p className="Input__label_fs note_fs">
                  {t("add_shipment_fetch_batch_note")}
                </p>
              )}
            </div>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className="InventoryOrders__actions_Popup">
          {selectedRows?.length ? (
            <div className="Quantity__display_space">
              {/* <div className={`AnalyticCard__label pink_label `}>
                <p className="AnalyticCard__label_fs ">
                  {totalQty
                    ? `Total Sending Quantity (Doses) : ${totalQty}`
                    : `Calculating...`}
                </p>
              </div> */}
              <div className="AnalyticCard__label green_label">
                <p className="AnalyticCard__label_fs ">
                  {totalQty
                    ? `${t("total_sending_qty")} : ${totalQty} ${
                        batchList?.product?.units
                      }`
                    : `${t("calculating")}...`}
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="Page__left_actions">
            {totalQty > reqQuantity ? (
              <div className="AnalyticCard__label red_label">
                <p className="AnalyticCard__label_fs ">
                  You cannot send more than the required quantity.
                </p>
              </div>
            ) : (
              <>
                {selectedRows?.length === 0 ? null : (
                  <button
                    type="submit"
                    className={`mi_btn mi_btn_medium  ${
                      isObjectNotEmpty ? "mi_btn_primary" : "mi_btn_disable"
                    }`}
                    onClick={handleBatch}
                    disabled={isObjectNotEmpty ? false : true}
                  >
                    <span>{t("confirm")}</span>
                  </button>
                )}
              </>
            )}
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}

// import React, { useState } from 'react';

// function App() {
//   const initialObject = {
//     "111": "15",
//     "122": "12",
//     "PEN123": "23"
//   };
//   const unit = 10;

//   const [updatedObject, setUpdatedObject] = useState(initialObject);

//   const handleUpdate = () => {
//     const updatedValues = {};
//     for (const key in initialObject) {
//       const originalValue = parseInt(initialObject[key]);
//       updatedValues[key] = (Math.floor(originalValue / unit) * unit).toString();
//     }
//     setUpdatedObject(updatedValues);
//   };

//   return (
//     <div>
//       <button onClick={handleUpdate}>Update Values</button>
//       <pre>{JSON.stringify(updatedObject, null, 2)}</pre>
//     </div>
//   );
// }

// export default App;
